import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CommonService } from '../../service/CommonService';
import { Dropdown } from 'primereact/dropdown';
import CostCenter from '../../components/costcenter/costCenter';
import UploadFile from '../../components/uploadfile/UploadFile';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { Growl } from 'primereact/growl';
import browser from 'browser-detect'
import EmployeeDialog from './EmployeeDialog';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import FileSaver from 'file-saver';
import { getResolution } from '../../../src/utility/commonUtility';
import ReactDOM from 'react-dom';
import leaveCodes from '../../utility/leaveCodes.json';
import EmployeeMasterTemplateDialog from './EmployeeMasterTemplateDialog';

let translator = new LocalizedStrings(translation);

class EmployeeMaster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            deleteButton: true,
            editedStatus: false,
            globalSearch: "",
            showEmployee: {
                active: true,
                inActive: true,
                includedInTime: true,
                notIncludedInTime: true,
                includePomoAccess: true,
                notIncludePomoAccess: true
            },
            jsonTemplate: {
                supervisorId: '',
                costCenterCode: "",
                employeeId: "",
                employeeName: "",
                employeeTypeCode: "",
                empStatus: "",
                timeincludestatus: "",
                pomoAccess:"",
                sortBy: "employeeName",
                globalSearch: "",
                sortDirection: "ASC",
                pageNumber: 0,
                recordCount: 150
            },
            displayDialog: false,
            groupNameArray: '',
            templateClick: false,
            employeeDetailIds: []

        };
        this.commonService = new CommonService();
    }

    componentDidMount = () => {
        this.setState({ loading: true });
        this.commonMethodForLifecycleMethod();
    }

    commonMethodForLifecycleMethod = () => {
        this.commonService.getLastFilterEmployeeMaster(this.props.browserLanguage)
            .then(res => {
                let jsonCopy1 = Object.assign({}, this.state.jsonTemplate);
                if (res.costCenter !== null) {
                    jsonCopy1.costCenterCode = res.costCenter.costCenterCode;

                    this.setState({
                        lastUsedCostCenter: res.costCenter,
                        pageNumber: res.employeePage.number,
                        pageSize: res.employeePage.size,
                        employeeData: res.employeePage.content,
                        totalEmployeeRecords: res.employeePage.totalElements,
                        recordSize: res.employeePage.numberOfElements,
                        loading: false,
                        displayDialog: false
                    })

                    this.setState({ jsonTemplate: jsonCopy1 });
                }
                this.setState({ loading: false, displayDialog: false });
            })
            .catch(err => {
                this.setState({ loading: false, displayDialog: false });
                this.showMultiple(err);
            })
    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {

        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });

    }

    //Function to call API service for GET Employee List.
    getList = (payload) => {

        this.commonService.getEmployeeList(payload, this.props.browserLanguage)
            .then(data => {
                if (data !== undefined && data !== null) {
                    this.setState({
                        pageNumber: data.employeePage.number,
                        pageSize: data.employeePage.size,
                        employeeData: data.employeePage.content,
                        totalEmployeeRecords: data.employeePage.totalElements,
                        recordSize: data.employeePage.numberOfElements,
                        loading: false,
                        displayDialog: false
                    })
                }

                if (this.state.editedStatus) {
                    this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'success', summary: translator.warningMessage, detail: translator.contractUpdatedSuccess });
                    this.setState({ editedStatus: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false, displayDialog: false });
                this.showMultiple(err);
                //Utility.showMultiple(err);
            })
    }

    //Function To get Employee master Excel Tempalte
    getTemplate = () => {
        this.setState({ displayDialog: false, loading: true });
        let filename = "employee.xlsx"
        if (this.props.browserLanguage === "fi") {
            filename = "employee_fi.xlsx"
        }
        this.commonService.getExcelTemplate(this.props.browserLanguage, filename)
            .then(
                response => {
                    let fileName = translator.empTemplateFileName + ".xlsx";
                    var blob = new Blob([response.data], { type: "application/xlsx" });
                    var reader = new FileReader();
                    reader.onloadend = function () {
                        FileSaver.saveAs(blob, fileName);
                    }
                    reader.readAsText(blob);
                    this.setState({ displayDialog: false, loading: false });
                }
            )
            .catch(err => {
                this.setState({ displayDialog: false, loading: false });
                this.showMultiple(err);
            })
    }


    //Function to implement Change Page functionality of Paginator. 
    onPageChange = (e) => {

        if (this.state.employeeData === undefined || this.state.employeeData.length === undefined) {
            return false;
        }

        this.setState({ first: e.first, loading: true, displayDialog: false });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.pageNumber = e.page;
        this.getList(jsonCopy);
    }

    //Function to call API for filtering records
    getFilterFuntion = (jsonCopy) => {
        if (this.state.employeeData === undefined || this.state.employeeData.length === undefined) {
            return false;
        }
        jsonCopy.pageNumber = 0;
        this.setState({
            jsonTemplate: jsonCopy,
            loading: true,
            first: 0,
            displayDialog: false
        });
        this.getList(jsonCopy);
    }



    //Function to implement Filtering functionality on Employee ID. 
    onEmployeeIdFilter = (empId, key) => {
        if ((key === 'Enter' || key === 'Tab') && empId !== "") {
            this.setState({ displayDialog: false })
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeId = empId;
            this.getFilterFuntion(jsonCopy);
        }
    }

    filterCleared = (e, field) => {

        if (e.target.value === "" && field === "employeeId") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeId = "";
            this.setState({ jsonTemplate: jsonCopy, displayDialog: false });
            this.getList(jsonCopy);
        }
        else if (e.target.value === "" && field === "employeeName") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeName = "";
            this.setState({ jsonTemplate: jsonCopy, displayDialog: false });
            this.getList(jsonCopy);
        }
        else if (e.target.value === "" && field === "employeeTypeCode") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeTypeCode = "";
            this.setState({ jsonTemplate: jsonCopy, displayDialog: false });
            this.getList(jsonCopy);
        }
        else if (e.target.value === "" && field === "empGroupNames") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.empGroupNames = "";
            this.setState({ jsonTemplate: jsonCopy, displayDialog: false });
            this.getList(jsonCopy);
        }


    }
    //Function to implement Filtering functionality on Employee Name. 
    onemployeeNameFilter = (empName, key) => {
        if ((key === 'Enter' || key === 'Tab') && empName !== "") {
            this.setState({ displayDialog: false })
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeName = empName;
            this.getFilterFuntion(jsonCopy);
        }
    }

    //Function to implement Filtering functionality on Employee Type Code. 
    onemployeeTypeFilter = (empType, key) => {
        if ((key === 'Enter' || key === 'Tab') && empType !== "") {
            this.setState({ displayDialog: false })
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeTypeCode = empType;
            this.getFilterFuntion(jsonCopy);
        }
    }

    //Function to implement Filtering functionality on Group Name. 
    onemployeeGroupFilter = (empGroupNames, key) => {
        if ((key === 'Enter' || key === 'Tab') && empGroupNames !== "") {
            this.setState({ displayDialog: false })
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.empGroupNames = empGroupNames;
            this.getFilterFuntion(jsonCopy);
        }
    }

    //Function to implement Sorting functionality. 
    onSortChange = (e) => {

        if (this.state.employeeData === undefined || this.state.employeeData.length === undefined) {
            return false;
        }
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true, displayDialog: false, first: 0 });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.sortDirection = 'ASC';
        jsonCopy.sortBy = e.sortField;

        if (e.sortOrder === -1) {
            jsonCopy.sortDirection = "DESC"
        }

        this.getList(jsonCopy);
        this.setState({ jsonTemplate: jsonCopy });
    }

    //Function to set default filters
    clearFilterControl = () => {
        if (document.getElementById('employeeId')) {
            document.getElementById('employeeId').value = ''
        }
        if (document.getElementById('employeeName')) {
            document.getElementById('employeeName').value = ''
        }
        if (document.getElementById('employeeTypeCode')) {
            document.getElementById('employeeTypeCode').value = ''
        }
        if (document.getElementById('empGroupNames')) {
            document.getElementById('empGroupNames').value = ''
        }

    }
    //Function to implement functionality for cost center dropdown selection. 
    onCostCenterSelect = (data) => {
        if (this.growl !== undefined && this.growl !== null) {
            this.growl.clear();
        }
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        this.clearFilterControl();
        this.setState({
            displayDialog: false
        });
        if (data === '') {
            jsonCopy.costCenterCode = '';
            this.setState({
                employeeData: [],
                totalEmployeeRecords: undefined,
                jsonTemplate: jsonCopy
            });
            ReactDOM.unmountComponentAtNode(document.getElementById('costCenter'));
            ReactDOM.render(<CostCenter onChange={this.onCostCenterSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />, document.getElementById('costCenter'))
            return false;
        }

        jsonCopy.costCenterCode = data;
        jsonCopy.employeeName = '';
        jsonCopy.employeeId = '';
        jsonCopy.employeeTypeCode = '';
        jsonCopy.timeincludestatus = '';
        jsonCopy.empStatus = '';
        jsonCopy.pomoAccess = '';
        jsonCopy.globalSearch = '';
        jsonCopy.pageNumber = 0;
        this.setState({
            displayDialog: false,
            loading: true,
            jsonTemplate: jsonCopy,
            sortField: '',
            sortOrder: null,
            first: 0,
            showEmployee: {
                active: true,
                inActive: true,
                includedInTime: true,
                notIncludedInTime: true,
                includePomoAccess: true,
                notIncludePomoAccess: true
            }
        });

        this.getList(jsonCopy);
    }

    //Function to implement functionality of Number of Record Dropdown change of Pagination. 
    noOfRecordChange = (e) => {

        this.setState({
            displayDialog: false,
            dropdownDisplay: e.value,
            loading: true,
            first: 0
        })
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.recordCount = e.value.code;
        jsonCopy.pageNumber = 0;

        if (this.state.employeeData === undefined || this.state.employeeData.length === undefined) {
            this.setState({ loading: false });
            this.setState({ jsonTemplate: jsonCopy });
            return false;
        }

        this.getList(jsonCopy);
        this.setState({ jsonTemplate: jsonCopy });
    }

    //Function to implement functionality of Upload Excel (Callback from Upload Component). 
    onUploadFile = (failed) => {
        if (failed === 'failed') {
            this.setState({ loading: false, displayDialog: false });
            return false;
        }
        this.setState({ loading: true, displayDialog: false });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        if (jsonCopy.costCenterCode !== "") {
            this.getList(jsonCopy);
        }
        this.setState({ loading: false, jsonTemplate: jsonCopy, displayDialog: false });
    }

    //Function to implement functionality of Editing on any Row Selection. 
    onRowSelect = (e) => {
        this.commonService.getEmployee(e.data.employeeDetailsId, this.props.browserLanguage)
            .then(res => {
                // To remove employeeTypeEnglish property from object
                let employeeDropNew = Object.assign({}, res.data.wfmTEmployeeType);
                let costCentershow = this.props.masterData.costCenter.filter(data => data.costCenterCode === this.state.jsonTemplate.costCenterCode);
                delete employeeDropNew.employeeTypeEnglish;
                this.setState({
                    costCenterDisplay: this.props.masterData.costCenter,
                    isAdmin: this.props.masterData.admin,
                    employeeTypeDrop: this.props.masterData.employeeType,
                    wageTypeDrop: this.props.masterData.wagesType,
                    displayDialog: true,
                    deleteButton: true,
                    employeeEditData: Object.assign({}, res.data),
                    employeeTypeCodeShow: employeeDropNew,
                    masterCostCenterCode: costCentershow[0],
                    costCenterShow: {
                        costCenterMasterId: res.data.wfmTCostCenterMaster.costCenterMasterId,
                        costCenterName: res.data.wfmTCostCenterMaster.costCenterName,
                        costCenterCode: res.data.wfmTCostCenterMaster.costCenterCode,
                        //areaCode: res.data.wfmTCostCenterMaster.wfmTAreaMaster.areaCode,
                        //areaName: res.data.wfmTCostCenterMaster.wfmTAreaMaster.areaName,
                        value: res.data.wfmTCostCenterMaster.value
                    },
                    wageTypeCodeShow: res.data.wfmTWagesType,
                    contractDetailShow: res.data.empContractDetails,
                    empActive: res.data.empActive
                });
            }).catch(err => {
                // this.setState({loading:false});
                this.showMultiple(err);
            })
    }


    //Function to implement functionality of Add New Employee.
    addNew = () => {
        let costCentershow = this.props.masterData.costCenter.filter(data => data.costCenterCode === this.state.jsonTemplate.costCenterCode);
        this.setState({
            costCenterDisplay: this.props.masterData.costCenter,
            isAdmin: this.props.masterData.admin,
            employeeTypeDrop: this.props.masterData.employeeType,
            wageTypeDrop: this.props.masterData.wagesType,
            employeeEditData: {
                deactivationDate: '',
                employeeDetailsId: 0,
                employeeId: '',
                employeeName: '',
                remainingVacations: '',
                remainingFlexLeaves: '',
                savedLeaves: '',
                wagesType: "",
                empContractDetails: [],
                empGroupDetails: [],
                empGroupNames: [],
                contractBlockModified: false,
                includedInTime: 'Y',
                inactive: 'N',
                pomoAccess: 'Y'
            },
            contractDetailShow: [],
            costCenterShow: {},
            masterCostCenterCode: costCentershow[0],
            employeeTypeCodeShow: {},
            wageTypeCodeShow: {},
            empActive: true,
            deleteButton: false,
            displayDialog: true
        });
    }

    //Function to implement functionality of Wrapping Long Text With "...".
    nameTemplate = (rowData, column) => {

        if (column.field === 'wfmTCostCenterMaster.costCenterName') {
            return <div className="extraTextWrapping" title={rowData.wfmTCostCenterMaster.costCenterName}>{rowData.wfmTCostCenterMaster.costCenterName}</div>;
        }
        else {
            return <div className="extraTextWrapping" title={rowData[column.field]}>{rowData[column.field]}</div>;
        }

    }


    wageTemplate = (rowData, column) => {
        if (column.field === 'wfmTWagesType.wagesTypeName') {
            return <div className="extraTextWrapping" title={rowData.wfmTWagesType.wagesTypeName}>{rowData.wfmTWagesType.wagesTypeName}</div>;
        }
        else {
            return <div className="extraTextWrapping" title={rowData[column.field]}>{rowData[column.field]}</div>;
        }
    }

    //Function to display comma seprated group name
    separateGroup = (rowData, column) => {
        let groupNameArray = rowData.empGroupNames.join();
        return <div className="extraTextWrapping" title={groupNameArray}>{groupNameArray}</div>;
    }

    changeFormatHoursWeek = (data, part) => {
        if (data.length === 1 && part === "1") {
            return "0" + String(data);
        }
        else if (data.length === 1 && part === "2") {
            return String(data) + "0";
        }
        else return data;
    }

    //Function to display hours per week data in corresponding locale format
    hrsPerWeekTemplate = (rowData, column) => {

        if (rowData[column.field] !== null) {
            let data = String(rowData[column.field]);
            let content = data.split('.');
            if (content[1] !== undefined) {
                let hoursPerWeek = this.changeFormatHoursWeek(content[0], "1") + ":" + this.changeFormatHoursWeek(content[1], "2");
                return <div className="extraTextWrapping" title={hoursPerWeek}>{hoursPerWeek}</div>;
            }
            else {
                return <div className="extraTextWrapping" title={rowData[column.field]}>{rowData[column.field]}</div>;
            }

        }
        else {
            return <div className="extraTextWrapping" title={rowData[column.field]}>{rowData[column.field]}</div>;
        }

    }

    //Function called when callback is received from Dialog.
    callbackFromDialog = (employeeData, contractEditedStatus) => {
        this.setState({ displayDialog: false, loading: true, editedStatus: contractEditedStatus })
        this.getList(this.state.jsonTemplate);
    }

    //Function called when callback is received from Dialog on onHide event
    callbackToHideDialog = () => {
        this.setState({ displayDialog: false, templateClick: false });
    }

    employeeIdTemplate = (rowData) => {
        if (rowData['wfmTEmployeeType'].employeeType !== translator.dummy && rowData.employeeId > 0) {
            return (<div>{rowData.employeeId}</div>)
        }
    }

    changeToColonFormat = (data) => {
        let data1 = String(data);
        let content = data1.split('.');
        let newContent = content[0] + ":" + content[1]
        return newContent;
    }

    changeToDotFormat = (data) => {
        let data1 = String(data);
        let content = data1.split(':');
        let newContent = content[0] + "." + content[1]
        return newContent;
    }

    globalFilter = (value) => {
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);

        if (value === "") {
            jsonCopy.globalSearch = "";
            this.setState({ jsonTemplate: jsonCopy, loading: true, displayDialog: false });
            this.getList(jsonCopy)
        }

        else {
            jsonCopy.employeeId = "";
            jsonCopy.employeeName = "";
            jsonCopy.employeeTypeCode = "";
            jsonCopy.empGroupNames = "";
            jsonCopy.globalSearch = value;
            document.getElementById("employeeId").value = "";
            document.getElementById("employeeName").value = "";
            document.getElementById("employeeTypeCode").value = "";
            document.getElementById("empGroupNames").value = "";
            this.setState({ jsonTemplate: jsonCopy, displayDialog: false })
        }

    }

    setGlobalSearch = (key) => {
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        if (key === "Enter" || key === "Tab") {
            this.getFilterFuntion(jsonCopy);
        }
    }

    statusTemplate = (rowData, column) => {
        if (rowData.inactive === 'N') {
            return (<div style={{ marginLeft: '38%', color: 'gray' }}><i className="pi pi-check" /></div>)
        }
    }

    timeStatusTemplate = (rowData, column) => {
        if (rowData.includedInTime === 'Y') {
            return (<div style={{ marginLeft: '38%', color: 'gray' }}><i className="pi pi-check" /></div>)
        }
    }

    // pomoAccessTemplate = (rowData, column) => {
    //     if (rowData.pomoAccess === 'Y') {
    //         return (<div style={{ marginLeft: '38%', color: 'gray' }}><i className="pi pi-check" /></div>)
    //     }
    // }

    statusChanged = (property) => {
        let showStatus = Object.assign({}, this.state.showEmployee);
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        if (property === "Active") {

            if (showStatus.active === false && showStatus.inActive === true) {
                showStatus.active = true;
                jsonCopy.empStatus = "all";
            }
            else if (showStatus.active === true && showStatus.inActive === false) {
                showStatus.active = false;
                jsonCopy.empStatus = "all";
            }
            else if (showStatus.active === false) {

                showStatus.active = true;
                jsonCopy.empStatus = "active";
            }
            else if (showStatus.active === true) {
                showStatus.active = false;
                jsonCopy.empStatus = "inactive";
            }
            this.setState({ showEmployee: showStatus, jsonTemplate: jsonCopy })
        }

        else if (property === "InActive") {
            if (showStatus.inActive === false && showStatus.active === true) {
                showStatus.inActive = true;
                jsonCopy.empStatus = "all";
            }
            else if (showStatus.inActive === true && showStatus.active === false) {
                showStatus.inActive = false;
                jsonCopy.empStatus = "all";
            }
            else if (showStatus.inActive === false) {
                showStatus.inActive = true;
                jsonCopy.empStatus = "inactive";
            }
            else if (showStatus.inActive === true) {
                showStatus.inActive = false;
                jsonCopy.empStatus = "active";
            }
            this.setState({ showEmployee: showStatus, jsonTemplate: jsonCopy })
        }
        this.getFilterFuntion(jsonCopy);
    }

    timeStatusChanged = (property) => {
        let showStatus = Object.assign({}, this.state.showEmployee);
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        if (property === "included") {

            if (showStatus.includedInTime === false && showStatus.notIncludedInTime === true) {
                showStatus.includedInTime = true;
                jsonCopy.timeincludestatus = "all";
            }
            else if (showStatus.includedInTime === true && showStatus.notIncludedInTime === false) {
                showStatus.includedInTime = false;
                jsonCopy.timeincludestatus = "all";
            }
            else if (showStatus.includedInTime === false) {
                showStatus.includedInTime = true;
                jsonCopy.timeincludestatus = "y";
            }
            else if (showStatus.includedInTime === true) {
                showStatus.includedInTime = false;
                jsonCopy.timeincludestatus = "n";
            }
            this.setState({ showEmployee: showStatus, jsonTemplate: jsonCopy })
        }

        else if (property === "notIncluded") {
            if (showStatus.notIncludedInTime === false && showStatus.includedInTime === true) {
                showStatus.notIncludedInTime = true;
                jsonCopy.timeincludestatus = "all";
            }
            else if (showStatus.notIncludedInTime === true && showStatus.includedInTime === false) {
                showStatus.notIncludedInTime = false;
                jsonCopy.timeincludestatus = "all";
            }
            else if (showStatus.notIncludedInTime === false) {
                showStatus.notIncludedInTime = true;
                jsonCopy.timeincludestatus = "n";
            }
            else if (showStatus.notIncludedInTime === true) {
                showStatus.notIncludedInTime = false;
                jsonCopy.timeincludestatus = "y";
            }
            this.setState({ showEmployee: showStatus, jsonTemplate: jsonCopy })
        }
        this.getFilterFuntion(jsonCopy);
    }

    // pomoAccessChanged = (property) => {
    //     let showStatus = Object.assign({}, this.state.showEmployee);
    //     let jsonCopy = Object.assign({}, this.state.jsonTemplate);
    //     if (property === "included") {

    //         if (showStatus.includePomoAccess === false && showStatus.notIncludePomoAccess === true) {
    //             showStatus.includePomoAccess = true;
    //             jsonCopy.pomoAccess = "all";
    //         }
    //         else if (showStatus.includePomoAccess === true && showStatus.notIncludePomoAccess === false) {
    //             showStatus.includePomoAccess = false;
    //             jsonCopy.pomoAccess = "all";
    //         }
    //         else if (showStatus.includePomoAccess === false) {
    //             showStatus.includePomoAccess = true;
    //             jsonCopy.pomoAccess = "y";
    //         }
    //         else if (showStatus.includePomoAccess === true) {
    //             showStatus.includePomoAccess = false;
    //             jsonCopy.pomoAccess = "n";
    //         }
    //         this.setState({ showEmployee: showStatus, jsonTemplate: jsonCopy })
    //     }

    //     else if (property === "notIncluded") {
    //         if (showStatus.notIncludePomoAccess === false && showStatus.includePomoAccess === true) {
    //             showStatus.notIncludePomoAccess = true;
    //             jsonCopy.pomoAccess = "all";
    //         }
    //         else if (showStatus.notIncludePomoAccess === true && showStatus.includePomoAccess === false) {
    //             showStatus.notIncludePomoAccess = false;
    //             jsonCopy.pomoAccess = "all";
    //         }
    //         else if (showStatus.notIncludePomoAccess === false) {
    //             showStatus.notIncludePomoAccess = true;
    //             jsonCopy.pomoAccess = "n";
    //         }
    //         else if (showStatus.notIncludePomoAccess === true) {
    //             showStatus.notIncludePomoAccess = false;
    //             jsonCopy.pomoAccess = "y";
    //         }
    //         this.setState({ showEmployee: showStatus, jsonTemplate: jsonCopy })
    //     }
    //     this.getFilterFuntion(jsonCopy);
    // }

    //Function to filter top 100 Ids to Get Template with Data
    filterEmployeeId = () => {
        this.setState({ templateClick: true, displayDialog: false })
        let data = this.state.employeeData;
        let filterData = [];
        if (data.length > 100) {
            this.growl.show({  life: leaveCodes.growlMessageTimeout,severity: 'info', summary: translator.templateSummary, detail: translator.templateInfo });
            data.slice(0, 100).forEach(value => {
                filterData.push(value.employeeDetailsId);
            });
        }
        else {
            data.forEach(v => {
                filterData.push(v.employeeDetailsId);
            });
        }
        this.setState({ employeeDetailIds: filterData });

    }

    render() {

        translator.setLanguage(this.props.browserLanguage);

        const noOfRecord = [
            { name: translator.Records_150, code: 150 },
            { name: translator.Records_200, code: 200 },
            { name: translator.Records_250, code: 250 }
        ];

        let employeeIdFilter = <InputText maxLength="8" id="employeeId" onChange={(e) => this.filterCleared(e, "employeeId")} keyfilter={/^[0-9\b]+$/} className="employeeIdFilterWidth" onKeyDown={(e) => this.onEmployeeIdFilter(e.target.value, e.key)} disabled={this.state.jsonTemplate.globalSearch !== "" ? true : false} />
        let employeeNameFilter = <InputText type="text" id="employeeName" onChange={(e) => this.filterCleared(e, "employeeName")} className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeNameFilter(e.target.value, e.key)} disabled={this.state.jsonTemplate.globalSearch !== "" ? true : false} />
        let employeeTypeFilter = <InputText type="text" id="employeeTypeCode" onChange={(e) => this.filterCleared(e, "employeeTypeCode")} className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeTypeFilter(e.target.value, e.key)} disabled={this.state.jsonTemplate.globalSearch !== "" ? true : false} />
        let groupNameFilter = <InputText type="text" id="empGroupNames" onChange={(e) => this.filterCleared(e, "empGroupNames")} className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeGroupFilter(e.target.value, e.key)} disabled={this.state.jsonTemplate.globalSearch !== "" ? true : false} />
        let statusFilter = <div>
            <span onClick={(e) => { this.statusChanged("Active") }}><i style={{ color: this.state.showEmployee.active === true ? "#ff751a" : "gray" }} className="pi pi-check" /></span>
            <span onClick={(e) => { this.statusChanged("InActive") }}><i className="pi pi-times" style={{ color: this.state.showEmployee.inActive === true ? "#ff751a" : "gray" }} /></span>
        </div>

        let timeStatusFilter = <div>
            <span onClick={(e) => { this.timeStatusChanged("included") }}><i style={{ color: this.state.showEmployee.includedInTime === true ? "#ff751a" : "gray" }} className="pi pi-check" /></span>
            <span onClick={(e) => { this.timeStatusChanged("notIncluded") }}><i className="pi pi-times" style={{ color: this.state.showEmployee.notIncludedInTime === true ? "#ff751a" : "gray" }} /></span>
        </div>

        // let pomoAccessFilter = <div>
        //     <span onClick={(e) => { this.pomoAccessChanged("included") }}><i style={{ color: this.state.showEmployee.includePomoAccess === true ? "#ff751a" : "gray" }} className="pi pi-check" /></span>
        //     <span onClick={(e) => { this.pomoAccessChanged("notIncluded") }}><i className="pi pi-times" style={{ color: this.state.showEmployee.notIncludePomoAccess === true ? "#ff751a" : "gray" }} /></span>
        // </div>
        return (

            <div className="bodyColor">
                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>
                {this.state.templateClick ?
                    <EmployeeMasterTemplateDialog
                        handleTemplateClickCallback={this.callbackToHideDialog}
                        filterData={this.state.employeeDetailIds}
                    />
                    : null}

                {this.state.displayDialog ?
                    <EmployeeDialog browserLanguage={this.props.browserLanguage}
                        displayDialog={true}
                        onChange={this.callbackFromDialog}
                        wagesType={this.state.wageTypeDrop}
                        deleteButton={this.state.deleteButton}
                        jsonTemplate={this.state.jsonTemplate}
                        costCenterShow={this.state.costCenterShow}
                        wageTypeCodeShow={this.state.wageTypeCodeShow}
                        employeeTypeDrop={this.state.employeeTypeDrop}
                        costCenterDisplay={this.state.costCenterDisplay}
                        masterCostCenterCode={this.state.masterCostCenterCode}
                        employeeTypeCodeShow={this.state.employeeTypeCodeShow}
                        employeeEditData={this.state.employeeEditData}
                        contractDetailData={this.state.contractDetailShow}
                        callbackPropToHideDialog={this.callbackToHideDialog}
                        empActive={this.state.empActive} />
                    : null
                }

                <Growl ref={(el) => this.growl = el} position="topright" ></Growl>

                <BreadCrumbs initial={translator.master} final={translator.employee} browserLanguage={this.props.browserLanguage} />

                <div>
                    <div className="costcenterPosition" id="costCenter">
                        <CostCenter lastUsedCostCenter={this.state.lastUsedCostCenter} onChange={this.onCostCenterSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} disabled={this.state.jsonTemplate.globalSearch === "" ? false : true} />
                    </div>

                    <div className="buttonsPostion">
                        <table>
                            <tbody>
                                <tr>
                                    <td>

                                    </td>
                                    <td className="btn-blank">
                                        {this.props.masterData.admin ?
                                            <span className="p-inputgroup" style={{ marginTop: '1%', float: 'right' }}>
                                                <InputText placeholder={translator.globalSearchKeyword}
                                                    style={{ width: '250px', height: '32px', padding: '2%', marginRight: '2%' }}
                                                    value={this.state.jsonTemplate.globalSearch}
                                                    onChange={(e) => { this.globalFilter(e.target.value) }}
                                                    onKeyDown={(e) => { this.setGlobalSearch(e.key) }} />
                                            </span> : null}
                                    </td>
                                    <td>
                                        {/* {(this.props.masterData === null ? false : this.props.masterData.admin) ?                                      */}
                                        <Button label={translator.getTemplate} title={translator.getTemplate} icon="pi pi-download" className="btnDefault"
                                            // onClick={() => this.setState({ templateClick: true })}
                                            onClick={this.filterEmployeeId}
                                            style={{ marginRight: '.25em' }} />
                                        {/* :null} */}
                                    </td>
                                    <td>
                                        {/* {(this.props.masterData === null ? false : this.props.masterData.admin) ?  */}
                                        <UploadFile onChange={this.onUploadFile} browserLanguage={this.props.browserLanguage} uploadType="EMPLOYEE" />
                                        {/* :null} */}
                                    </td>
                                    <td><Button className="addbuttonEmp" title={translator.addBtn} label={translator.addBtn} icon="pi pi-plus" onClick={this.addNew} style={{ marginRight: '.25em' }} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* <div style={{ marginBottom: '20px', marginLeft: '1.7%' }}>
                    <span style={{ float: 'left' }}> <Checkbox onChange={e => this.setGlobalSearch(e.checked)} checked={this.state.globalSearch} />Global Search </span>
                </div> */}

                {
                    this.state.jsonTemplate.costCenterCode !== '' ?
                        this.state.totalEmployeeRecords === 0 && (document.getElementById('employeeId') !== null ? (document.getElementById('employeeId').value === '' && document.getElementById('employeeName').value === '' && document.getElementById('employeeTypeCode').value === '') && this.state.loading === false : true) ?
                            null :
                            <div className="paginatorDropdownPosition" >
                                <span className="recordOF">{this.state.totalEmployeeRecords === undefined || this.state.totalEmployeeRecords === 0 ? '0-0' : (this.state.pageNumber * this.state.pageSize) + 1 + '-' + Number((this.state.pageNumber * this.state.pageSize) + Number(this.state.recordSize))} {translator.of} {this.state.totalEmployeeRecords === undefined ? 0 : this.state.totalEmployeeRecords} {translator.records}</span>
                                <div className="paginatorEmp">
                                    <Paginator rows={this.state.jsonTemplate.recordCount} totalRecords={this.state.totalEmployeeRecords === undefined ? 0 : this.state.totalEmployeeRecords} first={this.state.first} onPageChange={(e) => this.onPageChange(e)}></Paginator>
                                    <Dropdown value={this.state.dropdownDisplay} className="dropDwonDefault colRecord dropmargin" options={noOfRecord} onChange={(e) => this.noOfRecordChange(e)} placeholder={translator.Records_150} optionLabel="name" />
                                </div>
                            </div>
                        : null
                }

                <br />
                {/* {
                    this.state.jsonTemplate.costCenterCode !== '' ?
                        this.state.totalEmployeeRecords === 0 && (document.getElementById('employeeId') !== null ? (document.getElementById('employeeId').value === '' && document.getElementById('employeeName').value === '' && document.getElementById('employeeTypeCode').value === '') && this.state.loading === false : true) ?
                            // <h4 className="noRecordFoundMessage">{translator.noRecordFound}</h4> 
                            : */}
                {this.state.employeeData !== undefined ?
                    <DataTable
                        scrollable={browser().name === 'ie' ? false : true}
                        resizableColumns={true}
                        ref={(el) => this.dt = el}
                        editable={true}
                        value={this.state.employeeData}
                        selectionMode="multiple"
                        rows={this.state.pageSize}
                        onSort={(e) => this.onSortChange(e)}
                        scrollHeight={getResolution()}
                        className="dataTable"
                        frozenWidth="0px"
                        onRowSelect={(e) => this.onRowSelect(e)}
                        sortField={this.state.sortField}
                        sortOrder={this.state.sortOrder}
                        emptyMessage={translator.noRecordFound}

                    >
                        <Column field="wfmTCostCenterMaster.costCenterName" header={translator.cc} className="textLeftAlign extraTextWrapping" body={this.nameTemplate} style={{ width: '120px' }} />
                        <Column field="employeeName" id="employeeName" header={translator.employeeName} sortable={true} filter={true} filterElement={employeeNameFilter} className="textLeftAlign datatableHeaderWidth132" body={this.nameTemplate} />
                        <Column field="wfmTEmployeeType.employeeType" id="employeeType" header={translator.employeeTypeCode} sortable={true} filter={true} filterElement={employeeTypeFilter} className="textLeftAlign datatableHeaderWidth132" />
                        <Column field="employeeId" header={translator.employeeId} sortable={true} filter={true} filterElement={employeeIdFilter} className="numberRightAlign datatableHeaderWidth132" body={this.employeeIdTemplate} />
                        <Column field="empGroupNames" id="empGroupNames" header={translator.groupName} filter={true} filterElement={groupNameFilter} className="textLeftAlign" body={this.separateGroup} style={{ width: '112px' }} />
                        <Column field="wfmTWagesType.wagesTypeName" header={translator.wagesType} sortable={true} className="textLeftAlign " body={this.wageTemplate} style={{ width: '87px' }} />
                        <Column field="hrsPerWeek" header={translator.hoursPerWeekly} className="numberRightAlign datatableHeaderWidth85" body={this.hrsPerWeekTemplate} />
                        <Column field="remainingVacations" header={translator.remainingVacations} sortable={true} className="numberRightAlign datatableHeaderWidth105" />
                        <Column field="remainingFlexLeaves" header={translator.remainingFlexible} sortable={true} className="numberRightAlign datatableHeaderWidth105" />
                        <Column field="savedLeaves" header={translator.savedLeaves} sortable={true} className="numberRightAlign " style={{ width: '114px' }} />
                        <Column field="" header={translator.status} className="datatableHeaderWidth55" filterElement={statusFilter} filter={true} body={this.statusTemplate} />
                        <Column field="" header={translator.includeInTime} filterElement={timeStatusFilter} filter={true} body={this.timeStatusTemplate} style={{ width: '65px' }} />
                        {/* <Column field="" header={translator.pomoAccess} filterElement={pomoAccessFilter} filter={true} body={this.pomoAccessTemplate} style={{ width: '65px' }} /> */}
                    </DataTable>
                    : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        masterData: state.masterData,
        browserLanguage: state.browserLanguage
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeMaster); 