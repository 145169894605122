import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { ProgressSpinner } from 'primereact/progressspinner';
import { CommonService } from '../../service/CommonService';
import { Growl } from 'primereact/growl';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import leaveCodes from '../../utility/leaveCodes.json';
import TransferConfirmation from './TransferConfirmation';

let translator = new LocalizedStrings(translation);
let keepEmpDialogCompOpen = false;

class TransferDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            costCenterSelected: null,
            displayConfirmDialog: false,
            loading: false
        }

        this.commonService = new CommonService();
    }

    componentDidMount = () => {

        this.setState({
            displayDialog: this.props.displayDialog,
            transferJSONTemplate: this.props.transferJSONTemplate,
        })

    }

    updateCostCenterMasterId = () => {
        let originalCostCenter = this.props.masterData.costCenter.filter(record => record.costCenterCode === this.props.transferJSONTemplate.existingCCId);
        let cMasterId = originalCostCenter[0].costCenterMasterId;
        return cMasterId

    }

    //Function to call calbackprop and hide this dialog
    callcallbackToHideDialog = () => {
        // this.props.callbackPropToHideDialog();
        if (this.state.displayConfirmDialog) {
            this.setState({ displayDialog: false })
        }
        else {
            this.props.callbackPropToHideDialog();
        }

    }

    //Template for Cost Center Dropdown display.
    costCenterTemplate = (option) => {
        return (
            <div className="dvcostCenter">
                <Table className="costCenterTable table-striped">
                    <tbody>
                        <tr>
                            <td className="costCenterCode">{option.costCenterCode}</td>
                            <td className="costCenterName">{option.costCenterName}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>

        );
    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {
        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life: leaveCodes.growlMessageTimeout, summary: translator.errorMessage, detail: detail, severity: "error" });
    }

    updateProperty = (property, value) => {
        if (property === "costCenterSelected") {
            let transferJsonCopy = Object.assign({}, this.state.transferJSONTemplate);
            if (value !== null) {
                transferJsonCopy.existingCCId = this.updateCostCenterMasterId();
                transferJsonCopy.targetCCId = value.costCenterMasterId;
            }
            else if (value === null) {
                transferJsonCopy.targetCCId = value;
            }

            this.setState({
                costCenterSelected: value,
                transferJSONTemplate: transferJsonCopy
            });
        }

        else if (property === "mergeAllocation") {
            let transferJsonCopy = Object.assign({}, this.state.transferJSONTemplate);
            transferJsonCopy.mergeAllocation = value;
            transferJsonCopy.mergeShift = value;
            this.setState({ transferJSONTemplate: transferJsonCopy });
        }

        else if (property === "mergeShift") {
            let transferJsonCopy = Object.assign({}, this.state.transferJSONTemplate);
            if (value === false) {
                transferJsonCopy.mergeAllocation = value;
                transferJsonCopy.mergeShift = value;
                this.growl.show({ life: leaveCodes.growlMessageTimeout, summary: translator.warningMessage, detail: translator.mergeTransferError, severity: "warn" });
            }
            else {
                transferJsonCopy.mergeShift = value;
            }
            this.setState({ transferJSONTemplate: transferJsonCopy });
        }
    }

    handleSubmit = () => {
        keepEmpDialogCompOpen = false;
        this.setState({
            displayDialog: false,
            displayConfirmDialog: true

        });
    }

    showTransferDialog = () => {
        keepEmpDialogCompOpen = false;
        this.setState({ displayConfirmDialog: false, displayDialog: true });
    }

    callbackSaveService = () => {
        this.setState({ displayConfirmDialog: false, loading: true });
        this.commonService.saveTransferEmployee(this.state.transferJSONTemplate, this.props.browserLanguage).then(respose => {
            this.props.onChange();
        }).catch(error => {
            this.showMultiple(error);
            this.setState({loading:false})
        })
    }

    render() {

        translator.setLanguage(this.props.browserLanguage);

        let dropDownOption = this.props.masterData.costCenter;
        if (this.props.transferJSONTemplate !== undefined) {
            dropDownOption = this.props.masterData.costCenter.filter(record => record.costCenterCode !== this.props.transferJSONTemplate.existingCCId);
        }

        let dialogFooter = (<div className="ui-dialog-buttonpane p-clearfix">
            <Button label={translator.save} icon="pi pi-check" className="deleteButton" onClick={this.handleSubmit} disabled={this.state.costCenterSelected === null ? true : false} />
        </div>);

        return (
            <React.Fragment>
                <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>

                {this.state.displayConfirmDialog ? <TransferConfirmation visible={true} callbackSaveService={this.callbackSaveService} lang={this.props.browserLanguage} callbackProp={this.showTransferDialog} /> : null}

                <Dialog visible={this.state.displayDialog} className="transferDialogBoxPosition" modal={true} header={translator.transferDialogHeader} footer={dialogFooter} onHide={keepEmpDialogCompOpen ? () => { } : this.callcallbackToHideDialog}>
                    {
                        this.state.transferJSONTemplate &&
                        <div>
                            <Row className="padding7pxBottom">
                                <Col>
                                    <label >{translator.targetCostCenter}</label>
                                </Col>
                                <Col>
                                    <Dropdown value={this.state.costCenterSelected}
                                        options={this.props.masterData === null ? null : dropDownOption}
                                        onChange={(e) => this.updateProperty("costCenterSelected", e.value)}
                                        placeholder={translator.selectCostCenter}
                                        itemTemplate={this.costCenterTemplate}
                                        optionLabel="value"
                                        filter={true}
                                        filterPlaceholder={translator.searchCostCenter}
                                        filterBy="label,value"
                                        className="costcenterdrop"
                                        showClear={true} />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <label>{translator.mergeAllocation}</label>
                                    <Checkbox id="mergeAllocation" onChange={(e) => { this.updateProperty("mergeAllocation", e.checked) }} checked={this.state.transferJSONTemplate.mergeAllocation}></Checkbox>
                                </Col>

                                <Col>
                                    <label>{translator.mergeShift}</label>
                                    <Checkbox id="mergeShift" onChange={(e) => { this.updateProperty("mergeShift", e.checked) }} checked={this.state.transferJSONTemplate.mergeShift}></Checkbox>
                                </Col>

                            </Row>

                        </div>
                    }
                </Dialog>

            </React.Fragment>
        );
    }
}

export default TransferDialog;