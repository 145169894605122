import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import PlanningPeriod from '../../components/planningperiod/planningperiod';
import AutoGeneratedIDropDown from '../../components/autoGenerateDropDown/AutoGeneratedIDropDown';
import { CommonService } from '../../service/CommonService';
import { ColumnGroup } from 'primereact/columngroup';
import { InputText } from 'primereact/inputtext';
import { Row } from 'primereact/row';
import leaveCodes from '../../utility/leaveCodes.json';
import './ShiftAllocation.css';
import Breadcrumbs from '../../components/breadcrumbs/BreadCrumbs';
import CostCenter from '../../components/costcenter/costCenter';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import { Paginator } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import browser from 'browser-detect';
import { keyboardValues, getResolutionAutoGeneratedShift } from "../../utility/commonUtility";
import AdvanceFilterShiftAllocation from './AdvanceFilterShiftAllocation';
import AdvanceFilterCopyShift from './AdvanceFilterCopyShift';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import ConfirmationDialog from './ConfirmationDialog';
import { Checkbox } from 'primereact/checkbox';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';

let translator = new LocalizedStrings(translation);
let removeClicked = false;
let leaveCodesArray = ["VL", "VL2", "PO", "JV", "TV", "P", "AY", "SV", "L", "R", "PR", "C", "H", "M"];
let reservedCodesArray = ["L", "R", "PR", "C", "H", "M"];

class AutoGeneratedShift extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shiftdata: null,
            redirect: false,
            copyShift: false,
            aiShift: false,
            week1: false,
            week2: false,
            week3: false,
            week4: false,
            checkedDates: [], //check which dates are checked
            dates: [],
            dateString: [],
            shiftWeeks: [],
            noOfRecords: 150,
            language: this.props.browserLanguage,
            loading: false,
            pageSize: 0,
            validShift: false,
            removefilterdisable: true,
            CumulativeDialog: false,
            displayShiftCnfDialog: false,
            jsonTemplate: {
                aiIntegrationLogId: 0,
                planningPeriodID: "",
                centerMasterID: null,
                exceptionWeeks: [],
                exceptionDates: [], //array of dates checked for getting data
                employeeIds: [],
                employeeGroupName: [],
                employeeSearch: {
                    supervisorId: "",
                    costCenterCode: "",
                    employeeId: "",
                    employeeName: "",
                    employeeTypeCode: "",
                    sortBy: "employeeName",
                    sortDirection: "ASC",
                    pageNumber: 0,
                    recordCount: 150,
                    shiftCodeList: ""
                }
            },
            viewProposedShiftPlan: {
                aiIntegrationLogId: 0,
                exceptionWeeks: [],
                exceptionDates: [],
                employeeIds: [],
                employeeGroupName: [],
                acceptAllocation: false,
                employeeSearch: {
                    supervisorId: "",
                    costCenterCode: "1",
                    employeeId: "",
                    employeeName: "",
                    employeeTypeCode: "",
                    sortBy: "employeeName",
                    sortDirection: "ASC",
                    pageNumber: 0,
                    recordCount: 150,
                    shiftCodeList: ""
                }
            },
            copyJSONTemplate: {
                currentPlanningPeriodID: "",
                centerMasterID: null,
                exceptionWeeks: [],
                exceptionDates: [],
                employeeIds: [],
                acceptAllocation: false,
                copyFromLogId: null,
                employeeGroupName: [],
                employeeSearch: {
                    supervisorId: "",
                    costCenterCode: "1",
                    employeeId: "",
                    employeeName: "",
                    employeeTypeCode: "",
                    sortBy: "employeeName",
                    sortDirection: "ASC",
                    pageNumber: 0,
                    recordCount: 150,
                    shiftCodeList: ""
                }
            }

        };
        this.redirectData = null;
        this.CommonService = new CommonService();
    }

    componentDidMount = () => {
        if (this.props.location.state !== undefined) {
            
            let jsonCopy1 = Object.assign({}, this.state.jsonTemplate);
            let jsonCopy2 = Object.assign({}, this.state.copyJSONTemplate);
            let jsonCopy3 = Object.assign({}, this.state.viewProposedShiftPlan);

            jsonCopy1.centerMasterID = this.props.location.state.res.costCenter['costCenterMasterId'];
            jsonCopy1.employeeSearch.costCenterCode = this.props.location.state.res.costCenter['costCenterCode'];
            jsonCopy1.planningPeriodID = this.props.location.state.res.planningPeriod['planningPeriodId'];

            jsonCopy2.centerMasterID = this.props.location.state.res.costCenter['costCenterMasterId'];
            jsonCopy2.currentPlanningPeriodID = this.props.location.state.res.planningPeriod['planningPeriodId'];
            
            //code to set id on the basis of copy or AI request
            this.props.location.state.readableId.startsWith('CS') ? 
                jsonCopy2.copyFromLogId = Number(this.props.location.state.readableId.match(/[a-z]+|[^a-z]+/gi)[1])
            :   jsonCopy3.aiIntegrationLogId = Number(this.props.location.state.readableId.match(/[a-z]+|[^a-z]+/gi)[1])          

            this.setState({
                //state to display accept/reject on the basis of copy or AI request
                copyShift : this.props.location.state.readableId.startsWith('CS') ? true : false,
                aiShift : this.props.location.state.readableId.startsWith('CS') ? false : true,
                lastUsedAIDropdown: this.props.location.state.res.shiftOptimizerDropDown,
                holidayArray: this.props.location.state.res.holidays,
                lastUsedCostCenter: this.props.location.state.res.costCenter,
                lastUsedPlanningPeriod: this.props.location.state.res.planningPeriod,
                planningPeriodID: this.props.location.state.res.planningPeriod['planningPeriodId'],
                costCenterCode: this.props.location.state.res.costCenter['costCenterCode'],
                centerMasterID: this.props.location.state.res.costCenter['costCenterMasterId'],
                dates: this.props.location.state.res.dates,
                dateString: this.props.location.state.res.dateString,
                shiftWeeks: this.props.location.state.res.shiftWeeks,
                pageNumber: this.props.location.state.res.pageNumber,
                pageSize: this.props.location.state.res.shiftPageSize,
                shiftdata: this.props.location.state.res.data,
                totalShiftAllocatedRecords: this.props.location.state.res.totalRecords,
                employeeTypeId: this.props.location.state.res.data.employeeTypeId,
                loading: false,
                displayDialog: false,
                CumulativeDialog: false
            })
            setTimeout(() => { this.setState({ jsonTemplate: jsonCopy1, copyJSONTemplate: jsonCopy2, viewProposedShiftPlan : jsonCopy3 }); }, 1)

        }
        else {
            this.setState({ loading: true });
            this.getLastUsedData();
        }
    }

    getLastUsedData = () => {
        this.CommonService.getLastFilterShiftAllocation(this.props.browserLanguage)
            .then(res => {
                let jsonCopy1 = Object.assign({}, this.state.jsonTemplate);
                let jsonCopy2 = Object.assign({}, this.state.copyJSONTemplate);
                if (res.data !== null) {
                    jsonCopy1.centerMasterID = res.costCenter['costCenterMasterId'];
                    jsonCopy1.employeeSearch.costCenterCode = res.costCenter['costCenterCode'];
                    jsonCopy1.planningPeriodID = res.planningPeriod['planningPeriodId'];

                    jsonCopy2.centerMasterID = res.costCenter['costCenterMasterId'];
                    jsonCopy2.currentPlanningPeriodID = res.planningPeriod['planningPeriodId'];

                    this.setState({
                        holidayArray: res.holidays,
                        lastUsedCostCenter: res.costCenter,
                        lastUsedPlanningPeriod: res.planningPeriod,
                        planningPeriodID: res.planningPeriod['planningPeriodId'],
                        costCenterCode: res.costCenter['costCenterCode'],
                        centerMasterID: res.costCenter['costCenterMasterId'],
                        dates: res.dates,
                        dateString: res.dateString,
                        shiftWeeks: res.shiftWeeks,
                        pageNumber: res.pageNumber,
                        pageSize: res.shiftPageSize,
                        shiftdata: res.data,
                        totalShiftAllocatedRecords: res.totalRecords,
                        employeeTypeId: res.data.employeeTypeId,
                        loading: false,
                        displayDialog: false,
                        CumulativeDialog: false
                    })

                    this.setState({ jsonTemplate: jsonCopy1, copyJSONTemplate: jsonCopy2 });

                }
                this.setState({ loading: false });
            })
            .catch(err => {
                this.setState({ loading: false, displayDialog: false, CumulativeDialog: false });
                this.showMultiple(err);
            })
    }
    // Function called when Callback receive from AdvanceFilter Dialog
    handleCumulativeSelection = (jsonCopy) => {

        this.setState({ CumulativeDialog: false, displayDialog: false, loading: true, removefilterdisable: false, AdvanceFilterDialog: false });
        this.setState({ jsonTemplate: jsonCopy });
        removeClicked = false;
        document.getElementById('employeeSkills').value = '';
        document.getElementById('employeeName').value = '';
        document.getElementById('employeeTypeCode').value = '';
        //document.getElementById('employeeGroupNameFilterTextField').value = '';
        this.getList(jsonCopy);
    }

    //hide Cumulative Dialog When Clicked without selection
    hideCumulativeDialog = () => {
        this.setState({ CumulativeDialog: false });
    }

    //function to clear out advance filter
    removeAdvanceFilter = () => {
        removeClicked = true;
        this.setState({ loading: true });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeIds = [];
        this.setState({ CumulativeDialog: false, displayDialog: false, removefilterdisable: true, jsonTemplate: jsonCopy });
        this.getList(jsonCopy);
    }


    //Color Editor for showing leaves color 
    colorTemplateweekday = (rowData, weekday) => {
        if (rowData[weekday] === null) {     //Sets color when employee is deactivated on that date
            return <div id={'dv' + weekday} className="elemnetFill textUpperCase" title={translator.employeeDeactivatedForDate} style={{ backgroundColor: leaveCodes.color_Hol }}>-</div>
        }
        else if (rowData[weekday].shiftCode.toLowerCase() === leaveCodes.VL.toLowerCase()) {    //Sets color when VL is entered
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_VL }}>
                    {rowData[weekday].shiftCode}

                </div>
            )
        }
        else if (rowData[weekday].shiftCode.toLowerCase() === leaveCodes.VL2.toLowerCase()) {    //Sets color when VL2 is entered
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_VL2 }}>
                    {rowData[weekday].shiftCode}

                </div>
            )
        }
        else if (rowData[weekday].shiftCode.toLowerCase() === leaveCodes.PO.toLowerCase()) {    //Sets color when PO is entered
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_PO }}>
                    {rowData[weekday].shiftCode}

                </div>
            )
        }
        else if (rowData[weekday].shiftCode.toLowerCase() === leaveCodes.JV.toLowerCase()) {    //Sets color when JV is entered
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_JV }}>
                    {rowData[weekday].shiftCode}

                </div>
            )
        }
        else if (rowData[weekday].shiftCode.toLowerCase() === leaveCodes.TV.toLowerCase()) {    //Sets color when TV is entered
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_TV }}>
                    {rowData[weekday].shiftCode}

                </div>
            )
        }
        else if (rowData[weekday].shiftCode.toLowerCase() === leaveCodes.P.toLowerCase()) {    //Sets color when P is entered
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_P }}>
                    {rowData[weekday].shiftCode}

                </div>
            )
        }
        // else if (rowData[weekday].shiftCode.toLowerCase() === leaveCodes.Hol.toLowerCase()) {    //Sets color when there is holiday on that date
        //     return (
        //         <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_Hol }}>
        //             -

        //         </div>
        //     )
        // }
        else if (rowData[weekday].shiftCode === leaveCodes.Sat || rowData[weekday].shiftCode === leaveCodes.Sun) {  //Executed when day is weekend
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ color: "black" }}>
                    -

                </div>
            )
        }
        // else if((reservedCodesArray.indexOf(rowData[weekday].shiftCode.toUpperCase())===-1) && rowData[weekday].shiftCode.toUpperCase()!=="-" && (rowData.skills.split(',').indexOf(rowData[weekday].shiftCode.toUpperCase())===-1)){
        else if ((reservedCodesArray.indexOf(rowData[weekday].shiftCode.toUpperCase()) === -1) && rowData[weekday].shiftCode.toUpperCase() !== "-" && this.checkEmployeeNotTrainedOnSkill(rowData.skills, rowData[weekday].shiftCode.toUpperCase())) {
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_warningShift }}>
                    {rowData[weekday].shiftCode}

                </div>
            )
        }
        else if (reservedCodesArray.indexOf(rowData[weekday].shiftCode.toUpperCase()) !== -1) {  //Executed when reserved code is present on that day
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_reservedCode }}>
                    {rowData[weekday].shiftCode}

                </div>
            )
        }
        else {
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ color: "black" }}>
                    {rowData[weekday].shiftCode}

                </div>
            )
        }
    }

    //Function to check if employee is trained or not on entered shift code
    checkEmployeeNotTrainedOnSkill = (employeeSkills, enteredShiftCodes) => {
        if (enteredShiftCodes.includes(',')) {    //multiple shifts entered
            enteredShiftCodes = enteredShiftCodes.split(',');
            let flag = false;
            for (let index = 0; index < enteredShiftCodes.length; index++) {

                if (employeeSkills === '')
                    flag = true
                else if (employeeSkills.includes(',')) {
                    if (!employeeSkills.includes(enteredShiftCodes[index]))
                        flag = true;
                }
            }
            return flag;
        }
        else {  //single shift entered
            let flagForSingleShift = false;
            if (employeeSkills === '')
                flagForSingleShift = true
            else if (employeeSkills.includes(',')) {
                if (!employeeSkills.includes(enteredShiftCodes))
                    flagForSingleShift = true;
            }
            return flagForSingleShift;
        }
    }

    //Function to call API service for GET ShiftAllocation Data.
    getList = (payload) => {
        if (payload.aiIntegrationLogId !== 0) {
            this.CommonService.viewProposedShift(payload, this.props.browserLanguage)
                .then(data => {
                    if (data !== undefined && data !== null) {
                        this.setState({
                            holidayArray: data.holidays,
                            dates: data.dates,
                            // dateString: data.dateString,
                            shiftWeeks: data.shiftWeeks,
                            pageNumber: data.pageNumber,
                            pageSize: data.shiftPageSize,
                            shiftdata: data.data,
                            totalShiftAllocatedRecords: data.totalRecords,
                            employeeTypeId: data.data.employeeTypeId,
                            loading: false,
                            displayDialog: false,
                            CumulativeDialog: false
                        })
                    }
                })
                .catch(err => {
                    this.setState({ loading: false, displayDialog: false, CumulativeDialog: false });
                    this.showMultiple(err);
                })
        }

        else {
            this.CommonService.getShiftAllocationData(payload, this.props.browserLanguage)
                .then(data => {
                    if (data !== undefined && data !== null) {
                        this.setState({
                            holidayArray: data.holidays,
                            dates: data.dates,
                            dateString: data.dateString,
                            shiftWeeks: data.shiftWeeks,
                            pageNumber: data.pageNumber,
                            pageSize: data.shiftPageSize,
                            shiftdata: data.data,
                            totalShiftAllocatedRecords: data.totalRecords,
                            employeeTypeId: data.data.employeeTypeId,
                            loading: false,
                            displayDialog: false,
                            CumulativeDialog: false
                        })
                    }
                })
                .catch(err => {
                    this.setState({ loading: false, displayDialog: false, CumulativeDialog: false });
                    this.showMultiple(err);
                })
        }

    }


    //Function to handle page Change Event
    onPageChange = (e) => {
        if (this.state.shiftdata === undefined || this.state.shiftdata.length === undefined) {
            return false;
        }

        this.setState({ first: e.first, loading: true, displayDialog: false, CumulativeDialog: false });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeSearch.pageNumber = e.page;
        this.getList(jsonCopy);
    }

    //Function to call API for filtering records
    getFilterFuntion = (jsonCopy) => {
        if (this.state.shiftdata === undefined || this.state.shiftdata.length === undefined) {
            return false;
        }

        jsonCopy.employeeSearch.pageNumber = 0;
        this.setState({
            jsonTemplate: jsonCopy,
            loading: true,
            // first : 0
        });
        this.getList(jsonCopy);

    }


    //Function to implement Filtering functionality on Employee ID. 
    onEmployeeSkillsFilter = (empSkills, key) => {
        if (_.includes(keyboardValues, key) && empSkills !== "") {
            this.setState({ displayDialog: false, CumulativeDialog: false })
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.shiftCodeList = empSkills;
            this.getFilterFuntion(jsonCopy);
        }

    }


    onkeydownonemployeeNameFilter = (empName, key) => {
        if (_.includes(keyboardValues, key) && empName !== "") {
            this.setState({ displayDialog: false, CumulativeDialog: false });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.employeeName = empName;
            this.getFilterFuntion(jsonCopy);
        }

    }

    //Function to implement Filtering functionality on Employee Type Code. 
    onemployeeTypeFilter = (empType, key) => {
        if (_.includes(keyboardValues, key) && empType !== "") {
            this.setState({ displayDialog: false, CumulativeDialog: false })
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.employeeTypeCode = empType;
            this.getFilterFuntion(jsonCopy);
        }

    }

    //Function to clear filtered data
    filterCleared = (e, field) => {

        if (e.target.value === "" && field === "employeeSkills") {
            this.setState({ loading: true });

            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.shiftCodeList = "";
            this.setState({ jsonTemplate: jsonCopy });
            this.getList(jsonCopy);

        }
        else if (e.target.value === "" && field === "employeeName") {
            this.setState({ loading: true });

            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.employeeName = "";
            this.setState({ jsonTemplate: jsonCopy });
            this.getList(jsonCopy);

        }
        else if (e.target.value === "" && field === "employeeTypeCode") {
            this.setState({ loading: true });

            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.employeeTypeCode = "";
            this.setState({ jsonTemplate: jsonCopy });
            this.getList(jsonCopy);

        }
    }

    //Function to handle Sorting on Shift Allocation Data
    onSortChange = (e) => {
        if (this.state.shiftdata === undefined || this.state.shiftdata.length === undefined) {
            return false;
        }

        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, displayDialog: false, loading: true, CumulativeDialog: false });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeSearch.sortDirection = "ASC";
        if (e.sortOrder === -1) {
            jsonCopy.employeeSearch.sortDirection = "DESC";
        }
        jsonCopy.employeeSearch.sortBy = e.sortField;
        this.getList(jsonCopy);


    }

    // Cell Editors for all columns
    WeekShiftEditors = (props, weekday) => {

        if (props.rowData[weekday] === null) {
            return <div className="elemnetFill" title={translator.employeeDeactivatedForDate} style={{ backgroundColor: leaveCodes.color_Hol }}>-</div>;
        }
        // else if (props.rowData[weekday].shiftCode === leaveCodes.Hol) {
        //     return <div className="elemnetFill" title={props.rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_Hol }}>-</div>;
        // }

        else {
            // return <div className="elemnetFill" title={props.rowData[weekday].shiftRemarks}>{props.rowData[weekday].shiftCode}</div>;
            return this.colorTemplateweekday(props.rowData, weekday);
        }


    }

    // to check shifts/skills are avaialalbe for respective rows
    validShiftCheck(rowData, value, key, event) {
        let skillsData = rowData.skills.split(',');
        let shiftCodes = value.includes(',') ? value.split(',') : value;
        let isValidShift = true;
        /* skillsData.map(skillitem => {      //old code       
            if (skillitem.toUpperCase()  === value.toUpperCase() || _.includes(leaveCodesArray,value.toUpperCase())) {
                this.setState({
                    displayDialog:false,
                    CumulativeDialog:false,
                    validShift: true
                });
            }
            return this.state.validShift;
        });    */
        if (value.includes(',')) {
            shiftCodes.forEach(shiftCode => {
                if (skillsData.indexOf(shiftCode) === -1 && !_.includes(leaveCodesArray, shiftCode.toUpperCase())) {
                    isValidShift = false
                }

            })
        }
        else {
            if (skillsData.indexOf(shiftCodes) === -1 && !_.includes(leaveCodesArray, shiftCodes.toUpperCase()))
                isValidShift = false
        }
        this.setState({
            displayDialog: false,
            CumulativeDialog: false,
            validShift: isValidShift
        });
    }

    showWarningMessage = (errorMessage) => {

        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'warn', summary: translator.warningMessage, detail: errorMessage });
    }

    showSuccessMessage = () => {

        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'success', summary: translator.successMessage, detail: translator.shiftUpdateSucess });
    }

    showMultiple = (errorBody) => {

        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });
    }

    //handle CumulativeSearch data
    handleCumulativeSearch = () => {
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeSearch.recordCount = 150;
        this.setState({ CumulativeDialog: true, displayDialog: false });
    }

    //Function to set default filters
    clearFilterControl = () => {
        if (document.getElementById('employeeSkills')) {
            document.getElementById('employeeSkills').value = ''
        }
        if (document.getElementById('employeeName')) {
            document.getElementById('employeeName').value = ''
        }
        if (document.getElementById('employeeTypeCode')) {
            document.getElementById('employeeTypeCode').value = ''
        }

    }

    //function call when cost center selected
    onCostCenterSelect = (data, data1) => {
        if (this.growl !== undefined && this.growl !== null) {
            this.growl.clear();
        }

        this.clearFilterControl();
        if (data1 === '' || data === '') {
            this.setState({
                shiftdata: null,
                copyShift: false,
                aiShift: false,
                week1: false,
                week2: false,
                week3: false,
                week4: false,
                days: [],
                dates: [],
                dateString: [],
                shiftWeeks: [],
                pageNumber: 0,
                pageSize: 0,
                displayDialog: false,
                CumulativeDialog: false,
                totalShiftAllocatedRecords: undefined,
                jsonTemplate: {
                    aiIntegrationLogId: 0,
                    planningPeriodID: "",
                    centerMasterID: null,
                    exceptionWeeks: [],
                    exceptionDates: [],
                    employeeIds: [],
                    employeeGroupName: [],
                    employeeSearch: {
                        supervisorId: "",
                        costCenterCode: "",
                        employeeId: "",
                        employeeName: "",
                        employeeTypeCode: "",
                        sortBy: "employeeName",
                        sortDirection: "ASC",
                        pageNumber: 0,
                        recordCount: 150,
                        shiftCodeList: ""
                    }
                }
            });
            ReactDOM.unmountComponentAtNode(document.getElementById('costCenter'));
            ReactDOM.render(<CostCenter onChange={this.onCostCenterSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />, document.getElementById('costCenter'))
            return false;
        }

        this.setState({
            costCenterCode: data,
            centerMasterID: data1,
            removefilterdisable: true,
            displayDialog: false, CumulativeDialog: false,
            week1: false,
            week2: false,
            week3: false,
            week4: false,
            copyShift: false,
            aiShift: false
        });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeSearch.costCenterCode = data;
        jsonCopy.aiIntegrationLogId = 0;
        jsonCopy.centerMasterID = data1;
        jsonCopy.planningPeriodID = this.state.planningPeriodID;
        jsonCopy.employeeIds = [];
        jsonCopy.employeeGroupName = [];
        jsonCopy.employeeSearch.shiftCodeList = '';
        jsonCopy.employeeSearch.employeeName = '';
        jsonCopy.employeeSearch.employeeTypeCode = '';
        jsonCopy.exceptionWeeks = [];
        jsonCopy.exceptionDates = [];
        if (this.state.costCenterCode !== '' && this.state.planningPeriodID !== undefined) {
            // if(this.state.CumulativeDialog===true){
            //     this.removeAdvanceFilter();
            // }
            ReactDOM.unmountComponentAtNode(document.getElementById('autoGeneratedId'));
            ReactDOM.render(<AutoGeneratedIDropDown costCenter={data1} planningPeriod={this.state.jsonTemplate.planningPeriodID} browserLanguage={this.props.browserLanguage} onChange={this.onAutoGenerationIdSelect} />, document.getElementById('autoGeneratedId'))
            this.setState({ loading: true, displayDialog: false, CumulativeDialog: false });
            this.getList(jsonCopy);
            this.setState({ jsonTemplate: jsonCopy });
        }

    }
    //Function which call when planning period selcted
    onPlanningPeriodSelect = (data) => {
        if (this.growl !== undefined && this.growl !== null) {
            this.growl.clear();
        }

        this.setState({
            planningPeriodID: data,
            week1: false,
            week2: false,
            week3: false,
            week4: false,
            copyShift: false,
            aiShift: false
        })

        if (this.state.costCenterCode !== undefined && this.state.planningPeriodID !== '') {
            ReactDOM.unmountComponentAtNode(document.getElementById('autoGeneratedId'));
            ReactDOM.render(<AutoGeneratedIDropDown costCenter={this.state.jsonTemplate.centerMasterID} planningPeriod={data} browserLanguage={this.props.browserLanguage} onChange={this.onAutoGenerationIdSelect} />, document.getElementById('autoGeneratedId'))

            this.setState({ loading: true, displayDialog: false, CumulativeDialog: false })
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.centerMasterID = this.state.centerMasterID;
            jsonCopy.planningPeriodID = data;
            jsonCopy.aiIntegrationLogId = 0;
            jsonCopy.exceptionWeeks = [];
            jsonCopy.exceptionDates = [];
            this.getList(jsonCopy);
            this.setState({ jsonTemplate: jsonCopy, displayDialog: false, CumulativeDialog: false })
        }
    }

    //onAutoGenerationId Select
    onAutoGenerationIdSelect = (data, tag, acceptedBy) => {
        if (acceptedBy !== null) {
            this.showWarningMessage(translator.alreadyAcceptedPlan);
        }
        if (tag.substring(0, 2) === 'CS') {
            let jsonCopy = Object.assign({}, this.state.copyJSONTemplate);
            jsonCopy.copyFromLogId = data;
            this.setState({ copyJSONTemplate: jsonCopy, loading: true, copyShift: true, aiShift : false });

            this.CommonService.getCopyData(jsonCopy, this.props.browserLanguage)
                .then(res => {
                    if (res !== null) {
                        this.setState({
                            holidayArray: res.holidays,
                            dates: res.dates,
                            shiftWeeks: res.shiftWeeks,
                            pageNumber: res.pageNumber,
                            pageSize: res.shiftPageSize,
                            shiftdata: res.data,
                            totalShiftAllocatedRecords: res.totalRecords,
                            employeeTypeId: res.data.employeeTypeId,
                            loading: false,
                            displayDialog: false,
                            CumulativeDialog: false
                        })

                    }
                    this.setState({ loading: false });
                })
                .catch(err => {
                    this.setState({ loading: false, displayDialog: false, CumulativeDialog: false });
                    this.showMultiple(err);
                })

        }
        else {
            let jsonCopy = Object.assign({}, this.state.viewProposedShiftPlan);
            jsonCopy.aiIntegrationLogId = data;
            this.setState({ viewProposedShiftPlan: jsonCopy, loading: true, aiShift: true, copyShift: false });

            this.CommonService.viewProposedShift(jsonCopy, this.props.browserLanguage)
                .then(res => {
                    let jsonCopy1 = Object.assign({}, this.state.jsonTemplate);
                    if (res.data !== null) {
                        jsonCopy1.centerMasterID = res.costCenter['costCenterMasterId'];
                        jsonCopy1.employeeSearch.costCenterCode = res.costCenter['costCenterCode'];
                        jsonCopy1.planningPeriodID = res.planningPeriod['planningPeriodId'];
                        jsonCopy1.aiIntegrationLogId = data;

                        this.setState({
                            holidayArray: res.holidays,
                            lastUsedCostCenter: res.costCenter,
                            lastUsedPlanningPeriod: res.planningPeriod,
                            planningPeriodID: res.planningPeriod['planningPeriodId'],
                            costCenterCode: res.costCenter['costCenterCode'],
                            centerMasterID: res.costCenter['costCenterMasterId'],
                            dates: res.dates,
                            // dateString: res.dateString,
                            shiftWeeks: res.shiftWeeks,
                            pageNumber: res.pageNumber,
                            pageSize: res.shiftPageSize,
                            shiftdata: res.data,
                            totalShiftAllocatedRecords: res.totalRecords,
                            employeeTypeId: res.data.employeeTypeId,
                            loading: false,
                            displayDialog: false,
                            CumulativeDialog: false
                        })

                        this.setState({ jsonTemplate: jsonCopy1 });

                    }
                    this.setState({ loading: false });
                })
                .catch(err => {
                    this.setState({ loading: false, displayDialog: false, CumulativeDialog: false });
                    this.showMultiple(err);
                })
        }

    }


    //Function to chnage data while changing no of record   
    noOfRecordChange = (e) => {
        this.setState({
            noOfRecords: e.value.code,
            dropdownDisplay: e.value,
            loading: true,
            first: 0,
            displayDialog: false,
            CumulativeDialog: false
        })

        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeSearch.recordCount = e.value.code;
        jsonCopy.employeeSearch.pageNumber = 0;

        this.getList(jsonCopy);

        this.setState({ jsonTemplate: jsonCopy });

    }

    //Function to print exception report
    exceptionWeek = (event, weekNo) => {

        this.setState({ loading: true });
        switch (weekNo) {
            case 'week1':
                this.setState({ week1: event.checked });
                break;

            case 'week2':
                this.setState({ week2: event.checked });
                break;

            case 'week3':
                this.setState({ week3: event.checked });
                break;

            case 'week4':
                this.setState({ week4: event.checked });
                break;
            default:
                break;
        }

        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        if (event.checked) {
            jsonCopy.exceptionWeeks.push(event.value);
        }
        else {
            jsonCopy.exceptionWeeks.splice(jsonCopy.exceptionWeeks.indexOf(event.value), 1);
        }
        jsonCopy.exceptionWeeks.sort();
        this.setState({ jsonTemplate: jsonCopy });

        this.getList(jsonCopy)



    }

    exceptionDay = (event, dayDate) => {

        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        let checkedDatesCopy = this.state.checkedDates;
        //Shows loader
        this.setState({ loading: true });
        //Add or remove date for sending as request body
        if (event.checked) {
            jsonCopy.exceptionDates.push(dayDate);
            checkedDatesCopy.push(dayDate);
        }
        else {
            jsonCopy.exceptionDates.splice(jsonCopy.exceptionDates.indexOf(dayDate), 1);
            checkedDatesCopy.splice(checkedDatesCopy.indexOf(dayDate), 1);
        }
        jsonCopy.exceptionDates.sort();
        //Add or remove date for marking checked and unchecked
        this.setState({ jsonTemplate: jsonCopy, checkedDates: checkedDatesCopy });
        this.getList(jsonCopy);

    }

    //Function to implement functionality of Wrapping Long Text With "...".
    nameTemplate = (rowData, column) => {
        return (<div className="extraTextWrappingShitAllocation" title={rowData[column.field]} style={{ float: column.field === 'hrsPerWeek' ? 'right' : 'unset' }}>
            {rowData[column.field]}
        </div>
        );
    }

    // Function to call accept or reject proposed shift plan
    acceptAutoGeneratedShift = () => {
        let jsonCopy = Object.assign({}, this.state.viewProposedShiftPlan);
        jsonCopy.acceptAllocation = true;
        this.CommonService.acceptOrRejectProposedShiftPlan(jsonCopy, this.props.browserLanguage).then(res => {
            //Redirect from here to shift allocation
            this.redirectData = res;
            this.setState({ redirect: true });
        })
            .catch(err => {
                this.setState({ loading: false, displayDialog: false, CumulativeDialog: false });
                this.showMultiple(err);
            })

    }

    //Function to execute when yes button is pressed in Confirmation Dialog
    onYesFromConfirmDialog = (action) => {
        //Execute when confirmation dialog was opened for unborrowing
        if (action === 'confirmAutoGeneratedShift')
            this.acceptAutoGeneratedShift()

        else if (action === 'acceptCopyShift')
            this.acceptCopyPlan();

        else if (action === 'rejectCopyShift')
            this.rejectCopyPlan()

        this.setState({ displayShiftCnfDialog: false });
    }

    //Function to execute when no button is pressed in Confirmation Dialog
    onNoFromConfirmDialog = () => {
        this.setState({ displayShiftCnfDialog: false });
    }

    acceptConfirm = (e) => {
        if (this.state.viewProposedShiftPlan.aiIntegrationLogId !== 0) {
            this.setState({ displayShiftCnfDialog: true })
        }
    }

    acceptConfirmForCopyShift = (flag) => {
        this.setState({ displayShiftCnfDialog: true, flagForCopy: flag });
    }

    acceptCopyPlan = () => {
        let jsonObject = Object.assign({}, this.state.copyJSONTemplate);
        jsonObject.acceptAllocation = true;
        jsonObject.centerMasterID = this.state.jsonTemplate.centerMasterID;
        jsonObject.currentPlanningPeriodID = this.state.jsonTemplate.planningPeriodID;

        this.CommonService.acceptRejectShiftAllocation(jsonObject, this.props.browserLanguage)
            .then(res => {
                this.redirectData = res;
                this.setState({ redirect: true });
            })
            .catch(err => {
                this.setState({ loading: false, displayDialog: false, CumulativeDialog: false });
                this.showMultiple(err);
            })

    }

    rejectCopyPlan = () => {
        let jsonObject = Object.assign({}, this.state.copyJSONTemplate);
        jsonObject.acceptAllocation = false;
        jsonObject.centerMasterID = this.state.jsonTemplate.centerMasterID;
        jsonObject.currentPlanningPeriodID = this.state.jsonTemplate.planningPeriodID;

        this.CommonService.acceptRejectShiftAllocation(jsonObject, this.props.browserLanguage)
            .then(res => {
                this.redirectData = res;
                this.setState({ redirect: true });
            })
            .catch(err => {
                this.setState({ loading: false, displayDialog: false, CumulativeDialog: false });
                this.showMultiple(err);
            })

    }

    render() {
        if (this.state.redirect) {
            return (
                <Redirect to={{
                    pathname: '/shiftAllocation',
                    state: { res: this.redirectData }
                }}
                />
            )
        }

        if (document.getElementsByClassName('p-datatable-emptymessage') !== undefined && document.getElementsByClassName('p-datatable-emptymessage').length > 0 && document.getElementsByClassName('p-datatable-emptymessage')[1] !== undefined) {
            document.getElementsByClassName('p-datatable-emptymessage')[1].style.color = "transparent";
            document.getElementsByClassName('p-datatable-emptymessage')[1].firstChild.style.borderLeft = "0 none";
        }

        translator.setLanguage(this.props.browserLanguage);
        var weekName = [
            translator.monday,
            translator.tuesday,
            translator.wednesday,
            translator.thursday,
            translator.friday,
            translator.saturday,
            translator.sunday
        ];
        const noOfRecord = [
            { ShiftAllocatedData: translator.Records_150, code: 150 },
            { ShiftAllocatedData: translator.Records_200, code: 200 },
            { ShiftAllocatedData: translator.Records_250, code: 250 }
        ];
        let employeeSkillsFilter = <InputText onChange={(e) => { this.filterCleared(e, "employeeSkills") }} id="employeeSkills" className="employeeIdFilterWidth" onKeyDown={(e) => this.onEmployeeSkillsFilter(e.target.value, e.key)} />
        let employeeNameFilter = <InputText onChange={(e) => { this.filterCleared(e, "employeeName") }} id="employeeName" className="employeeIdFilterWidth" onKeyDown={(e) => this.onkeydownonemployeeNameFilter(e.target.value, e.key)} />
        let employeeTypeFilter = <InputText onChange={(e) => { this.filterCleared(e, "employeeTypeCode") }} id="employeeTypeCode" className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeTypeFilter(e.target.value, e.key)} />

        //Current Week Header
        let frozenHeaderGroup = <ColumnGroup>
            {
                this.state.shiftdata ?

                    <Row>
                        <Column colSpan={3} header={translator.employeeDetails} className="EmpDetailWeekHeader" style={{ height: '56px', width: '300px' }}></Column>
                    </Row> : null
            }
            {
                this.state.shiftdata ?
                    <Row>
                        <Column field="employeeName" header={translator.employeeName} rowSpan={3} className="EmployeeDetailsHeader" sortable={true} filter={true} filterElement={employeeNameFilter} style={{ width: '100px !important' }} />
                        <Column field="employeeType" header={translator.employeeTypeCode} rowSpan={3} className="EmployeeDetailsHeader" sortable={true} filter={true} filterElement={employeeTypeFilter} style={{ width: '100px !important' }} />
                        <Column field="skills" header={translator.employeeSkills} rowSpan={3} className="EmployeeDetailsHeader paddingtop" filter={true} filterElement={employeeSkillsFilter} style={{ width: '100px !important' }} />
                    </Row> : null
            }
        </ColumnGroup>
        let headerGroup = <ColumnGroup>
            {
                this.state.shiftdata ?
                    <Row>
                        {this.state.shiftWeeks.map((item, key) => {

                            if (key === 0) {
                                return (
                                    <Column
                                        key={key}
                                        header={<div>
                                            <Checkbox value="1" onChange={e => this.exceptionWeek(e, "week1")} checked={this.state.week1}></Checkbox>{item.week}
                                            <InputText disabled={true} id={'haittaTxtBox' + key} className="haitta-textbox" defaultValue={item.weeklyCommentAndHaittaInput !== null ? (this.props.browserLanguage === 'en' ? item.weeklyCommentAndHaittaInput.weeklyHaitta : item.weeklyCommentAndHaittaInput.weeklyHaitta.replace(/\./g, ",")) : ''} maxLength="5" keyfilter={/^\d*(\.|,)?\d*$/} /> WTI
                                            <div
                                                title={item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : ''}
                                                className="extraTextWrapping"
                                                style={{ padding: '0.54em 0em', fontWeight: '500' }}>
                                                {item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : ''}
                                            </div>
                                        </div>}
                                        body={this.navigationTemplate} colSpan={7} className="WeekHeader" />)
                            }
                            else if (key === 1) {
                                return (
                                    <Column
                                        key={key}
                                        header={<div>
                                            <Checkbox value="2" onChange={e => this.exceptionWeek(e, "week2")} checked={this.state.week2}></Checkbox>{item.week}
                                            <InputText disabled={true} id={'haittaTxtBox' + key} className="haitta-textbox" defaultValue={item.weeklyCommentAndHaittaInput !== null ? (this.props.browserLanguage === 'en' ? item.weeklyCommentAndHaittaInput.weeklyHaitta : item.weeklyCommentAndHaittaInput.weeklyHaitta.replace(/\./g, ",")) : ''} maxLength="5" keyfilter={/^\d*(\.|,)?\d*$/} /> WTI
                                <div
                                                title={item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : ''}
                                                className="extraTextWrapping"
                                                style={{ padding: '0.54em 0em', fontWeight: '500' }}>{item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : ''}
                                            </div>
                                        </div>}
                                        body={this.navigationTemplate} colSpan={7} className="WeekHeader" />)
                            }
                            else if (key === 2) {
                                return (
                                    <Column
                                        key={key}
                                        header={<div>
                                            <Checkbox value="3" onChange={e => this.exceptionWeek(e, "week3")} checked={this.state.week3}></Checkbox>{item.week}
                                            <InputText disabled={true} id={'haittaTxtBox' + key} className="haitta-textbox" defaultValue={item.weeklyCommentAndHaittaInput !== null ? (this.props.browserLanguage === 'en' ? item.weeklyCommentAndHaittaInput.weeklyHaitta : item.weeklyCommentAndHaittaInput.weeklyHaitta.replace(/\./g, ",")) : ''} maxLength="5" keyfilter={/^\d*(\.|,)?\d*$/} /> WTI
                                <div
                                                title={item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : ''}
                                                className="extraTextWrapping"
                                                style={{ padding: '0.54em 0em', fontWeight: '500' }}>{item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : ''}
                                            </div>
                                        </div>}
                                        body={this.navigationTemplate} colSpan={7} className="WeekHeader" />)
                            }
                            else {
                                return (
                                    <Column
                                        key={key}
                                        header={<div>
                                            <Checkbox value="4" onChange={e => this.exceptionWeek(e, "week4")} checked={this.state.week4}></Checkbox>{item.week}
                                            <InputText disabled={true} id={'haittaTxtBox' + key} className="haitta-textbox" defaultValue={item.weeklyCommentAndHaittaInput !== null ? (this.props.browserLanguage === 'en' ? item.weeklyCommentAndHaittaInput.weeklyHaitta : item.weeklyCommentAndHaittaInput.weeklyHaitta.replace(/\./g, ",")) : ''} maxLength="5" keyfilter={/^\d*(\.|,)?\d*$/} /> WTI
                                            <div
                                                title={item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : ''}
                                                className="extraTextWrapping"
                                                style={{ padding: '0.54em 0em', fontWeight: '500' }}>{item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : ''}
                                            </div>
                                        </div>}
                                        body={this.navigationTemplate} colSpan={7} className="WeekHeader" />)
                            }

                        })}
                    </Row>

                    : null
            }
            {
                this.state.shiftdata ?
                    <Row>
                        {
                            this.state.dateString.map((date, index) => {
                                return (<Column key={date.slice(-2)} header={<div title={this.state.holidayArray.includes(date) ? translator.holiday : null}><Checkbox value={this.state.dateString[index]} onChange={e => this.exceptionDay(e, this.state.dateString[index])} checked={this.state.checkedDates.includes(this.state.dateString[index])} ></Checkbox>{date.slice(-2)} </div>} style={this.state.holidayArray.includes(date) ? { width: '70px', backgroundColor: leaveCodes.color_holidayHeader } : { width: '70px' }} className="dateHeader" />)

                            })
                        }

                    </Row>
                    : null
            }
            {
                this.state.shiftdata ?
                    <Row >

                        {weekName.map(day => {
                            return (<Column key={day} header={day} style={{ width: '70px' }} className="DaysHeader" />)
                        })}
                        {weekName.map(day => {
                            return (<Column key={day} header={day} style={{ width: '70px' }} className="DaysHeader" />)
                        })}
                        {weekName.map(day => {
                            return (<Column key={day} header={day} style={{ width: '70px' }} className="DaysHeader" />)
                        })}
                        {weekName.map(day => {
                            return (<Column key={day} header={day} style={{ width: '70px' }} className="DaysHeader" />)
                        })}
                    </Row>
                    : null
            }

        </ColumnGroup>;



        return (

            <div className="bodyColor">

                <Growl ref={(el) => this.growl = el} position="topright" className="shiftGrowl"></Growl>
                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>

                {
                    this.state.displayShiftCnfDialog ?
                        this.state.copyShift === false ?
                            <ConfirmationDialog callbackForYes={action => this.onYesFromConfirmDialog(action)} lang={this.props.browserLanguage} callbackForNo={this.onNoFromConfirmDialog} action="confirmAutoGeneratedShift" />
                            :
                            this.state.flagForCopy === 'accept' ?
                                <ConfirmationDialog callbackForYes={action => this.onYesFromConfirmDialog(action)} lang={this.props.browserLanguage} callbackForNo={this.onNoFromConfirmDialog} action="acceptCopyShift" />
                                :
                                <ConfirmationDialog callbackForYes={action => this.onYesFromConfirmDialog(action)} lang={this.props.browserLanguage} callbackForNo={this.onNoFromConfirmDialog} action="rejectCopyShift" />
                        : null

                }

                <Breadcrumbs initial={translator.allocation} final={translator.autoGenerateshift} />
                <div >
                    <div>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="CostCenterDiv" id="costCenter">
                                        <CostCenter lastUsedCostCenter={this.state.lastUsedCostCenter} onChange={this.onCostCenterSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />
                                    </td>

                                    <td className="PlanningPeriodDiv">
                                        <PlanningPeriod lastUsedPlanningPeriod={this.state.lastUsedPlanningPeriod} onChange={this.onPlanningPeriodSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />
                                    </td>
                                    <td className="floatRightShiftAllocation">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="paddingRight">
                                                        {this.state.shiftdata ?
                                                            <div>
                                                                {!this.state.removefilterdisable ?
                                                                    <Button className="clearFilterBtn marginTop" title={translator.clearAdvanceFilter} label="X" onClick={() => this.removeAdvanceFilter()} />
                                                                    : null}
                                                                <Button className="btnDefault marginTop" icon="pi pi-filter" label={translator.advanceFilter} onClick={() => this.handleCumulativeSearch()} />
                                                            </div>
                                                            : null}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {this.state.shiftdata ?
                    <div>
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div id="autoGeneratedId" className="autoGeneratedDiv">
                                                {this.state.jsonTemplate.centerMasterID !== null ?
                                                    <AutoGeneratedIDropDown lastUsedValue={this.state.lastUsedAIDropdown} costCenter={this.state.jsonTemplate.centerMasterID} planningPeriod={this.state.jsonTemplate.planningPeriodID} browserLanguage={this.props.browserLanguage} onChange={this.onAutoGenerationIdSelect} />
                                                    : null}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> : null}
                {this.state.shiftdata ?
                    this.state.totalShiftAllocatedRecords === 0 && (document.getElementById('employeeName') !== null ? (document.getElementById('employeeName').value === '' && document.getElementById('employeeTypeCode').value === '' && document.getElementById('employeeSkills').value === '') && this.state.loading === false : true) ?
                        null :
                        <div className="paginatorShiftAllo" >
                            <span className="recordOF">{this.state.totalShiftAllocatedRecords === undefined || this.state.totalShiftAllocatedRecords === 0 ? '0-0' : (this.state.pageNumber * this.state.noOfRecords) + 1 + '-' + Number((this.state.pageNumber * this.state.noOfRecords) + Number((this.state.pageSize)))} {translator.of} {this.state.totalShiftAllocatedRecords === undefined ? 0 : this.state.totalShiftAllocatedRecords} {translator.records}</span>
                            <div className="paginatorEmp">
                                <Paginator rows={this.state.noOfRecords} totalRecords={this.state.totalShiftAllocatedRecords === undefined ? 0 : this.state.totalShiftAllocatedRecords} first={this.state.first} onPageChange={(e) => this.onPageChange(e)}></Paginator>
                                <Dropdown value={this.state.dropdownDisplay} className="dropDwonDefault colRecord dropmargin" options={noOfRecord} onChange={(e) => this.noOfRecordChange(e)} placeholder={translator.Records_150} optionLabel="ShiftAllocatedData" />
                            </div>
                        </div>
                    : null}
                {this.state.shiftdata ?
                    this.state.totalShiftAllocatedRecords === 0 && (document.getElementById('employeeName') !== null ? (document.getElementById('employeeName').value === '' && document.getElementById('employeeTypeCode').value === '' && document.getElementById('employeeSkills').value === '') && this.state.loading === false : true) ?
                        <h4 className="noRecordFoundMessage">{translator.noRecordFound}</h4> :
                        <div className="ShiftAllocationTable">
                            <DataTable
                                value={this.state.shiftdata}
                                scrollable={browser().name === 'ie' ? false : true}
                                paginatorPosition="top"
                                headerColumnGroup={headerGroup}
                                frozenHeaderColumnGroup={frozenHeaderGroup}
                                editable={true}
                                selectionMode="multiple"
                                rows={this.state.jsonTemplate.recordCount}
                                onSort={(e) => this.onSortChange(e)}
                                scrollHeight={getResolutionAutoGeneratedShift()}
                                frozenWidth="405px"
                                resizableColumns={true}
                                className="dataTableShiftAllocation"
                                sortField={this.state.sortField}
                                sortOrder={this.state.sortOrder}
                                emptyMessage={translator.noRecordFound}>

                                <Column field="employeeName" frozen={true} sortable={true} className="EmployeeNameColShiftAllo" filter={true} filterElement={employeeNameFilter} body={this.nameTemplate} style={{ width: '100px' }} />
                                <Column field="employeeType" frozen={true} sortable={true} className="EmpTypeColShiftAllo" filter={true} filterElement={employeeTypeFilter} style={{ width: '100px' }} />
                                <Column field="skills" frozen={true} className="EmpSkillsColShiftAllo" filter={true} filterElement={employeeSkillsFilter} body={this.nameTemplate} style={{ width: '100px' }} />
                                <Column field="week1day1.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week1day1')} body={(e) => this.colorTemplateweekday(e, 'week1day1')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week1day2.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week1day2')} body={(e) => this.colorTemplateweekday(e, 'week1day2')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week1day3.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week1day3')} body={(e) => this.colorTemplateweekday(e, 'week1day3')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week1day4.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week1day4')} body={(e) => this.colorTemplateweekday(e, 'week1day4')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week1day5.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week1day5')} body={(e) => this.colorTemplateweekday(e, 'week1day5')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week1day6.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week1day6')} body={(e) => this.colorTemplateweekday(e, 'week1day6')} className="SatColShiftAllo" style={{ backgroundColor: leaveCodes.color_weekend, width: '70px' }} />
                                <Column field="week1day7.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week1day7')} body={(e) => this.colorTemplateweekday(e, 'week1day7')} className="MonToFriColShiftAllo" style={{ backgroundColor: leaveCodes.color_weekend, width: '70px' }} />
                                <Column field="week2day1.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week2day1')} body={(e) => this.colorTemplateweekday(e, 'week2day1')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week2day2.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week2day2')} body={(e) => this.colorTemplateweekday(e, 'week2day2')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week2day3.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week2day3')} body={(e) => this.colorTemplateweekday(e, 'week2day3')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week2day4.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week2day4')} body={(e) => this.colorTemplateweekday(e, 'week2day4')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week2day5.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week2day5')} body={(e) => this.colorTemplateweekday(e, 'week2day5')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week2day6.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week2day6')} body={(e) => this.colorTemplateweekday(e, 'week2day6')} className="SatColShiftAllo" style={{ backgroundColor: leaveCodes.color_weekend, width: '70px' }} />
                                <Column field="week2day7.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week2day7')} body={(e) => this.colorTemplateweekday(e, 'week2day7')} className="MonToFriColShiftAllo" style={{ backgroundColor: leaveCodes.color_weekend, width: '70px' }} />
                                <Column field="week3day1.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week3day1')} body={(e) => this.colorTemplateweekday(e, 'week3day1')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week3day2.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week3day2')} body={(e) => this.colorTemplateweekday(e, 'week3day2')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week3day3.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week3day3')} body={(e) => this.colorTemplateweekday(e, 'week3day3')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week3day4.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week3day4')} body={(e) => this.colorTemplateweekday(e, 'week3day4')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week3day5.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week3day5')} body={(e) => this.colorTemplateweekday(e, 'week3day5')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week3day6.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week3day6')} body={(e) => this.colorTemplateweekday(e, 'week3day6')} className="SatColShiftAllo" style={{ backgroundColor: leaveCodes.color_weekend, width: '70px' }} />
                                <Column field="week3day7.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week3day7')} body={(e) => this.colorTemplateweekday(e, 'week3day7')} className="MonToFriColShiftAllo" style={{ backgroundColor: leaveCodes.color_weekend, width: '70px' }} />
                                <Column field="week4day1.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week4day1')} body={(e) => this.colorTemplateweekday(e, 'week4day1')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week4day2.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week4day2')} body={(e) => this.colorTemplateweekday(e, 'week4day2')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week4day3.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week4day3')} body={(e) => this.colorTemplateweekday(e, 'week4day3')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week4day4.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week4day4')} body={(e) => this.colorTemplateweekday(e, 'week4day4')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week4day5.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week4day5')} body={(e) => this.colorTemplateweekday(e, 'week4day5')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week4day6.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week4day6')} body={(e) => this.colorTemplateweekday(e, 'week4day6')} className="SatColShiftAllo" style={{ backgroundColor: leaveCodes.color_weekend, width: '70px' }} />
                                <Column field="week4day7.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week4day7')} body={(e) => this.colorTemplateweekday(e, 'week4day7')} className="MonToFriColShiftAllo" style={{ backgroundColor: leaveCodes.color_weekend, width: '70px' }} />


                            </DataTable>
                            <br />

                            {
                                this.state.copyShift === true && this.state.loading === false ?
                                    <div>
                                        <Button className="btnDefault" icon="pi pi-times" style={{ float: 'right', marginLeft: '1%' }} label={translator.reject} onClick={(e) => this.acceptConfirmForCopyShift('reject')} />
                                        <Button className="btnDefault" icon="pi pi-check" style={{ float: 'right' }} label={translator.accept} onClick={(e) => this.acceptConfirmForCopyShift('accept')} />
                                    </div>
                                    :
                                    this.state.aiShift === true && this.state.loading === false ?
                                        <Button className="btnDefault" icon="pi pi-check" style={{ float: 'right' }} label={translator.accept} onClick={(e) => this.acceptConfirm(e)} />
                                        : null

                            }

                        </div>
                    : null}

                {this.state.CumulativeDialog ?
                    this.state.copyShift === false ?
                        <AdvanceFilterShiftAllocation
                            browserLanguage={this.props.browserLanguage}
                            onHideDialog={this.hideCumulativeDialog}
                            onSelectedEmp={this.handleCumulativeSelection}
                            costCenterCode={this.state.costCenterCode}
                            jsonTemplate={this.state.jsonTemplate}
                            removeClicked={removeClicked}
                            CumulativeDialog={true}
                        />
                        :
                        <AdvanceFilterCopyShift
                            browserLanguage={this.props.browserLanguage}
                            onHideDialog={this.hideCumulativeDialog}
                            onSelectedEmp={this.handleCumulativeSelection}
                            costCenterCode={this.state.copyJSONTemplate.centerMasterID}
                            jsonTemplate={this.state.copyJSONTemplate}
                            removeClicked={removeClicked}
                            CumulativeDialog={true}
                        />
                    : null}

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        masterData: state.masterData,
        browserLanguage: state.browserLanguage
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoGeneratedShift); 
