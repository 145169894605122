import React, { Component } from 'react';
import { Chart } from 'primereact/chart';
import { Dropdown } from '../../../node_modules/primereact/dropdown';
import './dashboard.css';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import { connect } from 'react-redux';
import { CommonService } from '../../service/CommonService';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Growl } from 'primereact/growl';
import { MultiSelect } from 'primereact/multiselect';
import apiUrl from '../../CommonConstants.json';
import { Redirect } from 'react-router-dom';
import leaveCodes from '../../utility/leaveCodes.json';

let translator = new LocalizedStrings(translation);

class EmployeeGroupDataGraph extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            jsonTemplateForEmployeeGroup: {
                planningPeriodid: null,
                costCenterId: null
            }
        }
        this.commonService = new CommonService();
        this.employeeGroupNameArray = [];
        this.employeeGroupAllocatedArray = [];
        this.employeeGroupUnAllocatedArray = [];

        this.filteredEmployeeGroupNameArray = [];
        this.filteredEmployeeGroupAllocatedArray = [];
        this.filteredEmployeeGroupUnAllocatedArray = [];
    }

    componentDidMount = () => {

        this.employeeGroupNameArray = this.props.employeeGroupNameArray;
        this.employeeGroupAllocatedArray = this.props.employeeGroupAllocatedArray;
        this.employeeGroupUnAllocatedArray = this.props.employeeGroupUnAllocatedArray;

        let tempData = [];
        this.employeeGroupNameArray.map((data, index) => {
            if (index <= 9) {
                this.filteredEmployeeGroupNameArray.push(data);
                this.filteredEmployeeGroupAllocatedArray.push(this.props.employeeGroupAllocatedArray[index]);
                this.filteredEmployeeGroupUnAllocatedArray.push(this.props.employeeGroupUnAllocatedArray[index]);
                tempData.push(data)
            }
            return 0;
        })

        this.setState({
            selectedPlaningPeriodForGroupData: this.props.lastUsedPlanningPeriod,
            selectedGroupNameFilter: tempData
        });
        if (this.props.lastUsedCostCenter !== null && this.props.lastUsedPlanningPeriod !== null) {
            this.setState({
                jsonTemplateForEmployeeGroup: {
                    planningPeriodid: this.props.lastUsedPlanningPeriod.planningPerioId,
                    costCenterId: this.props.lastUsedCostCenter.costCenterMasterId
                }
            });
            this.props.masterData.costCenter.map(data => {
                if (data.costCenterCode === this.props.lastUsedCostCenter.costCenterCode) {
                    this.setState({ costCenterSelectedForGroupData: data });
                }
                return 0;
            })
        }
        
    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {
        
        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });
      
    }

    getData = (payload) => {
        this.employeeGroupNameArray = [];
        this.employeeGroupAllocatedArray = [];
        this.employeeGroupUnAllocatedArray = [];

        this.filteredEmployeeGroupNameArray = [];
        this.filteredEmployeeGroupAllocatedArray = [];
        this.filteredEmployeeGroupUnAllocatedArray = [];

        this.commonService.getEmployeeGroupDataDashboard(this.props.masterData.admin ? apiUrl.getEmployeeGroupDataAdmin : apiUrl.getEmployeeGroupDataForDashboardUrl, payload, this.props.browserLanguage)
            .then(res => {
                if (res !== null && res !== undefined && res.length > 0) {
                    let tempData = [];
                    res.map((data, index) => {
                        this.employeeGroupNameArray.push(data.groupName);
                        this.employeeGroupAllocatedArray.push(data.allocatedHours);
                        this.employeeGroupUnAllocatedArray.push(data.nonAllocatedHours);

                        if (index <= 9) {
                            this.filteredEmployeeGroupNameArray.push(data.groupName);
                            this.filteredEmployeeGroupAllocatedArray.push(data.allocatedHours);
                            this.filteredEmployeeGroupUnAllocatedArray.push(data.nonAllocatedHours);
                            tempData.push(data.groupName)
                        }
                        return 0;
                    });

                    this.setState({ selectedGroupNameFilter: tempData });
                }
                this.setState({
                    loading: false
                });
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }

    onCostCenterSelectForGroupData = (event) => {
        if (event.target.value !== null) {
            event.target.value.value = `${event.target.value.value.split('|')[0]} | ${event.target.value.value.split('|')[1]}`;

            let jsonCopy = Object.assign({}, this.state.jsonTemplateForEmployeeGroup);
            jsonCopy.costCenterId = event.target.value.costCenterMasterId;
            this.setState({
                jsonTemplateForEmployeeGroup: jsonCopy,
                costCenterSelectedForGroupData: event.target.value,
                selectedGroupNameFilter: []
            });
            if (this.state.jsonTemplateForEmployeeGroup.planningPeriodid !== null) {
                this.setState({ loading: true });
                this.getData(jsonCopy);
            }
        }
        else {
            let jsonCopy = Object.assign({}, this.state.jsonTemplateForEmployeeGroup);
            jsonCopy.costCenterId = null;

            this.employeeGroupNameArray = [];
            this.employeeGroupAllocatedArray = [];
            this.employeeGroupUnAllocatedArray = [];

            this.filteredEmployeeGroupNameArray = [];
            this.filteredEmployeeGroupAllocatedArray = [];
            this.filteredEmployeeGroupUnAllocatedArray = [];

            this.setState({
                jsonTemplateForEmployeeGroup: jsonCopy,
                costCenterSelectedForGroupData: event.target.value,
                selectedGroupNameFilter: []
            });
        }
    }

    onPlanningPeriodSelectForGroupData = (event) => {

        let jsonCopy = Object.assign({}, this.state.jsonTemplateForEmployeeGroup);
        jsonCopy.planningPeriodid = event.target.value.planningPerioId;
        this.setState({
            jsonTemplateForEmployeeGroup: jsonCopy,
            selectedPlaningPeriodForGroupData: event.target.value,
            selectedGroupNameFilter: []
        });

        if (this.state.jsonTemplateForEmployeeGroup.costCenterId !== null) {
            this.setState({ loading: true });
            this.getData(jsonCopy);
        }
    }

    filterData = (e) => {
        if (e.value.length <= 10) {
            this.filteredEmployeeGroupNameArray = [];
            this.filteredEmployeeGroupAllocatedArray = [];
            this.filteredEmployeeGroupUnAllocatedArray = [];

            this.setState({ selectedGroupNameFilter: e.value });

            if (e.value.length > 0) {
                e.value.map(data => {
                    this.filteredEmployeeGroupNameArray.push(this.employeeGroupNameArray[this.employeeGroupNameArray.indexOf(data)]);
                    this.filteredEmployeeGroupAllocatedArray.push(this.employeeGroupAllocatedArray[this.employeeGroupNameArray.indexOf(data)]);
                    this.filteredEmployeeGroupUnAllocatedArray.push(this.employeeGroupUnAllocatedArray[this.employeeGroupNameArray.indexOf(data)]);
                    return 0;
                })
            }
        }

    }

    graphClicked = (event, data) => {
        if (data[0] !== undefined) {
            this.setState({ redirect: true });
        }
    }

    render() {
        if (this.state.redirect === true) {
            return (
                <Redirect to="/shiftAllocation" />
            )
        }

        translator.setLanguage(this.props.browserLanguage);

        const dataForEmployeeGroupAllocation = {
            labels: this.filteredEmployeeGroupNameArray,
            datasets: [
                {
                    label: translator.unallocatedHours,
                    backgroundColor: 'rgb(154, 208, 245, 0.8)',
                    data: this.filteredEmployeeGroupUnAllocatedArray
                },
                {
                    label: translator.allocatedHours,
                    backgroundColor: 'rgb(255, 188, 120, 0.8)',
                    data: this.filteredEmployeeGroupAllocatedArray
                }
            ]
        }

        const barGraphOptions = {
            responsive: true,
            legend: {
                position: 'bottom'
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 30,
                    bottom: 0
                }
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true
                    },
                    ticks: {
                        autoSkip: false
                    },
                    barThickness: 22
                }],
                yAxes: [{
                    ticks: {
                        min: 0,
                        maxTicksLimit: 5
                    },
                    gridLines: {
                        display: true
                    }
                }]
            },
            hover: {
                animationDuration: 1
            },
            animation: {
                duration: 1000,
                onComplete: function () {
                    var chartInstance = this.chart,
                        ctx = chartInstance.ctx;
                    ctx.textAlign = 'center';
                    ctx.fillStyle = "rgba(0, 0, 0, 1)";
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function (dataset, i) {
                        var meta = chartInstance.controller.getDatasetMeta(i);
                        meta.data.forEach(function (bar, index) {
                            var data = dataset.data[index];
                            ctx.fillText(data, bar._model.x, bar._model.y - 5);

                        });
                    });
                }
            },
            onClick: (e, dataArray) => this.graphClicked(e, dataArray)
        };

        const groupNameArrayForFilter = this.employeeGroupNameArray.map(data => {
            return { label: data, value: data }
        });

        return (
            <div>
                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>

                <Growl ref={(el) => this.growl = el} position="topright" ></Growl>

                <div className="form-inline dropDwonDefault">
                    <strong>{translator.costCenter}&nbsp;</strong>
                    <Dropdown
                        value={this.state.costCenterSelectedForGroupData}
                        options={this.props.masterData === null ? null : this.props.masterData.costCenter}
                        onChange={(e) => this.onCostCenterSelectForGroupData(e)}
                        placeholder={translator.selectCostCenter}
                        optionLabel="codeAndName"
                        filter={true}
                        filterPlaceholder={translator.searchCostCenter}
                        filterBy="codeAndName"
                        className="ccDropdownDashboard"
                        showClear={true} />

                    <div className="dropDwonDefault" style={{ marginLeft: '2%' }}>
                        <strong>{translator.planningPeriod}&nbsp;</strong>
                        <Dropdown
                            style={{ minWidth: '150px' }}
                            value={this.state.selectedPlaningPeriodForGroupData}
                            options={this.props.masterData === null ? null : this.props.masterData.planningPeriodList}
                            onChange={(e) => this.onPlanningPeriodSelectForGroupData(e)}
                            placeholder={translator.selectPlanningPeriod}
                            optionLabel='planningPeriodDescription'
                        />
                    </div>
                    {
                        this.state.jsonTemplateForEmployeeGroup.planningPeriodid !== null && this.state.jsonTemplateForEmployeeGroup.costCenterId !== null ?
                            <div className="form-inline employeeGroupFilter">
                                <strong>{translator.groupName}&nbsp;</strong>
                                <MultiSelect filter={true} maxSelectedLabels={0} placeholder={translator.selectGroupName} value={this.state.selectedGroupNameFilter} options={groupNameArrayForFilter} onChange={(e) => this.filterData(e)} />
                            </div>
                            : null
                    }
                </div>

                {
                    this.employeeGroupNameArray.length > 0 && this.employeeGroupAllocatedArray.length > 0 && this.employeeGroupUnAllocatedArray.length > 0 ?
                        <div className="scrollbar">
                            <div className="overflow">
                                <Chart type="bar" data={dataForEmployeeGroupAllocation} options={barGraphOptions} />
                            </div>
                        </div>
                        : null
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        masterData: state.masterData,
        browserLanguage: state.browserLanguage
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeGroupDataGraph);
