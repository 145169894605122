import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CommonService } from '../../service/CommonService';
import { Dropdown } from 'primereact/dropdown';
import CostCenter from '../../components/costcenter/costCenter';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { Growl } from 'primereact/growl';
import browser from 'browser-detect'
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getResolution } from '../../../src/utility/commonUtility';
import ReactDOM from 'react-dom';
import leaveCodes from '../../utility/leaveCodes.json';
import './EmployeeTransfer.css';
import TransferDialog from './TransferDialog';

let translator = new LocalizedStrings(translation);

class EmployeeTransfer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedEmployee : [],
            loading: false,
            deleteButton: true,
            editedStatus: false,
            jsonTemplate: {
                supervisorId: '',
                costCenterCode: "",
                employeeId: "",
                employeeName: "",
                employeeTypeCode: "",
                sortBy: "employeeName",
                sortDirection: "ASC",
                pageNumber: 0,
                recordCount: 150
            },
            transferJSONTemplate : {
                existingCCId : null,
                employeeDetailsIds : [],
                selectAll : false,
                targetCCId : null,
                mergeAllocation : true,
                mergeShift : true
            },
            displayDialog: false,
            groupNameArray: ''
        };
        this.commonService = new CommonService();
    }

    componentDidMount = () => {
        this.setState({ loading: true });
        this.commonMethodForLifecycleMethod();
    }

    commonMethodForLifecycleMethod = () => {

        this.commonService.getLastFilterEmployeeMaster(this.props.browserLanguage)
            .then(res => {
                let jsonCopy1 = Object.assign({}, this.state.jsonTemplate);
                if (res.costCenter !== null) {
                    jsonCopy1.costCenterCode = res.costCenter.costCenterCode;

                    this.setState({
                        lastUsedCostCenter: res.costCenter,
                        pageNumber: res.employeePage.number,
                        pageSize: res.employeePage.size,
                        employeeData: res.employeePage.content,
                        totalEmployeeRecords: res.employeePage.totalElements,
                        recordSize: res.employeePage.numberOfElements,
                        loading: false,
                        displayDialog: false
                    })

                    this.setState({ jsonTemplate: jsonCopy1 });
                }
                this.setState({ loading: false, displayDialog: false });
            })
            .catch(err => {
                this.setState({ loading: false, displayDialog: false });
                this.showMultiple(err);
            })
    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {

        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });

    }

    //Function to call API service for GET Employee List.
    getList = (payload) => {

        this.commonService.getEmployeeList(payload, this.props.browserLanguage)
            .then(data => {
                if (data !== undefined && data !== null) {
                    this.setState({
                        pageNumber: data.employeePage.number,
                        pageSize: data.employeePage.size,
                        employeeData: data.employeePage.content,
                        totalEmployeeRecords: data.employeePage.totalElements,
                        recordSize: data.employeePage.numberOfElements,
                        loading: false,
                        displayDialog: false
                    })
                }

                if (this.state.editedStatus) {
                    this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'success', summary: translator.warningMessage, detail: translator.contractUpdatedSuccess });
                    this.setState({ editedStatus: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false, displayDialog: false });
                this.showMultiple(err);
                //Utility.showMultiple(err);
            })
    }

    //Function to implement Change Page functionality of Paginator. 
    onPageChange = (e) => {

        if (this.state.employeeData === undefined || this.state.employeeData.length === undefined) {
            return false;
        }

        this.setState({ first: e.first, loading: true, displayDialog: false });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.pageNumber = e.page;
        this.getList(jsonCopy);
    }

    //Function to call API for filtering records
    getFilterFuntion = (jsonCopy) => {
        if (this.state.employeeData === undefined || this.state.employeeData.length === undefined) {
            return false;
        }
        jsonCopy.pageNumber = 0;
        this.setState({
            jsonTemplate: jsonCopy,
            loading: true,
            first: 0,
            displayDialog: false
        });
        this.getList(jsonCopy);
    }

    //Function to implement Filtering functionality on Employee ID. 
    onEmployeeIdFilter = (empId, key) => {
        if ((key === 'Enter' || key === 'Tab') && empId !== "") {
            this.setState({ displayDialog: false })
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeId = empId;
            this.getFilterFuntion(jsonCopy);
        }
    }

    filterCleared = (e, field) => {

        if (e.target.value === "" && field === "employeeId") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeId = "";
            this.setState({ jsonTemplate: jsonCopy, displayDialog: false });
            this.getList(jsonCopy);
        }
        else if (e.target.value === "" && field === "employeeName") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeName = "";
            this.setState({ jsonTemplate: jsonCopy, displayDialog: false });
            this.getList(jsonCopy);
        }
        else if (e.target.value === "" && field === "employeeTypeCode") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeTypeCode = "";
            this.setState({ jsonTemplate: jsonCopy, displayDialog: false });
            this.getList(jsonCopy);
        }
        else if (e.target.value === "" && field === "empGroupNames") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.empGroupNames = "";
            this.setState({ jsonTemplate: jsonCopy, displayDialog: false });
            this.getList(jsonCopy);
        }

    }
    //Function to implement Filtering functionality on Employee Name. 
    onemployeeNameFilter = (empName, key) => {
        if ((key === 'Enter' || key === 'Tab') && empName !== "") {
            this.setState({ displayDialog: false })
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeName = empName;
            this.getFilterFuntion(jsonCopy);
        }
    }

    //Function to implement Filtering functionality on Employee Type Code. 
    onemployeeTypeFilter = (empType, key) => {
        if ((key === 'Enter' || key === 'Tab') && empType !== "") {
            this.setState({ displayDialog: false })
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeTypeCode = empType;
            this.getFilterFuntion(jsonCopy);
        }
    }

    //Function to implement Filtering functionality on Group Name. 
    onemployeeGroupFilter = (empGroupNames, key) => {
        if ((key === 'Enter' || key === 'Tab') && empGroupNames !== "") {
            this.setState({ displayDialog: false })
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.empGroupNames = empGroupNames;
            this.getFilterFuntion(jsonCopy);
        }
    }

    //Function to implement Sorting functionality. 
    onSortChange = (e) => {

        if (this.state.employeeData === undefined || this.state.employeeData.length === undefined) {
            return false;
        }
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true, displayDialog: false, first: 0 });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.sortDirection = 'ASC';
        jsonCopy.sortBy = e.sortField;

        if (e.sortOrder === -1) {
            jsonCopy.sortDirection = "DESC"
        }

        this.getList(jsonCopy);
        this.setState({ jsonTemplate: jsonCopy });
    }

    //Function to set default filters
    clearFilterControl = () => {
        if (document.getElementById('employeeId')) {
            document.getElementById('employeeId').value = ''
        }
        if (document.getElementById('employeeName')) {
            document.getElementById('employeeName').value = ''
        }
        if (document.getElementById('employeeTypeCode')) {
            document.getElementById('employeeTypeCode').value = ''
        }
        if (document.getElementById('empGroupNames')) {
            document.getElementById('empGroupNames').value = ''
        }

    }
    //Function to implement functionality for cost center dropdown selection. 
    onCostCenterSelect = (data) => {
        if (this.growl !== undefined && this.growl !== null) {
            this.growl.clear();
        }
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        let jsonCopy1 = Object.assign({},this.state.transferJSONTemplate);
        
        this.clearFilterControl();
        this.setState({
            displayDialog: false
        });
        if (data === '') {
            jsonCopy.costCenterCode = '';
            jsonCopy1.existingCCId = "";
            this.setState({
                employeeData: [],
                selectedEmployee : [],
                totalEmployeeRecords: undefined,
                jsonTemplate: jsonCopy,
                transferJSONTemplate : jsonCopy1
            });
            ReactDOM.unmountComponentAtNode(document.getElementById('costCenter'));
            ReactDOM.render(<CostCenter onChange={this.onCostCenterSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />, document.getElementById('costCenter'))
            return false;
        }

        jsonCopy.costCenterCode = data;
        jsonCopy.employeeName = '';
        jsonCopy.employeeId = '';
        jsonCopy.employeeTypeCode = '';
        jsonCopy.pageNumber = 0;

        jsonCopy1.existingCCId = data;

        this.setState({
            displayDialog: false,
            selectedEmployee : [],
            loading: true,
            jsonTemplate: jsonCopy,
            sortField: '',
            sortOrder: null,
            first: 0,
            transferJSONTemplate : jsonCopy1
        });

        this.getList(jsonCopy);
    }

    //Function to implement functionality of Number of Record Dropdown change of Pagination. 
    noOfRecordChange = (e) => {

        this.setState({
            displayDialog: false,
            dropdownDisplay: e.value,
            loading: true,
            first: 0
        })
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.recordCount = e.value.code;
        jsonCopy.pageNumber = 0;

        if (this.state.employeeData === undefined || this.state.employeeData.length === undefined) {
            this.setState({ loading: false });
            this.setState({ jsonTemplate: jsonCopy });
            return false;
        }

        this.getList(jsonCopy);
        this.setState({ jsonTemplate: jsonCopy });
    }

    //Function to implement functionality of Wrapping Long Text With "...".
    nameTemplate = (rowData, column) => {

        if (column.field === 'wfmTCostCenterMaster.costCenterName') {
            return <div className="extraTextWrapping" title={rowData.wfmTCostCenterMaster.costCenterName}>{rowData.wfmTCostCenterMaster.costCenterName}</div>;
        }
        else {
            return <div className="extraTextWrapping" title={rowData[column.field]}>{rowData[column.field]}</div>;
        }

    }


    wageTemplate = (rowData, column) => {
        if (column.field === 'wfmTWagesType.wagesTypeName') {
            return <div className="extraTextWrapping" title={rowData.wfmTWagesType.wagesTypeName}>{rowData.wfmTWagesType.wagesTypeName}</div>;
        }
        else {
            return <div className="extraTextWrapping" title={rowData[column.field]}>{rowData[column.field]}</div>;
        }
    }

    //Function to display comma seprated group name
    separateGroup = (rowData, column) => {
        let groupNameArray = rowData.empGroupNames.join();
        return <div className="extraTextWrapping" title={groupNameArray}>{groupNameArray}</div>;
    }

    changeFormatHoursWeek = (data, part) =>{
        if (data.length === 1 && part === "1"){
            return "0"+String(data);
        }
        else if (data.length === 1 && part === "2") {
            return String(data)+"0";
        }
        else return data;
    }

   //Function to display hours per week data in corresponding locale format
   hrsPerWeekTemplate = (rowData, column) => {
        
    if(rowData[column.field]!==null){
        let data = String(rowData[column.field]);
        let content = data.split('.');
        if(content[1] !== undefined){
            let hoursPerWeek = this.changeFormatHoursWeek(content[0],"1")+":"+this.changeFormatHoursWeek(content[1],"2");
            return <div className="extraTextWrapping" title={hoursPerWeek}>{hoursPerWeek}</div>;
        }
        else{
            return <div className="extraTextWrapping" title={rowData[column.field]}>{rowData[column.field]}</div>;
        }
        
    }
    else{
        return <div className="extraTextWrapping" title={rowData[column.field]}>{rowData[column.field]}</div>;
    }
    
}

    employeeIdTemplate = (rowData) => {
        if (rowData['wfmTEmployeeType'].employeeType !== translator.dummy) {
            return ( <div>{rowData.employeeId}</div>) }
    }

    handleSelection = (e) =>{
        
        this.setState({ selectedEmployee : e.value })
        
        let jsonCopy = Object.assign({},this.state.transferJSONTemplate);
        jsonCopy.existingCCId = this.state.jsonTemplate.costCenterCode;
        jsonCopy.employeeDetailsIds = [];
        
        if(
            e.value.length === this.state.recordSize 
            && document.getElementById('employeeId').value === ""
            && document.getElementById('employeeName').value === ""
            && document.getElementById('employeeTypeCode').value === ""
            && document.getElementById('empGroupNames').value === ""
        ){
            jsonCopy.selectAll = true;
        }
        else{
            jsonCopy.selectAll = false;
            e.value.map(data => {
                jsonCopy.employeeDetailsIds.push(data.employeeDetailsId);
                return 0;
            })
        }

        this.setState({ transferJSONTemplate : jsonCopy })
    }

    //Function called when callback is received from Dialog.
    callbackFromDialog = () => {
        let jsonCopy = Object.assign({},this.state.jsonTemplate);
        jsonCopy.employeeId = "";
        jsonCopy.employeeName = "";
        jsonCopy.employeeTypeCode = "";
        jsonCopy.sortBy = "employeeName";
        jsonCopy.sortDirection = "ASC";
        jsonCopy.pageNumber = 0;
        jsonCopy.empGroupNames = "";

        this.clearFilterControl();
        
        this.setState({ 
            displayDialog: false, 
            selectedEmployee : [],
            jsonTemplate: jsonCopy
        })
        this.getList(this.state.jsonTemplate);
    }

    //Function called when callback is received from Dialog on onHide event
    callbackToHideDialog = () => {
        this.setState({ displayDialog: false });
    }

    render() {

        translator.setLanguage(this.props.browserLanguage);

        const noOfRecord = [
            { name: translator.Records_150, code: 150 },
            { name: translator.Records_200, code: 200 },
            { name: translator.Records_250, code: 250 }
        ];

        let employeeIdFilter = <InputText maxLength="8" id="employeeId" onChange={(e) => this.filterCleared(e, "employeeId")} keyfilter={/^[0-9\b]+$/} className="employeeIdFilterWidth" onKeyDown={(e) => this.onEmployeeIdFilter(e.target.value, e.key)} />
        let employeeNameFilter = <InputText type="text" id="employeeName" onChange={(e) => this.filterCleared(e, "employeeName")} className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeNameFilter(e.target.value, e.key)} />
        let employeeTypeFilter = <InputText type="text" id="employeeTypeCode" onChange={(e) => this.filterCleared(e, "employeeTypeCode")} className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeTypeFilter(e.target.value, e.key)} />
        let groupNameFilter = <InputText type="text" id="empGroupNames" onChange={(e) => this.filterCleared(e, "empGroupNames")} className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeGroupFilter(e.target.value, e.key)} />
        
        return (

            <div className="bodyColor">
                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>

                {this.state.displayDialog ?
                    <TransferDialog 
                        displayDialog={true}
                        masterData={this.props.masterData}
                        onChange={this.callbackFromDialog}
                        browserLanguage={this.props.browserLanguage}
                        callbackPropToHideDialog={this.callbackToHideDialog}
                        transferJSONTemplate={this.state.transferJSONTemplate} /> : null }

                <Growl ref={(el) => this.growl = el} position="topright" ></Growl>

                <BreadCrumbs initial={translator.admin} final={translator.employeeTransfer} browserLanguage={this.props.browserLanguage} />
                <div>
                    <div className="costcenterPosition" id="costCenter">
                        <CostCenter lastUsedCostCenter={this.state.lastUsedCostCenter} onChange={this.onCostCenterSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />
                    </div>
                    <div className="buttonsPostion">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="btn-blank"></td>
                                    <td>
                                        <Button label={translator.transfer} title={translator.transfer} className="btnDefault"
                                            style={{ marginRight: '1em', padding: '1px', width: '97% ' }} onClick={(e)=>{this.setState({displayDialog:true})}}
                                            disabled={ this.state.selectedEmployee.length >0 ? false : true } />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {
                    this.state.jsonTemplate.costCenterCode !== '' ?
                        this.state.totalEmployeeRecords === 0 && (document.getElementById('employeeId') !== null ? (document.getElementById('employeeId').value === '' && document.getElementById('employeeName').value === '' && document.getElementById('employeeTypeCode').value === '') && this.state.loading === false : true) ?
                            null :
                            <div className="paginatorDropdownPosition" >
                                <span className="recordOF">{this.state.totalEmployeeRecords === undefined || this.state.totalEmployeeRecords === 0 ? '0-0' : (this.state.pageNumber * this.state.pageSize) + 1 + '-' + Number((this.state.pageNumber * this.state.pageSize) + Number(this.state.recordSize))} {translator.of} {this.state.totalEmployeeRecords === undefined ? 0 : this.state.totalEmployeeRecords} {translator.records}</span>
                                <div className="paginatorEmp">
                                    <Paginator rows={this.state.jsonTemplate.recordCount} totalRecords={this.state.totalEmployeeRecords === undefined ? 0 : this.state.totalEmployeeRecords} first={this.state.first} onPageChange={(e) => this.onPageChange(e)}></Paginator>
                                    <Dropdown value={this.state.dropdownDisplay} className="dropDwonDefault colRecord dropmargin" options={noOfRecord} onChange={(e) => this.noOfRecordChange(e)} placeholder={translator.Records_150} optionLabel="name" />
                                </div>
                            </div>
                        : null
                }

                <br />
                {
                    this.state.jsonTemplate.costCenterCode !== '' ?
                        this.state.totalEmployeeRecords === 0 && (document.getElementById('employeeId') !== null ? (document.getElementById('employeeId').value === '' && document.getElementById('employeeName').value === '' && document.getElementById('employeeTypeCode').value === '') && this.state.loading === false : true) ?
                            <h4 className="noRecordFoundMessage">{translator.noRecordFound}</h4> :
                            <DataTable
                                scrollable={browser().name === 'ie' ? false : true}
                                resizableColumns={true}
                                ref={(el) => this.dt = el}
                                editable={true}
                                value={this.state.employeeData}
                                rows={this.state.pageSize}
                                onSort={(e) => this.onSortChange(e)}
                                scrollHeight={getResolution()}
                                className="dataTable employeeTransferDatatableCheckbox"
                                frozenWidth="0px"
                                sortField={this.state.sortField}
                                sortOrder={this.state.sortOrder}
                                emptyMessage={translator.noRecordFound}
                                onSelectionChange = {(e) => this.handleSelection(e)}
                                selection={this.state.selectedEmployee}

                            >
                                <Column selectionMode="multiple" style={{width:'2em'}}/>
                                {/* <Column field="wfmTCostCenterMaster.costCenterName" header={translator.cc} className="textLeftAlign datatableHeaderWidth105" body={this.nameTemplate} /> */}
                                <Column field="employeeName" id="employeeName" header={translator.employeeName} sortable={true} filter={true} filterElement={employeeNameFilter} className="textLeftAlign datatableHeaderWidth135" body={this.nameTemplate} />
                                <Column field="wfmTEmployeeType.employeeType" id="employeeType" header={translator.employeeTypeCode} sortable={true} filter={true} filterElement={employeeTypeFilter} className="textLeftAlign datatableHeaderWidth135" />
                                <Column field="employeeId" header={translator.employeeId} sortable={true} filter={true} filterElement={employeeIdFilter} className="numberRightAlign datatableHeaderWidth135" body={this.employeeIdTemplate} />
                                <Column field="empGroupNames" id="empGroupNames" header={translator.groupName} filter={true} sortable={true} filterElement={groupNameFilter} className="textLeftAlign datatableHeaderWidth135" body={this.separateGroup} />
                                <Column field="wfmTWagesType.wagesTypeName" header={translator.wagesType} sortable={true} className="textLeftAlign datatableHeaderWidth105" body={this.wageTemplate} />
                                <Column field="hrsPerWeek" header={translator.hoursPerWeekly} sortable={true} className="numberRightAlign datatableHeaderWidth85" body={this.hrsPerWeekTemplate}/>
                                {/* <Column field="remainingVacations" header={translator.remainingVacations} sortable={true} className="numberRightAlign datatableHeaderWidth105" /> */}
                                {/* <Column field="remainingFlexLeaves" header={translator.remainingFlexible} sortable={true} className="numberRightAlign datatableHeaderWidth85" /> */}
                                {/* <Column field="savedLeaves" header={translator.savedLeaves} sortable={true} className="numberRightAlign datatableHeaderWidth105" /> */}
                                {/* <Column field="deactivationDate" header={translator.deactivationDate} sortable={true} className="numberRightAlign datatableHeaderWidth85"/> */}
                            </DataTable>
                        : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        masterData: state.masterData,
        browserLanguage: state.browserLanguage
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTransfer); 
