import React, { Component } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import '../breadcrumbs/BreadCrumbs.css';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js'

let translator = new LocalizedStrings(translation);

class BreadCrumbs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: '',
            language: 'en'
        }
    }
    componentDidMount = () => {
        if (this.props.final === "Employee") {
            this.setState({ url: '/employeeMaster' })
        }
        else if (this.props.final === "Shift") {
            this.setState({ url: '/shiftMaster' })
        }
    }

    render() {
        translator.setLanguage(this.state.language);
        const items = [
            { label: this.props.initial, url: '' },
            { label: this.props.final, url: '' }
        ];

        const home = { icon: 'pi pi-home', url: '' }

        return (
            <div >
                <BreadCrumb model={items} home={home} className="pmenuitemtext" />
            </div>
        );
    }
}


export default BreadCrumbs;