import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js'
let translator = new LocalizedStrings(translation);

class ConfirmationDialog extends Component {
    constructor() {
        super();
        this.state = {
            shiftWithTimeFormat: true
        }
    }

    //Function to call function when no button is pressed
    callFuncForNoBtn = () => {
        this.props.callbackForNo();
    }

    //Function to call function  when yes button is pressed
    callFuncForYesBtn = () => {
        this.props.callbackForYes(this.props.action);
    }

    //Function to call function  when yes button is pressed
    callFuncForYesBtnPrint = () => {
        this.props.callbackForYes(this.state.shiftWithTimeFormat);
    }

    displayShiftFormat = (value) => {
        this.setState({ loading: true });
        if (value === "shiftwithStartTime") {
            this.setState({ shiftWithTimeFormat: true })
        }
        else {
            this.setState({ shiftWithTimeFormat: false })
        }
    }

    //Function to set focus to Yes button
    setFocusOnYesBtn = () => {
        if (document.getElementById("yesbtn") !== null)
            setTimeout(function () { document.getElementById("yesbtn").focus(); }, 2);
    }

    render() {
        translator.setLanguage(this.props.lang);

        let dialogHeader = this.props.action === "printPDF" ? translator.printHeader :
            this.props.action === "unborrow" ?
                translator.confirmUnborrowDialogHeader :
                this.props.action === 'confirmAutoGeneratedShift' || this.props.action === 'acceptCopyShift' ?
                    translator.confirmAutoShiftHeader
                    : translator.rejectAutoShiftHeader

        let dialogFooter = (<div className="ui-dialog-buttonpane p-clearfix">
            <Button id="yesbtn" label={translator.yes} className="deleteButton" onClick={this.props.action === "printPDF" ? this.callFuncForYesBtnPrint : this.callFuncForYesBtn} />
            <Button label={translator.no} className="deleteButton" onClick={this.callFuncForNoBtn} />
        </div>);

        return (
            <Dialog visible={true} className="deleteDialogBoxPosition" modal={true} header={dialogHeader} onHide={this.callFuncForNoBtn} footer={dialogFooter} onShow={this.setFocusOnYesBtn}>
                {this.props.action === "printPDF" ?
                    <div>
                        <div><input name="displayFormat" type="radio" value="shiftwithStartTime" checked={this.state.shiftWithTimeFormat ? true : false} style={{ marginLeft: '20px' }} onChange={(e) => this.displayShiftFormat(e.target.value)} /><label>{translator.printPDFWithTime}</label></div>
                        <div><input name="displayFormat" type="radio" value="shiftwithoutStartTime" checked={!this.state.shiftWithTimeFormat ? true : false} style={{ marginLeft: '20px' }} onChange={(e) => this.displayShiftFormat(e.target.value)} /><label>{translator.printPDFWithoutTime}</label></div>
                    </div>

                    : this.props.action === "unborrow" ?
                        translator.confirmUnborrowDeleteMessage :
                        this.props.action === 'confirmAutoGeneratedShift' || this.props.action === 'acceptCopyShift' ?
                            translator.confirmAutoGeneratedShift
                            : translator.rejectAutoGeneratedShift
                }
            </Dialog>
        );

    }

}

export default ConfirmationDialog;