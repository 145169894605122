import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../navmenu/NavMenu.css';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import { Link } from 'react-router-dom';
import { CommonService } from '../../service/CommonService';
import { authContext } from '../../../src/adalConfig';
import { masterDataAction } from '../../actions/masterDataAction';
import { changeLanguageAction } from '../../actions/changeLanguageAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Growl } from 'primereact/growl';

let translator = new LocalizedStrings(translation);

class NavMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.commonService = new CommonService();
    }

    // Function to change language and pass to other components
    changeLanguage = (language) => {
        this.props.changeLanguageActionDispatcher(language);

        this.commonService.getMasterData(language)
            .then(res => {
                if (res !== null && res !== undefined) {
                    if (res.userMemberships.length === 0) {
                        window.alert("You don't have access to this page");
                        authContext.logOut();
                    }
                    else {
                        this.props.masterDataActionDispatcher(res);
                    }
                    if (this.growl !== undefined || this.growl !== null) {
                        this.growl.clear();
                    }
                }
            })
            .catch(err => {
                this.showMultiple(err)
            })
    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {
        if (this.growl !== undefined && this.growl !== null) {
            this.growl.clear();
        }
        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ sticky: true, severity: 'error', summary: translator.errorMessage, detail: detail });

    }

    //Function to implement logout.
    logout = () => {
        authContext.logOut();
    }
    render() {
        translator.setLanguage(this.props.browserLanguage);
        return (

            <React.Fragment >
                <Growl ref={(el) => this.growl = el} position="topright" ></Growl>
                <Navbar variant="light">
                    <Navbar.Brand ><img alt="posti" title={this.props.masterData !== null ? this.props.masterData.devopsBuildNumber : null}
                        src={require('../../images/posti_logo_orange.png')}
                        className="navLogo" /></Navbar.Brand>
                    <span className="navmenu-wfm">{translator.workForceManagement}</span>
                    <Nav className="mr-auto">
                    </Nav>
                    <Nav className="navmenu-account">

                        <NavDropdown title={this.props.browserLanguage === 'en' ? 'English' : 'Suomi'} className="visibleLanguage">
                            <NavDropdown.Item onClick={() => this.changeLanguage('en')}>English</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => this.changeLanguage('fi')}>Suomi</NavDropdown.Item>
                        </NavDropdown>

                        <i className="accountname pi pi-user extraTextWrapping" title={this.props.masterData === null ? '' : this.props.masterData.userName} >{this.props.masterData === null ? '' : this.props.masterData.userName}</i>
                        <i onClick={() => this.logout()} className="logoutaccount pointerShow pi pi pi-fw pi-power-off">{translator.logout}</i>
                    </Nav>
                </Navbar>

                <Navbar variant="light" className="downNavbarWidth">
                    <Navbar.Brand></Navbar.Brand>
                    <Navbar.Collapse id="responsive-navbar-nav" className="linkspace">

                        <Nav className="mr-auto">
                            <NavDropdown title={translator.master}>
                                <NavDropdown.Item as={Link} to="/employeeMaster" >{translator.employee}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/shiftMaster" >{translator.shift}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/skillSet" >{translator.skill}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/kikyHours" >{translator.kikyHours}</NavDropdown.Item>
                            </NavDropdown>

                            <NavDropdown title={translator.allocation} >
                                <NavDropdown.Item as={Link} to="/shiftAllocation">{translator.navbarShiftAllocation}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/employeeAllocation">{translator.navbarEmployeeAllocation}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/autoShiftGeneration">{translator.autoGenerateshift}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/vacationPlanning">{translator.vacation}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/yearlyShiftAllocation">{translator.yearlyShiftAllocation}</NavDropdown.Item>
                            </NavDropdown>

                            <NavDropdown title={translator.balancing} >
                                <NavDropdown.Item as={Link} to="/dailyTVS">{translator.daily}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/weeklyTVS">{translator.weekly}</NavDropdown.Item>
                            </NavDropdown>

                            {
                                this.props.masterData !== null ?
                                    this.props.masterData.admin === true ?
                                        <NavDropdown title={translator.admin} >
                                            <NavDropdown.Item as={Link} to="/admin">{translator.adminPanel}</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="/dashboard">{translator.dashboard}</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="/employeeTransfer">{translator.employeeTransfer}</NavDropdown.Item>
                                        </NavDropdown>
                                        : null
                                    : null
                            }

                        </Nav>

                    </Navbar.Collapse>
                </Navbar>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        masterData: state.masterData,
        browserLanguage: state.browserLanguage
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        masterDataActionDispatcher: bindActionCreators(masterDataAction, dispatch),
        changeLanguageActionDispatcher: bindActionCreators(changeLanguageAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
