import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { CommonService } from '../../service/CommonService';
import { Column } from 'primereact/column';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import Row from 'react-bootstrap/Row';
import { ColumnGroup } from 'primereact/columngroup';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputText } from 'primereact/inputtext';
import { keyboardValues, getResolutionDailyTVS } from "../../utility/commonUtility";
import './TVSReport.css';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import browser from 'browser-detect';
import moment from 'moment';
import { Growl } from 'primereact/growl';
import leaveCodes from '../../utility/leaveCodes.json';
import momentDurationFormatSetup from "moment-duration-format";
import HaittaPerAndCommentDialog from './HaittaPerAndCommentDialog';

let translator = new LocalizedStrings(translation);
let totalTvsRecords = 0;
let pageNumber = 0;
let pageSize = 0;

class WeeklyReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            totalRecordsCount: 0,
            weeklyData: [],
            tvsData: null,
            startDate: [],
            saveWeeklyCommentAndHaittaInputJsonTemplate: {
                weeklyHaittaAndCommentId: "",
                weeklyComment: "",
                weekStartDate: "",
                weeklyHaitta: "",
                costCenterMasterId: ""
            },
        }
        this.commonService = new CommonService();
        momentDurationFormatSetup(moment);
    }

    componentDidMount = () => {

        if (document.getElementById('employeeName')) {
            document.getElementById('employeeName').value = ''
        }
        if (document.getElementById('employeeTypeCode')) {
            document.getElementById('employeeTypeCode').value = ''
        }

        if (this.props.previousData === null) {
            this.setState({ jsonTemplate: this.props.jsonTemplate });

            if (this.props.jsonTemplate.balancingPeriodID !== "" && this.props.jsonTemplate.centerMasterID !== "" && this.props.jsonTemplate.planningPeriodID !== "")
                this.getTVS(this.props.jsonTemplate);
        }
        else {
            totalTvsRecords = this.props.previousData.totalRecords;
            pageNumber = this.props.previousData.pageNumber;
            pageSize = this.props.previousData.shiftPageSize;

            this.setState({
                tvsData: this.props.previousData.data,
                startDate: this.props.previousData.weeksStartDate,
                weeklyData: this.props.previousData.week,
                jsonTemplate: this.props.jsonTemplate,
                loading: false,
                totalRecordsCount: this.props.previousData.totalRecords
            })
        }
    }

    componentWillReceiveProps = (nextProps) => {

        if (document.getElementById('employeeName')) {
            document.getElementById('employeeName').value = ''
        }
        if (document.getElementById('employeeTypeCode')) {
            document.getElementById('employeeTypeCode').value = ''
        }

        if (nextProps.previousData === null) {
            this.setState({
                jsonTemplate: nextProps.jsonTemplate
            });

            if (nextProps.jsonTemplate.balancingPeriodID !== "" && nextProps.jsonTemplate.centerMasterID !== "" && nextProps.jsonTemplate.planningPeriodID !== "")
                this.getTVS(nextProps.jsonTemplate);
        }
        else {
            totalTvsRecords = nextProps.previousData.totalRecords;
            pageNumber = nextProps.previousData.pageNumber;
            pageSize = nextProps.previousData.shiftPageSize;
            this.setState({
                tvsData: nextProps.previousData.data,
                startDate: nextProps.previousData.weeksStartDate,
                weeklyData: nextProps.previousData.week,
                jsonTemplate: nextProps.jsonTemplate,
                loading: false
            })

        }
    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {

        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });

    }

    //Function to call API for Daily TVS Data
    getTVS = (payload) => {
        this.setState({ loading: true });
        this.commonService.getWeeklyTVSData(payload, this.props.browserLanguage)
            .then(res => {
                if (res !== undefined && res !== null) {
                    totalTvsRecords = res.totalRecords;
                    pageNumber = res.pageNumber;
                    pageSize = res.shiftPageSize;
                    this.setState({
                        tvsData: res.data,
                        startDate: res.weeksStartDate,
                        weeklyData: res.week,
                        loading: false,
                        totalRecordsCount: res.totalRecords
                    })
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
                //Utility.showMultiple(err);
            })
    }

    //Function to implement Filtering functionality on Employee Name. 
    onemployeeNameFilter = (empName, key) => {

        if (keyboardValues.includes(key) && empName !== "") {
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.employeeName = empName;
            jsonCopy.employeeSearch.pageNumber = 0;
            this.getFilterFunction(jsonCopy);
        }
    }

    //Function to implement Filtering functionality on Employee Type Code. 
    onemployeeTypeFilter = (empType, key) => {
        if (keyboardValues.includes(key) && empType !== "") {
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.employeeTypeCode = empType;
            jsonCopy.employeeSearch.pageNumber = 0;
            this.getFilterFunction(jsonCopy);
        }
    }

    filterCleared = (e, field) => {

        if (e.target.value === "" && field === "employeeName") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.employeeName = "";
            jsonCopy.employeeSearch.pageNumber = 0;
            this.setState({ jsonTemplate: jsonCopy });
            this.getTVS(jsonCopy);
        }
        else if (e.target.value === "" && field === "employeeTypeCode") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.employeeTypeCode = "";
            jsonCopy.employeeSearch.pageNumber = 0;
            this.setState({ jsonTemplate: jsonCopy });
            this.getTVS(jsonCopy);
        }

    }

    //Function to call API for filtering records
    getFilterFunction = (jsonCopy) => {
        jsonCopy.employeeSearch.pageNumber = 0;
        this.setState({
            jsonTemplate: jsonCopy,
            loading: true,
            first: 0
        });
        this.getTVS(jsonCopy);
    }

    //Function to implement Sorting functionality. 
    onSortChange = (e) => {

        this.setState({
            sortField: e.sortField,
            sortOrder: e.sortOrder,
            loading: true,
            first: 0
        });

        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeSearch.sortDirection = 'ASC';
        jsonCopy.employeeSearch.sortBy = e.sortField;
        jsonCopy.employeeSearch.pageNumber = 0;

        if (e.sortOrder === -1) {
            jsonCopy.employeeSearch.sortDirection = "DESC"
        }

        this.getTVS(jsonCopy);
        this.setState({ jsonTemplate: jsonCopy });
    }

    //Function to implement functionality of Number of Record Dropdown change of Pagination. 
    noOfRecordChange = (e) => {

        this.setState({
            dropdownDisplay: e.value,
            loading: true,
            first: 0
        })
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeSearch.recordCount = e.value.code;
        jsonCopy.employeeSearch.pageNumber = 0;

        this.getTVS(jsonCopy);
        this.setState({ jsonTemplate: jsonCopy });
    }

    //Function to implement Change Page functionality of Paginator. 
    onPageChange = (e) => {
        this.setState({ first: e.first, loading: true, displayDialog: false });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeSearch.pageNumber = e.page;
        this.getTVS(jsonCopy);
    }
    //Function to implement functionality of data right align.
    dataTemplate = (rowData, column) => {
        return <div style={{ textAlign: 'right' }}>{rowData.weekHrs[column.field.split(".")[1]]}</div>;
    }
    // //Function to implement functionality of Wrapping Long Text With "...".
    // nameTemplate = (rowData, column) => {
    //     return <div className="extraTextWrapping" title={rowData[column.field]}>{rowData[column.field]}</div>;
    // }

    //Function to implement functionality of Wrapping Long Text With "..."
    nameTemplate = (rowData, column) => {
        /** Set tooltip value for employee name column as cccode appended with ccname 
         * for borrowed employees otherwise show only employee name.
         * For other fields, show value*/
        let title = rowData[column.field]
        let iconShowBorrowed=false
        if(column.rowData.borrowedInOtherCC){
            title=rowData[column.field] + "\n" + column.rowData.borrowedInOtherCCNames
            iconShowBorrowed=true
        }else if(column.rowData.borrowed){
            title=rowData[column.field] + "\n" + column.rowData.homeCostCenterCodeAndName
            iconShowBorrowed=true
        }
       // let title = column.rowData.borrowed ? rowData[column.field] + "\n" + column.rowData.homeCostCenterCodeAndName : rowData[column.field]
        return (<div className="extraTextWrappingShitAllocation" title={title} style={{ float: 'unset' }}>
            {rowData[column.field]}
            {column.field === 'employeeName' && iconShowBorrowed?
                <i className="pi pi-user-minus" style={{ float: 'right', cursor: 'pointer' }}></i>
                : null
            }
        </div>
        );
    }

    weekCommentClicked = (e, record) => {
        let data = record.commentAndHaittaInput;
        let dateString = Object.keys(record.allocationPlanDetailsMap);
        let haittaCopy = Object.assign({}, this.state.saveWeeklyCommentAndHaittaInputJsonTemplate);
        if (data !== null) {
            haittaCopy.weeklyHaittaAndCommentId = data.weeklyHaittaAndCommentId;
            haittaCopy.weeklyComment = data.weeklyComment;
            haittaCopy.weekStartDate = data.weekStartDate;
            haittaCopy.weeklyHaitta = data.weeklyHaitta;
            haittaCopy.costCenterMasterId = data.costCenterMasterId;
            this.setState({ clicked: true, saveWeeklyCommentAndHaittaInputJsonTemplate: haittaCopy, recordWeekno: record.weekNo });
        }
        else if (data === null) {
            haittaCopy.weeklyHaittaAndCommentId = "0";
            haittaCopy.weeklyComment = "";
            haittaCopy.weekStartDate = this.getStartDate(dateString);
            haittaCopy.weeklyHaitta = "";
            haittaCopy.costCenterMasterId = this.state.jsonTemplate.centerMasterID;
            this.setState({ clicked: true, saveWeeklyCommentAndHaittaInputJsonTemplate: haittaCopy, recordWeekno: record.weekNo });
        }
    }

    weeklyHaittaTooltipData = (data) => {
        if (data.commentAndHaittaInput.weeklyHaitta !== "" && data.commentAndHaittaInput.weeklyComment !== "") {
            return translator.haittaPercentage + ": " + data.commentAndHaittaInput.weeklyHaitta +", "+ translator.weekComment + ": " + data.commentAndHaittaInput.weeklyComment;
        }

        else if (data.commentAndHaittaInput.weeklyHaitta !== "" && data.commentAndHaittaInput.weeklyComment === "") {
            return translator.haittaPercentage + ": " + data.commentAndHaittaInput.weeklyHaitta;
        }

        else if (data.commentAndHaittaInput.weeklyHaitta === "" && data.commentAndHaittaInput.weeklyComment !== "") {
            return  translator.weekComment + ": " + data.commentAndHaittaInput.weeklyComment;
        }

        else if (data.commentAndHaittaInput.weeklyHaitta === "" && data.commentAndHaittaInput.weeklyComment === "") {
            return null;
        }
    }

    getStartDate = (data) =>{
        var i, j;
        let start = this.state.startDate;
        for( i=0 ; i< start.length; i++){
            for(j=0; j < data.length; j++){
                let splitDate = data[j].split('-');
                let newDate = splitDate[2] + "-" + splitDate[1];
                if(start[i] === newDate){
                    return data[j]
                }
            }
        }
    }

    callbackUpdateService = (record) => {
        this.setState({ clicked: false, loading:true })
        let haittaValue = record.weeklyHaitta.replace(",", ".");
        let tempVal = haittaValue.split('.')

        if(tempVal[1] !== undefined && tempVal[1].length===0){haittaValue = tempVal[0] }

        let haittaCopy = Object.assign({}, this.state.saveWeeklyCommentAndHaittaInputJsonTemplate);
        haittaCopy.weeklyComment = record.weeklyComment;
        haittaCopy.weeklyHaitta = haittaValue;

        this.commonService.updateWeeklyCommentAndHaittaInput(haittaCopy, this.props.browserLanguage)
        .then(res => {
            let weeklyCopy = Object.assign([], this.state.weeklyData);
            weeklyCopy = weeklyCopy.map((data) => {
                if(data.weekNo === this.state.recordWeekno){
                    data.commentAndHaittaInput = res;
                    return data;
                }
                return data;
            })
            this.setState({weeklyData :weeklyCopy, loading:false});
            this.getTVS(this.props.jsonTemplate);
        }).catch(error =>{
            this.setState({ loading: false });
            this.showMultiple(error);
        })
    }

    hideDialog = () => {
        this.setState({ clicked: false });
    }
    render() {

        translator.setLanguage(this.props.browserLanguage);
        let employeeNameFilter = <InputText type="text" id="employeeName" onChange={(e) => { this.filterCleared(e, "employeeName") }} className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeNameFilter(e.target.value, e.key)}  />
        let employeeTypeFilter = <InputText type="text" id="employeeTypeCode" onChange={(e) => { this.filterCleared(e, "employeeTypeCode") }} className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeTypeFilter(e.target.value, e.key)} />
        const noOfRecord = [
            { name: translator.Records_150, code: 150 },
            { name: translator.Records_200, code: 200 },
            { name: translator.Records_250, code: 250 }
        ];
        // let availableHoursSum = 0;
        // let availableMinutesSum = 0;

        // let scheduledHrsPermanentSum = 0;
        // let scheduledMinutesPermanentSum = 0;

        // let scheduledHrsFixedtermSum = 0;
        // let scheduledMinutesFixedtermSum = 0;

        // let scheduledHrsTemporarySum = 0;
        // let scheduledMinutesTemporarySum = 0;

        // let scheduledHrsOtherSubSum = 0;
        // let scheduledMinutesOtherSubSum = 0;

        // let eroSum = 0;
        // let eroSumMinutes = 0;

        let headerGroup =
            <ColumnGroup>
                <Row>
                    <Column header={translator.weeks} colSpan={2} style={{ textAlign: 'right', border: '0px' }} />
                    {this.state.weeklyData.map((data, key) => {
                      if (key===4 || key===9 || key===14){
                        return <Column header={data.weekNo} key={key} /> 
                       }else {
                       return <Column style={{ paddingTop: '0px', paddingRight: '0px' }}
                         header={<div> <div style={{ marginLeft: '-22%', marginTop: '14%' }}>{data.weekNo}</div> 
                         <span key={key} id={data.commentAndHaittaInput !== null ? "haittaTooltip" : null} 
                         aria-label={data.commentAndHaittaInput !== null && this.weeklyHaittaTooltipData(data) !== null ? 
                            this.weeklyHaittaTooltipData(data) : null}> 
                            <i className="pi pi-comment" style={{ float: 'right', marginTop: '-43%', color: data.commentAndHaittaInput !== null ?
                             "rgb(141, 205, 255)" : "rgb(206, 199, 199)" }} onClick={(e) => {this.weekCommentClicked(e, data)}} /> </span> 
                             </div>} key={key} />}
                    })}
                   {/* <Column header={translator.total} colSpan={2} /> */}
                </Row>
                <Row>
                    <Column header={translator.targetHours} colSpan={2} style={{ textAlign: 'right', border: '0px' }} />
                    {this.state.weeklyData.map((data, key) => {

                        // availableHoursSum += Number(data.targetHoursPerWeek.split(":")[0]) * 60
                        // availableMinutesSum += Number(data.targetHoursPerWeek.split(":")[1]);

                        return <Column header={data.targetHoursPerWeek} key={key} style={{ backgroundColor: 'rgb(255, 224, 179)', textAlign: 'right' }} />
                    })}

                    {/* <Column header={moment.duration((availableHoursSum + availableMinutesSum), 'minutes').format('hh:mm') === "0" ? "0:00" : moment.duration((availableHoursSum + availableMinutesSum), 'minutes').format('hh:mm')} style={{ textAlign: 'right', backgroundColor: 'rgb(255, 224, 179)' }} colSpan={2} /> */}
                </Row>

                <Row>
                    <Column header={translator.permanentScheduled} colSpan={2} style={{ textAlign: 'right', border: '0px' }} />
                    {this.state.weeklyData.map((data, key) => {

                        // scheduledHrsPermanentSum += Number(data.scheduledHrsPermanent.split(":")[0]) * 60
                        // scheduledMinutesPermanentSum += Number(data.scheduledHrsPermanent.split(":")[1]);

                        return <Column header={data.scheduledHrsPermanent} key={key} style={{ backgroundColor: 'rgb(255, 255, 102)', textAlign: 'right' }} />
                    })}
                    {/* <Column header={moment.duration((scheduledHrsPermanentSum + scheduledMinutesPermanentSum), 'minutes').format('h:mm') === "0" ? "0:00" : moment.duration((scheduledHrsPermanentSum + scheduledMinutesPermanentSum), 'minutes').format('h:mm')} style={{ textAlign: 'right', backgroundColor: 'rgb(255, 255, 102)' }} colSpan={2} /> */}
                </Row>

                <Row>
                    <Column header={translator.fixedTermScheduled} colSpan={2} style={{ textAlign: 'right', border: '0px' }} />
                    {this.state.weeklyData.map((data, key) => {


                        // scheduledHrsFixedtermSum += Number(data.scheduledHrsFixedterm.split(":")[0]) * 60;
                        // scheduledMinutesFixedtermSum += Number(data.scheduledHrsFixedterm.split(":")[1]);

                        return <Column header={data.scheduledHrsFixedterm} key={key} style={{ backgroundColor: 'rgb(255, 255, 102)', textAlign: 'right' }} />
                    })}
                    {/* <Column header={moment.duration((scheduledHrsFixedtermSum + scheduledMinutesFixedtermSum), 'minutes').format('h:mm') === "0" ? "0:00" : moment.duration((scheduledHrsFixedtermSum + scheduledMinutesFixedtermSum), 'minutes').format('h:mm')} style={{ textAlign: 'right', backgroundColor: 'rgb(255, 255, 102)' }} colSpan={2} /> */}
                </Row>

                <Row>
                    <Column header={translator.rented} colSpan={2} style={{ textAlign: 'right', border: '0px' }} />
                    {this.state.weeklyData.map((data, key) => {

                        // scheduledHrsTemporarySum += Number(data.scheduledHrsTemporary.split(":")[0]) * 60;
                        // scheduledMinutesTemporarySum += Number(data.scheduledHrsTemporary.split(":")[1]);

                        return <Column header={data.scheduledHrsTemporary} key={key} style={{ backgroundColor: 'rgb(255, 255, 102)', textAlign: 'right' }} />
                    })}
                    {/* <Column header={moment.duration((scheduledHrsTemporarySum + scheduledMinutesTemporarySum), 'minutes').format('h:mm') === "0" ? "0:00" : moment.duration((scheduledHrsTemporarySum + scheduledMinutesTemporarySum), 'minutes').format('h:mm')} style={{ textAlign: 'right', backgroundColor: 'rgb(255, 255, 102)' }} colSpan={2} /> */}
                </Row>

                <Row>
                    <Column header={translator.subContractor} colSpan={2} style={{ textAlign: 'right', border: '0px' }} />
                    {this.state.weeklyData.map((data, key) => {

                        // scheduledHrsTemporarySum += Number(data.scheduledHrsSubcontractor.split(":")[0]) * 60;
                        // scheduledMinutesTemporarySum += Number(data.scheduledHrsSubcontractor.split(":")[1]);

                        return <Column header={data.scheduledHrsSubcontractor} key={key} style={{ backgroundColor: 'rgb(255, 255, 102)', textAlign: 'right' }} />
                    })}
                    {/* <Column header={moment.duration((scheduledHrsTemporarySum + scheduledMinutesTemporarySum), 'minutes').format('h:mm') === "0" ? "0:00" : moment.duration((scheduledHrsTemporarySum + scheduledMinutesTemporarySum), 'minutes').format('h:mm')} style={{ textAlign: 'right', backgroundColor: 'rgb(255, 255, 102)' }} colSpan={2} /> */}
                </Row>

                <Row>
                    <Column header={translator.dummy} colSpan={2} style={{ textAlign: 'right', border: '0px' }} />
                    {this.state.weeklyData.map((data, key) => {

                        // scheduledHrsOtherSubSum += Number(data.scheduledHrsOtherSub.split(":")[0]) * 60;
                        // scheduledMinutesOtherSubSum += Number(data.scheduledHrsOtherSub.split(":")[1]);

                        return <Column header={data.scheduledHrsOtherSub} key={key} style={{ backgroundColor: 'rgb(255, 255, 102)', textAlign: 'right' }} />
                    })}
                    {/* <Column header={moment.duration((scheduledHrsOtherSubSum + scheduledMinutesOtherSubSum), 'minutes').format('h:mm') === "0" ? "0:00" : moment.duration((scheduledHrsOtherSubSum + scheduledMinutesOtherSubSum), 'minutes').format('h:mm')} style={{ textAlign: 'right', backgroundColor: 'rgb(255, 255, 102)' }} colSpan={2} /> */}
                </Row>
                <Row> 
                    <Column header={translator.totalPlanHours} colSpan={2} style={{ textAlign: 'right', border: '0px' }} />
                    {this.state.weeklyData.map((data, key) => {
                        return <Column header={data.totalPlanHours} key={key} style={{  textAlign: 'right' }} />
                    })} 
                    {/* <Column header={moment.duration((scheduledHrsTemporarySum + scheduledMinutesTemporarySum), 'minutes').format('h:mm') === "0" ? "0:00" : moment.duration((scheduledHrsTemporarySum + scheduledMinutesTemporarySum), 'minutes').format('h:mm')} style={{ textAlign: 'right', backgroundColor: 'rgb(255, 255, 102)' }} colSpan={2} /> */}
                 </Row>
                <Row>
                    <Column header={translator.remainingHours} colSpan={2} style={{ textAlign: 'right', border: '0px' }} />
                    {this.state.weeklyData.map((data, key) => {

                        // eroSum += Number(data.ero.split(":")[0]) * 60;
                        // eroSumMinutes += Number(data.ero.split(":")[1]);

                        if (Number(data.ero.split(":")[0]) < 0) {
                            return <Column header={data.ero} key={key} className="negativeDifference" style={{ textAlign: 'right' }} />
                        }
                        else {
                            return <Column header={data.ero} key={key} style={{ textAlign: 'right' }} />
                        }
                    })}
                    {/* {
                        eroSum < 0 ? <Column style={{ textAlign: 'right' }} header={moment.duration((eroSum + eroSumMinutes), 'minutes').format('h:mm') === "0" ? "0:00" : moment.duration((eroSum + eroSumMinutes), 'minutes').format('h:mm')} className="negativeDifference" colSpan={2} /> : <Column header={moment.duration((eroSum + eroSumMinutes), 'minutes').format('h:mm') === "0" ? "0:00" : moment.duration((eroSum + eroSumMinutes), 'minutes').format('h:mm')} style={{ textAlign: 'right' }} colSpan={2} />
                    } */}
                </Row>
                <Row>
                    <Column header={translator.remainingPercentage} colSpan={2} style={{ textAlign: 'right', border: '0px' }} />
                    {this.state.weeklyData.map((data, key) => {

                        // eroSum += Number(data.remainingHrsPercentage.split(":")[0]) * 60;
                        // eroSumMinutes += Number(data.remainingHrsPercentage.split(":")[1]);

                        if (data.remainingHrsPercentage.includes('-')) {
                            return <Column header={data.remainingHrsPercentage} key={key} className="negativeDifference" style={{ textAlign: 'right' }} />
                        }
                        else {
                            return <Column header={data.remainingHrsPercentage} key={key} style={{ textAlign: 'right' }} />
                        }
                    })}
                    {/* {
                        eroSum < 0 ? <Column style={{ textAlign: 'right' }} header={moment.duration((eroSum + eroSumMinutes), 'minutes').format('h:mm') === "0" ? "0:00" : moment.duration((eroSum + eroSumMinutes), 'minutes').format('h:mm')} className="negativeDifference" colSpan={2} /> : <Column header={moment.duration((eroSum + eroSumMinutes), 'minutes').format('h:mm') === "0" ? "0:00" : moment.duration((eroSum + eroSumMinutes), 'minutes').format('h:mm')} style={{ textAlign: 'right' }} colSpan={2} />
                    } */}
                </Row>
                <Row>
                    <Column header={translator.reservedcodes} colSpan={2} style={{ textAlign: 'right', border: '0px' }} />
                    {this.state.weeklyData.map((data, key) => {
                        return <Column header={<div title={data.reservedCodeToolTip}>{data.reservationCodeHrs}</div>} key={key} style={{  textAlign: 'right' }} />
                    })}
                    {/* <Column header={moment.duration((scheduledHrsTemporarySum + scheduledMinutesTemporarySum), 'minutes').format('h:mm') === "0" ? "0:00" : moment.duration((scheduledHrsTemporarySum + scheduledMinutesTemporarySum), 'minutes').format('h:mm')} style={{ textAlign: 'right', backgroundColor: 'rgb(255, 255, 102)' }} colSpan={2} /> */}
                </Row>


                <Row>
                    {/* <Column header={translator.cc} style={{ width: '115px' }} /> */}
                    <Column field="employeeName" filter={true} filterElement={employeeNameFilter} sortable={true} header={translator.employeeName}  />
                    <Column field="employeeType" filter={true} filterElement={employeeTypeFilter} sortable={true} header={translator.employeeTypeCode} />
                    {
                        this.state.startDate.length > 0 ? this.state.startDate.map((data, key) => {
                            return (
                                <Column header={data} key={key} style={{ width: '60px' }} />
                            )
                        }) : null
                    }

                    <Column header={translator.remainingHours} style={{ width: '65px' }} />
                    <Column header={translator.workHrs} style={{ width: '60px' }} />
                    <Column header={translator.absences} style={{ width: '60px' }} />
                </Row>
            </ColumnGroup>

        return (
            <div className="bodyColorTVS">

                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>

                {this.state.clicked ? <HaittaPerAndCommentDialog callbackUpdateService={this.callbackUpdateService} browserLanguage={this.props.browserLanguage} callbackProp={this.hideDialog} haittaPercentageAndComment={this.state.saveWeeklyCommentAndHaittaInputJsonTemplate} admin={this.props.masterData.admin}/> : null}
                <Growl ref={(el) => this.growl = el} position="topright" ></Growl>
                {
                    this.state.tvsData ? this.state.totalRecordsCount === 0 && (document.getElementById('employeeName') !== null ? (document.getElementById('employeeName').value === "" && document.getElementById('employeeTypeCode').value === "" && this.state.loading === false) : true) ?
                        <h4 className="noRecordFoundMessage">{translator.noRecordFound}</h4> :
                        <div>
                            <div className="dvtvsPagging">

                                <div className="recordOfTVS">
                                    {totalTvsRecords === undefined || totalTvsRecords === 0 ? '0-0' : (pageNumber * this.state.jsonTemplate.employeeSearch.recordCount) + 1 + '-' + Number((pageNumber * this.state.jsonTemplate.employeeSearch.recordCount) + Number((pageSize)))} {translator.of} {totalTvsRecords === undefined ? 0 : totalTvsRecords} {translator.records}
                                </div>
                                <Paginator
                                    className="tvsPagginator"
                                    rows={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.employeeSearch.recordCount : null}
                                    totalRecords={totalTvsRecords === undefined ? 0 : totalTvsRecords}
                                    first={this.state.first}
                                    onPageChange={(e) => this.onPageChange(e)}></Paginator>

                                <Dropdown
                                    value={this.state.dropdownDisplay}
                                    className="colRecordTVSWeekly dropmarginTVS dropDwonDefault"
                                    options={noOfRecord}
                                    onChange={(e) => this.noOfRecordChange(e)}
                                    placeholder={translator.Records_150}
                                    optionLabel="name" />
                            </div>
                            <DataTable
                                value={this.state.tvsData}
                                headerColumnGroup={this.state.tvsData ? headerGroup : null}
                                scrollable={browser().name === 'ie' ? false : true}
                                ref={(el) => this.dt = el}
                                onSort={(e) => this.onSortChange(e)}
                                scrollHeight={getResolutionDailyTVS()}
                                className="tvsDatatable"
                                emptyMessage={translator.noRecordFound}
                                sortField={this.state.sortField}
                                sortOrder={this.state.sortOrder}
                            >

                                {/* <Column field="costCenter" header={translator.cc} style={{ width: '115px' }} body={this.nameTemplate} /> */}
                                <Column field="employeeName" filter={true} filterElement={employeeNameFilter} sortable={true} header={translator.employeeName} style={{ width: '135px' }} body={this.nameTemplate} />
                                <Column field="employeeType" filter={true} filterElement={employeeTypeFilter} sortable={true} header={translator.employeeTypeCode} style={{ width: '135px' }} />

                                <Column field="weekHrs.week1" style={{ width: '58px' }} className="textAlignCenterHeaderTVS" body={this.dataTemplate} />
                                <Column field="weekHrs.week2" style={{ width: '58px' }} className="textAlignCenterHeaderTVS" body={this.dataTemplate} />
                                <Column field="weekHrs.week3" style={{ width: '58px' }} className="textAlignCenterHeaderTVS" body={this.dataTemplate} />
                                <Column field="weekHrs.week4" style={{ width: '58px' }} className="textAlignCenterHeaderTVS" body={this.dataTemplate} />
                                <Column field="weekHrs.week5" style={{ width: '58px' }} className="textAlignCenterHeaderTVS" body={this.dataTemplate} />
                                <Column field="weekHrs.week6" style={{ width: '58px' }} className="textAlignCenterHeaderTVS" body={this.dataTemplate} />
                                <Column field="weekHrs.week7" style={{ width: '58px' }} className="textAlignCenterHeaderTVS" body={this.dataTemplate} />
                                <Column field="weekHrs.week8" style={{ width: '58px' }} className="textAlignCenterHeaderTVS" body={this.dataTemplate} />
                                <Column field="weekHrs.week9" style={{ width: '58px' }} className="textAlignCenterHeaderTVS" body={this.dataTemplate} />
                                <Column field="weekHrs.week10" style={{ width: '58px' }} className="textAlignCenterHeaderTVS" body={this.dataTemplate} />
                                <Column field="weekHrs.week11" style={{ width: '58px' }} className="textAlignCenterHeaderTVS" body={this.dataTemplate} />
                                <Column field="weekHrs.week12" style={{ width: '58px' }} className="textAlignCenterHeaderTVS" body={this.dataTemplate} />
                                <Column field="weekHrs.remaininghrs" header={translator.remainingHours} style={{ width: '70px' }} className="textAlignCenterHeaderTVS" body={this.dataTemplate} />
                                <Column field="weekHrs.workHours" header={translator.workHrs} style={{ width: '70px' }} className="textAlignCenterHeaderTVS" body={this.dataTemplate} />
                                <Column field="weekHrs.absences" header={translator.absences} style={{ width: '70px' }} className="textAlignCenterHeaderTVS" body={this.dataTemplate} />

                            </DataTable>
                        </div>
                        : null
                }

            </div>
        );
    }
}

export default WeeklyReport;