import React, { Component } from 'react';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import FileSaver from 'file-saver';
import { CommonService } from '../../service/CommonService';
import './AdminPage.css';
import AdminUpload from '../../components/uploadfile/AdminUpload.js';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Growl } from 'primereact/growl';
import { Redirect } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import leaveCodes from '../../utility/leaveCodes.json';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { getResolutionCCMaintainenace, getResolutionCCMapping } from '../../utility/commonUtility';
import { ColumnGroup } from 'primereact/columngroup';

let translator = new LocalizedStrings(translation);

class AdminPage extends Component {
    constructor() {
        super();
        this.state = {
            activeIndex: 0,
            activeTabIndex: 0,
            enableCostCenterMappingDatatableSaveButton: false,
            loading: false,
            ccMaintenanceData: [],
            supervisorDropdownValue: "",
            costCenterDetails: [],
            supervisorDetails: [],
            costCenterSupervisorMapping: [],
            selectedCostCenterData: [],
            multipleCostCenterDetails: [],
            disableBaseline: true,
            baselineTemplate: {
                costCenterIds: [],
                planningPeriodId: 0,
                employeeTypeIds: [],
                baselineComment: "",
                fromAdmin: true,
                allSelected : true

            },
            updateMappingJsonTemplate: {
                supervisorDetailsList: [],
                mapping: [
                    {
                        supervisorAdId: "",
                        supervisorEmailId: "",
                        costCenterCodes: []
                    }
                ],
                costCentersList: [
                    {
                        costCenterCode: "",
                        costCenterName: "",
                        costCenterMasterId: "",
                        areaCode: "",
                        areaName: "",
                        value: "",
                        codeAndName: "",
                        startDate: "",
                        endDate: "",
                        includedInTime: "",
                        includeBorrowedEmployees: ""
                    }
                ]
            },
            mappingByDropDownValue: "",
            costCenterMappingDropdownValue: "",
            selectedSupervisorData: [],
            enableSupervisorMappingDatatableSaveButton: false,
            newStartDate: "",
            newEndDate: "",
            employeePurgeJson: {
                configDataType: "",
                configDetailId: null,
                configName: "",
                configValue: null,
                purgeType : 'EMPLOYEE',
                description : ""
            },
           allocationPurgeJson: {
                configDataType: "",
                configDetailId: null,
                configName: "",
                configValue: null,
                purgeType : 'SHIFT_ALLOCATION',
                description : ""
            },
            shaerpointJson :{
            configurationName: "SHAREPOINT_CONFIG",
            configurationValue: "",
            configurationId: null,
            fileName: ""
            }
        }
        this.commonService = new CommonService();
    }

    componentDidMount = () => {
        this.commonMethodForLifecycleMethod();
    }

    //Function Common To DidMount and WillReceiveProps
    commonMethodForLifecycleMethod = () => {
        let baselineCopy = Object.assign({}, this.state.baselineTemplate);
        this.props.masterData.employeeType.map(data => {
            baselineCopy.employeeTypeIds.push(data.employeeTypeId);
            return 0;
        })
        this.setState({
            loading: true,
            baselineTemplate: baselineCopy,
            yearSelectedHolidayPlanning: {
                "label": this.props.masterData.holidayYears[0]
            },
            mappingByDropDownValue: {
                "label": translator.supervisor,
                "value": translator.supervisor
            },
            mappingByDropDownOptions: [
                { "label": translator.supervisor, "value": translator.supervisor },
                { "label": translator.costCenter, "value": translator.costCenter }
            ]
        });
        this.getCostCenterMaintenanceData();
        this.getPurgeData();
        this.getCurrentYearHoliday(this.props.masterData.holidayYears[0]);
        this.getCostCenterSupervisorMappingData();
        this.getCostCenterDetails(this.props.masterData.costCenter);
        this.getEmployeeTypeOption();
        this.getSharepointConfigData();
        
    }
    getSharepointConfigData = () => {

        this.commonService.getSharepointConfigData(this.props.browserLanguage)
            .then(res => {
                let jsonCopy = Object.assign({}, this.state.shaerpointJson);
                jsonCopy.configurationId = res.configurationId;
                jsonCopy.configurationName = res.configurationName;
                jsonCopy.configurationValue = res.configurationValue;
                jsonCopy.fileName = res.fileName;
                this.setState({ shaerpointJson: jsonCopy });

            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }

    getPurgeData = () => {

        this.commonService.getEmployeePurgeData(this.props.browserLanguage)
            .then(res => {

                let jsonCopy = Object.assign({}, this.state.employeePurgeJson);

                jsonCopy.configDataType = res.configDataType;
                jsonCopy.configDetailId = res.configDetailId;
                jsonCopy.configName = res.configName;
                jsonCopy.configValue = res.configValue;
                // jsonCopy.description = res.description;

                this.setState({ employeePurgeJson: jsonCopy });

            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })

            this.commonService.getAllocationPurgeData(this.props.browserLanguage)
            .then(res => {

                let jsonCopy = Object.assign({}, this.state.allocationPurgeJson);

                jsonCopy.configDataType = res.configDataType;
                jsonCopy.configDetailId = res.configDetailId;
                jsonCopy.configName = res.configName;
                jsonCopy.configValue = res.configValue;
                // jsonCopy.description = res.description;

                this.setState({ allocationPurgeJson: jsonCopy });

            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })

            
    }

    getCostCenterDetails = (costCenter) => {
        let options = costCenter.map(record => {
            return ({ label: record.costCenterCode, value: record.costCenterMasterId });
        })
        this.setState({ multipleCostCenterDetails: options })
    }

    getEmployeeTypeOption = () => {
        let employeeOption = this.props.masterData.employeeType.map(data => {
            return ({ value: data.employeeTypeId, label: data.employeeType });
        })
        this.setState({ employeeTypeOptions: employeeOption })
    }

    getCurrentYearHoliday = (year) => {
        this.commonService.getHolidayForYear(this.props.browserLanguage, year)
            .then(res => {
                this.setState({
                    currentYearHolidayData: res,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }

    getCostCenterMaintenanceData = () => {
        this.commonService.getCostCenterMaintenanceAdmin(this.props.browserLanguage)
            .then(res => {
                this.setState({
                    ccMaintenanceData: res,
                    loading: false
                })
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }
    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {


        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });

    }

    //Function To get Employee master Excel Tempalte
    getTemplate = (filename, templateName) => {
        this.setState({ loading: true });
        this.commonService.getExcelTemplateForAdmin(this.props.browserLanguage, filename)
            .then(
                response => {
                    let fileName = templateName + ".xlsx";
                    var blob = new Blob([response.data], { type: "application/xlsx" });
                    var reader = new FileReader();
                    reader.onloadend = function () {
                        FileSaver.saveAs(blob, fileName);
                    }
                    reader.readAsText(blob);
                    this.setState({ loading: false });
                }
            )
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }

    //Function To get Employee master Excel Tempalte
    getTemplateForHoliday = (templateName) => {

        this.setState({ loading: true });
        this.commonService.getExcelTemplateHoliday(this.props.browserLanguage, this.state.yearSelectedHolidayPlanning.label)
            .then(
                response => {
                    let fileName = templateName + ".xlsx";
                    var blob = new Blob([response.data], { type: "application/xlsx" });
                    var reader = new FileReader();
                    reader.onloadend = function () {
                        FileSaver.saveAs(blob, fileName);
                    }
                    reader.readAsText(blob);
                    this.setState({ loading: false });
                }
            )
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }

    //Function to implement functionality of Upload Excel (Callback from Upload Component). 
    onUploadFile = (failed, uploadCard) => {
        if (failed === 'failed') {
            this.setState({ loading: false });
            return false;
        }
        this.setState({ loading: true });
        switch (uploadCard) {
            case 'HOLIDAYMAINTENANCE':
                this.getCurrentYearHoliday(this.state.yearSelectedHolidayPlanning.label);
                break;

            case 'COSTCENTERMAPPING':
                this.getCostCenterSupervisorMappingData();
                break;

            case 'COSTCENTERMAINTENANCE':
                this.getCostCenterMaintenanceData();
                break;

            default:
                this.setState({ loading: false });
                break;
        }
        // this.getCostCenterMaintenanceData();
        //this.getCurrentYearHoliday(this.state.yearSelectedHolidayPlanning.label);
        // this.getCostCenterSupervisorMappingData();
        this.setState({ loading: false });
    }

    onHolidayYearChange = (e) => {
        this.setState({ yearSelectedHolidayPlanning: e.value, loading: true });
        this.getCurrentYearHoliday(e.value.label);
    }

    getCostCenterSupervisorMappingData = () => {
        this.commonService.getCostCenterSupervisorMappingData(this.props.browserLanguage)
            .then(res => {
                this.setState({
                    costCenterDetails: res.data.costCentersList,
                    supervisorDetails: res.data.supervisorDetailsList,
                    costCenterSupervisorMapping: res.data.mapping
                });
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }

    onSupervisorSelectionFromSupervisorDropdown = (event) => {
        let selectedSupervisorEmailId = event.value;
        //Code to hide datatable when cross of dropdown is selected
        if (selectedSupervisorEmailId === null) {
            this.setState({ supervisorDropdownValue: "", enableCostCenterMappingDatatableSaveButton: false });
            return false;
        }
        this.setState({ supervisorDropdownValue: selectedSupervisorEmailId, enableCostCenterMappingDatatableSaveButton: false });
        this.getCurrentMappedCostCentersForSelectedSupervisor(selectedSupervisorEmailId)
    }

    getCurrentMappedCostCentersForSelectedSupervisor = (selectedSupervisorEmailId) => {
        if (selectedSupervisorEmailId !== null && selectedSupervisorEmailId !== "") {
            let mappedCostCenterCodes = [];
            this.state.costCenterSupervisorMapping.map(mappingObject => {
                if (selectedSupervisorEmailId === mappingObject.supervisorEmailId)
                    mappedCostCenterCodes = mappingObject.costCenterCodes
                return mappingObject;
            })
            this.setState({
                selectedCostCenterData: this.state.costCenterDetails.filter(costCenter => { return mappedCostCenterCodes.indexOf(costCenter.costCenterCode) > -1 })
            })
        }

    }

    //Function to update cost center mapping
    updateCostCenterMapping = () => {
        //Conditional block executing when supervisor is selected in "mapping by" dropdown
        //and mapping for one supervisor to multiple cost center needs to be updated
        if (this.state.mappingByDropDownValue.value === translator.supervisor) {
            this.updateCostCenterMappingBySupervisor();
        }
        //Conditional block executing when cost center is selected in "mapping by" dropdown
        //and mapping for one cost center to multiple supervisor needs to be updated
        else if (this.state.mappingByDropDownValue.value === translator.costCenter) {
            this.updateCostCenterMappingByCostCenter();
        }
    }

    //Function to update mapping for one supervisor to multiple cost centers
    updateCostCenterMappingBySupervisor = () => {
        let supervisoradid = "";
        this.setState({ loading: true });
        for (let index = 0; index < this.state.supervisorDetails.length; index++) {
            if (this.state.supervisorDetails[index].supervisorEmailId === this.state.supervisorDropdownValue) {
                supervisoradid = this.state.supervisorDetails[index].supervisorAdId
                break;
            }
        }
        let updateMappingJsonTemplateCopy = Object.assign({}, this.state.updateMappingJsonTemplate);
        updateMappingJsonTemplateCopy.mapping["0"].supervisorAdId = supervisoradid;
        updateMappingJsonTemplateCopy.mapping["0"].supervisorEmailId = this.state.supervisorDropdownValue;
        updateMappingJsonTemplateCopy.mapping["0"].costCenterCodes = this.state.selectedCostCenterData.map(data => { return data.costCenterCode })
        this.commonService.updateCostCenterSupervisorMappingData(updateMappingJsonTemplateCopy, this.props.browserLanguage)
            .then(response => {
                if (response.status === 200) {
                    this.updateMappingDataFromResponse(response.data)
                    this.setState({ loading: false });
                    this.showSuccessMessageForSupervisorCostCenterMappingUpdate();
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }

    //Function to update mapping for one cost center to multiple supervisors
    updateCostCenterMappingByCostCenter = () => {
        this.setState({ loading: true });
        let updateMappingJsonTemplateCopy = Object.assign({}, this.state.updateMappingJsonTemplate);
        updateMappingJsonTemplateCopy.costCentersList["0"].costCenterCode = this.state.costCenterMappingDropdownValue;
        updateMappingJsonTemplateCopy.mapping = this.state.selectedSupervisorData;
        this.commonService.updateCostCenterSupervisorMappingData(updateMappingJsonTemplateCopy, this.props.browserLanguage)
            .then(response => {
                if (response.status === 200) {
                    this.updateMappingDataFromResponse(response.data)
                    this.setState({ loading: false });
                    this.showSuccessMessageForSupervisorCostCenterMappingUpdate();
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }

    //Function to update mapping data saved in state from update API response
    updateMappingDataFromResponse = (responseMappingData) => {
        let updatedMappingData;
        if (responseMappingData.costCentersList["0"].costCenterCode !== '') {
            updatedMappingData = responseMappingData.mapping;
        }
        else {
            let oldMappingDataCopy = this.state.costCenterSupervisorMapping;
            updatedMappingData = oldMappingDataCopy.map(mapping => {
                if (mapping.supervisorEmailId === responseMappingData.mapping["0"].supervisorEmailId)
                    mapping.costCenterCodes = responseMappingData.mapping["0"].costCenterCodes
                return mapping;
            })
        }

        this.setState({ costCenterSupervisorMapping: updatedMappingData })
    }

    showSuccessMessageForSupervisorCostCenterMappingUpdate = () => {

        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'success', summary: translator.successMessage, detail: translator.supervisorccMappingUpdateSucess });

    }
    //Function to check whether to enable or disable save button on Cost Center Mapping
    onSelectedCostCenterDataChange = (e) => {
        this.setState({ selectedCostCenterData: e.value });

        let prevData = [];
        let newData = [];
        let allPrevValuesExist = true;

        this.state.costCenterSupervisorMapping.map(data => {
            if (data.supervisorEmailId === this.state.supervisorDropdownValue) {
                prevData = data.costCenterCodes;
            }
            return false;
        });
        e.value.map(data => {
            newData.push(data.costCenterCode)
            return false;
        })
        for (let i = 0; i < prevData.length; i++) {
            if (newData.indexOf(prevData[i]) === -1) {
                allPrevValuesExist = false;
                break;
            }
        }
        if (newData.length === prevData.length && allPrevValuesExist === true) {
            this.setState({ enableCostCenterMappingDatatableSaveButton: false });
        }
        else {
            this.setState({ enableCostCenterMappingDatatableSaveButton: true });
        }
    }

    //Function to create body for checkbox column of Cost Center Maintenance Datatable
    ccMaintenanceTableBody = (rowData, column) => {
        if (column === 'includedInTime') {
            return (
                <div className="includeInTimeCheckBoxContainer">
                    <Checkbox
                        // Checkbox checked on UI marks "Exclusion" of Cost Center but 
                        // backend works on "inclusion" criteria, so reverse of check box 
                        // state (!e.checked) is sent to backend.
                        onChange={e => this.updateCostCenterTimeInclusionFlag(rowData, column, !e.checked)}
                        // Backend will perform operations based on includedInTime flag,
                        // but on UI operations are performed based on "exclusion" criteria
                        // that is why NOT operator is used with includedInTime flag.
                        checked={rowData.includedInTime !== 'Y'} />
                </div>
            )
        }
        else if (column === 'includeBorrowedEmployees') {
            return (
                <div className="includeBorrowedEmployeesCheckBoxContainer">
                    <Checkbox
                        onChange={e => this.updateBorrowedEmployeesOfCostCenterTimeInclusionFlag(rowData, column, e.checked)}
                        checked={rowData.includeBorrowedEmployees === 'Y'}
                        disabled={rowData.includedInTime !== 'Y'}
                        readOnly={rowData.includedInTime !== 'Y'} />
                </div>
            )
        }
        else if (column === 'costCenterName') {
            return (
                <div className="extraTextWrappingShitAllocation" title={rowData.costCenterName}>
                    {rowData.costCenterName}
                </div>
            )
        }
    }

    //Function to update "inclusion flag of cost center in TIME" in state when checkbox corresponding to cost center
    // is checked or unchecked
    updateCostCenterTimeInclusionFlag = (rowData, column, checked) => {
        // let rowDataCopy = [...props.value] ;
        // rowDataCopy.includedInTime = checked ? 'Y' : 'N';
        let ccMaintenanceDataCopy = this.state.ccMaintenanceData.map((costCenterData, index) => {
            if (costCenterData.costCenterCode === rowData.costCenterCode) {
                costCenterData.includedInTime = checked ? 'Y' : 'N';
                costCenterData.includeBorrowedEmployees = checked ? costCenterData.includeBorrowedEmployees : 'N';
                return costCenterData;
            }
            return costCenterData;
        });

        this.setState({ ccMaintenanceData: ccMaintenanceDataCopy });
    }

    //Function to update "inclusion flag of borrowed employees of cost center in TIME" 
    // in state when checkbox corresponding to cost center is checked or unchecked
    updateBorrowedEmployeesOfCostCenterTimeInclusionFlag = (rowData, column, checked) => {
        // let rowDataCopy = [...props.value] ;
        // rowDataCopy.includeBorrowedEmployees = checked ? 'Y' : 'N';
        let ccMaintenanceDataCopy = this.state.ccMaintenanceData.map((costCenterData, index) => {
            if (costCenterData.costCenterCode === rowData.costCenterCode) {
                costCenterData.includeBorrowedEmployees = checked ? 'Y' : 'N';
                return costCenterData;
            }
            return costCenterData;
        });

        this.setState({ ccMaintenanceData: ccMaintenanceDataCopy });
    }

    //Function to call service to update cost center maintenance data
    updateCostCenterMaintenanceData = () => {
        this.setState({ loading: true });
        if (this.state.addClicked) {
            if (this.state.addNewCCJsonTemplate.costCenterCode !== "" && this.state.addNewCCJsonTemplate.costCenterName !== "") {

                let newData = Object.assign({}, this.state.addNewCCJsonTemplate);
                if (this.state.newEndDate !== "") {
                    let month = this.state.newEndDate.getMonth() + 1 < 10 ? '0' + (this.state.newEndDate.getMonth() + 1) : this.state.newEndDate.getMonth() + 1;
                    let date = this.state.newEndDate.getDate() < 10 ? '0' + (this.state.newEndDate.getDate()) : this.state.newEndDate.getDate();

                    let tempDate = `${date}/${month}/${this.state.newEndDate.getFullYear()}`;
                    newData.endDate = tempDate;
                }
                if (this.state.newStartDate !== "") {
                    let month = this.state.newStartDate.getMonth() + 1 < 10 ? '0' + (this.state.newStartDate.getMonth() + 1) : this.state.newStartDate.getMonth() + 1;
                    let date = this.state.newStartDate.getDate() < 10 ? '0' + (this.state.newStartDate.getDate()) : this.state.newStartDate.getDate();

                    let tempDate = `${date}/${month}/${this.state.newStartDate.getFullYear()}`;
                    newData.startDate = tempDate;
                }

                let data = Object.assign([], this.state.ccMaintenanceData);
                data.unshift(newData);

                this.commonService.updateCostCenterMaintenanceData(data, this.props.browserLanguage)
                    .then(response => {
                        this.setState({
                            loading: false,
                            ccMaintenanceData: response,
                            addClicked: false
                        });
                        this.showSuccessMessageForUpdateCostCenterMaintenanceData();
                    }
                    )
                    .catch(err => {
                        this.getCostCenterMaintenanceData();
                        this.setState({ addClicked: false });
                        this.showMultiple(err);
                    })
            }

            else {
                this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.errorForCCAdd });

                this.setState({
                    loading: false,
                });
                return 0;
            }
        }

        else {
            this.commonService.updateCostCenterMaintenanceData(this.state.ccMaintenanceData, this.props.browserLanguage)
                .then(response => {
                    this.setState({
                        loading: false,
                        ccMaintenanceData: response
                    });
                    this.showSuccessMessageForUpdateCostCenterMaintenanceData();
                }
                )
                .catch(err => {
                    this.getCostCenterMaintenanceData();
                    this.setState();
                    this.showMultiple(err);
                })
        }

    }

    //Function to show success message when cost center data update is successful
    showSuccessMessageForUpdateCostCenterMaintenanceData = () => {


        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'success', summary: translator.successMessage, detail: translator.updateCostCenterMaintenanceDataSuccess });

    }

    //Function called when cost center is selected from cost center drop down in "cost center mapping"
    onCostCenterSelectionFromCostCenterMappingDropdown = (event) => {
        let selectedCostCenterCode = event.value;
        //Code to hide datatable when cross of dropdown is selected
        if (selectedCostCenterCode === null) {
            this.setState({ costCenterMappingDropdownValue: "", enableSupervisorMappingDatatableSaveButton: false });
            return false;
        }
        this.setState({ costCenterMappingDropdownValue: selectedCostCenterCode, enableSupervisorMappingDatatableSaveButton: false });
        this.getCurrentMappedSupervisorForSelectedCostCenter(selectedCostCenterCode);
    }

    //Function to get mapped supervisors for selected cost center
    getCurrentMappedSupervisorForSelectedCostCenter = (selectedCostCenterCode) => {
        if (selectedCostCenterCode !== null && selectedCostCenterCode !== "") {
            let mappedSupervisorsEmailId = [];
            this.state.costCenterSupervisorMapping.map(mappingObject => {
                if (mappingObject.costCenterCodes.indexOf(selectedCostCenterCode) > -1)
                    mappedSupervisorsEmailId.push(mappingObject.supervisorEmailId)
                return mappingObject;
            })
            this.setState({
                selectedSupervisorData: this.state.supervisorDetails.filter(supervisorDetail => { return mappedSupervisorsEmailId.indexOf(supervisorDetail.supervisorEmailId) > -1 })
            })
        }
    }

    //Function called when selected supervisors are changed in Supervisor datatable in "cost center mapping"
    onSelectedSupervisorDataChange = (event) => {
        this.setState({ selectedSupervisorData: event.value });

        let prevData = [];  //array which will be storing supervisor email ids that have mapping to chosen cost center
        let newData = [];   //array which will be storing supervisor email ids that have been selected by user
        let allPrevValuesExist = true;

        //Iterating over mapping data and getting exisitng mapped supervisor email IDs
        this.state.costCenterSupervisorMapping.map(data => {
            if (data.costCenterCodes.indexOf(this.state.costCenterMappingDropdownValue.toString()) > -1) {
                prevData.push(data.supervisorEmailId);
            }
            return data;
        });

        //Iterating over selected supervisor data and adding their email IDs
        event.value.map(data => {
            newData.push(data.supervisorEmailId)
            return data;
        })

        //Iterating and checking for difference
        for (let i = 0; i < prevData.length; i++) {
            if (newData.indexOf(prevData[i]) === -1) {
                allPrevValuesExist = false;
                break;
            }
        }

        if (newData.length === prevData.length && allPrevValuesExist === true) {
            this.setState({ enableSupervisorMappingDatatableSaveButton: false });
        }
        else {
            this.setState({ enableSupervisorMappingDatatableSaveButton: true });
        }
    }

    //Function called when option is changed in 'Mapping By' dropdown
    onMappingByDropDownValueChange = (event) => {
        this.setState({
            mappingByDropDownValue: event.value,
            supervisorDropdownValue: "",
            costCenterMappingDropdownValue: ""
        })
    }

    editor = (e) => {

        switch (e.field) {
            case 'costCenterName':
                return this.inputTextEditor(e, e.field);

            case 'startDate':
            case 'endDate':
                return this.inputDateEditor(e, e.field);

            default:
                break;

        }
    }

    inputTextEditor = (props, field) => {
        return <InputText
            type="text"
            value={props.rowData[field]}
            onChange={(e) => this.onEditorValueChange(props, e.target.value)}
            onBlur={(e) => this.onEditorValueChangeOnBlur(props, e.target.value)}
        />;
    }

    onEditorValueChangeOnBlur = (props, value) => {
        if (props.field === 'costCenterName' && value === '') {
            let updatedData = [...props.value];
            updatedData[props.rowIndex][props.field] = updatedData[props.rowIndex]['codeAndName'].split('|')[1].trim();
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.errorForCCAdd });
            this.setState({ ccMaintenanceData: updatedData });
        }
    }

    inputDateEditor = (props, field) => {

        let viewDate = new Date();
        let dateValue = '';

        if (props.rowData[field] !== "") {
            let dateArr = props.rowData[field].split('/');

            viewDate = new Date(`${dateArr[1]}/${dateArr[0]}/${dateArr[2]}`)
        }

        return <Calendar
            value={dateValue}
            monthNavigator={true}
            yearNavigator={true}
            viewDate={viewDate}
            dateFormat="dd/mm/yy"
            yearRange={`${String(new Date().getFullYear() - 5)}:${String(new Date().getFullYear() + 30)}`}
            onChange={(e) => e.value !== null ? this.onEditorValueChange(props, e.value) : null}
            appendTo={document.getElementById('costCenterMaintenanceDatatable')}
        ></Calendar>
    }

    onEditorValueChange = (props, value) => {

        if (typeof (value) === 'object' && value !== null && (props.field === 'startDate' || props.field === 'endDate')) {
            this.setState({ dateSelected: value })
            let month = value.getMonth() + 1 < 10 ? '0' + (value.getMonth() + 1) : value.getMonth() + 1;
            let date = value.getDate() < 10 ? '0' + (value.getDate()) : value.getDate();

            let tempDate = `${date}/${month}/${value.getFullYear()}`;

            value = tempDate;
            this.dt.closeEditingCell();
        }
        let updatedData = [...props.value];
        updatedData[props.rowIndex][props.field] = value;
        this.setState({ ccMaintenanceData: updatedData });
    }

    //update property for baselineTemplate
    filterData = (property, e) => {
        let baselineCopy = Object.assign({}, this.state.baselineTemplate);
        if (property === "multipleCC") {
            baselineCopy.costCenterIds = e.value;
            this.setState({ baselineTemplate: baselineCopy })
        }

        else if (property === "planningPeriod") {
            baselineCopy.planningPeriodId = e.value.planningPerioId;
            this.setState({ baselineTemplate: baselineCopy, selectedPlaningPeriod: e.value })
        }

        else if (property === "employeeType") {

            if(e.value.length === this.props.masterData.employeeType.length){
                baselineCopy.allSelected = true;
            }
            else{
                baselineCopy.allSelected = false;
            }
            baselineCopy.employeeTypeIds = e.value;
            this.setState({ baselineTemplate: baselineCopy })
        }

        else if (property === "remarks") {
            baselineCopy.baselineComment = e.target.value;
            this.setState({ baselineTemplate: baselineCopy })
        }

        if (baselineCopy.costCenterIds.length > 0 && baselineCopy.planningPeriodId !== 0 && baselineCopy.employeeTypeIds.length > 0) {
            this.setState({ disableBaseline: false })
        }

    }

    //suuccess response on creating baseline
    showSuccessMessageBaseline = () => {

        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'success', summary: translator.successMessage, detail: translator.baselineCreatedSuccess });
    }

    //if all the required field are not filled
    showErrorMessage = () => {
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.requiredCreateBaseline });
    }

    //suuccess response on creating baseline
    showSuccessMessageBaselineRequest = (message) => {

        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'success', summary: translator.successMessage, detail: message});
    }
    //baseline function for multiple cost center
    createBaseline = () => {
        let baselineDatacopy = Object.assign({}, this.state.baselineTemplate);
        this.setState({ loading: true })
        this.commonService.createBaseline(baselineDatacopy, this.props.browserLanguage)
            .then(res => {
                
                if (res.data !== undefined && res.data !== null) {
                    let arrayId = []
                    this.props.masterData.employeeType.map(data => {
                        arrayId.push(data.employeeTypeId);
                        return 0;
                    })
                    this.setState({
                        loading: false,
                        selectedPlaningPeriod: "",
                        baselineTemplate: {
                            costCenterIds: [],
                            planningPeriodId: 0,
                            employeeTypeIds: arrayId,
                            baselineComment: "",
                            fromAdmin: true,
                            allSelected : true
                        }
                    });
                    this.showSuccessMessageBaselineRequest(res.data);
                }
            })

            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.state.disableBaseline) {
            this.createBaseline();
        }
        else {
            this.showErrorMessage();
        }
    }

    addNewCC = () => {

        this.setState({
            addClicked: true,
            newStartDate: '',
            newEndDate: '',
            newTimeCheckbox: false,
            newBorrowCheckbox: false,
            addNewCCJsonTemplate: {
                areaCode: null,
                areaName: null,
                codeAndName: "",
                costCenterCode: "",
                costCenterMasterId: null,
                costCenterName: "",
                endDate: "",
                includeBorrowedEmployees: "N",
                includedInTime: "Y",
                startDate: "",
                value: "",
            }
        })
    }

    setNewCCData = (field, value) => {

        let jsonCopy = Object.assign({}, this.state.addNewCCJsonTemplate);

        switch (field) {
            case 'costCenterCode':
                jsonCopy.costCenterMasterId = null;
                jsonCopy.costCenterCode = String(value);
                break;

            case 'costCenterName':
                jsonCopy.costCenterName = value;
                break;

            case 'TimeFeed':
                this.setState({ newTimeCheckbox: value })
                if (value === true) {
                    this.setState({ newBorrowCheckbox: false })
                    jsonCopy.includedInTime = 'N';
                }
                else {
                    jsonCopy.includedInTime = 'Y';
                }
                break;

            case 'Borrow':
                this.setState({ newBorrowCheckbox: value })
                if (value === true) {
                    jsonCopy.includeBorrowedEmployees = 'Y';
                }
                else {
                    jsonCopy.includeBorrowedEmployees = 'N';
                }
                break;

            default:
                break;

        }
        this.setState({ addNewCCJsonTemplate: jsonCopy })
    }

    cancelAddNewCC = () => {
        this.setState({
            addClicked: false,
            newTimeCheckbox: true,
            newBorrowCheckbox: false,
            addNewCCJsonTemplate: {
                areaCode: null,
                areaName: null,
                codeAndName: "",
                costCenterCode: "",
                costCenterMasterId: null,
                costCenterName: "",
                endDate: "",
                includeBorrowedEmployees: "N",
                includedInTime: "Y",
                startDate: "",
                value: "",
            }
        })
    }

    changeEmployeePurgeData = (value, type) => {

        if(type === 'employee'){
            this.setState(() => ({
                employeePurgeJson: {
                    ...this.state.employeePurgeJson,
                    configValue: parseInt(value, 10),
                    purgeType : type,
                    description : translator.employeePurgeDescription
                }
            }));
        }
        else{
            this.setState(() => ({
                allocationPurgeJson: {
                    ...this.state.allocationPurgeJson,
                    configValue: parseInt(value, 10),
                    purgeType : type,
                    description : translator.allocationPurgeDescription
                }
            }));
        }
        


    }

    saveEmployeePurgeData = (label) => {
        if(label === 'employee'){
            
            if (this.state.employeePurgeJson.configValue >= 12) {
                this.setState({ loading: true });
    
                this.commonService.updatePurgeData(this.state.employeePurgeJson,this.props.browserLanguage)
                    .then(res => {
                        this.setState({ employeePurgeJson: res, loading: false });
                    })
                    .catch(err => {
                        this.setState({ loading: false });
                        this.showMultiple(err);
                    })
            }
    
            else {
                this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.dataPurgeError });
            }
        }

        else{
            if (this.state.allocationPurgeJson.configValue >= 18) {
                this.setState({ loading: true });
    
                this.commonService.updatePurgeData(this.state.allocationPurgeJson, this.props.browserLanguage)
                    .then(res => {
                        this.setState({ allocationPurgeJson: res, loading: false });
                    })
                    .catch(err => {
                        this.setState({ loading: false });
                        this.showMultiple(err);
                    })
            }
    
            else {
                this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.allocationDataPurgeError });
            }
        }
        

    }
    changeSharepointData = (value, type) => {

        if(type === 'folder'){
            this.setState(() => ({
                shaerpointJson: {
                    ...this.state.shaerpointJson,
                    configurationValue: value
                }
            }));
        }
        else{
            this.setState(() => ({
                shaerpointJson: {
                    ...this.state.shaerpointJson,
                    fileName: value
                }
            }));
        }
    }

    saveSharepointData = () => {
       
        if (this.state.shaerpointJson.configurationValue !== "" && this.state.shaerpointJson.fileName !== "") {
            
            this.setState({ loading: true });

            this.commonService.saveSharepointConfigData(this.state.shaerpointJson, this.props.browserLanguage)
                .then(res => {
                    // let shaerpointJsonCopy = Object.assign({}, this.state.shaerpointJson);
                    this.setState({  loading: false });
                })
                .catch(err => {
                    this.setState({ loading: false });
                    this.showMultiple(err);
                })
        }

        else {
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.dataPurgeError });
        }

    }
    render() {

        //Code to move selected cost center records to top of datatable of costCenterMapping
        this.state.selectedCostCenterData.map(data => {
            let temp = this.state.costCenterDetails[this.state.costCenterDetails.indexOf(data)];
            this.state.costCenterDetails.splice(this.state.costCenterDetails.indexOf(data), 1);
            this.state.costCenterDetails.unshift(temp);
            return false;
        })

        //Code to move selected supervisor records to top of datatable of costCenterMapping
        this.state.selectedSupervisorData.map(data => {
            let temp = this.state.supervisorDetails[this.state.supervisorDetails.indexOf(data)];
            this.state.supervisorDetails.splice(this.state.supervisorDetails.indexOf(data), 1);
            this.state.supervisorDetails.unshift(temp);
            return false;
        })

        if (this.props.masterData.admin === false) {
            return (
                <Redirect to="/" />
            )
        }
        translator.setLanguage(this.props.browserLanguage);

        const holidayYearsDropdownValues = [];

        this.props.masterData.holidayYears.map((data, key) => {
            return holidayYearsDropdownValues.push({ "label": data })
        })

        let headerColumn =
            <ColumnGroup>
                <Row>
                    <Column header={translator.costCenterCode} style={{ width: '24%' }} />
                    <Column header={translator.costCenterName} body={(e) => this.ccMaintenanceTableBody(e, 'costCenterName')} style={{ width: '22%' }} />
                    <Column header={translator.ccStartDate} />
                    <Column header={translator.ccEndDate} />
                    <Column header={translator.excludeInTime} body={(e) => this.ccMaintenanceTableBody(e, 'includedInTime')} />
                    <Column header={translator.includeBorrowedEmployees} body={(e) => this.ccMaintenanceTableBody(e, 'includeBorrowedEmployees')} />
                </Row>

                <Row>
                    <Column style={{ backgroundColor: "white" }} header={<InputText style={{ width: '100%' }} onChange={(e) => this.setNewCCData('costCenterCode', e.target.value)} keyfilter="int" />} />
                    <Column style={{ backgroundColor: "white" }} header={<InputText style={{ width: '100%' }} onChange={(e) => this.setNewCCData('costCenterName', e.target.value)} />} />
                    <Column
                        style={{ backgroundColor: "white" }}
                        header={
                            <Calendar
                                value={this.state.newStartDate}
                                monthNavigator={true}
                                yearNavigator={true}
                                inputStyle={{ width: '100%' }}
                                dateFormat="dd/mm/yy"
                                yearRange={`${String(new Date().getFullYear() - 5)}:${String(new Date().getFullYear() + 30)}`}
                                onChange={(e) => this.setState({ newStartDate: e.value })}
                                appendTo={document.getElementById('costCenterMaintenanceDatatable')}
                            />}
                    />
                    <Column
                        style={{ backgroundColor: "white" }}
                        header={
                            <Calendar
                                value={this.state.newEndDate}
                                monthNavigator={true}
                                yearNavigator={true}
                                inputStyle={{ width: '100%' }}
                                dateFormat="dd/mm/yy"
                                yearRange={`${String(new Date().getFullYear() - 5)}:${String(new Date().getFullYear() + 30)}`}
                                onChange={(e) => this.setState({ newEndDate: e.value })}
                                appendTo={document.getElementById('costCenterMaintenanceDatatable')}
                            />}
                    />
                    <Column style={{ backgroundColor: "white" }} header={<div className="includeInTimeCheckBoxContainer"><Checkbox checked={this.state.newTimeCheckbox} onChange={(e) => this.setNewCCData('TimeFeed', e.checked)} ></Checkbox></div>} />
                    <Column style={{ backgroundColor: "white" }} header={<div className="includeBorrowedEmployeesCheckBoxContainer"><Checkbox checked={this.state.newBorrowCheckbox} onChange={(e) => this.setNewCCData('Borrow', e.checked)} style={{ paddingTop: "2px" }} disabled={this.state.newTimeCheckbox}></Checkbox></div>} />
                </Row>
            </ColumnGroup>;

        return (
            <div>
                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>null
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>
                <Growl ref={(el) => this.growl = el} position="topright" className="shiftGrowlScroll"></Growl>


                <Container style={{ marginTop: '2%' }}>
                    <Row>
                        <Col
                            md={8}>

                            <Accordion activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>

                                <AccordionTab header={translator.costCenterMaintainence}>

                                    <Card>
                                        <div style={{ textAlign: 'right' }}>
                                            {
                                                !this.state.addClicked ?
                                                    <Button
                                                        label={translator.addBtn}
                                                        icon="pi pi-plus"
                                                        style={{ marginRight: '5%' }}
                                                        onClick={this.addNewCC}
                                                    />
                                                    : null
                                            }

                                            <div id="adminCCDownloadBtnContainer">
                                                <Button className="btnDefault" icon="pi pi-download" label={translator.getTemplate} onClick={() => this.props.browserLanguage === 'en' ? this.getTemplate('costcentermaintenance.xlsx', translator.costCenterMaintenanceTemplate) : this.getTemplate('costcentermaintenance_fi.xlsx', translator.costCenterMaintenanceTemplate)} />
                                            </div>
                                            <div id="adminCCUploadBtnContainer">
                                                <AdminUpload onChange={this.onUploadFile} browserLanguage={this.props.browserLanguage} uploadType="COSTCENTERMAINTENANCE" />
                                            </div>

                                            <DataTable
                                                ref={(el) => this.dt = el}
                                                value={this.state.ccMaintenanceData}
                                                id="costCenterMaintenanceDatatable"
                                                className="costCenterMaintenanceDatatable"
                                                style={{ marginTop: '2%', marginLeft: '1%' }}
                                                sortMode="multiple"
                                                scrollable={true}
                                                editable={true}
                                                headerColumnGroup={this.state.addClicked ? headerColumn : null}
                                                scrollHeight={getResolutionCCMaintainenace()}
                                                emptyMessage={translator.noRecordFound}
                                                footer={
                                                    <div>
                                                        <Button className="btnDefault" icon="pi pi-check" label={translator.save} onClick={this.updateCostCenterMaintenanceData} />
                                                        {this.state.addClicked ? <Button style={{ marginLeft: '2%' }} className="btnDefault" icon="pi pi-times" label={translator.cancel} onClick={this.cancelAddNewCC} /> : null}
                                                    </div>
                                                }
                                            >

                                                <Column field="costCenterCode" header={translator.costCenterCode} sortable={true} filter={true} style={{ width: '24%' }} />
                                                <Column field="costCenterName" editor={(e) => this.editor(e)} header={translator.costCenterName} sortable={true} filter={true} body={(e) => this.ccMaintenanceTableBody(e, 'costCenterName')} style={{ width: '22%' }} />
                                                <Column field="startDate" editor={(e) => this.editor(e)} header={translator.ccStartDate} />
                                                <Column field="endDate" editor={(e) => this.editor(e)} header={translator.ccEndDate} />
                                                <Column field="includedInTime" header={translator.excludeInTime} body={(e) => this.ccMaintenanceTableBody(e, 'includedInTime')} />
                                                <Column field="includeBorrowedEmployees" header={translator.includeBorrowedEmployees} body={(e) => this.ccMaintenanceTableBody(e, 'includeBorrowedEmployees')} />

                                            </DataTable>

                                        </div>
                                    </Card>

                                </AccordionTab>

                                <AccordionTab header={translator.costCenterMapping}>

                                    <Card>
                                        <div style={{ textAlign: 'right' }}>

                                            <div style={{ float: 'left', textAlign: 'left' }}>
                                                <span>{translator.mappingBY} </span>
                                                <Dropdown
                                                    className="dropDwonDefault"
                                                    style={{ minWidth: '111px' }}
                                                    optionLabel="label"
                                                    value={this.state.mappingByDropDownValue}
                                                    options={this.state.mappingByDropDownOptions}
                                                    onChange={(e) => this.onMappingByDropDownValueChange(e)} />
                                            </div>

                                            <div id="adminCCDownloadBtnContainer">
                                                <Button className="btnDefault" icon="pi pi-download" label={translator.getTemplate} onClick={() => this.props.browserLanguage === 'en' ? this.getTemplate('costcentermapping.xlsx', translator.costCenterMappingTemplate) : this.getTemplate('costcentermapping_fi.xlsx', translator.costCenterMappingTemplate)} />
                                            </div>
                                            <div id="adminCCUploadBtnContainer">
                                                <AdminUpload onChange={this.onUploadFile} browserLanguage={this.props.browserLanguage} uploadType="COSTCENTERMAPPING" />
                                            </div>
                                            {
                                                this.state.mappingByDropDownValue.value === translator.supervisor ?
                                                    <div id="supervisorMappingDropDownContainer">
                                                        <Dropdown
                                                            value={this.state.supervisorDropdownValue !== "" ? this.state.supervisorDropdownValue : null}
                                                            options={this.state.supervisorDetails.map(supervisorDetail => { return { label: supervisorDetail.displayDetail, value: supervisorDetail.supervisorEmailId } })}
                                                            onChange={(e) => { this.onSupervisorSelectionFromSupervisorDropdown(e) }}
                                                            placeholder={translator.selectSupervisor}
                                                            className="supervisorMappingDropDown dropDwonDefault"
                                                            filter={true}
                                                            filterPlaceholder={translator.enterSupervisor}
                                                            filterBy="label,value"
                                                            showClear={true}
                                                        />
                                                    </div>
                                                    :
                                                    <div id="costcenterMappingDropDownContainer">
                                                        <Dropdown
                                                            value={this.state.costCenterMappingDropdownValue !== "" ? this.state.costCenterMappingDropdownValue : null}
                                                            options={this.state.costCenterDetails.map(costCenterDetail => { return { label: costCenterDetail.codeAndName, value: costCenterDetail.costCenterCode } })}
                                                            onChange={(e) => { this.onCostCenterSelectionFromCostCenterMappingDropdown(e) }}
                                                            placeholder={translator.selectCostCenter}
                                                            className="costcenterMappingDropDown dropDwonDefault"
                                                            filter={true}
                                                            filterPlaceholder={translator.enterCostCenter}
                                                            filterBy="label,value"
                                                            showClear={true}
                                                        />
                                                    </div>
                                            }

                                            {
                                                this.state.supervisorDropdownValue !== "" && this.state.mappingByDropDownValue.value === translator.supervisor ?
                                                    <div id="costCenterMappingDataTableContainer">
                                                        <DataTable
                                                            value={this.state.costCenterDetails}
                                                            className="costCenterMappingDatatable"
                                                            selection={this.state.selectedCostCenterData}
                                                            onSelectionChange={(e) => this.onSelectedCostCenterDataChange(e)}
                                                            footer={this.state.supervisorDropdownValue !== "" ? <Button disabled={!this.state.enableCostCenterMappingDatatableSaveButton} className="btnDefault" icon="pi pi-check" label={translator.save} onClick={this.updateCostCenterMapping} /> : null}
                                                            emptyMessage={translator.noRecordFound}
                                                            scrollable={true}
                                                            scrollHeight={getResolutionCCMapping()}>
                                                            <Column selectionMode="multiple" style={{ width: '10em', textAlign: 'center' }} />
                                                            <Column field="codeAndName" header={translator.costCenter} sortable={true} filter={true} filterMatchMode="contains" />
                                                        </DataTable>
                                                    </div>
                                                    : null
                                            }

                                            {
                                                this.state.costCenterMappingDropdownValue !== "" && this.state.mappingByDropDownValue.value === translator.costCenter ?
                                                    <div id="supervisorMappingDataTableContainer">
                                                        <DataTable
                                                            value={this.state.supervisorDetails}
                                                            className="supervisorMappingDatatable"
                                                            selection={this.state.selectedSupervisorData}
                                                            onSelectionChange={(e) => this.onSelectedSupervisorDataChange(e)}
                                                            footer={this.state.costCenterMappingDropdownValue !== "" ? <Button disabled={!this.state.enableSupervisorMappingDatatableSaveButton} className="btnDefault" icon="pi pi-check" label={translator.save} onClick={this.updateCostCenterMapping} /> : null}
                                                            emptyMessage={translator.noRecordFound}
                                                            scrollable={true}
                                                            scrollHeight={getResolutionCCMapping()}>
                                                            <Column selectionMode="multiple" style={{ width: '10em', textAlign: 'center' }} />
                                                            <Column field="displayDetail" header={translator.supervisor} sortable={true} filter={true} filterMatchMode="contains" />
                                                        </DataTable>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </Card>

                                </AccordionTab>

                            </Accordion>

                        </Col>

                        <Col
                            md={4}>

                            <Accordion activeIndex={this.state.activeTabIndex} onTabChange={(e) => this.setState({ activeTabIndex: e.index })} style={{ width: '120%' }}>

                                <AccordionTab header={translator.holidayPlanning}>

                                    <Card>

                                        <div style={{ textAlign: 'right' }}>

                                            <Dropdown className="dropDwonDefault" style={{ minWidth: '20%', float: 'left', textAlign: 'left' }} optionLabel="label" value={this.state.yearSelectedHolidayPlanning} options={holidayYearsDropdownValues} onChange={(e) => this.onHolidayYearChange(e)} placeholder={translator.selectYear} />

                                            <div id="adminCCDownloadBtnContainer" style={{ marginLeft: '5%' }}>
                                                <Button className="btnDefault" icon="pi pi-download" label={translator.getTemplate} onClick={() => this.getTemplateForHoliday(translator.holidayPlanningTemplate)} />
                                            </div>
                                            <div id="adminCCUploadBtnContainer">
                                                <AdminUpload onChange={this.onUploadFile} browserLanguage={this.props.browserLanguage} uploadType="HOLIDAYMAINTENANCE" />
                                            </div>

                                        </div>

                                        <DataTable
                                            value={this.state.currentYearHolidayData}
                                            className="holidayDatatable"
                                            style={{ marginTop: '2%', textAlign: 'center' }}
                                            sortMode="multiple"
                                            scrollable={true}
                                            scrollHeight="60.5vh"
                                            emptyMessage={translator.noRecordFound}
                                        >

                                            <Column field="date" header={translator.date} sortable={true} filter={true} />
                                            <Column field="day" header={translator.day} sortable={true} filter={true} />
                                            <Column field="description" header={translator.description} sortable={true} filter={true} />
                                            {/* <Column rowEditor={true} style={{'width': '70px', 'textAlign': 'center'}}></Column> */}

                                        </DataTable>

                                    </Card>

                                </AccordionTab>

                                <AccordionTab header={translator.multipleCCBaseline}>
                                    <Card>
                                        <form onSubmit={this.handleSubmit}>
                                            <Row className="padding7pxBottom">
                                                <Col><label>{translator.costCenter} <span className="requiredAsterisk">*</span></label></Col>
                                                <Col><MultiSelect style={{ width: '220px' }} filter={true} maxSelectedLabels={0} placeholder={translator.selectCostCenter} value={this.state.baselineTemplate.costCenterIds} options={this.state.multipleCostCenterDetails} onChange={(e) => this.filterData("multipleCC", e)} /></Col>
                                            </Row>

                                            <Row className="padding7pxBottom">
                                                <Col><label>{translator.planningPeriod} <span className="requiredAsterisk">*</span></label></Col>
                                                <Col>
                                                    <Dropdown value={this.state.selectedPlaningPeriod}
                                                        options={this.props.masterData === null ? null : this.props.masterData.planningPeriodList}
                                                        onChange={(e) => this.filterData("planningPeriod", e)}
                                                        placeholder={translator.selectPlanningPeriod}
                                                        optionLabel='planningPeriodDescription'
                                                        className="dropDwonDefault"
                                                        style={{ width: '220px' }}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="padding7pxBottom">
                                                <Col><label>{translator.employeeType} <span className="requiredAsterisk">*</span></label></Col>
                                                <Col>
                                                    <MultiSelect style={{ width: '220px' }} filter={true} maxSelectedLabels={0} placeholder={translator.selectEmployeeType} value={this.state.baselineTemplate.employeeTypeIds} options={this.state.employeeTypeOptions} onChange={(e) => this.filterData("employeeType", e)} />
                                                </Col>
                                            </Row>

                                            <Row className="padding7pxBottom">
                                                <Col><label>{translator.remarks}</label></Col>
                                                <Col>
                                                    <InputTextarea rows={5} cols={30} style={{ width: '220px' }} value={this.state.baselineTemplate.baselineComment} onChange={(e) => this.filterData("remarks", e)} />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col></Col>
                                                <Col><Button type="sumit" className="btnDefault" icon="pi pi-check" label={translator.createBaseline} style={{ float: 'right' }} /></Col>
                                            </Row>
                                        </form>
                                    </Card>
                                </AccordionTab>

                                <AccordionTab header={translator.dataPurgeConfig}>

                                    <Card>
                                        <div>
                                            <strong>{translator.employee}</strong>
                                            <br/><br/>
                                            {translator.employeePurgeDescription}
                                            <InputText
                                                type="number"
                                                keyfilter="pint"
                                                style={{ width: '12%', marginLeft: '1%', marginRight: '1%' }}
                                                value={this.state.employeePurgeJson.configValue}
                                                onChange={(e) => this.changeEmployeePurgeData(e.target.value,'employee')}
                                            />
                                            {/* {translator.months} */}
                                            <br/>
                                            <Button
                                                label={translator.save}
                                                icon="pi pi-check"
                                                onClick={() => this.saveEmployeePurgeData('employee')}
                                                style={{ marginTop: '2%' }} />

                                        </div>
                                    </Card>

                                    <Card>
                                        <div>
                                            <strong>{translator.allocation}</strong>
                                            <br/><br/>
                                            {translator.allocationPurgeDescription}
                                            <InputText
                                                type="number"
                                                keyfilter="pint"
                                                style={{ width: '12%', marginLeft: '1%', marginRight: '1%' }}
                                                value={this.state.allocationPurgeJson.configValue}
                                                onChange={(e) => this.changeEmployeePurgeData(e.target.value,'allocation')}
                                            />
                                            {/* {translator.months} */}
                                            <br/>
                                            <Button
                                                label={translator.save}
                                                icon="pi pi-check"
                                                onClick={() => this.saveEmployeePurgeData('allocations')}
                                                style={{ marginTop: '2%' }} />
                                                
                                        </div>
                                    </Card>

                                </AccordionTab>

                                <AccordionTab header={translator.sharepointConfiguration}>

                                    <Card>
                                        <div>
                                            <strong>{translator.sharepointFolderPath}</strong>
                                            <span className="requiredAsterisk">*</span>
                                            <br /><br />
                                           
                                            <InputText 
                                                type="text"
                                                //keyfilter="pint"
                                                style={{ width: '100%', marginLeft: '1%', marginRight: '1%' }}
                                                value={this.state.shaerpointJson.configurationValue}
                                                onChange={(e) => this.changeSharepointData(e.target.value, 'folder')}
                                            />
                                             <br />
                                             <br />
                                             <strong>{translator.sharepointFileName}</strong>
                                             <span className="requiredAsterisk">*</span>
                                             <br />
                                             <br />
                                            <InputText
                                                type="text"
                                               // keyfilter="pint"
                                                style={{ width: '100%', marginLeft: '1%', marginRight: '1%' }}
                                                value={this.state.shaerpointJson.fileName}
                                                onChange={(e) => this.changeSharepointData(e.target.value, 'fileName')}
                                            />  
                                            <br />
                                            <Button
                                                label={translator.save}
                                                type="button"
                                                icon="pi pi-check"
                                                onClick={() => this.saveSharepointData()}
                                                style={{ marginTop: '2%' }}
                                                disabled={this.state.shaerpointJson.configurationValue !== "" && this.state.shaerpointJson.fileName !== "" ? false : true} />
                                                

                                        </div>
                                    </Card>


                                </AccordionTab>

                            </Accordion>

                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        masterData: state.masterData,
        browserLanguage: state.browserLanguage
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage); 