import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import { translation } from '../../locales/translation.js'
import LocalizedStrings from 'react-localization';
import { CommonService } from '../../service/CommonService';
import { maxDuration } from '../../utility/commonUtility';
import './shiftMaster.css';
import leaveCodes from '../../utility/leaveCodes.json';
import { hhmmToMinutes, minutesToHHmm } from "../../utility/commonUtility";

let translator = new LocalizedStrings(translation);
let keepEmpDialogCompOpen = false;

class ShiftDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayDeleteDialog: false,
            disableSave: true,
            shiftEndTime: {
                monEndTime: "",
                tueEndTime: "",
                wedEndTime: "",
                thuEndTime: "",
                friEndTime: "",
                satEndTime: "",
                sunEndTime: ""
            }
        }

        this.commonService = new CommonService();
    }

    componentDidMount = () => {

        this.setState({
            costCenterShow: this.props.costCenterShow,
            displayDialog: this.props.displayDialog,
            jsonTemplate: this.props.jsonTemplate,
            deleteButton: this.props.deleteButton,
            shiftEditData: this.props.shiftEditData,
        })

    }

    componentWillMount = () => {
        if (this.props.deleteButton) {
            this.findEndTime(this.props.shiftEditData)
        }
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            costCenterShow: nextProps.costCenterShow,
            displayDialog: nextProps.displayDialog,
            jsonTemplate: nextProps.jsonTemplate,
            deleteButton: nextProps.deleteButton,
            shiftEditData: nextProps.shiftEditData,
        })
    }

    findEndTime = (rowData) => {

        let monEndTime = "";
        let tueEndTime = "";
        let wedEndTime = "";
        let thurEndTime = "";
        let friEndTime = "";
        let satEndTime = "";
        let sunEndTime = "";

        if (rowData.monStartTime !== null) {
            monEndTime = this.getEndTime(rowData.monStartTime, rowData.mondayDuration)
        }

        if (rowData.tueStartTime !== null) {
            tueEndTime = this.getEndTime(rowData.tueStartTime, rowData.tuesdayDuration)
        }

        if (rowData.wedStartTime !== null) {
            wedEndTime = this.getEndTime(rowData.wedStartTime, rowData.wednesdayDuration)
        }

        if (rowData.thuStartTime !== null) {
            thurEndTime = this.getEndTime(rowData.thuStartTime, rowData.thursdayDuration)
        }

        if (rowData.friStartTime !== null) {
            friEndTime = this.getEndTime(rowData.friStartTime, rowData.fridayDuration)
        }

        if (rowData.satStartTime !== null) {
            satEndTime = this.getEndTime(rowData.satStartTime, rowData.saturdayDuration)
        }

        if (rowData.sunStartTime !== null) {
            sunEndTime = this.getEndTime(rowData.sunStartTime, rowData.sundayDuration)
        }

        let endTimeShift = Object.assign({}, this.state.shiftEndTime)
        endTimeShift.monEndTime = monEndTime;
        endTimeShift.tueEndTime = tueEndTime;
        endTimeShift.wedEndTime = wedEndTime;
        endTimeShift.thuEndTime = thurEndTime;
        endTimeShift.friEndTime = friEndTime;
        endTimeShift.satEndTime = satEndTime;
        endTimeShift.sunEndTime = sunEndTime;
        this.setState({ shiftEndTime: endTimeShift })
    }

    getEndTime = (startTime, duration) => {
        let shiftEndTime = "";
        let newHour = 0;
        let startTimeArray = startTime.split(':');
        let durationArray = duration.split(':');
        let hours = ((Number(startTimeArray[0]) * 60 + Number(startTimeArray[1])) + (Number(durationArray[0]) * 60 + Number(durationArray[1]))) / 60
        let rhours = Math.floor(hours);
        let minutes = (hours - rhours) * 60;
        let rminutes = Math.round(minutes);

        if (hours > 24) {
            newHour = rhours - 24;
        }
        else {
            newHour = rhours;
        }


        if (isNaN(rhours) && isNaN(rminutes)) {
            shiftEndTime = "";
        }

        else {
            shiftEndTime = this.getDateCheck(newHour) + ":" + this.getDateCheck(rminutes);
        }
        return shiftEndTime
    }

    //function to add '0' in single digit month (ex:01,02)
    getMonthCheck = (month) => {
        let newMonth = 0
        if (month < 10) {
            newMonth = '0' + month;
            return newMonth
        }
        else {
            return month
        }
    }

    //function to add '0' in single digit date (ex:01,02)
    getDateCheck = (date) => {
        let newDate = 0
        if (date < 10) {
            newDate = '0' + date;
            return newDate
        }
        else {
            return date
        }
    }

    //Function to check if time is in 24 hour format
    checkStartTimeValue = (startTime) => {

        let startTimeArray = startTime.split(':');
        if ((Number(startTimeArray[0]) > 23) || (Number(startTimeArray[1]) > 59)) {
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'warn', summary: translator.warningMessage, detail: translator.shiftStartTimeError });
            return false;
        }

        else {
            return true;
        }
    }

    // function to check if shift duration does not exceed 15 hour
    checkDuration = (duration) => {
        let durationArray = duration.split(':');

        if ((Number(durationArray[1]) > 59) || (Number(durationArray[0]) > maxDuration) || ((Number(durationArray[0]) === maxDuration) && (Number(durationArray[1]) > 0))) {
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'warn', summary: translator.warningMessage, detail: translator.shiftDurationError });
            return false
        }

        else {
            return true
        }

    }

    //Function to call delete service
    callDeleteService = () => {

        let date = new Date();
        var currentDate = date.getFullYear() + '-' + (this.getMonthCheck(date.getMonth() + 1)) + '-' + this.getDateCheck(date.getDate());
        this.setState({ displayDeleteDialog: false });
        let deleteshiftData = Object.assign({}, this.state.shiftEditData);
        deleteshiftData.deactivationDate = currentDate;

        this.commonService.deleteShift(deleteshiftData, this.props.browserLanguage)
            .then(res => {
                if (res.errorMessage !== null) {
                    this.setState({ loading: false });
                    this.props.onChange(res.errorMessage);
                }

                else {
                    this.props.onChange(res.errorMessage);
                    this.setState({ loading: false });
                }

            }).catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }

    //Function to show Shift Dialog and hide delete shift confirmation dialog after deleting shift record. 
    showEmployeeDialogAgainAfterDeleteDialog = () => {

        keepEmpDialogCompOpen = false;
        this.setState({ displayDeleteDialog: false, displayDialog: true, deleteClicked: false });
    }

    //Function to call calbackprop and hide this dialog
    callbackToHideDialog = () => {
        this.setState({ displayDialog: false })
        this.props.callbackPropToHideDialog();
    }

    //Function to implement functionality of Add and Edit Validations On Pop Up Box.
    handleSubmit = (event) => {
        event.preventDefault();
        this.save();
    }

    showError = (err) => {

        this.growl.show({ severity: 'error', life: leaveCodes.growlMessageTimeout, summary: translator.errorMessage, detail: err });

    }

    //Function to implement functionality of Save Button On Add And Edit Employee.
    save = () => {
        this.setState({ loading: true });
        this.commonService.updateShift(this.state.shiftEditData, this.props.browserLanguage)
            .then(data => {
                if (data.errorMessage !== null) {
                    this.setState({ loading: false, displayDialog: true });
                    this.showError(data.errorMessage)
                }
                else {
                    this.props.onChange(data.errorMessage);
                    this.setState({ loading: false, displayDialog: false });
                }

            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })

    }

    //Function to implement functionality of Delete Button On Edit Employee.
    delete = () => {

        keepEmpDialogCompOpen = false;
        this.setState({
            displayDialog: false,
            deleteClicked: true,
            displayDeleteDialog: true
        });
    }

    updateProperty = (property, value) => {

        if (property === 'wfmTCostCenterMaster') {
            let shiftEditData = this.state.shiftEditData;
            shiftEditData["costCenterCode"] = value.costCenterCode;
            this.setState({ shiftEditData: shiftEditData, costCenterShow: value });
        }

        else if (property === 'shiftCode') {
            let shiftEditData = this.state.shiftEditData;
            shiftEditData[property] = value;
            this.setState({ shiftEditData: shiftEditData });
        }

        else if (property === 'routeCodes') {
            let shiftEditData = this.state.shiftEditData;
            shiftEditData[property] = value;
            this.setState({ shiftEditData: shiftEditData });
        }

        else if (property === 'remarks') {
            let shiftEditData = this.state.shiftEditData;
            shiftEditData[property] = value;
            shiftEditData['description'] = value;
            shiftEditData['shiftType'] = value;
            this.setState({ shiftEditData: shiftEditData });
        }

        else if (property === 'monStartTime') {
            if (this.checkStartTimeValue(value)) {
                let shiftEditData = this.state.shiftEditData;
                shiftEditData[property] = value;
                this.setState({ shiftEditData: shiftEditData });
                this.findEndTime(this.state.shiftEditData)
            }
        }

        else if (property === 'mondayDuration') {
            if (this.checkDuration(value)) {
                let shiftEditData = this.state.shiftEditData;
                shiftEditData[property] = value;
                this.findEndTime(this.state.shiftEditData)
                this.setState({ shiftEditData: shiftEditData });
            }
        }

        else if (property === 'tueStartTime') {
            if (this.checkStartTimeValue(value)) {
                let shiftEditData = this.state.shiftEditData;
                shiftEditData[property] = value;
                this.setState({ shiftEditData: shiftEditData });
                this.findEndTime(this.state.shiftEditData)
            }
        }

        else if (property === 'tuesdayDuration') {
            if (this.checkDuration(value)) {
                let shiftEditData = this.state.shiftEditData;
                shiftEditData[property] = value;
                this.findEndTime(this.state.shiftEditData)
                this.setState({ shiftEditData: shiftEditData });
            }
        }

        else if (property === 'wedStartTime') {
            if (this.checkStartTimeValue(value)) {
                let shiftEditData = this.state.shiftEditData;
                shiftEditData[property] = value;
                this.setState({ shiftEditData: shiftEditData });
                this.findEndTime(this.state.shiftEditData)
            }
        }

        else if (property === 'wednesdayDuration') {
            if (this.checkDuration(value)) {
                let shiftEditData = this.state.shiftEditData;
                shiftEditData[property] = value;
                this.findEndTime(this.state.shiftEditData)
                this.setState({ shiftEditData: shiftEditData });
            }
        }

        else if (property === 'thuStartTime') {
            if (this.checkStartTimeValue(value)) {
                let shiftEditData = this.state.shiftEditData;
                shiftEditData[property] = value;
                this.setState({ shiftEditData: shiftEditData });
                this.findEndTime(this.state.shiftEditData)
            }
        }

        else if (property === 'thursdayDuration') {
            if (this.checkDuration(value)) {
                let shiftEditData = this.state.shiftEditData;
                shiftEditData[property] = value;
                this.findEndTime(this.state.shiftEditData)
                this.setState({ shiftEditData: shiftEditData });
            }
        }

        else if (property === 'friStartTime') {
            if (this.checkStartTimeValue(value)) {
                let shiftEditData = this.state.shiftEditData;
                shiftEditData[property] = value;
                this.setState({ shiftEditData: shiftEditData });
                this.findEndTime(this.state.shiftEditData)
            }
        }

        else if (property === 'fridayDuration') {
            if (this.checkDuration(value)) {
                let shiftEditData = this.state.shiftEditData;
                shiftEditData[property] = value;
                this.findEndTime(this.state.shiftEditData)
                this.setState({ shiftEditData: shiftEditData, });
            }
        }

        else if (property === 'satStartTime') {
            if (this.checkStartTimeValue(value)) {
                let shiftEditData = this.state.shiftEditData;
                shiftEditData[property] = value;
                this.setState({ shiftEditData: shiftEditData });
                this.findEndTime(this.state.shiftEditData)
            }
        }

        else if (property === 'saturdayDuration') {
            if (this.checkDuration(value)) {
                let shiftEditData = this.state.shiftEditData;
                shiftEditData[property] = value;
                this.findEndTime(this.state.shiftEditData)
                this.setState({ shiftEditData: shiftEditData, });
            }
        }

        else if (property === 'sunStartTime') {
            if (this.checkStartTimeValue(value)) {
                let shiftEditData = this.state.shiftEditData;
                shiftEditData[property] = value;
                this.setState({ shiftEditData: shiftEditData });
                this.findEndTime(this.state.shiftEditData)
            }
        }

        else if (property === 'sundayDuration') {
            if (this.checkDuration(value)) {
                let shiftEditData = this.state.shiftEditData;
                shiftEditData[property] = value;
                this.findEndTime(this.state.shiftEditData)
                this.setState({ shiftEditData: shiftEditData });
            }
        }


        if ((this.state.shiftEditData.costCenterCode !== "") && (this.state.shiftEditData.shiftCode !== "") && (this.state.shiftEditData.monStartTime !== "" || this.state.shiftEditData.tueStartTime !== "" || this.state.shiftEditData.wedStartTime !== "" || this.state.shiftEditData.thuStartTime !== "" || this.state.shiftEditData.friStartTime !== "" || this.state.shiftEditData.satStartTime !== "" || this.state.shiftEditData.sunStartTime !== "")) {
            this.setState({ disableSave: false })
        }
        else {
            this.setState({ disableSave: true })
        }
    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {

        var detail =
            errorBody.message.split(',').map(item => {
                return (<li key={item}>{item}</li>)
            })
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });

    }

    //FUNCTION to set minutes to duration of any day based on field
    setMinutes = (value, field) => {

        this.setState(() => ({
            shiftEditData: {
                ...this.state.shiftEditData,
                [field]:  hhmmToMinutes(value)
            }
        }));

    }


    render() {

        translator.setLanguage(this.props.browserLanguage);

        const costCenter = this.props.costCenterDisplay;

        let dialogFooter = (<div className="ui-dialog-buttonpane p-clearfix">
            {this.state.deleteButton ? <Button label={translator.delete} icon="pi pi-times" className="deleteButton" onClick={this.delete} /> : null}
            <Button label={translator.save} icon="pi pi-check" className="deleteButton" type="submit" onClick={this.handleSubmit} disabled={this.state.deleteButton ? false : this.state.disableSave} />
        </div>);

        let weeklyHoursData = "00:00";

        if(this.state.shiftEditData !== undefined){
            weeklyHoursData = minutesToHHmm( this.state.shiftEditData.monDuration + this.state.shiftEditData.tueDuration +
                this.state.shiftEditData.wedDuration + this.state.shiftEditData.thuDuration + this.state.shiftEditData.friDuration +
                this.state.shiftEditData.satDuration + this.state.shiftEditData.sunDuration
            );
        }
        

        return (

            <React.Fragment>

                <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                {this.state.displayDeleteDialog ? <DeleteConfirmationDialog callbackDeleteService={this.callDeleteService} callbackProp={this.showEmployeeDialogAgainAfterDeleteDialog} lang={this.props.browserLanguage} screen="shiftMaster" /> : null}

                <form onSubmit={this.handleSubmit}>
                    {this.state.displayDialog ?
                        <Dialog visible={this.state.displayDialog} className="dialogBoxPosition" modal={true} header={translator.shiftDetails} footer={dialogFooter} onHide={keepEmpDialogCompOpen ? () => { } : this.callbackToHideDialog}>
                            {
                                this.state.shiftEditData && this.state.shiftEndTime ?

                                    <div>
                                        <Container>

                                            <Row className="padding14pxBottom">
                                                <Col><label >{translator.costCenterId}<span className="requiredAsterisk">*</span></label></Col>
                                                <Col><Dropdown id="wfmTCostCenterMaster" className="dropDwonDefault defaultWidth" dataKey="costCenterCode" value={this.state.costCenterShow} options={costCenter} onChange={(e) => { this.updateProperty('wfmTCostCenterMaster', e.target.value) }} placeholder={translator.selectCostCenter} optionLabel="codeAndName" filter={true} filterPlaceholder={translator.searchCostCenter} filterBy="costCenterCode,costCenterName" required disabled={this.state.deleteButton ? true : false} /></Col>
                                                <Col><label >{translator.shiftCode}<span className="requiredAsterisk">*</span></label></Col>
                                                <Col><InputText className="defaultWidth" id="shiftCode" maxLength={6} onChange={(e) => { this.updateProperty('shiftCode', e.target.value) }} value={this.state.shiftEditData.shiftCode} required disabled={this.state.deleteButton ? true : false} /></Col>
                                            </Row>

                                            <Row className="padding14pxBottom">
                                                <Col><label >{translator.routes}</label></Col>
                                                <Col><InputText className="defaultWidth" id="routeCodes" onChange={(e) => { this.updateProperty('routeCodes', e.target.value) }} value={this.state.shiftEditData.routeCodes} /></Col>
                                                <Col><label >{translator.shiftRemarks}</label></Col>
                                                <Col><InputText className="defaultWidth" id="shiftRemarks" onChange={(e) => { this.updateProperty('remarks', e.target.value) }} value={this.state.shiftEditData.description} /></Col>
                                            </Row>

                                            <Row className="padding14pxBottom">
                                                <Col><label className="labelDisplay">{translator.day}</label></Col>
                                                <Col><label className="labelDisplay">{translator.startTime}</label></Col>
                                                <Col><label className="labelDisplay">{translator.duration}</label></Col>
                                                <Col><label className="labelDisplay">{translator.endTime}</label></Col>
                                            </Row>

                                            <Row className="padding7pxBottom">
                                                <Col><label>{translator.monDay}</label></Col>
                                                <Col><InputMask mask="99:99" placeholder="__:__" className="defaultWidth" id="monStartTime" value={this.state.shiftEditData.monStartTime} onChange={(e) => { this.updateProperty('monStartTime', e.value) }}></InputMask></Col>
                                                <Col><InputMask onComplete={(e) => this.setMinutes(e.originalEvent.target.value, 'monDuration')} mask="99:99" placeholder="__:__" className="defaultWidth" id="monDuration" value={this.state.shiftEditData.mondayDuration} onChange={(e) => { this.updateProperty('mondayDuration', e.value) }} disabled={this.state.shiftEditData.monStartTime !== null && this.state.shiftEditData.monStartTime !== "" ? false : true}></InputMask></Col>
                                                <Col><InputText className="defaultWidth endTimeInputText" id="monEndTime" value={this.state.shiftEndTime.monEndTime} disabled /></Col>
                                            </Row>

                                            <Row className="padding7pxBottom">
                                                <Col><label>{translator.tuesDay}</label></Col>
                                                <Col><InputMask mask="99:99" placeholder="__:__" className="defaultWidth" id="tueStartTime" value={this.state.shiftEditData.tueStartTime} onChange={(e) => { this.updateProperty('tueStartTime', e.value) }}></InputMask></Col>
                                                <Col><InputMask onComplete={(e) => this.setMinutes(e.originalEvent.target.value, 'tueDuration')} mask="99:99" placeholder="__:__" className="defaultWidth" id="tueDuration" value={this.state.shiftEditData.tuesdayDuration} onChange={(e) => { this.updateProperty('tuesdayDuration', e.value) }} disabled={this.state.shiftEditData.tueStartTime !== null && this.state.shiftEditData.tueStartTime !== "" ? false : true}></InputMask></Col>
                                                <Col><InputText className="defaultWidth endTimeInputText" id="tueEndTime" value={this.state.shiftEndTime.tueEndTime} disabled /></Col>
                                            </Row>

                                            <Row className="padding7pxBottom">
                                                <Col><label>{translator.wednesDay}</label></Col>
                                                <Col><InputMask mask="99:99" placeholder="__:__" className="defaultWidth" id="wedStartTime" value={this.state.shiftEditData.wedStartTime} onChange={(e) => { this.updateProperty('wedStartTime', e.value) }}></InputMask></Col>
                                                <Col><InputMask onComplete={(e) => this.setMinutes(e.originalEvent.target.value, 'wedDuration')} mask="99:99" placeholder="__:__" className="defaultWidth" id="wedDuration" value={this.state.shiftEditData.wednesdayDuration} onChange={(e) => { this.updateProperty('wednesdayDuration', e.value) }} disabled={this.state.shiftEditData.wedStartTime !== null && this.state.shiftEditData.wedStartTime !== "" ? false : true} ></InputMask></Col>
                                                <Col><InputText className="defaultWidth endTimeInputText" id="wedEndTime" value={this.state.shiftEndTime.wedEndTime} disabled /></Col>
                                            </Row>

                                            <Row className="padding7pxBottom">
                                                <Col><label>{translator.thursDay}</label></Col>
                                                <Col><InputMask mask="99:99" placeholder="__:__" className="defaultWidth" id="thuStartTime" value={this.state.shiftEditData.thuStartTime} onChange={(e) => { this.updateProperty('thuStartTime', e.value) }}></InputMask></Col>
                                                <Col><InputMask onComplete={(e) => this.setMinutes(e.originalEvent.target.value, 'thuDuration')} mask="99:99" placeholder="__:__" className="defaultWidth" id="thuDuration" value={this.state.shiftEditData.thursdayDuration} onChange={(e) => { this.updateProperty('thursdayDuration', e.value) }} disabled={this.state.shiftEditData.thuStartTime !== null && this.state.shiftEditData.thuStartTime !== "" ? false : true}></InputMask></Col>
                                                <Col><InputText className="defaultWidth endTimeInputText" id="thurEndTime" value={this.state.shiftEndTime.thuEndTime} disabled /></Col>
                                            </Row>

                                            <Row className="padding7pxBottom">
                                                <Col><label>{translator.friDay}</label></Col>
                                                <Col><InputMask mask="99:99" placeholder="__:__" className="defaultWidth" id="friStartTime" value={this.state.shiftEditData.friStartTime} onChange={(e) => { this.updateProperty('friStartTime', e.value) }}></InputMask></Col>
                                                <Col><InputMask onComplete={(e) => this.setMinutes(e.originalEvent.target.value, 'friDuration')} mask="99:99" placeholder="__:__" className="defaultWidth" id="friDuration" value={this.state.shiftEditData.fridayDuration} onChange={(e) => { this.updateProperty('fridayDuration', e.value) }} disabled={this.state.shiftEditData.friStartTime !== null && this.state.shiftEditData.friStartTime !== "" ? false : true}></InputMask></Col>
                                                <Col><InputText className="defaultWidth endTimeInputText" id="friEndTime" value={this.state.shiftEndTime.friEndTime} disabled /></Col>
                                            </Row>

                                            <Row className="padding7pxBottom">
                                                <Col><label>{translator.saturDay}</label></Col>
                                                <Col><InputMask mask="99:99" placeholder="__:__" className="defaultWidth" id="satStartTime" value={this.state.shiftEditData.satStartTime} onChange={(e) => { this.updateProperty('satStartTime', e.value) }}></InputMask></Col>
                                                <Col><InputMask onComplete={(e) => this.setMinutes(e.originalEvent.target.value, 'satDuration')} mask="99:99" placeholder="__:__" className="defaultWidth" id="satDuration" value={this.state.shiftEditData.saturdayDuration} onChange={(e) => { this.updateProperty('saturdayDuration', e.value) }} disabled={this.state.shiftEditData.satStartTime !== null && this.state.shiftEditData.satStartTime !== "" ? false : true}></InputMask></Col>
                                                <Col><InputText className="defaultWidth endTimeInputText" id="satEndTime" value={this.state.shiftEndTime.satEndTime} disabled /></Col>
                                            </Row>

                                            <Row className="padding7pxBottom">
                                                <Col><label>{translator.sunDay}</label></Col>
                                                <Col><InputMask mask="99:99" placeholder="__:__" className="defaultWidth" id="sunStartTime" value={this.state.shiftEditData.sunStartTime} onChange={(e) => { this.updateProperty('sunStartTime', e.target.value) }}></InputMask></Col>
                                                <Col><InputMask onComplete={(e) => this.setMinutes(e.originalEvent.target.value, 'sunDuration')} mask="99:99" placeholder="__:__" className="defaultWidth" id="sunDuration" value={this.state.shiftEditData.sundayDuration} onChange={(e) => { this.updateProperty('sundayDuration', e.target.value) }} disabled={this.state.shiftEditData.sunStartTime !== null && this.state.shiftEditData.sunStartTime !== "" ? false : true}></InputMask></Col>
                                                <Col><InputText className="defaultWidth endTimeInputText" id="sunEndTime" value={this.state.shiftEndTime.sunEndTime} disabled /></Col>
                                            </Row>

                                            <Row className="padding7pxBottom">
                                                <Col></Col>
                                                <Col><label className="labelDisplay">{translator.weeklyHours}</label></Col>
                                                <Col><InputText className="defaultWidth endTimeInputText" id="sunEndTime" value={weeklyHoursData} disabled /></Col>
                                                <Col></Col>
                                            </Row>

                                        </Container>
                                    </div> : null
                            }
                        </Dialog> : null}</form>
            </React.Fragment>
        )
    }
}

export default ShiftDialog;