import React, { Component } from 'react';
import { connect } from 'react-redux';
import browser from 'browser-detect';
import ReactDOM from 'react-dom';
import { CommonService } from '../../service/CommonService';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import leaveCodes from '../../utility/leaveCodes.json';
import CostCenter from '../../components/costcenter/costCenter';
import { getResolution } from '../../../src/utility/commonUtility';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js'
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Paginator } from 'primereact/paginator';
import InputMask from 'react-input-mask';
import './KikyHours.css';

import { yearDropDown } from '../../utility/commonUtility';


let translator = new LocalizedStrings(translation);


class KikyHours extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            deleteButton: true,
            kikyData: null,
            selectedYear: null,
            getKikyDataTemplate: {
                costCenterId: "",
                year: null,
                employeeSearch: {
                    costCenterCode: "",
                    employeeId: "",
                    employeeName: "",
                    employeeTypeCode: "",
                    groupName: "",
                    sortBy: "employeeName",
                    sortDirection: "ASC",
                    pageNumber: 0,
                    recordCount: 150,
                    shiftCodeList: ""
                }
            },
            updateKikyHoursJson: {
                employeeDetailsId: null,
                kikyWeeksId: null,
                hours: "",
                availableHoursOne: "",
                availableHoursTwo: "",
                availableHoursThree: "",
                year: null
            },
            displayDialog: false,
            weeks: [],
            noOfColumns: null,
            updateHoursValue: null
        };
        this.commonService = new CommonService();
    }

    componentDidMount = () => {
        this.setState({ loading: true });
        this.commonMethodForLifecycleMethod();
    }

    commonMethodForLifecycleMethod = () => {
        let yearDropValue = yearDropDown()
        this.setState({ years: yearDropValue });
        this.commonService.getKikyHoursDataAsPerLastUsedFilter(this.props.browserLanguage)
            .then(res => {
                if (res !== undefined && res !== null && res.wfmTCostCenterMaster !==null) {
                    let initialFilterData = Object.assign({}, this.state.getKikyDataTemplate);
                    initialFilterData.employeeSearch.costCenterCode = res.wfmTCostCenterMaster['costCenterCode'];
                    initialFilterData.costCenterId = res.wfmTCostCenterMaster['costCenterMasterId'];
                    initialFilterData.year = res.year;
                    this.setState({
                        getKikyDataTemplate: initialFilterData,
                        lastUsedCostCenter: res.wfmTCostCenterMaster,
                        selectedYear: res.year,
                        pageNumber: res.pageNumber,
                        pageSize: res.pageSize,
                        kikyData: res.data,
                        totalRecords: res.totalRecords,
                        recordSize: res.totalRecords,
                        costCenterCode: res.wfmTCostCenterMaster['costCenterCode'],
                        costCenterId: res.wfmTCostCenterMaster['costCenterMasterId'],
                        loading: false,
                        weeks: res.weeks,
                        noOfColumns: res.noOfColumns
                    });
                }
                this.setState({ loading: false })

            })
            .catch(err => {
                this.setState({ loading: false, displayDialog: false });
                this.showMultiple(err);
            })
    }



    //Function to call service to display data according to the selected cost center and year from DropDown
    getList = (payload) => {
        this.setState({ loading: true });
        this.commonService.getKikyHoursData(payload, this.props.browserLanguage)
            .then(res => {
                if (res !== undefined && res !== null) {
                    this.setState({
                        kikyData: res.data,
                        pageNumber: res.pageNumber,
                        pageSize: res.pageSize,
                        totalRecords: res.totalRecords,
                        recordSize: res.totalRecords,
                        loading: false,
                        displayDialog: false,
                        weeks: res.weeks,
                        noOfColumns: res.noOfColumns
                    })
                }
            })
            .catch(err => {
                this.showMultiple(err);
                this.setState({ loading: false, displayDialog: false });
            })
    }


    //function to get Data on selection of the year in Year DropDown.
    onYearDropDownSelect = (event) => {
        if (this.growl !== undefined && this.growl !== null) {
            this.growl.clear();
        }
        this.setState({
            selectedYear: event.value,
            kikyData: null,
            displayDialog: false
        })

        let jsonCopy = Object.assign({}, this.state.getKikyDataTemplate);
        jsonCopy.costCenterId = this.state.costCenterId;
        jsonCopy.year = Number(event.value);
        jsonCopy.employeeSearch.employeeId = '';
        jsonCopy.employeeSearch.employeeName = '';
        jsonCopy.employeeSearch.employeeTypeCode = '';
        jsonCopy.employeeSearch.groupName = '';

        this.setState({ getKikyDataTemplate: jsonCopy });

        if (this.state.getKikyDataTemplate.costCenterId !== '' && event.value !== null) {
            this.setState({ loading: true, displayDialog: false });
            this.getList(jsonCopy);
        }

    }

    //function call when cost center selected
    onCostCenterSelect = (data, data1) => {
        if (this.growl !== undefined && this.growl !== null) {
            this.growl.clear();
        }
        this.setState({
            displayDialog: false,
            kikyData: null
        })

        if (data1 === '' || data === '') {
            this.setState({
                selectedYear: null,
                totalRecords: undefined,
                pageNumber: 0,
                pageSize: 0,
                getKikyDataTemplate: {
                    costCenterId: 0,
                    year: null,
                    employeeSearch: {
                        costCenterCode: "",
                        employeeId: "",
                        employeeName: "",
                        employeeTypeCode: "",
                        groupName: "",
                        sortBy: "employeeName",
                        sortDirection: "ASC",
                        PageNumber: 0,
                        recordCount: 150,
                        shiftCodeList: ""
                    }
                }

            });
            ReactDOM.unmountComponentAtNode(document.getElementById('costCenter'));
            ReactDOM.render(<CostCenter onChange={this.onCostCenterSelect}
                browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null :
                    this.props.masterData} />, document.getElementById('costCenter'))
            return false;
        }

        this.setState({
            costCenterCode: data,
            costCenterId: data1,
            // sortField: '',
            // sortOrder: null
        });

        let jsonCopy = Object.assign({}, this.state.getKikyDataTemplate);
        jsonCopy.employeeSearch.costCenterCode = data;
        jsonCopy.costCenterId = data1;
        jsonCopy.year = Number(this.state.selectedYear);
        jsonCopy.employeeSearch.shiftCodeList = '';
        jsonCopy.employeeSearch.employeeName = '';
        jsonCopy.employeeSearch.employeeTypeCode = '';
        jsonCopy.employeeSearch.groupName = '';
        this.setState({ getKikyDataTemplate: jsonCopy });

        if (data !== '' && this.state.selectedYear !== null) {
            this.setState({
                loading: true,
                first: 0
            });
            this.getList(jsonCopy);
        }

    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {

        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });

    }

    //Function to implement Change Page functionality of Paginator. 
    onPageChange = (e) => {
        this.setState({ first: e.first, loading: true, displayDialog: false });
        let pageChangeJsonCopy = Object.assign({}, this.state.getKikyDataTemplate);
        pageChangeJsonCopy.employeeSearch.pageNumber = e.page;
        this.getList(pageChangeJsonCopy);
    }

    //Function to implement functionality of Number of Record Dropdown change of Pagination. 
    noOfRecordChange = (e) => {

        this.setState({
            displayDialog: false,
            dropdownDisplay: e.value,
            loading: true,
            first: 0
        })
        let jsonCopy = Object.assign({}, this.state.getKikyDataTemplate);
        jsonCopy.employeeSearch.recordCount = e.value.code;
        jsonCopy.employeeSearch.pageNumber = 0;

        if (this.state.kikyData === undefined || this.state.kikyData.length === undefined) {
            this.setState({ loading: false });
            this.setState({ getKikyDataTemplate: jsonCopy });
            return false;
        }

        this.getList(jsonCopy);
        this.setState({ getKikyDataTemplate: jsonCopy });
    }

    //Function to implement Sorting functionality. 
    onSortChange = (e) => {

        this.setState({
            sortField: e.sortField,
            sortOrder: e.sortOrder,
            loading: true,
            displayDialog: false,
            first: 0
        });

        let jsonCopy = Object.assign({}, this.state.getKikyDataTemplate);
        jsonCopy.employeeSearch.sortDirection = 'ASC';
        jsonCopy.employeeSearch.sortBy = e.sortField;

        if (e.sortOrder === -1) {
            jsonCopy.employeeSearch.sortDirection = "DESC"
        }

        this.getList(jsonCopy);
        this.setState({ getKikyDataTemplate: jsonCopy });
    }

    //Function to implement functionality of Wrapping Long Text With "...".
    nameTemplate = (rowData, column) => {
        if (column.field === 'wfmTCostCenterMaster.costCenterName') {
            return <div className="extraTextWrapping" title={rowData.wfmTCostCenterMaster.costCenterName}>
                {rowData.wfmTCostCenterMaster.costCenterName}</div>;
        }
        else {
            return <div className="extraTextWrapping" title={rowData[column.field]}>{rowData[column.field]}</div>;
        }

    }

    dataTemplate = (rowData, column) => {

        return (
            <InputMask id={rowData["employeeDetailsId"] + column.field}
                mask="99:99"
                value={rowData[column.field]}
                onChange={(e) => { this.updateAvailableHoursForAYear(e, rowData, e.target.value, column.field) }}
                className="inputAlignDurationKiky kikyInputMaskSize" />
        )
    }

    weekDataTemplate = (rowData, column) => {
        if (String(column.header).includes(translator.total)) {
            if (rowData.weekHrs[column.header].split(':')[0].includes('-')) {
                return (
                    <div className="negativeDifference"><strong>{rowData.weekHrs[column.header]}</strong></div>
                )
            }else{
                return (
                    <div ><strong>{rowData.weekHrs[column.header]}</strong></div>
                )
            }
            
        } else {
            return (
                <InputMask id={rowData["employeeDetailsId"] + column.header}
                    mask="99:99"
                    value={rowData.weekHrs[column.header]}
                    onChange={(e) => { this.updateWeekAndHoursMapping(e, rowData, e.target.value, column) }}
                    className="inputAlignDurationKiky kikyInputMaskSize" />
            )
        }

    }

    //Function to implement Filtering functionality on Employee Name. 
    onemployeeNameFilter = (empName, key) => {
        if ((key === 'Enter' || key === 'Tab') && empName !== "") {
            this.setState({ displayDialog: false })
            let jsonCopy = Object.assign({}, this.state.getKikyDataTemplate);
            jsonCopy.employeeSearch.employeeName = empName;
            this.getList(jsonCopy);
        }
    }


    //Function to implement Filtering functionality on Employee Type Code. 
    onemployeeTypeFilter = (empType, key) => {
        if ((key === 'Enter' || key === 'Tab') && empType !== "") {
            this.setState({ displayDialog: false })
            let jsonCopy = Object.assign({}, this.state.getKikyDataTemplate);
            jsonCopy.employeeSearch.employeeTypeCode = empType;
            this.getList(jsonCopy);
        }
    }

    //function to clear filter
    filterCleared = (e, field) => {

        if (e.target.value === "" && field === "employeeName") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.getKikyDataTemplate);
            jsonCopy.employeeSearch.employeeName = "";
            this.setState({ getKikyDataTemplate: jsonCopy });
            this.getList(jsonCopy);
        }

        else if (e.target.value === "" && field === "employeeType") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.getKikyDataTemplate);
            jsonCopy.employeeSearch.employeeTypeCode = "";
            this.setState({ getKikyDataTemplate: jsonCopy });
            this.getList(jsonCopy);
        }


    }

    updateWeekAndHoursMapping = (e, rowData, value, column) => {
      
        if (!rowData.weekHrs[column.header].includes('_')) { this.setState({ updateHoursValue: rowData.weekHrs[column.header] }) }
        let jsonCopy = Object.assign({}, this.state.updateKikyHoursJson);
        jsonCopy.year = this.state.selectedYear;
        jsonCopy.employeeDetailsId = rowData.employeeDetailsId
        jsonCopy.hours = value
        this.state.weeks.map(item => {
            if (item.weekHeader === column.header) {
                jsonCopy.kikyWeeksId = item.kikyWeekId
            }
        })
        this.setState({ updateKikyHoursJson: jsonCopy });
        rowData.weekHrs[column.header] = value;

        if (!value.includes('_')) {

            if (Number(value.trim().split(':')[0]) > 23
                || Number(value.trim().split(':')[1]) > 59
            ) {
                this.validationError(translator.timeError);
                rowData.weekHrs[column.header] = this.state.updateHoursValue;
                return false;
            }
            this.setState({ loading: true });

            this.commonService.updateWeekAndHoursMapping(jsonCopy, this.props.browserLanguage)
                .then(res => {
                    this.getList(this.state.getKikyDataTemplate);
                    this.setState({
                        loading: false,
                        updateKikyHoursJson: {
                            employeeDetailsId: null,
                            kikyWeeksId: null,
                            hours: "",
                            availableHoursOne: "",
                            availableHoursTwo: "",
                            availableHoursThree: "",
                            year: null
                        },
                        updateHoursValue: null
                    });
                })
                .catch(err => {
                    this.setState({ loading: false });
                    this.showMultiple(err);
                })
        }
    }

    validationError(errorBody) {
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: errorBody });
    }
    updateAvailableHoursForAYear = (e, rowData, value, field) => {
        if (!rowData[field].includes('_')) { this.setState({ updateHoursValue: rowData[field] }) }
        let jsonCopy = Object.assign({}, this.state.updateKikyHoursJson);
        jsonCopy.year = this.state.selectedYear;
        jsonCopy.employeeDetailsId = rowData.employeeDetailsId
        jsonCopy.availableHoursOne = rowData.availableHoursOne
        jsonCopy.availableHoursTwo = rowData.availableHoursTwo
        jsonCopy.availableHoursThree = rowData.availableHoursThree
        jsonCopy[field] = value
        this.setState({ updateKikyHoursJson: jsonCopy });
        rowData[field] = value;

        if (!value.includes('_')) {

            if (Number(value.trim().split(':')[0]) > 23
                || Number(value.trim().split(':')[1]) > 59
            ) {
                this.validationError(translator.timeError);
                rowData[field] = this.state.updateHoursValue;
                return false;
            }
            this.setState({ loading: true });
            this.commonService.updateAvailableHoursForAYear(jsonCopy, this.props.browserLanguage)
                .then(res => {
                    this.getList(this.state.getKikyDataTemplate);
                    this.setState({
                        loading: false,
                        updateKikyHoursJson: {
                            employeeDetailsId: null,
                            kikyWeeksId: null,
                            hours: "",
                            availableHoursOne: "",
                            availableHoursTwo: "",
                            availableHoursThree: "",
                            year: null
                        },
                        updateHoursValue: null
                    });
                })
                .catch(err => {
                    this.setState({ loading: false });
                    this.showMultiple(err);
                })
        }
    }

    render() {

        translator.setLanguage(this.props.browserLanguage);
        const noOfRecord = [
            { name: translator.Records_150, code: 150 },
            { name: translator.Records_200, code: 200 },
            { name: translator.Records_250, code: 250 }
        ];

        let years = this.state.years;


        let employeeNameFilter = <InputText type="text" id="employeeName" onChange={(e) => this.filterCleared(e, "employeeName")} className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeNameFilter(e.target.value, e.key)} />
        let employeeTypeFilter = <InputText type="text" id="employeeType" onChange={(e) => this.filterCleared(e, "employeeType")} className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeTypeFilter(e.target.value, e.key)} />

        let widthOfHeader = this.state.noOfColumns
        let frozenHeaderGroup = <ColumnGroup>
            {
                this.state.kikyData ?

                    <Row>
                        <Column colSpan={5} header={translator.employeeDetails} className="EmpDetailWeekHeader" style={{ height: '56px', width: '714px' }}></Column>
                    </Row> : null
            }
            <Row>
                <Column header={translator.employeeName} field="employeeName" colSpan={1} sortable={true} filter={true} filterElement={employeeNameFilter} style={{ width: '150px !important' }} />
                <Column header={translator.employeeTypeCode} field="employeeType" sortable={true} colSpan={1} filter={true} filterElement={employeeTypeFilter} style={{ width: '150px !important' }} />
                <Column header={translator.availableKikyHoursOne} field="availableKikyHoursOne" colSpan={1} style={{ width: '100px !important' }} />
                <Column header={translator.availableKikyHoursTwo} field="availableKikyHoursTwo" colSpan={1} style={{ width: '100px !important' }} />
                <Column header={translator.availableKikyHoursThree} field="availableKikyHoursThree" colSpan={1} style={{ width: '100px !important' }} />
            </Row>
        </ColumnGroup>
        let headerGroup = <ColumnGroup>
            {
                this.state.kikyData ?

                    <Row>

                        <Column colSpan={widthOfHeader} header={translator.weeks}
                            className="EmpDetailWeekHeader" style={{ height: '56px' }}></Column>
                    </Row> : null
            }
            <Row>


                {
                    this.state.weeks.map((kikyWeek, index) => {

                        return (<Column key={kikyWeek.weekHeader} header={kikyWeek.weekHeader}
                            style={{ width: '80px', height: '60px', backgroundColor: kikyWeek.colorToFill }} className="dateHeader" />)

                    })
                }

            </Row>
        </ColumnGroup>;


        return (
            <div className="bodycolor">

                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>

                <Growl ref={(el) => this.growl = el} position="topright" className="shiftGrowlScroll"></Growl>
                <BreadCrumbs initial={translator.master} final={translator.kikyHours} browserLanguage={this.props.browserLanguage} />
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td className="CostCenterDiv" id="costCenter" style={{ width: "1%" }}>
                                    <CostCenter lastUsedCostCenter={this.state.lastUsedCostCenter} onChange={this.onCostCenterSelect}
                                        browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null :
                                            this.props.masterData} />
                                </td>

                                <td className="PlanningPeriodDiv" style={{ paddingLeft: "140px" }}>
                                    <strong>{translator.year} : </strong>
                                    <div className="dropDwonDefault">
                                        <Dropdown
                                            value={this.state.selectedYear}
                                            placeholder={translator.selectYear}
                                            options={years}
                                            onChange={(e) => { this.onYearDropDownSelect(e) }} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                {this.state.kikyData ?
                    this.state.totalRecords === 0 && (document.getElementById('employeeName') !== null ?
                        (document.getElementById('employeeName').value === '')
                        && this.state.loading === false : true) ?
                        null :
                        <div className="paginatorDropdownPosition" >
                            <span className="recordOF">{this.state.totalRecords === undefined ||
                                this.state.totalRecords === 0 ? '0-0' :
                                // (this.state.pageNumber * this.state.pageSize) + 1 + '-' + Number((this.state.pageNumber * this.state.pageSize)
                                //     + Number(this.state.recordSize))} {translator.of} {this.state.totalRecords === undefined ? 0
                                //         : this.state.totalRecords} 
                                (this.state.pageNumber * this.state.getKikyDataTemplate.employeeSearch.recordCount) + 1 + '-'
                                + Number((this.state.pageNumber * this.state.getKikyDataTemplate.employeeSearch.recordCount)
                                 + Number((this.state.pageSize)))} {translator.of} {this.state.totalRecords === undefined ? 0 : this.state.totalRecords} 
                                        {translator.records}</span>
                            <div className="paginatorEmp">
                                <Paginator rows={this.state.getKikyDataTemplate.employeeSearch.recordCount}
                                    totalRecords={this.state.totalRecords === undefined ? 0 : this.state.totalRecords}
                                    first={this.state.first} onPageChange={(e) => this.onPageChange(e)}></Paginator>
                                <Dropdown value={this.state.dropdownDisplay} className="dropDwonDefault colRecord dropmargin"
                                    options={noOfRecord} onChange={(e) => this.noOfRecordChange(e)}
                                    placeholder={translator.Records_150} optionLabel="name" />
                            </div>
                        </div> : null}
                <br />

                {this.state.kikyData ?
                    this.state.totalRecords === 0 && (document.getElementById('employeeName') !== null ?
                        (document.getElementById('employeeName').value === '')
                        && this.state.loading === false : true) ?
                        <h4 className="noRecordFoundMessage">{translator.noRecordFound}</h4> :

                        <DataTable
                            scrollable={browser().name === 'ie' ? false : true}
                            scrollHeight={getResolution()}
                            value={this.state.kikyData}
                            headerColumnGroup={headerGroup}
                            frozenHeaderColumnGroup={frozenHeaderGroup}
                            rows={this.state.pageSize}
                            className="datatableVacationPlanning"
                            onSort={(e) => this.onSortChange(e)}
                            sortField={this.state.sortField}
                            sortOrder={this.state.sortOrder}
                            emptyMessage={translator.noRecordFound}
                            resizableColumns={true}
                            frozenWidth="600px"
                        >
                            <Column field="employeeName" frozen={true} sortable={true} filter={true} filterElement={employeeNameFilter} body={this.nameTemplate} style={{ width: '150px', height: '32px' }} />
                            <Column field="employeeType" frozen={true} sortable={true} filter={true} filterElement={employeeTypeFilter} body={this.nameTemplate} style={{ width: '150px', height: '32px' }} />
                            <Column field="availableHoursOne" frozen={true} body={this.dataTemplate} style={{ width: '100px', height: '32px' }} />
                            <Column field="availableHoursTwo" frozen={true} body={this.dataTemplate} style={{ width: '100px', height: '32px' }} />
                            <Column field="availableHoursThree" frozen={true} body={this.dataTemplate} style={{ width: '100px', height: '32px' }} />
                            {
                                this.state.weeks.map((kikyWeek, index) => {

                                    return (<Column key={kikyWeek.weekHeader} header={kikyWeek.weekHeader}
                                        style={{ width: '80px', height: '32px' }}  body={this.weekDataTemplate} />)

                                })
                            }
                        </DataTable>
                    : null}

            </div>
        )
    }

}
const mapStateToProps = (state) => {
    return {
        masterData: state.masterData,
        browserLanguage: state.browserLanguage
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KikyHours);
