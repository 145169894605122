import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import { CommonService } from '../../service/CommonService';
import CostCenter from '../../components/costcenter/costCenter';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import './TVSReport.css';
import { Growl } from 'primereact/growl';
import DailyReport from './DailyReport.js';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import WeeklyReport from './WeeklyReport.js';
import BalancingPeriod from '../../components/balancingperiod/BalancingPeriod.js';
import ReactDOM from 'react-dom';
import leaveCodes from '../../utility/leaveCodes.json';
import ConfirmationDialog from './ConfirmationDialog.js';

let translator = new LocalizedStrings(translation);

class TVSReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jsonTemplate: {
                balancingPeriodID: '',
                centerMasterID: '',
                planningPeriodID: '',
                twelveWeekDisplay: true,
                employeeSearch: {
                    supervisorId: "",
                    costCenterCode: "",
                    employeeId: "",
                    employeeName: "",
                    employeeTypeCode: "",
                    sortBy: "employeeName",
                    sortDirection: "ASC",
                    pageNumber: 0,
                    recordCount: 150,
                    shiftCodeList: ""
                }
            },
            previousData: null,
            costCenterSelected: false
        }
        this.commonService = new CommonService();
    }

    componentDidMount = () => {
                
        this.setState({ 
            loading: true,
            jsonTemplate: {
                balancingPeriodID: '',
                centerMasterID: '',
                planningPeriodID: '',
                twelveWeekDisplay: true,
                employeeSearch: {
                    supervisorId: "",
                    costCenterCode: "",
                    employeeId: "",
                    employeeName: "",
                    employeeTypeCode: "",
                    sortBy: "employeeName",
                    sortDirection: "ASC",
                    pageNumber: 0,
                    recordCount: 150,
                    shiftCodeList: ""
                }
            }
        });
        this.commonMethodForLifecycleMethod(this.props);
    }

    commonMethodForLifecycleMethod = (props) =>{
        if (window.location.pathname === "/dailyTVS") {
            this.setState({ activeIndex: 0 });
            this.commonService.getLastFilterTVSDaily(props.browserLanguage)
                .then(res => {

                    let jsonCopy1 = Object.assign({}, this.state.jsonTemplate);
                    if (res.data.length > 0) {
                        jsonCopy1.employeeSearch.costCenterCode = res.costCenter.costCenterCode;
                        jsonCopy1.balancingPeriodID = res.balancePeriod.balancingPeriodId;
                        jsonCopy1.centerMasterID = res.costCenter.costCenterMasterId;
                        jsonCopy1.planningPeriodID = res.planningPeriod.planningPeriodId;
                        jsonCopy1.twelveWeekDisplay = res.twelveWeekDisplay;
                        this.setState({
                            lastUsedCostCenter: res.costCenter,
                            lastUsedBalancingPeriod: res.balancePeriod,
                            previousData: res,
                            loading: false
                        })
                        // this.getPlanningPeriod(res.balancePeriod.balancingPeriodId)
                        this.setState({ jsonTemplate: jsonCopy1 });
                    }
                    this.setState({ loading: false });
                })
                .catch(err => {
                    this.setState({ loading: false });
                    this.showMultiple(err);
                })

        }

        else if (window.location.pathname === "/weeklyTVS") {
            this.setState({ activeIndex: 1 });
            this.commonService.getLastFilterTVSWeekly(props.browserLanguage)
                .then(res => {

                    let jsonCopy1 = Object.assign({}, this.state.jsonTemplate);
                    if (res.data !== null ) {
                        jsonCopy1.employeeSearch.costCenterCode = res.costCenter.costCenterCode;
                        jsonCopy1.balancingPeriodID = res.balancePeriod.balancingPeriodId;
                        jsonCopy1.centerMasterID = res.costCenter.costCenterMasterId;
                        jsonCopy1.planningPeriodID = null;
                        this.setState({
                            lastUsedCostCenter: res.costCenter,
                            lastUsedBalancingPeriod: res.balancePeriod,
                            previousData: res,
                            loading: false
                        })

                        this.setState({ jsonTemplate: jsonCopy1 });

                    }
                    this.setState({ loading: false });
                })
                .catch(err => {
                    this.setState({ loading: false });
                    this.showMultiple(err);
                })

        }
    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {
        
        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });
        
    }

    //Function to implement functionality for cost center dropdown selection. 
    onCostCenterSelect = (ccCode, ccMasterId) => {
        if (this.growl !== undefined && this.growl !== null) {
            this.growl.clear();
        }
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        if (this.state.jsonTemplate.balancingPeriodID !== "") {
            for (let i = 0; i < this.props.masterData.balancingPeriodList.length; i++) {
                if (this.props.masterData.balancingPeriodList[i].balancingPerioId === this.state.jsonTemplate.balancingPeriodID) {
                    this.props.masterData.balancingPeriodList[i].planningPeriodList.map((data, key) => {
                        if (key === 0) {
                            jsonCopy.planningPeriodID = data.planningPeriodId;
                        }
                        return false;
                    })
                }
            }
        }
        if (ccMasterId === '') {
            jsonCopy.centerMasterID = '';
            jsonCopy.employeeSearch.costCenterCode = '';
            jsonCopy.employeeSearch.supervisorId = '';
            jsonCopy.employeeSearch.employeeId = '';
            jsonCopy.employeeSearch.employeeName = '';
            jsonCopy.employeeSearch.employeeTypeCode = '';
            jsonCopy.employeeSearch.sortBy = 'employeeName';
            jsonCopy.employeeSearch.sortDirection = 'ASC';
            jsonCopy.employeeSearch.pageNumber = 0;
            jsonCopy.employeeSearch.recordCount = 150;
            jsonCopy.employeeSearch.shiftCodeList = '';
            jsonCopy.twelveWeekDisplay = true;
            this.setState({
                jsonTemplate: jsonCopy,
                costCenterSelected: false,
                previousData: null
            });
            ReactDOM.unmountComponentAtNode(document.getElementById('costCenter'));
            ReactDOM.render(<CostCenter onChange={this.onCostCenterSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />, document.getElementById('costCenter'))
        }
        else {
            jsonCopy.employeeSearch.employeeId = '';
            jsonCopy.employeeSearch.employeeName = '';
            jsonCopy.employeeSearch.employeeTypeCode = '';
            jsonCopy.centerMasterID = ccMasterId;
            jsonCopy.employeeSearch.costCenterCode = ccCode;
            jsonCopy.twelveWeekDisplay = true;
            this.setState({ jsonTemplate: jsonCopy, costCenterSelected: true, previousData: null });
        }

    }

    //Callback to get the Balancing Period Id.
    onBalancingPeriodSelect = (bpId, ppId) => {
        if (this.growl !== undefined && this.growl !== null) {
            this.growl.clear();
        }
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);

        if (bpId === '') {
            jsonCopy.balancingPeriodID = '';
            jsonCopy.planningPeriodID = '';
            jsonCopy.employeeSearch.supervisorId = '';
            jsonCopy.employeeSearch.employeeId = '';
            jsonCopy.employeeSearch.employeeName = '';
            jsonCopy.employeeSearch.employeeTypeCode = '';
            jsonCopy.employeeSearch.sortBy = 'employeeName';
            jsonCopy.employeeSearch.sortDirection = 'ASC';
            jsonCopy.employeeSearch.pageNumber = 0;
            jsonCopy.employeeSearch.recordCount = 150;
            jsonCopy.employeeSearch.shiftCodeList = '';
            jsonCopy.twelveWeekDisplay = true;
            this.setState({ jsonTemplate: jsonCopy, previousData: null });
            return false;
        }
        
        jsonCopy.employeeSearch.employeeId = '';
        jsonCopy.employeeSearch.employeeName = '';
        jsonCopy.employeeSearch.employeeTypeCode = '';
        jsonCopy.balancingPeriodID = bpId;
        jsonCopy.planningPeriodID = ppId;
        jsonCopy.twelveWeekDisplay = true;
        this.setState({ jsonTemplate: jsonCopy, previousData: null });
    }

    render() {

        translator.setLanguage(this.props.browserLanguage);

        return (
            <div className="bodyColor">
                <Growl ref={(el) => this.growl = el} position="topright" ></Growl>

                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner className="loadingTVS" strokeWidth="5" animationDuration=".8s" />
                </Dialog>

                {this.state.printPDFClicked ?<ConfirmationDialog callbackPrintService={this.callPrintService} lang={this.props.browserLanguage} callbackProp={this.hideDialog} planningPeriodList={this.state.planningPeriodList}/> : null}

                <BreadCrumbs initial={translator.reports} final={this.state.activeIndex === 0 || this.props.option === "daily" ? translator.daily : translator.weekly} browserLanguage={this.props.browserLanguage} />

                <div className="tvsReportTop">
                    <div className="tvsReportCostCenterDiv" id="costCenter">
                        <CostCenter lastUsedCostCenter={this.state.lastUsedCostCenter} onChange={this.onCostCenterSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />
                    </div>
                    <div className={this.state.activeIndex === 0 ? "tvsReportPlanningPeriodDurationDiv" : "tvsReportPlanningPeriodDiv"} id="balancePeriod">
                        <BalancingPeriod lastUsedBalancingPeriod={this.state.lastUsedBalancingPeriod} onChange={this.onBalancingPeriodSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />
                    </div>
                </div>

                {
                    this.state.loading === false ?

                        <div>
                            {
                                this.state.activeIndex === 0 ?
                                    <DailyReport
                                        onDisplayPreferenceChange={this.displayPreference}
                                        previousData={this.state.previousData}
                                        browserLanguage={this.props.browserLanguage}
                                        jsonTemplate={this.state.jsonTemplate}
                                        masterData={this.props.masterData === null ? null : this.props.masterData}
                                    />
                                    : null
                            }

                            {
                                this.state.activeIndex === 1 ?
                                    <WeeklyReport
                                        previousData={this.state.previousData}
                                        browserLanguage={this.props.browserLanguage}
                                        jsonTemplate={this.state.jsonTemplate}
                                        masterData={this.props.masterData === null ? null : this.props.masterData}
                                    />
                                    : null
                            }

                        </div>
                        : null}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
      masterData: state.masterData,
      browserLanguage: state.browserLanguage
    }
  }
  
  
  const mapDispatchToProps = (dispatch) => {
    return {
      
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(TVSReport);
