import React, { Component } from 'react';
import { Chart } from 'primereact/chart';
import { Dropdown } from '../../../node_modules/primereact/dropdown';
import './dashboard.css';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import { connect } from 'react-redux';
import { CommonService } from '../../service/CommonService';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Growl } from 'primereact/growl';
import { MultiSelect } from 'primereact/multiselect';
import { Redirect } from 'react-router-dom';
import leaveCodes from '../../utility/leaveCodes.json';

let translator = new LocalizedStrings(translation);

class TargetHoursGraph extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            filterUsed: false
        }
        this.commonService = new CommonService();
        this.costCenterNameArrayForTargetHours = [];
        this.targetHoursArray = [];
        this.allocatedHoursArray = [];

        this.filteredCostCenterNameArrayForTargetHours = [];
        this.filteredTargetHoursArray = [];
        this.filteredAllocatedHoursArray = [];
    }

    componentDidMount = () => {
        this.costCenterNameArrayForTargetHours = this.props.costCenterNameArrayForTargetHours;
        this.targetHoursArray = this.props.targetHoursArray;
        this.allocatedHoursArray = this.props.allocatedHoursArray;

        let tempData = [];
        this.costCenterNameArrayForTargetHours.map(data => {
            return tempData.push(data);
        })
        this.setState({ selectedPlaningPeriodForTargetHours: this.props.masterData.currentPlanningperiod, selectedCCFilter: tempData });

    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {
        
        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });
        
    }

    onPlanningPeriodSelectForTargetHours = (event) => {
        this.setState({
            selectedPlaningPeriodForTargetHours: event.target.value,
            loading: true
        });

        this.commonService.getTargetHoursForSupervisorDashboard(event.target.value.planningPerioId, this.props.browserLanguage)
            .then(res => {
                if (res !== null && res !== undefined) {
                    this.costCenterNameArrayForTargetHours = [];
                    this.targetHoursArray = [];
                    this.allocatedHoursArray = [];
                    res.map(data => {
                        this.costCenterNameArrayForTargetHours.push(data.costCenterCode)
                        this.targetHoursArray.push(data.targetHours)
                        this.allocatedHoursArray.push(data.sumOfAllocatedHours)
                        return false;
                    });
                    this.setState({
                        loading: false
                    });
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })

    }

    filterData = (e) => {
        this.filteredCostCenterNameArrayForTargetHours = [];
        this.filteredTargetHoursArray = [];
        this.filteredAllocatedHoursArray = [];

        this.setState({ selectedCCFilter: e.value });

        if (e.value.length > 0) {
            this.setState({ filterUsed: true });
            e.value.map(data => {
                this.filteredCostCenterNameArrayForTargetHours.push(this.costCenterNameArrayForTargetHours[this.costCenterNameArrayForTargetHours.indexOf(data)]);
                this.filteredAllocatedHoursArray.push(this.allocatedHoursArray[this.costCenterNameArrayForTargetHours.indexOf(data)]);
                this.filteredTargetHoursArray.push(this.targetHoursArray[this.costCenterNameArrayForTargetHours.indexOf(data)]);
                return 0;
            })
        }
        else {
            this.setState({ filterUsed: false });
        }
    }
    test = (event, data) => {
        if(data[0] !== undefined){
            this.setState({ redirect : true });
        }
        

    }
    render() {
        if(this.state.redirect === true){
            return (
                <Redirect to="/dailyTVS"/>
            )
        }
        translator.setLanguage(this.props.browserLanguage);

        const dataForTargetHours = {
            labels: this.state.filterUsed === false ? this.costCenterNameArrayForTargetHours : this.filteredCostCenterNameArrayForTargetHours,
            datasets: [
                {
                    label: translator.targetHours,
                    backgroundColor: 'rgb(154, 208, 245, 0.8)',
                    data: this.state.filterUsed === false ? this.targetHoursArray : this.filteredTargetHoursArray
                },
                {
                    label: translator.allocatedHours,
                    backgroundColor: 'rgb(255, 188, 120, 0.8)',
                    data: this.state.filterUsed === false ? this.allocatedHoursArray : this.filteredAllocatedHoursArray
                }
            ]
        };

        const barGraphOptions = {
            responsive: true,
            legend:{
                position:'bottom'
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 30,
                    bottom: 0
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        min: 0,
                        maxTicksLimit: 5
                    },
                    gridLines: {
                        display: true
                    }
                }],
                xAxes: [{
                    gridLines: {
                        display: true
                    },
                    barThickness: 22
                }]
            },
            hover: {
                animationDuration: 1
            },
            animation: {
                duration: 1000,
                onComplete: function () {
                    var chartInstance = this.chart,
                        ctx = chartInstance.ctx;
                    ctx.textAlign = 'center';
                    ctx.fillStyle = "rgba(0, 0, 0, 1)";
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function (dataset, i) {
                        var meta = chartInstance.controller.getDatasetMeta(i);
                        meta.data.forEach(function (bar, index) {
                            var data = dataset.data[index];
                            ctx.fillText(data, bar._model.x, bar._model.y - 5);

                        });
                    });
                }
            },
            defaultFontFamily: "'Arial','sans-serif'",
            onClick: (e, dataArray) => this.test(e, dataArray)
        };

        const costCenterNameArrayForFilter = this.costCenterNameArrayForTargetHours.map(data => {
            return { label: data, value: data }
        })
        
        return (
            <div>
                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>

                <Growl ref={(el) => this.growl = el} position="topright" ></Growl>

                <div className=" form-inline dropDwonDefault">
                    <strong>{translator.planningPeriod}&nbsp;</strong>
                    <Dropdown
                        style={{ minWidth : '150px' }}
                        value={this.state.selectedPlaningPeriodForTargetHours}
                        options={this.props.masterData === null ? null : this.props.masterData.planningPeriodList}
                        onChange={(e) => this.onPlanningPeriodSelectForTargetHours(e)}
                        placeholder={translator.selectPlanningPeriod}
                        optionLabel='planningPeriodDescription'
                    />
                    <strong className="targetHoursFilter">{translator.costCenter}&nbsp;</strong>
                    <MultiSelect filter={true} maxSelectedLabels={0} placeholder={translator.selectCostCenter} value={this.state.selectedCCFilter} options={costCenterNameArrayForFilter} onChange={(e) => this.filterData(e)} />
                </div>

                {
                    this.costCenterNameArrayForTargetHours.length > 0 && this.targetHoursArray.length > 0 ?
                        <div className="container-outer scrollbar">
                            <div className="container-inner overflow">
                                <Chart type="bar" data={dataForTargetHours} options={barGraphOptions} />
                            </div>
                        </div>
                        : null
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        masterData: state.masterData,
        browserLanguage: state.browserLanguage
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetHoursGraph);
