import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CommonService } from '../../service/CommonService';
import { Dropdown } from 'primereact/dropdown';
import CostCenter from '../../components/costcenter/costCenter';
import UploadFile from '../../components/uploadfile/UploadFile';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { Growl } from 'primereact/growl';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import browser from 'browser-detect';
import { InputText } from 'primereact/inputtext';
import { getResolution } from '../../../src/utility/commonUtility';
import ReactDOM from 'react-dom';
import ShiftDialog from './ShiftDialog';
import { connect } from 'react-redux';
import leaveCodes from '../../utility/leaveCodes.json';
import { minutesToHHmm } from "../../utility/commonUtility";
import ShiftMasterTemplateDialog from './ShiftMasterTemplateDialog';

let translator = new LocalizedStrings(translation);

class ShiftMaster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            deleteButton: true,
            editedStatus: false,
            displayDialog: false,
            noOfRecords: 150,
            jsonTemplate: {
                supervisorId: '',
                costCenterCode: "",
                shiftType: "",
                shiftCode: "",
                description: "",
                sortBy: "shiftType",
                sortDirection: "ASC",
                pageNumber: 0,
                recordCount: 150
            },
            templateClick: false
        };
        this.commonService = new CommonService();

    }
    componentDidMount = () => {
        this.setState({ loading: true });
        this.commonMethodforLifecycleMethod();
    }

    commonMethodforLifecycleMethod = () => {
        this.commonService.getLastFilterShiftMaster(this.props.browserLanguage)
            .then(res => {
                let jsonCopy1 = Object.assign({}, this.state.jsonTemplate);
                if (res.costCenter !== null) {
                    jsonCopy1.costCenterCode = res.costCenter.costCenterCode;

                    this.setState({
                        lastUsedCostCenter: res.costCenter,
                        pageNumber: res.shiftPage.number,
                        pageSize: res.shiftPage.size,
                        shiftData: res.shiftPage.content,
                        totalShiftRecords: res.shiftPage.totalElements,
                        recordSize: res.shiftPage.numberOfElements,
                        loading: false
                    })
                    this.setState({ jsonTemplate: jsonCopy1 });
                }
                this.setState({ loading: false });
            })
            .catch(err => {
                this.setState({ loading: false, displayDialog: false });
                this.showMultiple(err);
            })
    }

    //Function to call API service for GET Shift List.
    getList = (payload) => {

        this.commonService.getShiftList(payload, this.props.browserLanguage)
            .then(data => {
                if (data !== undefined && data !== null) {
                    this.setState({
                        pageNumber: data.shiftPage.number,
                        pageSize: data.shiftPage.size,
                        shiftData: data.shiftPage.content,
                        totalShiftRecords: data.shiftPage.totalElements,
                        recordSize: data.shiftPage.numberOfElements,
                        loading: false
                    })
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {

        var detail =
            errorBody.message.split(',').map(item => {
                return (<li key={item}>{item}</li>)
            })
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });

    }

    //Function to implement Change Page functionality of Paginator. 
    onPageChange = (e) => {
        if (this.state.shiftData === undefined || this.state.shiftData.length === undefined) {
            return false;
        }
        this.setState({ first: e.first, loading: true });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate)
        jsonCopy.pageNumber = e.page;

        this.getList(Object.assign({}, jsonCopy));
        this.setState({ jsonTemplate: jsonCopy });
    }

    //Function to call API for filtering records
    getFilterFuntion = (jsonCopy) => {
        if (this.state.shiftData === undefined || this.state.shiftData.length === undefined) {
            return false;
        }

        jsonCopy.pageNumber = 0;
        this.setState({
            jsonTemplate: jsonCopy,
            loading: true,
            first: 0
        });
        this.getList(jsonCopy);

    }


    //Function to implement Filtering functionality on Shift Code. 
    onShiftCodeFilter = (shiftCode, key) => {
        if ((key === 'Enter' || key === 'Tab') && shiftCode !== "") {
            this.setState({ displayDialog: false })
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.shiftCode = shiftCode;
            this.getFilterFuntion(jsonCopy);
        }
    }

    //Function to implement Filtering functionality on Remark. 
    onRemarkFilter = (description, key) => {
        if ((key === 'Enter' || key === 'Tab') && description !== "") {
            this.setState({ displayDialog: false })
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.description = description;
            this.getFilterFuntion(jsonCopy);
        }

    }

    filterCleared = (e, field) => {

        if (e.target.value === "" && field === "shiftCode") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.shiftCode = "";
            this.setState({ jsonTemplate: jsonCopy });
            this.getList(jsonCopy);
        }
        else if (e.target.value === "" && field === "remark") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.description = "";
            this.setState({ jsonTemplate: jsonCopy });
            this.getList(jsonCopy);
        }
    }

    //Function to implement Sorting functionality. 
    onSortChange = (e) => {

        if (this.state.jsonTemplate.costCenterCode === '') {
            return false;
        }

        if (this.state.shiftData === undefined || this.state.shiftData.length === undefined) {
            return false;
        }

        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true, first: 0 });

        let jsonCopy = Object.assign({}, this.state.jsonTemplate)
        jsonCopy.sortDirection = 'ASC';
        jsonCopy.sortBy = e.sortField;
        jsonCopy.pageNumber = 0;
        this.setState({
            loading: true
        });

        if (e.sortOrder === -1) {
            jsonCopy.sortDirection = "DESC"
        }
        this.getList(Object.assign({}, jsonCopy));
        this.setState({ jsonTemplate: jsonCopy });
    }

    //Function to implement functionality of Upload Excel (Callback from Upload Component). 
    onUploadFile = (failed) => {
        if (failed === 'failed') {
            this.setState({ loading: false });
            return false;
        }
        this.setState({ loading: true });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate)
        this.getList(Object.assign({}, jsonCopy));
        this.setState({ jsonTemplate: jsonCopy });
    }

    //Function to implement functionality of Number of Record Dropdown change of Pagination. 
    noOfRecordChange = (e) => {

        this.setState({
            noOfRecords: e.value.code,
            dropdownDisplay: e.value,
            loading: true,
            first: 0
        })
        let jsonCopy = Object.assign({}, this.state.jsonTemplate)
        jsonCopy.recordCount = e.value.code;
        jsonCopy.pageNumber = 0;

        if (this.state.shiftData === undefined || this.state.shiftData.length === undefined) {
            this.setState({ loading: false });
            this.setState({ jsonTemplate: jsonCopy });
            return false;
        }

        this.getList(jsonCopy);
        this.setState({ jsonTemplate: jsonCopy });
    }

    //Function to implement functionality of Upload Button Disabling (Callback).
    uploadEnable = () => {

        this.setState({ loading: true })
    }

    //Function to set default filters
    clearFilterControl = () => {

        if (document.getElementById('shiftCode')) {
            document.getElementById('shiftCode').value = ''
        }
        if (document.getElementById('remark')) {
            document.getElementById('remark').value = ''
        }
    }

    //Function to implement functionality for cost center dropdown selection. 
    onCostCenterSelect = (data) => {
        if (this.growl !== undefined && this.growl !== null) {
            this.growl.clear();
        }
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        this.clearFilterControl();
        if (data === '') {
            jsonCopy.costCenterCode = '';
            this.setState({
                shiftData: [],
                totalShiftRecords: undefined,
                jsonTemplate: jsonCopy
            });
            ReactDOM.unmountComponentAtNode(document.getElementById('costCenter'));
            ReactDOM.render(<CostCenter onChange={this.onCostCenterSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />, document.getElementById('costCenter'))
            return false;
        }
        jsonCopy.costCenterCode = data;
        jsonCopy.shiftCode = '';
        jsonCopy.shiftType = '';
        jsonCopy.description = '';
        jsonCopy.pageNumber = 0;
        this.setState({
            loading: true,
            jsonTemplate: jsonCopy,
            sortField: '',
            sortOrder: null,
            first: 0
        });
        this.getList(Object.assign({}, jsonCopy));
        this.setState({ jsonTemplate: jsonCopy });
    }

    //Function to implement functionality of Wrapping Long Text With "...".
    nameTemplate = (rowData, column) => {

        if (column.field === 'wfmTCostCenterMaster.costCenterName') {
            return <div className="extraTextWrapping" title={rowData.wfmTCostCenterMaster.costCenterName}>{rowData.wfmTCostCenterMaster.costCenterName}</div>;
        }
        else {
            return <div className="extraTextWrapping" title={rowData[column.field]}>{rowData[column.field]}</div>;
        }

    }

    //Function to implement functionality of Wrapping Long Text With "...".
    weeklyHoursTemplate = (rowData, column) => {

        var totalMinutes = rowData.monDuration + rowData.tueDuration + rowData.wedDuration
            + rowData.thuDuration + rowData.friDuration + rowData.satDuration + rowData.sunDuration;

        return <div>{minutesToHHmm(totalMinutes)}</div>

    }

    addNew = () => {
        this.setState({
            costCenterDisplay: this.props.masterData.costCenter,
            isAdmin: this.props.masterData.admin,
            costCenterShow: {},
            shiftEditData: {
                shiftDetailsId: "",
                deactivationDate: "",
                description: "",
                routeCodes: "",
                shiftCode: "",
                shiftType: "",
                remarks: "",
                monStartTime: "",
                tueStartTime: "",
                wedStartTime: "",
                thuStartTime: "",
                friStartTime: "",
                satStartTime: "",
                sunStartTime: "",
                mondayDuration: "",
                tuesdayDuration: "",
                wednesdayDuration: "",
                thursdayDuration: "",
                fridayDuration: "",
                saturdayDuration: "",
                sundayDuration: "",
                costCenterCode: ""
            },
            deleteButton: false,
            displayDialog: true
        });

    }

    getStartTime = (startTime) => {
        let shiftStartTime = "";

        if (startTime === null || startTime === '') {
            return shiftStartTime;
        }
        else {
            let startTimeArray = startTime.split(':');
            shiftStartTime = String(startTimeArray[0] + ":" + startTimeArray[1])
            return shiftStartTime;
        }

    }

    onRowSelect = (e) => {
        this.setState({
            costCenterShow: {
                costCenterMasterId: e.data.wfmTCostCenterMaster.costCenterMasterId,
                costCenterName: e.data.wfmTCostCenterMaster.costCenterName,
                costCenterCode: e.data.wfmTCostCenterMaster.costCenterCode,
                //areaCode: e.data.wfmTCostCenterMaster.wfmTAreaMaster.areaCode,
                //areaName: e.data.wfmTCostCenterMaster.wfmTAreaMaster.areaName,
                // value: e.data.wfmTCostCenterMaster.wfmTAreaMaster.value
            },
            // shiftEditData: Object.assign({}, e.data),
            shiftEditData: {
                shiftDetailsId: e.data.shiftDetailsId,
                deactivationDate: e.data.deactivationDate,
                description: e.data.description,
                routeCodes: e.data.routeCodes,
                shiftCode: e.data.shiftCode,
                shiftType: e.data.shiftType,
                remarks: e.data.remarks,
                monStartTime: this.getStartTime(e.data.monStartTime),
                tueStartTime: this.getStartTime(e.data.tueStartTime),
                wedStartTime: this.getStartTime(e.data.wedStartTime),
                thuStartTime: this.getStartTime(e.data.thuStartTime),
                friStartTime: this.getStartTime(e.data.friStartTime),
                satStartTime: this.getStartTime(e.data.satStartTime),
                sunStartTime: this.getStartTime(e.data.sunStartTime),
                monDuration: e.data.monDuration,
                tueDuration: e.data.tueDuration,
                wedDuration: e.data.wedDuration,
                thuDuration: e.data.thuDuration,
                friDuration: e.data.friDuration,
                satDuration: e.data.satDuration,
                sunDuration: e.data.sunDuration,
                mondayDuration: e.data.mondayDuration,
                tuesdayDuration: e.data.tuesdayDuration,
                wednesdayDuration: e.data.wednesdayDuration,
                thursdayDuration: e.data.thursdayDuration,
                fridayDuration: e.data.fridayDuration,
                saturdayDuration: e.data.saturdayDuration,
                sundayDuration: e.data.sundayDuration,
                costCenterCode: e.data.wfmTCostCenterMaster.costCenterCode
            },
            costCenterDisplay: this.props.masterData.costCenter,
            isAdmin: this.props.masterData.admin,
            displayDialog: true,
            deleteButton: true,
        })

    }

    //Function called when callback is received from Dialog.
    callbackFromDialog = (errorMessage) => {

        if (errorMessage === null || errorMessage === undefined) {
            this.setState({ displayDialog: false, loading: true })
            this.getList(this.state.jsonTemplate);
        }
        else {
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: errorMessage });
            this.setState({ displayDialog: false, loading: true })
            this.getList(this.state.jsonTemplate);
        }
    }

    //Function called when callback is received from Dialog on onHide event
    callbackToHideDialog = () => {
        this.setState({ displayDialog: false, templateClick: false });
    }

    render() {
        // if (document.getElementsByClassName('p-datatable-emptymessage') !== undefined ) {
        //     if(document.getElementsByClassName('p-datatable-emptymessage').length > 0){
        //     document.getElementsByClassName('p-datatable-emptymessage')[1].style.color = "transparent";
        //     document.getElementsByClassName('p-datatable-emptymessage')[1].firstChild.style.borderLeft = "0 none";
        //    }   }

        translator.setLanguage(this.props.browserLanguage);

        const noOfRecord = [
            { name: translator.Records_150, code: 150 },
            { name: translator.Records_200, code: 200 },
            { name: translator.Records_250, code: 250 }
        ];

        let shiftCodeFilter = <InputText id="shiftCode" onChange={(e) => { this.filterCleared(e, "shiftCode") }} className="employeeIdFilterWidth" onKeyDown={(e) => this.onShiftCodeFilter(e.target.value, e.key)} />
        let shiftRemarkFilter = <InputText id="remark" onChange={(e) => { this.filterCleared(e, "remark") }} className="employeeIdFilterWidth" onKeyDown={(e) => this.onRemarkFilter(e.target.value, e.key)} />

        return (

            <div className="bodyColor">
                <Growl ref={(el) => this.growl = el} position="topright"></Growl>
                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>

                {this.state.templateClick ?
                    <ShiftMasterTemplateDialog
                        handleTemplateClick={this.callbackToHideDialog}
                        costCenterCode={this.state.jsonTemplate.costCenterCode}
                    />
                    : null}

                {this.state.displayDialog ?
                    <ShiftDialog browserLanguage={this.props.browserLanguage}
                        displayDialog={true}
                        onChange={this.callbackFromDialog}
                        callbackPropToHideDialog={this.callbackToHideDialog}
                        deleteButton={this.state.deleteButton}
                        jsonTemplate={this.state.jsonTemplate}
                        costCenterShow={this.state.costCenterShow}
                        costCenterDisplay={this.state.costCenterDisplay}
                        shiftEditData={this.state.shiftEditData} />
                    : null
                }

                <BreadCrumbs initial={translator.master} final={translator.shift} />
                <div>
                    <div className="costcenterPosition" id="costCenter">
                        <CostCenter lastUsedCostCenter={this.state.lastUsedCostCenter} onChange={this.onCostCenterSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />
                    </div>
                    <div className="buttonsPostion">
                        <table >
                            <tbody>
                                <tr>
                                    <td className="btn-blank"></td>
                                    <td>
                                        <Button label={translator.getTemplate} title={translator.getTemplate} icon="pi pi-download"
                                            // onClick={() => this.getTemplate()} 
                                            onClick={() => this.setState({ templateClick: true })}
                                            className="btnDefault" style={{ marginRight: '.25em' }} />
                                    </td>
                                    <td>
                                        <UploadFile onChange={this.onUploadFile} browserLanguage={this.props.browserLanguage} uploadType="SHIFT" />
                                    </td>
                                    <td><Button className="addbuttonEmp" title={translator.addBtn} label={translator.addBtn} icon="pi pi-plus" onClick={this.addNew} style={{ marginRight: '.25em' }} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {this.state.jsonTemplate.costCenterCode !== '' ?
                    this.state.totalShiftRecords === 0 && (document.getElementById('shiftCode') !== null ? (document.getElementById('shiftCode').value === '' && document.getElementById('remark').value === '') && this.state.loading === false : true) ?
                        null :
                        <div className="paginatorDropdownPosition">
                            <span className="recordOF">{this.state.totalShiftRecords === undefined || this.state.totalShiftRecords === 0 ? '0-0' : (this.state.pageNumber * this.state.pageSize) + 1 + '-' + Number((this.state.pageNumber * this.state.pageSize) + Number(this.state.recordSize))} {translator.of} {this.state.totalShiftRecords === undefined ? 0 : this.state.totalShiftRecords} {translator.records}</span>
                            <div className="paginatorshift">
                                <Paginator rows={this.state.noOfRecords} totalRecords={this.state.totalShiftRecords === undefined ? 0 : this.state.totalShiftRecords} first={this.state.first} onPageChange={(e) => this.onPageChange(e)}></Paginator>
                                <Dropdown value={this.state.dropdownDisplay} className="dropDwonDefault colRecord dropmargin" options={noOfRecord} onChange={(e) => this.noOfRecordChange(e)} placeholder={translator.Records_150} optionLabel="name" />
                            </div>
                        </div>
                    : null}
                <br />
                {this.state.jsonTemplate.costCenterCode !== '' ?
                    this.state.totalShiftRecords === 0 && (document.getElementById('shiftCode') !== null ? (document.getElementById('shiftCode').value === '' && document.getElementById('remark').value === '') && this.state.loading === false : true) ?
                        <h4 className="noRecordFoundMessage">{translator.noRecordFound}</h4> :
                        <DataTable
                            scrollable={browser().name === 'ie' ? false : true}
                            resizableColumns={true}
                            editable={true}
                            value={this.state.shiftData}
                            selectionMode="multiple"
                            rows={this.state.pageSize}
                            onSort={(e) => this.onSortChange(e)}
                            scrollHeight={getResolution()}
                            onRowSelect={(e) => this.onRowSelect(e)}
                            className="dataTable"
                            sortField={this.state.sortField}
                            sortOrder={this.state.sortOrder}
                            emptyMessage={translator.noRecordFound}
                            frozenWidth="410px"
                            unfrozenWidth="800px"
                        >
                            <Column field="wfmTCostCenterMaster.costCenterName" frozen={true} header={translator.cc} className="textLefttAlign datatableHeaderWidth105" body={this.nameTemplate} />
                            <Column field="shiftCode" header={translator.shiftCode} frozen={true} sortable={true} filter={true} filterElement={shiftCodeFilter} className="numberRightAlign datatableHeaderWidth110" body={this.nameTemplate} />
                            <Column field="routeCodes" header={translator.routes} frozen={true} className="datatableHeaderWidth90" body={this.nameTemplate} />
                            <Column field="description" header={translator.shiftRemarks} sortable={true} filter={true} filterElement={shiftRemarkFilter} className="textLefttAlign datatableHeaderWidth135" body={this.nameTemplate} />
                            <Column field="monStartTime" header={translator.monStartTime} sortable={true} className="numberRightAlign datatableHeaderWidth90" />
                            <Column field="tueStartTime" header={translator.tueStartTime} sortable={true} className="numberRightAlign datatableHeaderWidth90" />
                            <Column field="wedStartTime" header={translator.wedStartTime} sortable={true} className="numberRightAlign datatableHeaderWidth90" />
                            <Column field="thuStartTime" header={translator.thuStartTime} sortable={true} className="numberRightAlign datatableHeaderWidth90" />
                            <Column field="friStartTime" header={translator.friStartTime} sortable={true} className="numberRightAlign datatableHeaderWidth90" />
                            <Column field="satStartTime" header={translator.satStartTime} className="numberRightAlign datatableHeaderWidth85" />
                            <Column field="sunStartTime" header={translator.sunStartTime} className="numberRightAlign datatableHeaderWidth85" />
                            <Column field="mondayDuration" header={translator.monDuration} sortable={true} className="numberRightAlign datatableHeaderWidth85" />
                            <Column field="tuesdayDuration" header={translator.tueDuration} sortable={true} className="numberRightAlign datatableHeaderWidth85" />
                            <Column field="wednesdayDuration" header={translator.wedDuration} sortable={true} className="numberRightAlign datatableHeaderWidth85" />
                            <Column field="thursdayDuration" header={translator.thuDuration} sortable={true} className="numberRightAlign datatableHeaderWidth85" />
                            <Column field="fridayDuration" header={translator.friDuration} sortable={true} className="numberRightAlign datatableHeaderWidth85" />
                            <Column field="saturdayDuration" header={translator.satDuration} className="numberRightAlign datatableHeaderWidth85" />
                            <Column field="sundayDuration" header={translator.sunDuration} className="numberRightAlign datatableHeaderWidth85" />
                            <Column field="" header={translator.weeklyHours} className="numberRightAlign datatableHeaderWidth90" body={this.weeklyHoursTemplate} />
                            <Column field="deactivationDate" header={translator.deactivationDate} sortable={true} className="numberRightAlign datatableHeaderWidth95" />

                        </DataTable>
                    : null}

                <br />

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        masterData: state.masterData,
        browserLanguage: state.browserLanguage
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftMaster); 