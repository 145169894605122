import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js'
import { connect } from 'react-redux';
import FileSaver from 'file-saver';
import { CommonService } from '../../service/CommonService';
import { Growl } from 'primereact/growl';
import { ProgressSpinner } from 'primereact/progressspinner';
import leaveCodes from '../../utility/leaveCodes.json';

let translator = new LocalizedStrings(translation);

class ShiftMasterTemplateDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            response: "N"
        }
        this.commonService = new CommonService();
    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {

        var detail =
            errorBody.message.split(',').map(item => {
                return (<li key={item}>{item}</li>)
            })
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });

    }

    //Function To get Shift master Excel Tempalte
    getTemplateWithoutData = () => {

        this.setState({ loading: true });
        let filename = "shift.xlsx"
        if (this.props.browserLanguage === "fi") {
            filename = "shift_fi.xlsx"
        }
        this.commonService.getExcelTemplate(this.props.browserLanguage, filename)
            .then(
                response => {
                    let fileName = translator.shiftTemplateFileName + ".xlsx";
                    var blob = new Blob([response.data], { type: "application/xlsx" });
                    var reader = new FileReader();
                    reader.onloadend = function () {
                        FileSaver.saveAs(blob, fileName);
                    }
                    reader.readAsText(blob);
                    this.props.handleTemplateClick();
                    this.setState({ loading: false });
                }
            )
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })

    }

    //Function To get Shift master Excel Tempalte
    getTemplateWithData = () => {
        this.setState({ loading: true });
        
        var costCenter = this.props.masterData.costCenter.filter(data => { return data.costCenterCode === this.props.costCenterCode });
        
        this.commonService.getShiftExcelTemplateWithData(this.props.browserLanguage, costCenter[0].costCenterMasterId)
            .then(
                response => {
                    let fileName = translator.shiftTemplateFileName + ".xlsx";
                    var blob = new Blob([response.data], { type: "application/xlsx" });
                    var reader = new FileReader();
                    reader.onloadend = function () {
                        FileSaver.saveAs(blob, fileName);
                    }
                    reader.readAsText(blob);
                    this.props.handleTemplateClick();
                    this.setState({ loading: false });
                }
            )
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })

    }

    handleTemplateClick = () => {

        if (this.state.response === "Y") {
            this.getTemplateWithData();
        }
        else {
            this.getTemplateWithoutData();
        }
    }


    render() {

        translator.setLanguage(this.props.browserLanguage);

        const footer = (
            <div>
                <Button
                    label={translator.templateFooter}
                    title={translator.templateFooter}
                    onClick={this.handleTemplateClick}
                    className="btnDefault"
                    icon="pi pi-download"
                />
            </div>
        );
        return (
            <div className="bodyColor">

                <Growl ref={(el) => this.growl = el} position="topright"></Growl>
                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>

                < Dialog
                    header={translator.templateHeader}
                    footer={footer}
                    visible={true}
                    style={{ width: '30%', height: '10vh', marginTop: '-5%' }}
                    onHide={this.props.handleTemplateClick}

                >
                    <div>
                        <input type="radio" id="Yes" value="Y" onChange={(event) => this.setState({ [event.target.name]: event.target.value })}
                            name="response" />
                        <label htmlFor="Yes" style={{ marginLeft: '2%' }}>
                            {translator.templateWithShiftDetails}
                        </label><br />
                    </div>
                    <div>
                        <input type="radio" id="No" value="N" defaultChecked onChange={(event) => this.setState({ [event.target.name]: event.target.value })}
                            name="response" />
                        <label htmlFor="No" style={{ marginLeft: '2%' }}>
                            {translator.templateWithoutShiftDetails}
                        </label>
                    </div>

                </Dialog>

            </div >

        );
    }
}

const mapStateToProps = (state) => {
    return {
        masterData: state.masterData,
        browserLanguage: state.browserLanguage
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftMasterTemplateDialog);
