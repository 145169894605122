import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
let translator = new LocalizedStrings(translation);

class HaittaPerAndCommentDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            haittaPercentageAndComment: {
                weeklyComment: "",
                weeklyHaitta: ""
            }
        }
    }

    componentDidMount = () => {
        let haittaCopy = Object.assign({}, this.state.haittaPercentageAndComment);
        haittaCopy.weeklyHaitta = this.props.haittaPercentageAndComment.weeklyHaitta;
        haittaCopy.weeklyComment = this.props.haittaPercentageAndComment.weeklyComment;
        this.setState({ haittaPercentageAndComment: haittaCopy })
    }

    //Function to call Employee Dialog function to dismiss delete confirmation dialog and again show Employee Dialog
    callToDismissDialog = () => {
        this.props.callbackProp();
    }

    //Function to call Employee Dialog function to call delete service
    callHaittaFunctionInTVS = () => {
        this.props.callbackUpdateService(this.state.haittaPercentageAndComment);
    }

    //Function to set focus to Yes button
    setFocusOnYesBtn = () => {
        if (document.getElementById("yesbtn") !== null)
            setTimeout(function () { document.getElementById("yesbtn").focus(); }, 2);
    }

    //Function to check if pattern of haitta input is matched or not
    checkHaittaInputPattern = (event) => {
        var inputValue = event.target.value;
        var numberKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

        if ((event.keyCode > 64 && event.keyCode < 91) || event.keyCode === 32){
            event.preventDefault();
        }

        if (this.props.browserLanguage === 'en') {
            //Conditional block to prevernt entering of ,
            if (event.key === ",")
                event.preventDefault();

            //Conditional block executes when already a . is present in entered value and one more . is trying to be entered
            if ((event.key === ",")  //prevents , to be entered
                || (inputValue.length === 0 && event.key === ".")  //when input is empty doesn`t allow .
                || (inputValue.indexOf('.') === -1 && inputValue.length === 3 && numberKeys.indexOf(event.key) >= 0)  //when 3 numbers entered before decimal doesn`t allow more number to enter before decimal
                || (inputValue.indexOf('.') > -1 && event.key === ".") //prevents more than one . to be entered
                || ((inputValue.indexOf('.') > -1 && inputValue.split('.')[1].length === 1) && numberKeys.indexOf(event.key) >= 0)) //when pattern is completed doesn`t allow more number to be entered
            {
                //prevents keyboard button value as input
                event.preventDefault();
            }

            else{
                let haittaCopy = Object.assign({}, this.state.haittaPercentageAndComment);
                haittaCopy.weeklyHaitta = event.target.value;
                this.setState({ haittaPercentageAndComment: haittaCopy })
            }
        }
        else    //when language is finish
        {
            //Conditional block to prevernt entering of ,
            if (event.key === ".")
                event.preventDefault();

            //Conditional block executes when already a , is present in entered value and one more , is trying to be entered
            if ((event.key === ".")  //prevents , to be entered
                || (inputValue.length === 0 && event.key === ",")  //when input is empty doesn`t allow ,
                || (inputValue.indexOf(',') === -1 && inputValue.length === 3 && numberKeys.indexOf(event.key) >= 0)  //when 3 numbers entered before decimal doesn`t allow more number to enter before decimal
                || (inputValue.indexOf(',') > -1 && event.key === ",") //prevents more than one . to be entered
                || ((inputValue.indexOf(',') > -1 && inputValue.split(',')[1].length === 1) && numberKeys.indexOf(event.key) >= 0)) //when pattern is completed doesn`t allow more number to be entered
            {
                //prevents keyboard button value as input
                event.preventDefault();
            }

            else{
                let haittaCopy = Object.assign({}, this.state.haittaPercentageAndComment);
                haittaCopy.weeklyHaitta = event.target.value;
                this.setState({ haittaPercentageAndComment: haittaCopy })
            }
        }
    }

    updateHaittaValue = (property, value) => {
        if (property === "weeklyHaitta") {
            let haittaCopy = Object.assign({}, this.state.haittaPercentageAndComment);
            haittaCopy.weeklyHaitta = value;
            this.setState({ haittaPercentageAndComment: haittaCopy })
        }

        else if (property === "weeklyComment") {
            let haittaCopy = Object.assign({}, this.state.haittaPercentageAndComment);
            haittaCopy.weeklyComment = value;
            this.setState({ haittaPercentageAndComment: haittaCopy })
        }
    }

    onChangeHaittaPer = (event) => {
        this.checkHaittaInputPattern(event);
    }

    render() {
        translator.setLanguage(this.props.browserLanguage);

        let dialogFooter = (<div className="ui-dialog-buttonpane p-clearfix">
            <Button id="yesbtn" label={translator.yes} className="deleteButton" onClick={this.callHaittaFunctionInTVS} disabled={this.state.selectedPlanningPeriod === "" ? true : false} style={{ padding: '2px' }} />
            <Button label={translator.no} className="deleteButton" onClick={this.callToDismissDialog} style={{ padding: '2px' }} />
        </div>);

        return (

            <Dialog visible={true} modal={true} className="haittaDialogBoxPosition" header={translator.haittaDialogHeader} onHide={this.callToDismissDialog} footer={dialogFooter} onShow={this.setFocusOnYesBtn} >

                <div>
                    <Row className="padding7pxBottom">
                        <Col>
                            <label>{translator.haittaPercentage}</label>
                        </Col>
                        <Col>
                            <InputText style={{ width: '170px' }} 
                            defaultValue={this.state.haittaPercentageAndComment.weeklyHaitta !== "" ? this.props.browserLanguage === 'en' ? this.state.haittaPercentageAndComment.weeklyHaitta :this.state.haittaPercentageAndComment.weeklyHaitta.replace(/\./g, ",") : ''} 
                            onKeyDown={(e) => { this.onChangeHaittaPer(e) }}
                            onBlur={(e) => { this.updateHaittaValue("weeklyHaitta", e.target.value) }}
                            disabled={this.props.admin? false:true}
                             />
                        </Col>
                    </Row>
                    <Row>
                        <Col>{translator.weekComment}</Col>
                        <Col>
                            <InputTextarea rows={5} cols={30} value={this.state.haittaPercentageAndComment.weeklyComment} onChange={(e) => { this.updateHaittaValue("weeklyComment", e.target.value) }} style={{width:'170px',minHeight:'30px'}} />
                        </Col>
                    </Row>
                </div>
            </Dialog>
        );

    }

}

export default HaittaPerAndCommentDialog;