import React, { Component } from 'react';
import { CommonService } from '../../service/CommonService';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import './ShiftAllocation.css';
import FileSaver from 'file-saver';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import leaveCodes from '../../utility/leaveCodes.json';

let translator = new LocalizedStrings(translation);

class BaselineAllocationPlan extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedEmpType: null,
            baselineJson: {
                costCenterId: null,
                planningPeriodId: null,
                employeeTypeId: null,
                baselineName: "",
                baselineComment: ""
            },
            baselinecommentval: "",
            baselineNameval: ""

        }
        this.createBaseline = this.createBaseline.bind(this);

        this.CommonService = new CommonService();
    }
    //Data from Parent(Shift Allocation) received here
    componentWillReceiveProps = () => {

        if (this.props.createBaselineEmp) {
            this.setState({
                disableFeedFile : this.props.disableFeedFile,
                createBaselineEmpData: this.props.createBaselineEmp,
                centerMasterID: this.props.centerMasterID,
                planningPeriodID: this.props.planningPeriodID,
                loading: this.props.loading
            })
        }
    }
    EmployeeTypeChange = (e) => {
        if (e.target.value === "") {
            this.showFailureMessage();
        }
        this.setState({
            selectedEmpType: e.target.value,
            BaselineemployeeTypeId: e.target.value.employeeTypeId
        });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.createBaseline();
    }
    //Save Basline Comment in var
    handlebaselineComment = (value) => {
        this.setState({
            baselinecommentval: value
        })
    }

    //Save baseline Name in var
    handlebaselineName = (value) => {
        this.setState({
            baselineNameval: value
        })
    }

    //Function to create Baseline data ...only for admin
    createBaseline = (event) => {
        let baselineDatacopy = Object.assign({}, this.state.baselineJson);
        baselineDatacopy.costCenterId = this.props.centerMasterID;
        baselineDatacopy.planningPeriodId = this.props.planningPeriodID;
        baselineDatacopy.employeeTypeId = this.state.BaselineemployeeTypeId;
        baselineDatacopy.baselineComment = this.state.baselinecommentval;
        baselineDatacopy.baselineName = this.state.baselineNameval;

        if (this.state.baselinecommentval !== '' && this.state.baselineNameval !== '' && this.state.BaselineemployeeTypeId !== undefined) {
            this.setState({ loading: true, displayDialog: false });
            this.CommonService.createBaseline(baselineDatacopy, this.props.browserLanguage)
                .then(res => {
                    if (res.status === 200) {
                        document.getElementById('Baselineidentifier').value = '';
                        document.getElementById('BaselineComment').value = '';
                        this.setState({ loading: false, displayDialog: false, selectedEmpType: '' });

                        this.showSuccessMessageBaseline();

                    }
                })

                .catch(err => {
                    this.setState({ loading: false });
                    this.showMultiple(err);
                });
        }
        else {
            this.setState({ loading: false, displayDialog: false });
            this.showFailureMessage();
        }
    }

    //Function To generate feed file Tempalte
    generateFeedFile = () => {
        this.setState({ loading: true, displayDialog: false });
        let JsonBody = {
            'planningPeriodid': this.props.planningPeriodID,
            'costCenterId': this.props.centerMasterID
        }
        this.CommonService.generateFeedFile(JsonBody, this.props.browserLanguage)
            .then(
                response => {
                    // let fileName = translator.feedFileName + ".csv";
                    //let fileName = "TIME_FEED_" + this.props.costCenterCode + this.props.ppDescription + moment().format('DDMMYYYYHHmmss') + ".csv";
                    let fileName = response.data.feedFileName;
                    var blob = new Blob([atob(response.data.feed)], { type: "text/plain" });
                    var reader = new FileReader();
                    reader.onloadend = function () {
                        FileSaver.saveAs(blob, fileName);
                    }
                    reader.readAsText(blob);
                    this.setState({ loading: false, displayDialog: false, selectedEmpType: '' });
                }
            )
            .catch(err => {
                this.setState({ displayDialog: false, loading: false });
                this.showMultiple(err);
            })
    }
    //show error when api is not successfully called
    showMultiple = (errorBody) => {

        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life : leaveCodes.growlMessageTimeout, summary: translator.errorMessage, detail: detail, severity: "error" });
    }
    showSuccessMessageBaseline = () => {
        
        this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'success', summary: translator.successMessage, detail: translator.baselineCreatedSuccess });
    }
    showFailureMessage = () => {
    
        this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.failureMessage, detail: translator.requiredCreateBaseline });
    }

    render() {
        translator.setLanguage(this.props.browserLanguage);
        return (
            <React.Fragment>
                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>
                <Growl ref={(el) => this.growl = el} position="topright"></Growl>


                <Toolbar className="ShiftAllocationBaselinePanel4">
                    <div className="p-toolbar-group-left">
                        <form onSubmit={this.handleSubmit}>
                            <Dropdown style={{ marginRight: '20px' }} value={this.state.selectedEmpType}
                                className="dropDwonDefault colRecord baselineDrop"
                                onChange={(e) => this.EmployeeTypeChange(e)}
                                placeholder={translator.employeeType}
                                optionLabel="employeeType"
                                options={this.props.createBaselineEmp} required />
                            <InputText style={{ marginRight: '20px' }} id="Baselineidentifier" type="text" placeholder={translator.baselineIdentifierId} onChange={(e) => { this.handlebaselineName(e.target.value) }} required />
                            <InputTextarea style={{ marginRight: '20px' }} id="BaselineComment" rows={2} cols={50} placeholder={translator.remarks}
                                className="ShiftAlloRemarkstxtarea" onChange={(e) => { this.handlebaselineComment(e.target.value) }} required />

                            <Button className="submitShiftAllocation" icon="pi pi-check" label={translator.createBaseline} type="submit" />
                        </form>
                    </div>

                    <div className="p-toolbar-group-right" >
                        <Button onClick={() => this.generateFeedFile()} disabled={this.state.disableFeedFile} className="GenerateShiftAllocation" icon="pi pi-download" label={translator.generateFeedFile} />

                    </div>
                    <br />
                </Toolbar>



            </React.Fragment>
        );
    }
}

export default BaselineAllocationPlan;