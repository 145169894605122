import React, { Component } from 'react';
import { connect } from 'react-redux';
import browser from 'browser-detect';
import ReactDOM from 'react-dom';
import { CommonService } from '../../service/CommonService';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import leaveCodes from '../../utility/leaveCodes.json';
import CostCenter from '../../components/costcenter/costCenter';
import { getResolution } from '../../../src/utility/commonUtility';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js'
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DraggableArea } from 'react-draggable-tags';
import { Paginator } from 'primereact/paginator';
import VacationLeaveDialog from './VacationLeaveDialog';
import {yearDropDown} from '../../utility/commonUtility';
import './vacationPlanning.css';

let translator = new LocalizedStrings(translation);
let currentYear = new Date().getFullYear();

class VacationPlanning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            deleteButton: true,
            vacationPlanData: null,
            months: [],
            selectedYear: null,
            leaveCodesAndDescriptionsArray: [],
            month: {
                jan: false,
                feb: false,
                mar: false,
                apr: false,
                may: false,
                jun: false,
                jul: false,
                aug: false,
                sep: false,
                oct: false,
                nov: false,
                dec: false,
            },
            vacationLeaveTemplate: {
                centerMasterID: "",
                year: null,
                employeeSearch: {
                    costCenterCode: "",
                    employeeId: "",
                    employeeName: "",
                    employeeTypeCode: "",
                    groupName: "",
                    sortBy: "employeeName",
                    sortDirection: "ASC",
                    pageNumber: 0,
                    recordCount: 150,
                    shiftCodeList: ""
                },
                employeeIds: [],
                exceptionMonths: []
            },

            leaveTemplate: {
                costCenterMasterId: "",
                employeeId: "",
                dates: [],
                leaveCode: ""
            },

            deleteLeaveTemplate: {
                costCenterMasterId: "",
                employeeId: "",
                shiftDate: "",
                leaveCode: ""
            },
            displayDialog: false
        };
        this.commonService = new CommonService();
    }

    componentDidMount = () => {
        this.setState({ loading: true });
        this.commonMethodForLifecycleMethod();
    }

    commonMethodForLifecycleMethod = () =>{
        let yearDropValue = yearDropDown()
            this.setState({ years: yearDropValue   });
            this.commonService.getVacationPlanningDetailsAsPerLastFilter(this.props.browserLanguage)
                .then(res => {
                    if (res.data.length > 0) {
                        let initialFilterData = Object.assign({}, this.state.vacationLeaveTemplate);
                        initialFilterData.employeeSearch.costCenterCode = res.wfmTCostCenterMaster['costCenterCode'];
                        initialFilterData.centerMasterID = res.wfmTCostCenterMaster['costCenterMasterId'];
                        initialFilterData.year = res.year;
                        this.setState({
                            vacationLeaveTemplate: initialFilterData,
                            lastUsedCostCenter: res.wfmTCostCenterMaster,
                            selectedYear: res.year,
                            pageNumber: res.pageNumber,
                            pageSize: res.pageSize,
                            vacationPlanData: res.data,
                            totalRecords: res.totalRecords,
                            recordSize: res.totalRecords,
                            costCenterCode: res.wfmTCostCenterMaster['costCenterCode'],
                            centerMasterID: res.wfmTCostCenterMaster['costCenterMasterId'],
                            loading: false
                        });
                    }
                    this.setState({ loading: false })
                    this.callServiceToGetLeaveCodeAndDescription()
                })
                .catch(err => {
                    this.setState({ loading: false, displayDialog: false });
                    this.showMultiple(err);
                })
    }

    //Function to call service to get leave codes and descriptio
    callServiceToGetLeaveCodeAndDescription = () => {
        this.commonService.getLeaveCodeAndDescription(this.props.browserLanguage)
            .then(res => {
                let leaveArray = res.leaveCodes.map(data => {
                    return ({ label: data.shiftCode + " | " + data.description, value: data.shiftCode })
                })
                this.setState({ leaveCodesAndDescriptionsArray: leaveArray })
            })
            .catch(err => {
                this.showMultiple(err);
            })
    }

    //Function to call service to display data according to the selected cost center and year from DropDown
    getList = (payload) => {
        this.setState({ loading: true });
        this.commonService.getEmployeeVacationPlan(payload, this.props.browserLanguage)
            .then(res => {
                if (res !== undefined && res !== null) {
                    this.setState({
                        vacationPlanData: res.data,
                        months: res.months,
                        pageNumber: res.pageNumber,
                        pageSize: res.pageSize,
                        totalRecords: res.totalRecords,
                        recordSize: res.totalRecords,
                        loading: false,
                        displayDialog: false,
                    })
                }
            })
            .catch(err => {
                this.showMultiple(err);
                this.setState({ loading: false, displayDialog: false });
            })
    }


    //function to get Data on selection of the year in Year DropDown.
    onYearDropDownSelect = (event) => {
        if (this.growl !== undefined && this.growl !== null) {
            this.growl.clear();
        }
        this.setState({
            selectedYear: event.value,
            vacationPlanData: null,
            displayDialog: false
        })

        let jsonCopy = Object.assign({}, this.state.vacationLeaveTemplate);
        jsonCopy.centerMasterID = this.state.centerMasterID;
        jsonCopy.year = Number(event.value);
        jsonCopy.employeeIds = [];
        jsonCopy.exceptionMonths = [];
        jsonCopy.employeeSearch.employeeId = '';
        jsonCopy.employeeSearch.employeeName = '';
        jsonCopy.employeeSearch.employeeTypeCode = '';
        jsonCopy.employeeSearch.groupName = '';
        
        this.setState({ vacationLeaveTemplate: jsonCopy });

        if (this.state.vacationLeaveTemplate.centerMasterID !== '' && event.value !== null) {
            this.setState({ loading: true, displayDialog: false });
            this.getList(jsonCopy);
        }

    }

    //function call when cost center selected
    onCostCenterSelect = (data, data1) => {
        if (this.growl !== undefined && this.growl !== null) {
            this.growl.clear();
        }
        this.setState({
            displayDialog : false,
            vacationPlanData: null
        })

        if (data1 === '' || data === '') {
            this.setState({
                selectedYear: null,
                totalRecords: undefined,
                pageNumber: 0,
                pageSize: 0,
                vacationLeaveTemplate: {
                    centerMasterID: 0,
                    year: null,
                    employeeSearch: {
                        costCenterCode: "",
                        employeeId: "",
                        employeeName: "",
                        employeeTypeCode: "",
                        groupName:"",
                        sortBy: "employeeName",
                        sortDirection: "ASC",
                        PageNumber: 0,
                        recordCount: 150,
                        shiftCodeList: ""
                    },
                    employeeIds: [],
                    exceptionMonths: []
                }

            });
            ReactDOM.unmountComponentAtNode(document.getElementById('costCenter'));
            ReactDOM.render(<CostCenter onChange={this.onCostCenterSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />, document.getElementById('costCenter'))
            return false;
        }

        this.setState({
            costCenterCode: data,
            centerMasterID: data1,
            // sortField: '',
            // sortOrder: null
        });

        let jsonCopy = Object.assign({}, this.state.vacationLeaveTemplate);
        jsonCopy.employeeSearch.costCenterCode = data;
        jsonCopy.centerMasterID = data1;
        jsonCopy.year = Number(this.state.selectedYear);
        jsonCopy.employeeIds = [];
        jsonCopy.exceptionMonths = [];
        jsonCopy.employeeSearch.shiftCodeList = '';
        jsonCopy.employeeSearch.employeeName = '';
        jsonCopy.employeeSearch.employeeTypeCode = '';
        jsonCopy.employeeSearch.groupName = '';
        this.setState({ vacationLeaveTemplate: jsonCopy });

        if (data !== '' && this.state.selectedYear !== null) {
            this.setState({
                loading: true,
                first: 0
            });
            this.getList(jsonCopy);
        }

    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {
        
        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });
        
    }

    //Function to implement Change Page functionality of Paginator. 
    onPageChange = (e) => {
        this.setState({ first: e.first, loading: true, displayDialog: false });
        let pageChangeJsonCopy = Object.assign({}, this.state.vacationLeaveTemplate);
        pageChangeJsonCopy.employeeSearch.pageNumber = e.page;
        this.getList(pageChangeJsonCopy);
    }

    //Function to implement functionality of Number of Record Dropdown change of Pagination. 
    noOfRecordChange = (e) => {

        this.setState({
            displayDialog: false,
            dropdownDisplay: e.value,
            loading: true,
            first: 0
        })
        let jsonCopy = Object.assign({}, this.state.vacationLeaveTemplate);
        jsonCopy.employeeSearch.recordCount = e.value.code;
        jsonCopy.employeeSearch.pageNumber = 0;

        if (this.state.vacationPlanData === undefined || this.state.vacationPlanData.length === undefined) {
            this.setState({ loading: false });
            this.setState({ vacationLeaveTemplate: jsonCopy });
            return false;
        }

        this.getList(jsonCopy);
        this.setState({ vacationLeaveTemplate: jsonCopy });
    }

    //Function to implement Sorting functionality. 
    onSortChange = (e) => {

        this.setState({ sortField: e.sortField, 
                        sortOrder: e.sortOrder,
                        loading: true,
                        displayDialog: false,
                        first: 0 });
                        
        let jsonCopy = Object.assign({}, this.state.vacationLeaveTemplate);
        jsonCopy.employeeSearch.sortDirection = 'ASC';
        jsonCopy.employeeSearch.sortBy = e.sortField;

        if (e.sortOrder === -1) {
            jsonCopy.employeeSearch.sortDirection = "DESC"
        }

        this.getList(jsonCopy);
        this.setState({ vacationLeaveTemplate: jsonCopy });
    }

    //Function to implement functionality of Wrapping Long Text With "...".
    nameTemplate = (rowData, column) => {

        if (column.field === 'wfmTCostCenterMaster.costCenterName') {
            return <div className="extraTextWrapping" title={rowData.wfmTCostCenterMaster.costCenterName}>{rowData.wfmTCostCenterMaster.costCenterName}</div>;
        }
        else {
            return <div className="extraTextWrapping" title={rowData[column.field]}>{rowData[column.field]}</div>;
        }

    }

    //Function to implement Filtering functionality on Employee Name. 
    onemployeeNameFilter = (empName, key) => {
        if ((key === 'Enter' || key === 'Tab') && empName !== "") {
            this.setState({ displayDialog: false })
            let jsonCopy = Object.assign({}, this.state.vacationLeaveTemplate);
            jsonCopy.employeeSearch.employeeName= empName;
            this.getList(jsonCopy);
        }
    }

    //Function to implement Filtering functionality on Employee ID. 
    onEmployeeIdFilter = (empId, key) => {
        if ((key === 'Enter' || key === 'Tab') && empId !== "") {
            this.setState({ displayDialog: false })
            let jsonCopy = Object.assign({}, this.state.vacationLeaveTemplate);
            jsonCopy.employeeSearch.employeeId = Number(empId);
            this.getList(jsonCopy);
        }
    }

    //Function to implement Filtering functionality on Employee Type Code. 
    onemployeeTypeFilter = (empType, key) => {
        if ((key === 'Enter' || key === 'Tab') && empType !== "") {
            this.setState({ displayDialog: false })
            let jsonCopy = Object.assign({}, this.state.vacationLeaveTemplate);
            jsonCopy.employeeSearch.employeeTypeCode = empType;
            this.getList(jsonCopy);
        }
    }

    //Function to implement Filtering functionality on Group Name. 
    onemployeeGroupFilter = (empGrpName, key) => {
        if ((key === 'Enter' || key === 'Tab') && empGrpName !== "") {
            this.setState({ displayDialog: false })
            let jsonCopy = Object.assign({}, this.state.vacationLeaveTemplate);
            jsonCopy.employeeSearch.groupName = empGrpName;
            this.getList(jsonCopy);
        }
    }

    //function to clear filter
    filterCleared = (e, field) => {

        if (e.target.value === "" && field === "employeeId") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.vacationLeaveTemplate);
            jsonCopy.employeeSearch.employeeId = "";
            this.setState({ vacationLeaveTemplate: jsonCopy });
            this.getList(jsonCopy);
        }

        else if (e.target.value === "" && field === "employeeName") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.vacationLeaveTemplate);
            jsonCopy.employeeSearch.employeeName = "";
            this.setState({ vacationLeaveTemplate: jsonCopy });
            this.getList(jsonCopy);
        }

        else if (e.target.value === "" && field === "employeeType") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.vacationLeaveTemplate);
            jsonCopy.employeeSearch.employeeTypeCode = "";
            this.setState({ vacationLeaveTemplate: jsonCopy });
            this.getList(jsonCopy);
        }

        else if (e.target.value === "" && field === "empGrpName") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.vacationLeaveTemplate);
            jsonCopy.employeeSearch.groupName = "";
            this.setState({ vacationLeaveTemplate: jsonCopy });
            this.getList(jsonCopy);
        }
    }


    //function to display color according to leave code.
    colorCode = (leaveCode) => {
        if (leaveCode === 'VL') {
            return leaveCodes.color_VL
        }

        else if (leaveCode === 'VL2') {
            return leaveCodes.color_VL2
        }

        else if (leaveCode === 'JV') {
            return leaveCodes.color_JV
        }

        else if (leaveCode === 'LO') {
            return leaveCodes.color_LO
        }

        else if (leaveCode === 'AY') {
            return leaveCodes.color_AY
        }

        else if (leaveCode === 'TV') {
            return leaveCodes.color_TV
        }
        else if (leaveCode === 'SV') {
            return leaveCodes.color_SV
        }
        else if (leaveCode === 'P') {
            return leaveCodes.color_P
        }
        else if (leaveCode === 'PO') {
            return leaveCodes.color_PO
        }
        else {
            return leaveCodes.color_reservedCode
        }


    }

    //function to display only date value in tags.
    getDate = (date) => {
        let tagDate = new Date(date);
        return tagDate.getDate()
    }

    //function to display leave date tags 
    LeaveCodeTemplate = (rowData, column) => {
        
        return (
            <div className="dvdraggable">
                <DraggableArea
                    tags={rowData.vacationPlan[column.field]}
                    disabled={true}
                    style={{cursor:'pointer'}}
                    render={
                        ({ tag, index }) => (

                            <div id="leaveTag" className="tag" style={{ border: this.colorCode(tag.leaveCode), backgroundColor: this.colorCode(tag.leaveCode), cursor: "default" }}>
                                <Button id="tagbtn" className="clearBtn" label="X"
                                    style={{ border: this.colorCode(tag.leaveCode), backgroundColor: this.colorCode(tag.leaveCode), cursor: this.state.selectedYear >= currentYear ? "pointer" : "none" }}
                                    onClick={(e) => { this.handleClickDelete(rowData, tag, column) }}
                                    disabled={this.state.selectedYear >= currentYear ? false : true} />
                                <span title={tag.leaveCode}>{this.getDate(tag.date)}</span>
                            </div>

                        )} />
            </div>

        )
    }

    //function to display plus icon in every row for adding leave.
    addLeaveTemplate = (rowData, column) => {
        return (<div id="addLeaveDiv">
            <Button onClick={(e) => this.onAddLeave(rowData)} id="saveContract" icon="pi pi-plus" className="contractFooter" style={{ width: "22px", height: "22px", padding: "0px", marginLeft: "3px" }} disabled={this.state.selectedYear >= currentYear ? false : true} />
            </div>)
    }


    //function to call service for deleting the leave 
    handleClickDelete = (rowData, tag, column) => {

        let jsonCopy1 = Object.assign({}, this.state.deleteLeaveTemplate);
        jsonCopy1.costCenterMasterId = this.state.vacationLeaveTemplate.centerMasterID;
        jsonCopy1.employeeId = rowData.employeeDetailsId;
        jsonCopy1.shiftDate = tag.date;
        jsonCopy1.leaveCode = tag.leaveCode;
        this.setState({ deleteLeaveTemplate: jsonCopy1, loading: true });

        this.commonService.saveDeleteLeaveRecord(jsonCopy1, this.props.browserLanguage)
            .then(res => {

                rowData.vacationPlan[column.field].map((data, index) => {
                    if (data.date === tag.date) {
                        rowData.vacationPlan[column.field].splice(index, 1);
                        for (var i = 0; i < rowData.emplLeaveDates.length; i++) {
                            for (var j = 0; j < res.detailsResponses.length; j++)
                                if (rowData.emplLeaveDates[i] === res.detailsResponses[j].shiftDate) {
                                    rowData.emplLeaveDates.splice(i, 1);
                                }
                        }
                    }
                    return false;
                });
                this.setState({ loading: false, displayDialog: false });
            })
            .catch(err => {
                this.showMultiple(err);
                this.setState({ loading: false, displayDialog: false });
            })

    }

    //function for checkBox filter.
    exceptionWeek = (event, month) => {
        this.setState({ loading: true });
        switch (month) {
            case 'jan':
                let jsonCopy1 = Object.assign({}, this.state.month);
                jsonCopy1.jan = event.checked
                this.setState({ month: jsonCopy1 });
                break;

            case 'feb':
                let jsonCopy2 = Object.assign({}, this.state.month);
                jsonCopy2.feb = event.checked
                this.setState({ month: jsonCopy2 });
                break;

            case 'mar':
                let jsonCopy3 = Object.assign({}, this.state.month);
                jsonCopy3.mar = event.checked
                this.setState({ month: jsonCopy3 });
                break;

            case 'apr':
                let jsonCopy4 = Object.assign({}, this.state.month);
                jsonCopy4.apr = event.checked
                this.setState({ month: jsonCopy4 });
                break;

            case 'may':
                let jsonCopy5 = Object.assign({}, this.state.month);
                jsonCopy5.may = event.checked
                this.setState({ month: jsonCopy5 });
                break;

            case 'jun':
                let jsonCopy6 = Object.assign({}, this.state.month);
                jsonCopy6.jun = event.checked
                this.setState({ month: jsonCopy6 });
                break;

            case 'jul':
                let jsonCopy7 = Object.assign({}, this.state.month);
                jsonCopy7.jul = event.checked
                this.setState({ month: jsonCopy7 });
                break;

            case 'aug':
                let jsonCopy8 = Object.assign({}, this.state.month);
                jsonCopy8.aug = event.checked
                this.setState({ month: jsonCopy8 });
                break;

            case 'sep':
                let jsonCopy9 = Object.assign({}, this.state.month);
                jsonCopy9.sep = event.checked
                this.setState({ month: jsonCopy9 });
                break;

            case 'oct':
                let jsonCopy10 = Object.assign({}, this.state.month);
                jsonCopy10.oct = event.checked
                this.setState({ month: jsonCopy10 });
                break;

            case 'nov':
                let jsonCopy11 = Object.assign({}, this.state.month);
                jsonCopy11.nov = event.checked
                this.setState({ month: jsonCopy11 });
                break;

            case 'dec':
                let jsonCopy12 = Object.assign({}, this.state.month);
                jsonCopy12.dec = event.checked
                this.setState({ month: jsonCopy12 });
                break;
            default:
                break;
        }

        let jsonCopy = Object.assign({}, this.state.vacationLeaveTemplate);
        if (event.checked) {
            jsonCopy.exceptionMonths.push(event.value);
        }
        else {
            jsonCopy.exceptionMonths.splice(jsonCopy.exceptionMonths.indexOf(event.value), 1);
        }
        jsonCopy.exceptionMonths.sort();

        this.getList(jsonCopy);
        this.setState({ vacationLeaveTemplate: jsonCopy });

    }

    //Function to implement functionality of Editing on any Row Selection. 
    onAddLeave = (rowData) => {
        this.setState({
            displayDialog: true,
            employeeLeaveData: Object.assign({}, rowData),
        });
    }

    //Function called when callback is received from Dialog on onHide event
    callbackToHideDialog = () => {
        this.setState({ displayDialog: false });
    }

    //function to get month from date.
    getMonthArray = (d) => {
        var month = [];
        month[0] = "jan";
        month[1] = "feb";
        month[2] = "mar";
        month[3] = "apr";
        month[4] = "may";
        month[5] = "jun";
        month[6] = "jul";
        month[7] = "aug";
        month[8] = "sep";
        month[9] = "oct";
        month[10] = "nov";
        month[11] = "dec";
        var n = month[d.getMonth()];
        return n
    }

    //function to add '0' in single digit month (ex:01,02)
    getMonthCheck = (month) => {
        let newMonth = 0
        if (month < 10) {
            newMonth = '0' + month;
            return newMonth
        }
        else {
            return month
        }

    }

    //function to add '0' in single digit date (ex:01,02)
    getDateCheck = (date) => {
        let newDate = 0
        if (date < 10) {
            newDate = '0' + date;
            return newDate
        }
        else {
            return date
        }
    }

    showInvalidRecordwithScroll = (errorBody) => {
        errorBody = errorBody.filter(item => item.error !== null);
        var detail = errorBody.map((item, key) => {
            return (<li key={key}>{item.error + " " + item.shiftDate}</li>)
        })
        if (errorBody.length > 0) {
            this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });
        }
    }

    //Function to call service to save leave
    callbackSaveService = (payload, rowData) => {
        this.setState({ loading: true, displayDialog: false });

        this.commonService.saveDeleteLeaveRecord(payload, this.props.browserLanguage)
            .then(res => {
                let record = res.detailsResponses;

                for (let i = 0; i < record.length; i++) {
                    let date = new Date(record[i].shiftDate);
                    let month = this.getMonthArray(date);
                    let formatted_date = date.getFullYear() + "-" + (this.getMonthCheck(date.getMonth() + 1)) + "-" + (this.getDateCheck(date.getDate()));

                    if (record[i].error === null) {
                        rowData.vacationPlan[month].push({ "date": formatted_date, "leaveCode": record[i].leaveCode });
                        rowData.emplLeaveDates.push(record[i].shiftDate);

                    }
                    else if (record[i].error !== null) {
                        this.showInvalidRecordwithScroll(res.detailsResponses)
                    }
                }
                this.setState({ loading: false, displayDialog: false });
            })
            .catch(err => {
                this.showMultiple(err);
                this.setState({ loading: false, displayDialog: false });
            })

    }

    //function to display Id except dummy employees
    employeeIdTemplate = (rowData) => {
        if (rowData.employeeType !== translator.dummy) {
            return (
                <div>{rowData.employeeNumber}</div>
            )
        }
    }


    render() {

        translator.setLanguage(this.props.browserLanguage);

        const noOfRecord = [
            { name: translator.Records_150, code: 150 },
            { name: translator.Records_200, code: 200 },
            { name: translator.Records_250, code: 250 }
        ];

        let years = this.state.years;

        let employeeIdFilter = <InputText maxLength="8" id="employeeId" onChange={(e) => this.filterCleared(e, "employeeId")} keyfilter={/^[0-9\b]+$/} className="employeeIdFilterWidth" onKeyDown={(e) => this.onEmployeeIdFilter(e.target.value, e.key)} />
        let employeeNameFilter = <InputText type="text" id="employeeName" onChange={(e) => this.filterCleared(e, "employeeName")} className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeNameFilter(e.target.value, e.key)} />
        let employeeTypeFilter = <InputText type="text" id="employeeType" onChange={(e) => this.filterCleared(e, "employeeType")} className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeTypeFilter(e.target.value, e.key)} />
        let groupNameFilter = <InputText type="text" id="empGrpName" onChange={(e) => this.filterCleared(e, "empGrpName")} className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeGroupFilter(e.target.value, e.key)} />

        let headerGroup = <ColumnGroup>

            <Row>
                <Column header={translator.employeeName} field="employeeName" colSpan={1} sortable={true} filter={true} filterElement={employeeNameFilter} />
                <Column header={translator.employeeId} field="employeeNumber" colSpan={1} sortable={true} filter={true} filterElement={employeeIdFilter} />
                <Column header={translator.employeeTypeCode} field="employeeType" sortable={true} colSpan={1} filter={true} filterElement={employeeTypeFilter} />
                <Column header={translator.groupName} field="empGrpName" colSpan={1} filter={true} filterElement={groupNameFilter} />
                <Column colSpan={1} />


                <Column colSpan={1} header={<span> <Checkbox value={1} onChange={e => this.exceptionWeek(e, "jan")} checked={this.state.month.jan}></Checkbox>{translator.jan}</span>} className="" />
                <Column colSpan={1} header={<span> <Checkbox value={2} onChange={e => this.exceptionWeek(e, "feb")} checked={this.state.month.feb}></Checkbox>{translator.feb}</span>} className="" />
                <Column colSpan={1} header={<span> <Checkbox value={3} onChange={e => this.exceptionWeek(e, "mar")} checked={this.state.month.mar}></Checkbox>{translator.mar}</span>} className="" />

                <Column colSpan={1} header={<span> <Checkbox value={4} onChange={e => this.exceptionWeek(e, "apr")} checked={this.state.month.apr}></Checkbox>{translator.apr}</span>} className="" />
                <Column colSpan={1} header={<span> <Checkbox value={5} onChange={e => this.exceptionWeek(e, "may")} checked={this.state.month.may}></Checkbox>{translator.may}</span>} className="" />
                <Column colSpan={1} header={<span> <Checkbox value={6} onChange={e => this.exceptionWeek(e, "jun")} checked={this.state.month.jun}></Checkbox>{translator.jun}</span>} className="" />

                <Column colSpan={1} header={<span> <Checkbox value={7} onChange={e => this.exceptionWeek(e, "jul")} checked={this.state.month.jul}></Checkbox>{translator.jul}</span>} className="" />
                <Column colSpan={1} header={<span> <Checkbox value={8} onChange={e => this.exceptionWeek(e, "aug")} checked={this.state.month.aug}></Checkbox>{translator.aug}</span>} className="" />
                <Column colSpan={1} header={<span> <Checkbox value={9} onChange={e => this.exceptionWeek(e, "sep")} checked={this.state.month.sep}></Checkbox>{translator.sep}</span>} className="" />

                <Column colSpan={1} header={<span> <Checkbox value={10} onChange={e => this.exceptionWeek(e, "oct")} checked={this.state.month.oct}></Checkbox>{translator.oct}</span>} className="" />
                <Column colSpan={1} header={<span> <Checkbox value={11} onChange={e => this.exceptionWeek(e, "nov")} checked={this.state.month.nov}></Checkbox>{translator.nov}</span>} className="" />
                <Column colSpan={1} header={<span> <Checkbox value={12} onChange={e => this.exceptionWeek(e, "dec")} checked={this.state.month.dec}></Checkbox>{translator.dec}</span>} className="" />
            </Row>
        </ColumnGroup>;


        return (
            <div className="bodycolor">

                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>

                <Growl ref={(el) => this.growl = el} position="topright" className="shiftGrowlScroll"></Growl>

                {this.state.displayDialog ?
                    <VacationLeaveDialog browserLanguage={this.props.browserLanguage}
                        displayDialog={true}
                        yearSelected={this.state.selectedYear}
                        centerMasterID={this.state.vacationLeaveTemplate.centerMasterID}
                        leaveTemplate={this.state.leaveTemplate}
                        employeeLeaveData={this.state.employeeLeaveData}
                        disabledDates={this.state.employeeLeaveData.emplLeaveDates}
                        leaveCodesAndDescriptionsArray = {this.state.leaveCodesAndDescriptionsArray}
                        onCallbackSaveService={this.callbackSaveService}
                        callbackPropToHideDialog={this.callbackToHideDialog}
                    />
                    : null
                }

                <BreadCrumbs initial={translator.allocation} final={translator.vacation} browserLanguage={this.props.browserLanguage} />
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td className="CostCenterDiv" id="costCenter" style={{ width: "1%" }}>
                                    <CostCenter lastUsedCostCenter={this.state.lastUsedCostCenter} onChange={this.onCostCenterSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />
                                </td>

                                <td className="PlanningPeriodDiv" style={{ paddingLeft: "140px" }}>
                                    <strong>{translator.year} : </strong>
                                    <div className="dropDwonDefault">
                                        <Dropdown
                                            value={this.state.selectedYear}
                                            placeholder={translator.selectYear}
                                            options={years}
                                            onChange={(e) => { this.onYearDropDownSelect(e) }} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                {this.state.vacationPlanData ?
                    this.state.totalRecords === 0 && (document.getElementById('employeeName') !== null ? (document.getElementById('employeeName').value === '' && document.getElementById('employeeId').value === '') && this.state.loading === false && this.state.vacationLeaveTemplate.exceptionMonths.length === 0 : true) ?
                        null :
                        <div className="paginatorDropdownPosition" >
                            <span className="recordOF">{this.state.totalRecords === undefined || this.state.totalRecords === 0 ? '0-0' : (this.state.pageNumber * this.state.pageSize) + 1 + '-' + Number((this.state.pageNumber * this.state.pageSize) + Number(this.state.recordSize))} {translator.of} {this.state.totalRecords === undefined ? 0 : this.state.totalRecords} {translator.records}</span>
                            <div className="paginatorEmp">
                                <Paginator rows={this.state.vacationLeaveTemplate.employeeSearch.recordCount} totalRecords={this.state.totalRecords === undefined ? 0 : this.state.totalRecords} first={this.state.first} onPageChange={(e) => this.onPageChange(e)}></Paginator>
                                <Dropdown value={this.state.dropdownDisplay} className="dropDwonDefault colRecord dropmargin" options={noOfRecord} onChange={(e) => this.noOfRecordChange(e)} placeholder={translator.Records_150} optionLabel="name" />
                            </div>
                        </div> : null}
                <br />

                {this.state.vacationPlanData ?
                    this.state.totalRecords === 0 && (document.getElementById('employeeName') !== null ? (document.getElementById('employeeName').value === '' && document.getElementById('employeeId').value === '') && this.state.loading === false && this.state.vacationLeaveTemplate.exceptionMonths.length === 0 : true) ?
                        <h4 className="noRecordFoundMessage">{translator.noRecordFound}</h4> :

                        <DataTable
                            scrollable={browser().name === 'ie' ? false : true}
                            scrollHeight={getResolution()}
                            value={this.state.vacationPlanData}
                            headerColumnGroup={headerGroup}
                            rows={this.state.pageSize}
                            className="datatableVacationPlanning"
                            onSort={(e) => this.onSortChange(e)}
                            sortField={this.state.sortField}
                            sortOrder={this.state.sortOrder}
                            emptyMessage={translator.noRecordFound}
                            resizableColumns={true}
                        >
                            <Column field="employeeName" sortable={true} filter={true} filterElement={employeeNameFilter} className="textLeftAlign" style={{ width: '155px' }} body={this.nameTemplate} />
                            <Column field="employeeNumber" sortable={true} className="numberRightAlign" style={{ width: '155px' }} filter={true} filterElement={employeeIdFilter} body={this.employeeIdTemplate} />
                            <Column field="employeeType" sortable={true} className="textLeftAlign" style={{ width: '155px' }} filter={true} filterElement={employeeTypeFilter} body={this.nameTemplate} />
                            <Column field="empGrpName" className="textLeftAlign" style={{ width: '155px' }} filter={true} filterElement={groupNameFilter} body={this.nameTemplate} />
                            <Column field="" body={this.addLeaveTemplate} style={{ width: '50px' }} />
                            <Column field="jan" className="textLeftAlign " body={this.LeaveCodeTemplate} style={{ width: '200px' }} />
                            <Column field="feb" className="textLeftAlign " body={this.LeaveCodeTemplate} style={{ width: '200px' }} />
                            <Column field="mar" className="textLeftAlign " body={this.LeaveCodeTemplate} style={{ width: '200px' }} />
                            <Column field="apr" className="textLeftAlign " body={this.LeaveCodeTemplate} style={{ width: '200px' }} />
                            <Column field="may" className="textLeftAlign " body={this.LeaveCodeTemplate} style={{ width: '200px' }} />
                            <Column field="jun" className="textLeftAlign " body={this.LeaveCodeTemplate} style={{ width: '200px' }} />
                            <Column field="jul" className="textLeftAlign " body={this.LeaveCodeTemplate} style={{ width: '200px' }} />
                            <Column field="aug" className="textLeftAlign " body={this.LeaveCodeTemplate} style={{ width: '200px' }} />
                            <Column field="sep" className="textLeftAlign " body={this.LeaveCodeTemplate} style={{ width: '200px' }} />
                            <Column field="oct" className="textLeftAlign " body={this.LeaveCodeTemplate} style={{ width: '200px' }} />
                            <Column field="nov" className="textLeftAlign " body={this.LeaveCodeTemplate} style={{ width: '200px' }} />
                            <Column field="dec" className="textLeftAlign " body={this.LeaveCodeTemplate} style={{ width: '200px' }} />
                        </DataTable>
                    : null}

            </div>
        )
    }

}
const mapStateToProps = (state) => {
    return {
      masterData: state.masterData,
      browserLanguage: state.browserLanguage
    }
  }
  
  
  const mapDispatchToProps = (dispatch) => {
    return {
      
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(VacationPlanning);
