import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import ShiftAllocation from './screens/shiftallocation/ShiftAllocation';
import AutoGeneratedShift from './screens/shiftallocation/AutoGeneratedShift';
import EmployeeMaster from './screens/employeemaster/EmployeeMaster';
import ShiftMaster from './screens/shiftmaster/ShiftMaster'
import TVSReport from './screens/tvsreport/TVSReport';
import NavMenu from '../src/components/navmenu/NavMenu';
import { CommonService } from '../src/service/CommonService';
import LocalizedStrings from 'react-localization';
import { translation } from '../src/locales/translation.js';
import SkillSet from './screens/skillset/SkillSet';
import { Growl } from 'primereact/growl';
import { authContext } from '../src/adalConfig';
// import browserDetect from '../node_modules/browser-detect';
import browserDetect from 'browser-detect';
import EmployeeAllocation from './screens/employeeallocation/EmployeeAllocation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { masterDataAction } from './actions/masterDataAction';
import { changeLanguageAction } from './actions/changeLanguageAction';
import VacationPlanning from './screens/vacationPlanning/VacationPlanning';
import AdminPage from './screens/adminpage/AdminPage';
import Dashboard from './screens/dashboard/Dashboard';
import EmployeeTransfer from './screens/employeeTransfer/EmployeeTransfer';
import KikyHours from './screens/kikyhours/KikyHours';
import YearlyShiftAllocation from './screens/yearlyShiftAllocation/YearlyShiftAllocation';

let translator = new LocalizedStrings(translation);

class App extends Component {

  constructor() {
    super();
    this.state = {
      gotoRoutes: false
    }
    this.commonService = new CommonService();

  }

  componentWillReceiveProps = (nextProps) => {

    if (this.props.masterData !== nextProps.masterData && this.props.browserLanguage === nextProps.browserLanguage) {
      this.setState({ gotoRoutes: true });
    }
    else {
      this.setState({ gotoRoutes: false });
    }
  }
  componentDidMount = () => {
    let defaultLanguage = 'fi';
    let objLang = navigator.languages;

    if (browserDetect().name === 'ie') {
      if (navigator.browserLanguage === 'en' || navigator.browserLanguage === 'en-US') {
        defaultLanguage = 'en';
      }
      else if (navigator.browserLanguage === 'fi' || navigator.browserLanguage === 'FI') {
        defaultLanguage = 'fi';
      }
    }

    else {
      if (objLang.includes("fi")) {
        defaultLanguage = 'fi';
      }
      else if (objLang.includes("en") || objLang.includes("en-US")) {
        defaultLanguage = 'en';
      }
      else {
        defaultLanguage = 'fi';
      }
    }
    this.props.changeLanguageActionDispatcher(defaultLanguage);
    this.masterData(defaultLanguage);
  }

  //Function To call Master Data API
  masterData = (language) => {
    this.commonService.getMasterData(language)
      .then(res => {
        if(res !== null && res !==undefined)
        {
          if (res.userMemberships.length === 0) {
            window.alert(translator.noAccessError);
            authContext.logOut();
          }
          else {
            this.props.masterDataActionDispatcher(res);
            this.setState({ gotoRoutes: true })
          }
          if (this.growl !== undefined || this.growl !== null) {
            this.growl.clear();
          }
        }
      })
      .catch(err => {
        this.showMultiple(err)
      })
  }

  //Function to implement error handling and show Growl message.
  showMultiple = (errorBody) => {
    if (this.growl !== undefined || this.growl !== null) {
      this.growl.clear();
    }
    var detail = errorBody.message.split(',').map(item => {
      return (<li key={item}>{item}</li>)
    })
    this.growl.show({ sticky: true, severity: 'error', summary: translator.errorMessage, detail: detail });

  }

  render() {
    translator.setLanguage(this.props.browserLanguage);
    return (
      <React.Fragment>
        <Growl ref={(el) => this.growl = el} position="topright" ></Growl>
        {
          this.props.masterData === null || this.props.browserLanguage === '' ?
            null :
            <Router>
              <NavMenu />
              {this.state.gotoRoutes ?
                <Switch>
                  <Route exact path='/' component={EmployeeMaster} />
                  <Route path='/employeeMaster' component={EmployeeMaster} />
                  <Route path='/shiftMaster' component={ShiftMaster} />
                  <Route path='/shiftAllocation' component={ShiftAllocation} />
                  <Route path='/dailyTVS' component={ () => <TVSReport/> } />
                  <Route path='/weeklyTVS' component={ () => <TVSReport/> } />
                  <Route path='/skillSet' component={SkillSet} />
                  <Route path='/employeeAllocation' component={EmployeeAllocation} />
                  <Route path='/autoShiftGeneration' component={AutoGeneratedShift} />
                  <Route path='/vacationPlanning' component={VacationPlanning} />
                  <Route path='/admin' component={AdminPage} />
                  <Route path='/dashboard' component={Dashboard} />
                  <Route path='/employeeTransfer' component={EmployeeTransfer} />
                  <Route path='/kikyHours' component={KikyHours} />
                  <Route path='/yearlyShiftAllocation' component={YearlyShiftAllocation} />
                  <Route path='*' component={EmployeeMaster} />
                </Switch>
                : null
              }
            </Router>
        }

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    masterData: state.masterData,
    browserLanguage: state.browserLanguage
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    masterDataActionDispatcher: bindActionCreators(masterDataAction, dispatch),
    changeLanguageActionDispatcher: bindActionCreators(changeLanguageAction, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);