import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
let translator = new LocalizedStrings(translation);

class PlanningPeriod extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPlaningPeriod: []
        }
    }

    componentWillUpdate = () => {
        let selectedPlanningPr = null;
        if (this.props.masterData.planningPeriodList && this.props.lastUsedPlanningPeriod && this.state.selectedPlaningPeriod) {
            selectedPlanningPr = this.props.masterData.planningPeriodList.filter((costCenter) => {
                return costCenter.planningPerioId === this.props.lastUsedPlanningPeriod.planningPeriodId;
            });

            if (this.state.selectedPlaningPeriod.length === 0) {
                this.setState({ selectedPlaningPeriod: selectedPlanningPr[0] });
            }
        }
    }

    onPlanningPeriodSelect = (e) => {

        this.setState({
            selectedPlaningPeriod: e.target.value
        });
        if (e.target.value !== null) {
            this.props.onChange(e.value.planningPerioId);
        }
        else {
            this.props.onChange('');
        }
    }

    render() {
        translator.setLanguage(this.props.browserLanguage);

        return (
            <div className="dropDwonDefault">
                <strong>{translator.planningPeriod} : </strong>
                <Dropdown value={this.state.selectedPlaningPeriod}
                    options={this.props.masterData === null ? null : this.props.masterData.planningPeriodList}
                    onChange={(e) => this.onPlanningPeriodSelect(e)}
                    placeholder={translator.selectPlanningPeriod}
                    optionLabel='planningPeriodDescription'
                    className="planningPeriodDropdown"
                />
            </div>
        );
    }
}


export default PlanningPeriod;