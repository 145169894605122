import React, { Component } from 'react';
import ReactFileReader from 'react-file-reader';
import './UploadFile.css';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js'
import { CommonService } from '../../service/CommonService';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import FileSaver from 'file-saver';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import leaveCodes from '../../utility/leaveCodes.json';

let translator = new LocalizedStrings(translation);

class AdminUpload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      buttonDisable: false,
      csvData: '',
      fileName: ''
    };
    this.commonService = new CommonService();
  }

  //Function to implement error handling and show Growl message.
  showMultiple = (errorBody) => {

    var detail =
      errorBody.message.split(',').map(item => {
        return (<li key={item}>{item}</li>)
      })
    this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: "error", summary: translator.errorMessage, detail: detail });
  }

  //Function to receive and send the entire uploaded file to API.
  handleFiles = files => {
    if (files[0].name.split('.').length === 2) {
      if (files[0].name.split('.')[1].toLowerCase() === "xlsx") {
        this.setState({ loading: true, buttonDisable: true, fileName: files[0].name.split('.')[0], filename: files[0].name })
        let formData = new FormData();
        formData.append('file', files[0]);
        formData.append('uploadType', this.props.uploadType)
        this.commonService.uploadExcelForAdmin(formData, this.props.uploadType, this.props.browserLanguage)
          .then(
            response => {
              if (response.data.size > 0) {
                let fileName = files[0].name.split('.')[0] + "_" + translator.error + "_" + moment().format('YYYY_MM_DD_hh_mm') + ".xlsx";
                var blob = new Blob([response.data], { type: "application/xlsx" });
                var reader = new FileReader();
                reader.onloadend = function () {
                  FileSaver.saveAs(blob, fileName);
                }
                reader.readAsText(blob);
                this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: "error", summary: translator.uploadMessage, detail: translator.uploadMessageInvalidRecords })
              }
              else {
                this.showSuccessMessage();
              }
              this.props.onChange('',this.props.uploadType);
              this.setState({ loading: false, buttonDisable: false });
            }).catch(err => {
              this.setState({ loading: false, buttonDisable: false });
              this.showMultiple(err);
              this.props.onChange('failed',this.props.uploadType);
            })
      }
      else {
        this.messageEXCELOnly();
      }
    }
    else {
      this.messageEXCELOnly();
    }
  }

  showSuccessMessage = () => {
    
    this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'success', summary: translator.successMessage, detail: translator.fileUploadSucess });
  }

  messageEXCELOnly = () => {
    
    this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: "error", summary: translator.uploadMessage, detail: translator.fileSupport })
  }

  render() {
    translator.setLanguage(this.props.browserLanguage);

    return (
      <div>
        <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
          <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
        </Dialog>

        <Growl ref={(el) => this.growl = el} position="topright"></Growl>
        <span>
          <ReactFileReader handleFiles={this.handleFiles} fileTypes={'.xlsx'}>
            <span className="file-upload">
              <span className="file-select">
                <Button style={{ marginRight: '.25em' }} icon="pi pi-upload" className="btnDefault" label={translator.upload} disabled={this.state.buttonDisable} id="fileName"></Button >
                <input type="file" name="chooseFile" id="chooseFile" />
              </span>
            </span>
          </ReactFileReader>
        </span>
      </div>

    );
  }
}

export default AdminUpload;