import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card } from 'primereact/card';
import Carousel from 'react-bootstrap/Carousel';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import { Redirect } from 'react-router-dom';
import { CommonService } from '../../service/CommonService';
import { Growl } from 'primereact/growl';
import leaveCodes from '../../utility/leaveCodes.json';

let translator = new LocalizedStrings(translation);

class Notification extends Component {
    constructor(props){
        super(props);
        this.state = {
            redirect : false,
            readableId : ''
        }
        this.commonService = new CommonService();
        this.redirectData = null;
    }

    handleNotificationClick = (readableId) => {
        if (!readableId.includes("GB")) {
            this.commonService.notificationRedirectData(readableId, this.props.browserLanguage)
                .then(res => {
                    this.redirectData = res;
                    this.setState({ redirect: true, readableId: readableId });
                })
                .catch(err => {
                    this.showMultiple(err);
                })
        }
    }
    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {
        
        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });
        
    }

    render() {
        if (this.state.redirect) {
            return (
                <Redirect to={{
                    pathname: '/autoShiftGeneration',
                    state: { res: this.redirectData, readableId : this.state.readableId }
                }}
                />
            )
        }
        translator.setLanguage(this.props.browserLanguage);

        return (
            <div>
                <Growl ref={(el) => this.growl = el} position="topright" ></Growl>
                {
                    this.props.notification.length > 0 ?
                        <Carousel
                            nextIcon={<span style={{ color: 'black' }} className="pi pi-chevron-right"></span>}
                            prevIcon={<span style={{ color: 'black' }} className="pi pi-chevron-left"></span>}
                            indicators={false}
                            interval={10000}
                        >
                            {this.props.notification.map((data, index) => {

                                switch (data.status) {
                                    //Success case for AI request
                                    case 'Y':
                                        return (
                                            <Carousel.Item style={{ cursor : 'pointer' }} key={index} onClick={()=>this.handleNotificationClick(data.readableId)}>
                                                <div id="notificationGreen" className="d-block w-100">
                                                    <Card title={data.readableId} >
                                                        {translator.description} : {data.description}
                                                    </Card>
                                                </div>
                                            </Carousel.Item>
                                        )

                                    //Success case for AI request
                                    case 'A':
                                        return (
                                            <Carousel.Item style={{ cursor : 'pointer' }} key={index} onClick={()=>this.handleNotificationClick(data.readableId)}>
                                                <div id="notificationGreen" className="d-block w-100">
                                                    <Card title={data.readableId} >
                                                        {translator.description} : {data.description}
                                                    </Card>
                                                </div>
                                            </Carousel.Item>
                                        )

                                    //Pending case for AI request
                                    case 'N':
                                        return (
                                            <Carousel.Item key={index} >
                                                <div id="notificationOrange" className="d-block w-100">
                                                    <Card title={data.readableId} >
                                                        {translator.description} : {data.description}
                                                    </Card>
                                                </div>
                                            </Carousel.Item>
                                        )

                                    //Error case for AI request
                                    case 'E':
                                        return (
                                            <Carousel.Item key={index} >
                                                <div id="notificationRed" className="d-block w-100">
                                                    <Card title={data.readableId} >
                                                        {translator.description} : {data.description}
                                                    </Card>
                                                </div>
                                            </Carousel.Item>
                                        )

                                    //Error case for AI request
                                    case 'R':
                                        return (
                                            <Carousel.Item key={index} >
                                                <div id="notificationRed" className="d-block w-100">
                                                    <Card title={data.readableId} >
                                                        {translator.description} : {data.description}
                                                    </Card>
                                                </div>
                                            </Carousel.Item>
                                        )

                                    default:
                                        break;
                                }
                                return 0;
                            })
                            }
                        </Carousel>
                        : 
                            <Card>
                                <span>{translator.noNotificationMessage}</span>
                            </Card>
                }

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        masterData: state.masterData,
        browserLanguage: state.browserLanguage
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
