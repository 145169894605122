import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
  tenant: '88d417d8-95eb-427b-8828-c46c9129de97',
  clientId: '511104bf-b2c7-4152-bcfb-257c054a562b',
  redirectUri: window.location.origin,
  logoutUri: window.location.origin + '/logout',
  postLogoutRedirectUri: window.location.origin,
  endpoints: {
    api: 'https://itella.onmicrosoft.com/511104bf-b2c7-4152-bcfb-257c054a562b',
  },
  cacheLocation: 'localStorage',
  expireOffsetSeconds: 300
};



export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

export const ensureToken = () => {

  authContext.acquireToken(adalConfig.clientId, (message, token, msg) => {
    if (token === null || token === undefined) {
      console.log("Token renewal failure due to message =", message, " and msg = ", msg);
    }
  })
}