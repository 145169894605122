import React, { Component } from 'react';
import { Chart } from 'primereact/chart';
import { Dropdown } from '../../../node_modules/primereact/dropdown';
import './dashboard.css';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import { connect } from 'react-redux';
import { CommonService } from '../../service/CommonService';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Growl } from 'primereact/growl';
import { MultiSelect } from 'primereact/multiselect';
import { Redirect } from 'react-router-dom';
import leaveCodes from '../../utility/leaveCodes.json';

let translator = new LocalizedStrings(translation);

class AllocationDataGraphAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            jsonTemplate: {
                costCenterIds: [],
                adminFlag: "true",
                planningPeriodId: null
            }
        }
        this.commonService = new CommonService();
        this.costCenterNameArrayForAllocationDataAdmin = [];
        this.allocatedDataArrayAdmin = [];
        this.unAllocatedDataArrayAdmin = [];

    }

    componentDidMount = () => {
        this.costCenterNameArrayForAllocationDataAdmin = this.props.costCenterNameArrayForAllocationDataAdmin;
        this.allocatedDataArrayAdmin = this.props.allocatedDataArrayAdmin;
        this.unAllocatedDataArrayAdmin = this.props.unAllocatedDataArrayAdmin;

        if (this.props.lastUsedPlanningPeriodAdminAllocationData !== null) {
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.planningPeriodId = this.props.lastUsedPlanningPeriodAdminAllocationData.planningPerioId
            jsonCopy.costCenterIds = this.props.lastUserCostCenterAdminAllocationData;
            this.setState({ jsonTemplate: jsonCopy, selectedPlaningPeriodForAllocationData: this.props.lastUsedPlanningPeriodAdminAllocationData });
        }

        let tempData = [];
        this.costCenterNameArrayForAllocationDataAdmin.map(data => {
            return tempData.push(this.props.masterData.costCenter.filter(cc => cc.costCenterCode === data)[0].costCenterMasterId);
        })

        this.setState({ selectedCCFilter: tempData });

    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {
     
        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });
        
    }

    onPlanningPeriodSelectForAllocationData = (event) => {
        
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.planningPeriodId = event.target.value.planningPerioId;
        this.setState({
            selectedPlaningPeriodForAllocationData: event.target.value,
            jsonTemplate: jsonCopy
        });
        
        if (this.state.jsonTemplate.costCenterIds.length !== 0) {
            this.setState({ loading: true });
            this.commonService.getAllocationDataForSupervisorDashboardForAdmin(jsonCopy, this.props.browserLanguage)
                .then(res => {
                    if (res.length > 0) {
                        this.costCenterNameArrayForAllocationDataAdmin = [];
                        this.allocatedDataArrayAdmin = [];
                        this.unAllocatedDataArrayAdmin = [];
                        res.map(data => {
                            this.costCenterNameArrayForAllocationDataAdmin.push(data.costCenterCode)
                            this.allocatedDataArrayAdmin.push(data.allocatedShifts)
                            this.unAllocatedDataArrayAdmin.push(data.nonAllocatedShifts)
                            return false;
                        });
                        this.setState({
                            loading: false
                        });
                    }
                })
                .catch(err => {
                    this.setState({ loading: false });
                    this.showMultiple(err);
                })
        }
    }

    filterData = (e) => {

        if (e.value.length <= 10) {
            this.setState({ selectedCCFilter: e.value });

            if (e.value.length > 0) {
                let jsonCopy = Object.assign({}, this.state.jsonTemplate);
                jsonCopy.costCenterIds = e.value;
                this.setState({ jsonTemplate: jsonCopy });
                if (this.state.jsonTemplate.planningPeriodId !== null) {
                    this.setState({ loading: true });
                    this.commonService.getAllocationDataForSupervisorDashboardForAdmin(jsonCopy, this.props.browserLanguage)
                        .then(res => {
                            if (res.length > 0) {
                                this.costCenterNameArrayForAllocationDataAdmin = [];
                                this.allocatedDataArrayAdmin = [];
                                this.unAllocatedDataArrayAdmin = [];
                                res.map(data => {
                                    this.costCenterNameArrayForAllocationDataAdmin.push(data.costCenterCode)
                                    this.allocatedDataArrayAdmin.push(data.allocatedShifts)
                                    this.unAllocatedDataArrayAdmin.push(data.nonAllocatedShifts)
                                    return false;
                                });
                                this.setState({
                                    loading: false
                                });
                            }
                        })
                        .catch(err => {
                            this.setState({ loading: false });
                            this.showMultiple(err);
                        })
                }
            }
            else {
                this.costCenterNameArrayForAllocationDataAdmin = [];
                this.allocatedDataArrayAdmin = [];
                this.unAllocatedDataArrayAdmin = [];
            }
        }

    }

    graphClicked = (event, data) => {
        if(data[0] !== undefined){
            this.setState({ redirect : true });
        }
    }

    render() {
        if(this.state.redirect === true){
            return (
                <Redirect to="/shiftAllocation"/>
            )
        }

        translator.setLanguage(this.props.browserLanguage);

        const dataForAllocation = {
            labels: this.costCenterNameArrayForAllocationDataAdmin,
            datasets: [
                {
                    label: translator.unAllocatedShifts,
                    backgroundColor: 'rgb(154, 208, 245, 0.8)',
                    data: this.unAllocatedDataArrayAdmin
                },
                {
                    label: translator.allocatedShifts,
                    backgroundColor: 'rgb(255, 188, 120, 0.8)',
                    data: this.allocatedDataArrayAdmin
                }
            ]
        }
        
        const barGraphOptions = {
            responsive: true,
            legend:{
                position:'bottom'
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 30,
                    bottom: 0
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        min: 0,
                        maxTicksLimit: 5,
                    },
                    gridLines: {
                        display: true
                    },
                    padding:{
                        top: 10,
                        bottom: 4
                    }

                }],
                xAxes: [{
                    gridLines: {
                        display: true
                    },
                    barThickness: 22
                }]
            },
            hover: {
                animationDuration: 1
            },
            animation: {
                duration: 1000,
                onComplete: function () {
                    var chartInstance = this.chart,
                        ctx = chartInstance.ctx;
                    ctx.textAlign = 'center';
                    ctx.fillStyle = "rgba(0, 0, 0, 1)";
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function (dataset, i) {
                        var meta = chartInstance.controller.getDatasetMeta(i);
                        meta.data.forEach(function (bar, index) {
                            var data = dataset.data[index];
                            ctx.fillText(data, bar._model.x, bar._model.y - 5);

                        });
                    });
                }
            },
            onClick: (e, dataArray) => this.graphClicked(e, dataArray)
        };

        const costCenterNameArrayForFilter = [];

        this.costCenterNameArrayForAllocationDataAdmin.map(data => {
            return costCenterNameArrayForFilter.push({ label: data, value: this.props.masterData.costCenter.filter(cc => cc.costCenterCode === data)[0].costCenterMasterId });
        });

        this.props.masterData.costCenter.map(data => {
            if (costCenterNameArrayForFilter.filter(cc => cc.value === data.costCenterMasterId).length === 0) {
                costCenterNameArrayForFilter.push({ label: data.costCenterCode, value: data.costCenterMasterId });
            }
            return 0;
        });

        return (
            <div>
                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>

                <Growl ref={(el) => this.growl = el} position="topright" ></Growl>

                <div className="form-inline dropDwonDefault">
                    <strong>{translator.planningPeriod}&nbsp;</strong>
                    <Dropdown
                        style = {{ minWidth : '150px' }} 
                        value={this.state.selectedPlaningPeriodForAllocationData}
                        options={this.props.masterData === null ? null : this.props.masterData.planningPeriodList}
                        onChange={(e) => this.onPlanningPeriodSelectForAllocationData(e)}
                        placeholder={translator.selectPlanningPeriod}
                        optionLabel='planningPeriodDescription'
                    />

                    <strong className="allocationDataFilter">{translator.costCenter}&nbsp;</strong>
                    <MultiSelect filter={true} maxSelectedLabels={0} placeholder={translator.selectCostCenter} value={this.state.selectedCCFilter} options={costCenterNameArrayForFilter} onChange={(e) => this.filterData(e)} />
                </div>

                {
                    this.costCenterNameArrayForAllocationDataAdmin.length > 0 && this.allocatedDataArrayAdmin.length > 0 && this.unAllocatedDataArrayAdmin.length > 0 ?
                        <div className="container-outer scrollbar">
                            <div className="container-inner overflow">
                                <Chart type="bar" data={dataForAllocation} options={barGraphOptions} />
                            </div>
                        </div>
                        : null
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        masterData: state.masterData,
        browserLanguage: state.browserLanguage
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllocationDataGraphAdmin);
