import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import { Dropdown } from 'primereact/dropdown';
import { CommonService } from '../../service/CommonService';
import { Growl } from 'primereact/growl';
import leaveCodes from '../../utility/leaveCodes.json';

let translator = new LocalizedStrings(translation);

class AutoGeneratedIDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAutoGeneratedId: [],
            autoGeneratedData: []
        }

        this.CommonService = new CommonService();
    }

    componentDidMount = () => {
        
        let jsonObject = {
            "costCenterId": this.props.costCenter,
            "planningPeriodid": this.props.planningPeriod
        }
        this.CommonService.getAutoGeneratedId(jsonObject, this.props.browserLanguage)
            .then(res => {
                let autogenerationIdsArray = res.map((data) => {
                    return { 'shiftGenerationId': data.shiftGenerationId, 'aiIntegrationLogId': data.aiIntegrationLogId, 'acceptedBy': data.acceptedBy }
                })
                this.setState({ autoGeneratedData: autogenerationIdsArray });
            })
            .catch(err => {
                this.showMultiple(err);
            })
            
        if(this.props.lastUsedValue !== undefined && this.props.lastUsedValue !==null){
            this.setState({
                selectedAutoGeneratedId: this.props.lastUsedValue
            });
        }
    }

    showMultiple = (errorBody) => {
        
        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });
    }

    onSelectedAutoGeneratedId = (e) => {
        this.setState({
            selectedAutoGeneratedId: e.target.value
        });
        if (e.target.value !== null) {
            this.props.onChange(e.value.aiIntegrationLogId, e.value.shiftGenerationId, e.value.acceptedBy);
        }
        else {
            this.props.onChange('');
        }

    }

    render() {

        let dropDownvalue = this.state.autoGeneratedData

        translator.setLanguage(this.props.browserLanguage);
        return (
            <div>
                <Growl ref={(el) => this.growl = el} position="topright" className="shiftGrowl"></Growl>
                <div className="dropDwonDefault">
                    <strong >{translator.autoGeneratedShiftId} : </strong>
                    <Dropdown
                        value={this.state.selectedAutoGeneratedId}
                        options={dropDownvalue}
                        optionLabel='shiftGenerationId'
                        onChange={(e) => this.onSelectedAutoGeneratedId(e)}
                        placeholder={translator.select}
                        filter={dropDownvalue.length > 0 ? true : false}
                    />
                </div>
            </div>
        );
    }
}

export default AutoGeneratedIDropDown;