import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { CommonService } from '../../service/CommonService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { keyboardValues } from "../../utility/commonUtility";
import _ from 'lodash';
import { Growl } from 'primereact/growl';
import leaveCodes from '../../utility/leaveCodes.json';

let translator = new LocalizedStrings(translation);

class BorrowEmpDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            borrowEmpData: {
                empIdList: [],
                planningPeriodId: null,
                costCenterId: null
            },
            BorrowEmployeeDialog: false
        }
        this.handleBorrowEmpData = this.handleBorrowEmpData.bind(this);
        this.CommonService = new CommonService();
    }
    //Function call when component first loads on screen
    componentDidMount = () => {

        let jsonCopy = Object.assign({}, this.props.borrowjsonTemplate);
        jsonCopy.costCenterId = this.props.centerMasterID;
        jsonCopy.planningPeriodID = this.props.planningPeriodID;
        jsonCopy.employeeSearch.employeeName = "";
        jsonCopy.employeeSearch.employeeTypeCode = "";
        jsonCopy.employeeSearch.shiftCodeList = "";
        this.setState({ loading: true, borrowjsonTemplate: jsonCopy });
        this.getBorrowEmpData(jsonCopy);
    }
    //Receive Data from Shift Allocation
    componentWillReceiveProps = () => {
        if (this.props.getBorrowEmployeeData) {
            this.setState({
                selectedData: this.props.selectedData,
                getBorrowEmployeeData: this.props.getBorrowEmployeeData,
                borrowjsonTemplate: this.props.borrowjsonTemplate,
                centerMasterID: this.props.centerMasterID,
                planningPeriodID: this.props.planningPeriodID,
                loading: this.props.loading,
                BorrowEmployeeDialog: true
            })
        }
    }
    //get borrowEmoloyee Details
    getBorrowEmpData = (payload) => {
        this.CommonService.getborrowEmployee(payload, this.props.browserLanguage)
            .then(data => {
                this.setState({
                    BorrowEmployeeDialog: true,
                    getBorrowEmpDetails: data.content,
                    loading: false,
                    totalEmployeeRecords: data.totalElements,
                    pageNumber: data.number,
                    pageSize: data.size,
                    recordSize: data.numberOfElements
                })
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    displayDialog: false,
                    BorrowEmployeeDialog: true
                });
                this.showMultiple(err);
            })
        this.setState({ getborrowJson: payload });
    }
    //to save borrowed Employee Details
    handleBorrowEmpData = () => {
        let borrowEmployeeData = this.state.selectedData;
        let empids = [];
        if (this.state.selectedData !== undefined && this.state.selectedData.length > 0) {
            borrowEmployeeData.map(borrowdata => {

                return empids.push(borrowdata.employeeId)
            });
            let jsonCopy = Object.assign({}, this.state.borrowEmpData);
            jsonCopy.empIdList = empids;
            jsonCopy.costCenterId = this.props.centerMasterID;
            jsonCopy.planningPeriodId = this.props.planningPeriodID;

            this.CommonService.saveborrowEmployee(jsonCopy, this.props.browserLanguage)
                .then(res => {
                    this.setState({ displayDialog: false, selection: null });
                    this.showSuccessMessageBorrow();
                    this.props.onChange(this.state.BorrowEmployeeDialog, 'showSuccess');

                })
                .catch(err => {
                    this.showMultiple(err);
                });

        }

        else {
            this.showSelectionError();
        }

    }
    //Function to handle Sorting on Shift Allocation Data
    onSortChange = (e) => {
        if (this.state.getBorrowEmpDetails === undefined || this.state.getBorrowEmpDetails.length === undefined) {
            return false;
        }
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, displayDialog: false, loading: true });
        let jsonCopy = Object.assign({}, this.state.borrowjsonTemplate);
        jsonCopy.employeeSearch.sortDirection = "ASC";
        if (e.sortOrder === -1) {
            jsonCopy.employeeSearch.sortDirection = "DESC";
        }
        jsonCopy.employeeSearch.sortBy = e.sortField;
        this.getBorrowEmpData(jsonCopy);
    }
    //Function to implement Filtering functionality on Employee ID. 
    onEmployeeSkillsFilter = (empSkills, key) => {
        if (_.includes(keyboardValues, key)) {
            let jsonCopy = Object.assign({}, this.state.borrowjsonTemplate);
            jsonCopy.employeeSearch.shiftCodeList = empSkills;
            this.getFilterFuntion(jsonCopy);
        }
    }
    //Function to implement Filtering functionality on Employee ID. 
    onEmployeeIdFilter = (empId, key) => {
        if (_.includes(keyboardValues, key)) {
            let jsonCopy = Object.assign({}, this.state.borrowjsonTemplate);
            jsonCopy.employeeSearch.employeeId = empId;
            this.getFilterFuntion(jsonCopy);
        }
    }

    onkeydownonemployeeNameFilter = (empName, key) => {
        if (_.includes(keyboardValues, key)) {
            let jsonCopy = Object.assign({}, this.state.borrowjsonTemplate);
            jsonCopy.employeeSearch.employeeName = empName;
            this.getFilterFuntion(jsonCopy);
        }
    }

    //Function to implement Filtering functionality on Employee Type Code. 
    onemployeeTypeFilter = (empType, key) => {
        if (_.includes(keyboardValues, key)) {
            let jsonCopy = Object.assign({}, this.state.borrowjsonTemplate);
            jsonCopy.employeeSearch.employeeTypeCode = empType;
            this.getFilterFuntion(jsonCopy);
        }
    }
    onCostCenterFilter = (costcentercode, key) => {
        if (_.includes(keyboardValues, key)) {
            let jsonCopy = Object.assign({}, this.state.borrowjsonTemplate);
            jsonCopy.employeeSearch.costCenterCode = costcentercode;
            this.getFilterFuntion(jsonCopy);
        }
    }
    //Function to call API for filtering records
    getFilterFuntion = (jsonCopy) => {
        if (this.state.getBorrowEmpDetails === undefined || this.state.getBorrowEmpDetails.length === undefined) {
            return false;
        }

        jsonCopy.pageNumber = 0;
        this.setState({
            jsonTemplate: jsonCopy,
            loading: true,
            // first : 0
        });
        this.getBorrowEmpData(jsonCopy);

    }
    //Function to implement Change Page functionality of Paginator. 
    onPageChange = (e) => {
        if (this.state.getBorrowEmpDetails === undefined || this.state.getBorrowEmpDetails.length === undefined) {
            return false;
        }

        this.setState({ first: e.first, loading: true });
        let jsonCopy = Object.assign({}, this.state.borrowjsonTemplate);
        jsonCopy.employeeSearch.pageNumber = e.page;
        this.getBorrowEmpData(jsonCopy);
    }
    //Function to implement functionality of Number of Record Dropdown change of Pagination. 
    noOfRecordChange = (e) => {
        this.setState({
            dropdownDisplay: e.value,
            loading: true,
            first: 0
        })
        let jsonCopy = Object.assign({}, this.state.borrowjsonTemplate);
        jsonCopy.employeeSearch.recordCount = e.value.code;
        jsonCopy.employeeSearch.pageNumber = 0;

        if (this.state.getBorrowEmpDetails === undefined || this.state.getBorrowEmpDetails.length === undefined) {
            this.setState({ loading: false });
            this.setState({ borrowjsonTemplate: jsonCopy });
            return false;
        }

        this.getBorrowEmpData(jsonCopy);
        this.setState({ borrowjsonTemplate: jsonCopy });
    }

    //Error Messages
    showMultiple = (errorBody) => {
       
        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });
    }
    //Success Message 
    showSuccessMessageBorrow = () => {
        
        this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.successMessage, detail: translator.employeeBorrowedSuccess });
    }

    showSelectionError = () => {
        
        this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.selectionErrorMsg });
    }

    employeeNrTemplate = (rowData,column) => {
        
        if(rowData.employeeId > 0){
            return (<div>{rowData.employeeId}</div>)
        }
    }

    render() {
        translator.setLanguage(this.props.browserLanguage);
        const noOfRecord = [
            { name: translator.Records_150, code: 150 },
            { name: translator.Records_200, code: 200 },
            { name: translator.Records_250, code: 250 }
        ];

        let dialogFooter = (<div className="ui-dialog-buttonpane p-clearfix">
            <Button label={translator.borrow} icon="pi pi-check" className="BorrowOkButton" type="submit" onClick={this.handleBorrowEmpData} />
        </div>);
        let employeeIdFilter = <InputText maxLength="9" id="borrowdialogemployeeId" keyfilter={/^[0-9\b]+$/} className="employeeIdFilterWidth" onKeyDown={(e) => this.onEmployeeIdFilter(e.target.value, e.key)} />
        let employeeNameFilter = <InputText type="text" id="borrowdialogemployeeName" className="employeeIdFilterWidth" onKeyDown={(e) => this.onkeydownonemployeeNameFilter(e.target.value, e.key)} />
        let employeeTypeFilter = <InputText type="text" id="borrowdialogemployeeTypeCode" className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeTypeFilter(e.target.value, e.key)} />
        let employeeSkillsFilter = <InputText id="borrowdialogemployeeSkills" className="employeeIdFilterWidth" onKeyDown={(e) => this.onEmployeeSkillsFilter(e.target.value, e.key)} />
        let costCenterFilter = <InputText id="borrowdialogcostcenter" className="employeeIdFilterWidth" onKeyDown={(e) => this.onCostCenterFilter(e.target.value, e.key)} />
        return (
            <div className="dialogbox">
                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>
                <Growl ref={(el) => this.growl = el} position="topright"></Growl>
                <Dialog 
                    header={translator.borrowEmployee} 
                    visible={this.state.BorrowEmployeeDialog} 
                    footer={dialogFooter} 
                    className="defaultDialog" 
                    modal={true} 
                    onHide={() => {this.setState({ BorrowEmployeeDialog: false, loading: false });this.props.onChange(this.state.BorrowEmployeeDialog, 'doNotShowSuccess');} }>
                    <div className="paginatorAdvanceFilterPosition" >

                        <div className="paginatorAdvanceFilter">
                            <Paginator
                                leftContent={<span className="recordOFDialog">{this.state.totalEmployeeRecords === undefined || this.state.totalEmployeeRecords === 0 ? '0-0' : (this.state.pageNumber * this.state.pageSize) + 1 + '-' + Number((this.state.pageNumber * this.state.pageSize) + Number(this.state.recordSize))} {translator.of} {this.state.totalEmployeeRecords === undefined ? 0 : this.state.totalEmployeeRecords} {translator.records}</span>}
                                rows={this.props.borrowjsonTemplate.employeeSearch.recordCount}
                                totalRecords={this.state.totalEmployeeRecords === undefined ? 0 : this.state.totalEmployeeRecords}
                                first={this.state.first}
                                onPageChange={(e) => this.onPageChange(e)}></Paginator>
                            <Dropdown value={this.state.dropdownDisplay} className="dropDwonDefault colRecordAdvanceFilter dropmarginfilter" options={noOfRecord} onChange={(e) => this.noOfRecordChange(e)} placeholder={translator.Records_150} optionLabel="name" />
                        </div>
                    </div>
                    <DataTable value={this.state.getBorrowEmpDetails}
                        style={{width:'101%'}}
                        onSort={(e) => this.onSortChange(e)}
                        sortField={this.state.sortField}
                        sortOrder={this.state.sortOrder}
                        selection={this.state.selectedData}
                        onSelectionChange={e => this.setState({ selectedData: e.value })}>
                        <Column selectionMode="multiple" style={{ width: '3em' }} />
                        <Column field="costCenterCode" header={translator.costCenter} sortable={true} filter={true} className="numberRightAlign" filterElement={costCenterFilter} />
                        <Column field="employeeName" header={translator.employeeName} sortable={true} filter={true} filterElement={employeeNameFilter} className="textLeftAlign" />
                        <Column field="employeeType" header={translator.employeeTypeCode} sortable={true} filter={true} filterElement={employeeTypeFilter} className="textLeftAlign" />
                        <Column field="employeeId" header={translator.employeeId} sortable={true} filter={true} filterElement={employeeIdFilter} className="numberRightAlign" body={this.employeeNrTemplate}/>
                        <Column field="employeeSkills" header={translator.employeeSkills} filter={true} filterElement={employeeSkillsFilter} className="numberRightAlign" />


                    </DataTable>
                </Dialog>
            </div>

        );
    }
}

export default BorrowEmpDialog;