import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CopyPlanningPeriod from '../../components/planningperiod/CopyPlanningPeriod';
import PlanningPeriod from '../../components/planningperiod/planningperiod';
import { CommonService } from '../../service/CommonService';
import { ColumnGroup } from 'primereact/columngroup';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputText } from 'primereact/inputtext';
import { Row } from 'primereact/row';
import leaveCodes from '../../utility/leaveCodes.json';
import './ShiftAllocation.css';
// import Breadcrumbs from '../../components/breadcrumbs/BreadCrumbs';
import CostCenter from '../../components/costcenter/costCenter';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import { Paginator } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import browser from 'browser-detect';
import BorrowEmpDialog from './BorrowEmpDialog';
import { keyboardValues, getResolutionShiftAllocation, copyFromYearDropDown, copyToYearDropDown } from "../../utility/commonUtility";
import BaselineAllocationPlan from './BaselineAllocationPlan';
import AdvanceFilterShiftAllocation from './AdvanceFilterShiftAllocation';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';
import FileSaver from 'file-saver';
import { Checkbox } from 'primereact/checkbox';
import ReactDOM from 'react-dom';
import DeleteConfirmationDialog from '../employeemaster/DeleteConfirmationDialog';
import ConfirmationDialog from './ConfirmationDialog';
import _ from 'lodash';
import { Accordion, AccordionTab } from 'primereact/accordion';

let translator = new LocalizedStrings(translation);
let removeClicked = false;
let weekOff = ["Sat", "Sun", "DE"];
let leaveCodesArray = ["VL", "VL2", "PO", "JV", "TV", "P", "AY", "SV", "L", "R", "PR", "C", "H", "M","LO"];
let reservedCodesArray = ["L", "R", "PR", "C", "H", "M"];
let weekdays = ['week1day1', 'week1day2', 'week1day3', 'week1day4', 'week1day5', 'week1day6',
    'week2day1', 'week2day2', 'week2day3', 'week2day4', 'week2day5', 'week2day6',
    'week3day1', 'week3day2', 'week3day3', 'week3day4', 'week3day5', 'week3day6',
    'week4day1', 'week4day2', 'week4day3', 'week4day4', 'week4day5', 'week4day6'];
let bulkUpdateCostCenterMasterID = '';
let bulkUpdateValue = '';
let bulkUpdateArrayOfDatesHavingChanges = [];
let bulkUpdateOldValues = '';
let bulkUpdateEmployeeId = '';
let bulkUpdatePlanningPeriodId = '';
let bulkUpdateStartindex = '';
let bulkUpdateEndindex = '';
let bulkUpdateProps = '';
let bulkUpdateField = '';
let weeklyDialogContent = '';
let weeklyDialogFooter = '';
let newEnteredComment = '';
let helpPopupInnerContent = '';
let weeklyCommentLength = 100;
let dailyCommentOverlayContent = '';
let unborrowEmployeeId = '';
let copyPPDropdownData = [];
let shiftsCountData = {};

class ShiftAllocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            copyCheckbox: false,
            growlWithScroll: false,
            shiftdata: null,
            commentClicked: false,
            isWeeklyCommentDialogVisible: false,
            week1: false,
            week2: false,
            week3: false,
            week4: false,
            checkedDates: [], //check which dates are checked
            flag: false,
            dates: [],
            dateString: [],
            shiftWeeks: [],
            noOfRecords: 150,
            loading: false,
            pageSize: 0,
            validShift: false,
            removefilterdisable: true,
            CumulativeDialog: false,
            displayDeleteCnfDialog: false,
            displayUnborrowCnfDialog: false,
            displayHelpDialog: false,
            leaveCodesAndDescriptionsArray: [],
            timeToWait: "",
            jsonTemplate: {
                aiIntegrationLogId: 0,
                planningPeriodID: "",
                centerMasterID: null,
                twoWeekPlanningPeriod: false,
                shiftWithStartTime: false,
                exceptionWeeks: [],
                exceptionDates: [], //array of dates checked for getting data
                employeeIds: [],
                employeeGroupName: [],
                employeeSearch: {
                    supervisorId: "",
                    costCenterCode: "",
                    employeeId: "",
                    employeeName: "",
                    employeeTypeCode: "",
                    sortBy: "employeeName",
                    sortDirection: "ASC",
                    pageNumber: 0,
                    recordCount: 150,
                    shiftCodeList: ""
                }
            },
            editshiftCodejsonTemplate: {
                allocationPlanId: [],
                shiftCode: "",
                costCenterId: null,
                shiftCodes: [],
                date: [],
                employeeId: [],
                planningPeriodId: "",
                shiftStartTime: "",
                shiftEndTime: "",
                allocationPlanHeaderId: ""
            },
            getborrowJson: {
                costCenterId: 0,
                planningPeriodID: "",
                employeeSearch: {
                    costCenterCode: "",
                    employeeId: "",
                    employeeName: "",
                    employeeTypeCode: "",
                    sortBy: "employeeName",
                    sortDirection: "desc",
                    pageNumber: 0,
                    recordCount: 150,
                }
            },
            saveWeeklyCommentAndHaittaInputJsonTemplate: {
                weeklyHaittaAndCommentId: "",
                weeklyComment: "",
                weekStartDate: "",
                weeklyHaitta: "",
                costCenterMasterId: ""
            },
            unborrowEmployeeJsonTemplate: {
                costCenterId: "",
                employeeId: "",
                planningPeriodId: ""
            },
            saveDailyCommentJSONTemplate: {
                empDetailsId: null,
                shiftDate: "",
                costCenterMasterId: null,
                plaaningPeriodId: null,
                empShiftComment: []
            },
            copyShiftAllocationJSON: {
                currentPlanningPeriodID: null,
                oldPlanningPeriodID: null,
                centerMasterID: null,
                exceptionWeeks: [],
                exceptionDates: [],
                employeeIds: [],
                employeeGroupName: [],
                acceptAllocation: false,
                copyEntireYear: false,
                targetYear: null,
                sourceYear: null,
                employeeSearch: {
                    supervisorId: "",
                    costCenterCode: "",
                    employeeId: "",
                    employeeName: "",
                    employeeTypeCode: "",
                    sortBy: "employeeName",
                    sortDirection: "ASC",
                    pageNumber: 0,
                    recordCount: 150,
                    shiftCodeList: ""
                }
            },
            autoGenerateShiftTemplate: {
                costCenterCode: "",
                planningPeriodStartDate: "",
                planningPeriodEndDate: "",
                triggerType: "M",
                planningPeriodId: 0,
                costCenterId: 0,
                timeToWait: ""
            }

        };
        this.CommonService = new CommonService();
    }

    componentDidMount = () => {
        if (this.props.location.state !== undefined) {

            let jsonCopy1 = Object.assign({}, this.state.jsonTemplate);
            jsonCopy1.centerMasterID = this.props.location.state.res.costCenter['costCenterMasterId'];
            jsonCopy1.employeeSearch.costCenterCode = this.props.location.state.res.costCenter['costCenterCode'];
            jsonCopy1.planningPeriodID = this.props.location.state.res.planningPeriod['planningPeriodId'];
            this.CommonService.getCopyPP(this.props.browserLanguage, this.props.location.state.res.planningPeriod['planningPeriodId'])
                .then(res => {
                    copyPPDropdownData = res;
                    this.setState({});
                })
                .catch(err => {
                    this.setState({ loading: false, displayDialog: false, CumulativeDialog: false });
                    this.showMultiple(err);
                })
            shiftsCountData = this.props.location.state.res.data.shiftCountMapPerDay;
            this.setState({
                disableFeedFile : this.props.location.state.res.disableFeedFile,
                holidayArray: this.props.location.state.res.holidays,
                lastUsedCostCenter: this.props.location.state.res.costCenter,
                lastUsedPlanningPeriod: this.props.location.state.res.planningPeriod,
                planningPeriodID: this.props.location.state.res.planningPeriod['planningPeriodId'],
                costCenterCode: this.props.location.state.res.costCenter['costCenterCode'],
                centerMasterID: this.props.location.state.res.costCenter['costCenterMasterId'],
                dateString: this.props.location.state.res.dateString,
                shiftWeeks: this.props.location.state.res.shiftWeeks,
                pageNumber: this.props.location.state.res.pageNumber,
                pageSize: this.props.location.state.res.shiftPageSize,
                shiftdata: this.props.location.state.res.data,
                totalShiftAllocatedRecords: this.props.location.state.res.totalRecords,
                employeeTypeId: this.props.location.state.res.data.employeeTypeId,
                loading: false,
                displayDialog: false,
                CumulativeDialog: false
            })

            this.setState({ jsonTemplate: jsonCopy1 });
        }
        else {
            this.setState({ loading: true });
            this.commonMethodForLifecycleMethod();
        }

    }

    commonMethodForLifecycleMethod = () => {
        this.CommonService.getLastFilterShiftAllocation(this.props.browserLanguage)
            .then(res => {
                let jsonCopy1 = Object.assign({}, this.state.jsonTemplate);
                if (res.data !== null) {
                    jsonCopy1.centerMasterID = res.costCenter['costCenterMasterId'];
                    jsonCopy1.employeeSearch.costCenterCode = res.costCenter['costCenterCode'];
                    jsonCopy1.planningPeriodID = res.planningPeriod['planningPeriodId'];
                    this.CommonService.getCopyPP(this.props.browserLanguage, res.planningPeriod['planningPeriodId'])
                        .then(res => {
                            copyPPDropdownData = res;
                            this.setState({});
                        })
                        .catch(err => {
                            this.setState({ loading: false, displayDialog: false, CumulativeDialog: false });
                            this.showMultiple(err);
                        })
                    shiftsCountData = res.shiftCountMapPerDay;
                    this.setState({
                        disableFeedFile : res.disableFeedFile,
                        holidayArray: res.holidays,
                        lastUsedCostCenter: res.costCenter,
                        lastUsedPlanningPeriod: res.planningPeriod,
                        planningPeriodID: res.planningPeriod['planningPeriodId'],
                        costCenterCode: res.costCenter['costCenterCode'],
                        centerMasterID: res.costCenter['costCenterMasterId'],
                        dateString: res.dateString,
                        shiftWeeks: res.shiftWeeks,
                        pageNumber: res.pageNumber,
                        pageSize: res.shiftPageSize,
                        shiftdata: res.data,
                        totalShiftAllocatedRecords: res.totalRecords,
                        employeeTypeId: res.data.employeeTypeId,
                        loading: false,
                        displayDialog: false,
                        CumulativeDialog: false
                    })

                    this.setState({ jsonTemplate: jsonCopy1 });

                }
                this.setState({ loading: false });
            })
            .catch(err => {
                this.setState({ loading: false, displayDialog: false, CumulativeDialog: false });
                this.showMultiple(err);
            })

        //Calling service to get leave codes and description
        this.callServiceToGetLeaveCodeAndDescription();
    }

    //Function called when callback is received from Dialog.
    callbackFromDialog = (data, status) => {
        this.setState({ displayDialog: false, CumulativeDialog: false, loading: true })

        if (status === 'showSuccess') {
            this.getList(this.state.jsonTemplate);
            this.showSuccessMessageBorrow();
        }
        else {
            this.setState({ loading: false });
        }

    }
    // Function called when Callback receive from AdvanceFilter Dialog
    handleCumulativeSelection = (jsonCopy) => {
        this.setState({ CumulativeDialog: false, displayDialog: false, loading: true, removefilterdisable: false, AdvanceFilterDialog: false });
        this.setState({ jsonTemplate: jsonCopy });
        removeClicked = false;
        document.getElementById('employeeSkills').value = '';
        document.getElementById('employeeName').value = '';
        document.getElementById('employeeTypeCode').value = '';
        document.getElementById('employeeGroupNameFilterTextField').value = '';
        this.getList(jsonCopy);

    }

    //hide Cumulative Dialog When Clicked without selection
    hideCumulativeDialog = () => {
        this.setState({ CumulativeDialog: false });
    }

    //function to clear out advance filter
    removeAdvanceFilter = () => {
        removeClicked = true;
        this.setState({ loading: true });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeIds = [];
        this.setState({ CumulativeDialog: false, displayDialog: false, removefilterdisable: true, jsonTemplate: jsonCopy });
        this.getList(jsonCopy);
    }


    //Color Editor for showing leaves color 
    colorTemplateweekday = (rowData, weekday) => {
    
        //Flag for checking if comments are present for particular day for an employee
        let areThereAnyCommentsForDayForEmployee = false;
        //Block to iterate and check if there are any comments
        if (rowData[weekday] !== null) {
            rowData[weekday].shiftsOnThisDay.map((shiftDetails, index) => {
                if (shiftDetails.shiftComment !== null && shiftDetails.shiftComment !== '')
                    areThereAnyCommentsForDayForEmployee = true;
                return shiftDetails;
            })
        }

        if (rowData[weekday] === null) {     //Sets color when employee is deactivated on that date
            return <div id={'dv' + weekday} className="elemnetFill textUpperCase" title={translator.employeeDeactivatedForDate} style={{ backgroundColor: leaveCodes.color_Hol }}>-</div>
        }

        else if(rowData.borrowedInOtherCC === true && rowData[weekday].postalCodeForBorrowedEmployee!==null){
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ color: "black" }}>
                    {rowData[weekday].postalCodeForBorrowedEmployee}
                    <i className="pi pi-comment dailyCommentIcon" style={{ float: 'right', cursor: 'pointer', color: areThereAnyCommentsForDayForEmployee ? leaveCodes.color_dailyCommentActive : leaveCodes.color_dailyCommentUnActive }} onClick={(e) => this.showDailyCommentOverlay(e, rowData, weekday)}></i>
                </div>
            )   
        }

        else if (rowData[weekday].shiftCode.toLowerCase() === leaveCodes.VL.toLowerCase()) {    //Sets color when VL is entered
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_VL }}>
                    {rowData[weekday].shiftCode}
                    <i className="pi pi-comment dailyCommentIcon" style={{ float: 'right', cursor: 'pointer', color: areThereAnyCommentsForDayForEmployee ? leaveCodes.color_dailyCommentActive : leaveCodes.color_dailyCommentUnActive }} onClick={(e) => this.showDailyCommentOverlay(e, rowData, weekday)}></i>
                </div>
            )
        }
        else if (rowData[weekday].shiftCode.toLowerCase() === leaveCodes.VL2.toLowerCase()) {    //Sets color when VL2 is entered
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_VL2 }}>
                    {rowData[weekday].shiftCode}
                    <i className="pi pi-comment dailyCommentIcon" style={{ float: 'right', cursor: 'pointer', color: areThereAnyCommentsForDayForEmployee ? leaveCodes.color_dailyCommentActive : leaveCodes.color_dailyCommentUnActive }} onClick={(e) => this.showDailyCommentOverlay(e, rowData, weekday)}></i>
                </div>
            )
        }
        else if (rowData[weekday].shiftCode.toLowerCase() === leaveCodes.PO.toLowerCase()) {    //Sets color when PO is entered
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_PO }}>
                    {rowData[weekday].shiftCode}
                    <i className="pi pi-comment dailyCommentIcon" style={{ float: 'right', cursor: 'pointer', color: areThereAnyCommentsForDayForEmployee ? leaveCodes.color_dailyCommentActive : leaveCodes.color_dailyCommentUnActive }} onClick={(e) => this.showDailyCommentOverlay(e, rowData, weekday)}></i>
                </div>
            )
        }
        else if (rowData[weekday].shiftCode.toLowerCase() === leaveCodes.JV.toLowerCase()) {    //Sets color when JV is entered
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_JV }}>
                    {rowData[weekday].shiftCode}
                    <i className="pi pi-comment dailyCommentIcon" style={{ float: 'right', cursor: 'pointer', color: areThereAnyCommentsForDayForEmployee ? leaveCodes.color_dailyCommentActive : leaveCodes.color_dailyCommentUnActive }} onClick={(e) => this.showDailyCommentOverlay(e, rowData, weekday)}></i>
                </div>
            )
        }
        else if (rowData[weekday].shiftCode.toLowerCase() === leaveCodes.LO.toLowerCase()) {    //Sets color when LO is entered
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_LO }}>
                    {rowData[weekday].shiftCode}
                    <i className="pi pi-comment dailyCommentIcon" style={{ float: 'right', cursor: 'pointer', color: areThereAnyCommentsForDayForEmployee ? leaveCodes.color_dailyCommentActive : leaveCodes.color_dailyCommentUnActive }} onClick={(e) => this.showDailyCommentOverlay(e, rowData, weekday)}></i>
                </div>
            )
        }
        else if (rowData[weekday].shiftCode.toLowerCase() === leaveCodes.TV.toLowerCase()) {    //Sets color when TV is entered
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_TV }}>
                    {rowData[weekday].shiftCode}
                    <i className="pi pi-comment dailyCommentIcon" style={{ float: 'right', cursor: 'pointer', color: areThereAnyCommentsForDayForEmployee ? leaveCodes.color_dailyCommentActive : leaveCodes.color_dailyCommentUnActive }} onClick={(e) => this.showDailyCommentOverlay(e, rowData, weekday)}></i>
                </div>
            )
        }
        else if (rowData[weekday].shiftCode.toLowerCase() === leaveCodes.P.toLowerCase()) {    //Sets color when P is entered
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_P }}>
                    {rowData[weekday].shiftCode}
                    <i className="pi pi-comment dailyCommentIcon" style={{ float: 'right', cursor: 'pointer', color: areThereAnyCommentsForDayForEmployee ? leaveCodes.color_dailyCommentActive : leaveCodes.color_dailyCommentUnActive }} onClick={(e) => this.showDailyCommentOverlay(e, rowData, weekday)}></i>
                </div>
            )
        }
        // else if (rowData[weekday].shiftCode.toLowerCase() === leaveCodes.Hol.toLowerCase()) {    //Sets color when there is holiday on that date
        //     return (
        //         <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_Hol }}>
        //             -
        //             <i className="pi pi-comment dailyCommentIcon" style={{ float: 'right', cursor: 'pointer', color: areThereAnyCommentsForDayForEmployee ? leaveCodes.color_dailyCommentActive : leaveCodes.color_dailyCommentUnActive }} onClick={(e) => this.showDailyCommentOverlay(e, rowData, weekday)}></i>
        //         </div>
        //     )
        // }
        else if (rowData[weekday].shiftCode === leaveCodes.Sat || rowData[weekday].shiftCode === leaveCodes.Sun) {  //Executed when day is weekend
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ color: "black" }}>
                    -
                    <i className="pi pi-comment dailyCommentIcon" style={{ float: 'right', cursor: 'pointer', color: areThereAnyCommentsForDayForEmployee ? leaveCodes.color_dailyCommentActive : leaveCodes.color_dailyCommentUnActive }} onClick={(e) => this.showDailyCommentOverlay(e, rowData, weekday)}></i>
                </div>
            )
        }
        //condition to show bold text when same shift is allocated to some other employee as well
        else if ((reservedCodesArray.indexOf(rowData[weekday].shiftCode.toUpperCase()) === -1) && rowData[weekday].shiftCode.toUpperCase() !== "-" && this.checkIfShiftIsAssignedToOthers(rowData, weekday, rowData[weekday].shiftCode.toUpperCase()) !== "" ) { //Executed when there is a shift on which employee is untrained

            let title = rowData[weekday].shiftRemarks + `${translator.moreThanOneShiftOnSameDateTooltip} : ${this.checkIfShiftIsAssignedToOthers(rowData, weekday, rowData[weekday].shiftCode.toUpperCase())}`

            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={ title } style={this.checkEmployeeNotTrainedOnSkill(rowData.skills, rowData[weekday].shiftCode.toUpperCase()) ? { backgroundColor: leaveCodes.color_warningShift } : {}}>
                    <span style={{ fontWeight: 'bold' }}>{rowData[weekday].shiftCode}</span>
                    <i className="pi pi-comment dailyCommentIcon" style={{ float: 'right', cursor: 'pointer', color: areThereAnyCommentsForDayForEmployee ? leaveCodes.color_dailyCommentActive : leaveCodes.color_dailyCommentUnActive }} onClick={(e) => this.showDailyCommentOverlay(e, rowData, weekday)}></i>
                </div>
            )
        }
        else if ((reservedCodesArray.indexOf(rowData[weekday].shiftCode.toUpperCase()) === -1) && rowData[weekday].shiftCode.toUpperCase() !== "-" && this.checkEmployeeNotTrainedOnSkill(rowData.skills, rowData[weekday].shiftCode.toUpperCase())) { //Executed when there is a shift on which employee is untrained
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_warningShift }}>
                    {rowData[weekday].shiftCode}
                    <i className="pi pi-comment dailyCommentIcon" style={{ float: 'right', cursor: 'pointer', color: areThereAnyCommentsForDayForEmployee ? leaveCodes.color_dailyCommentActive : leaveCodes.color_dailyCommentUnActive }} onClick={(e) => this.showDailyCommentOverlay(e, rowData, weekday)}></i>
                </div>
            )
        }
        else if (reservedCodesArray.indexOf(rowData[weekday].shiftCode.toUpperCase()) !== -1) {  //Executed when reserved code is present on that day
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_reservedCode }}>
                    {rowData[weekday].shiftCode}
                    <i className="pi pi-comment dailyCommentIcon" style={{ float: 'right', cursor: 'pointer', color: areThereAnyCommentsForDayForEmployee ? leaveCodes.color_dailyCommentActive : leaveCodes.color_dailyCommentUnActive }} onClick={(e) => this.showDailyCommentOverlay(e, rowData, weekday)}></i>
                </div>
            )
        }
        else if (rowData[weekday].holiday) {  //Executed when reserved code is present on that day
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_weekend }}>
                    {rowData[weekday].shiftCode}
                    <i className="pi pi-comment dailyCommentIcon" style={{ float: 'right', cursor: 'pointer', color: areThereAnyCommentsForDayForEmployee ? leaveCodes.color_dailyCommentActive : leaveCodes.color_dailyCommentUnActive }} onClick={(e) => this.showDailyCommentOverlay(e, rowData, weekday)}></i>
                </div>
            )
        }
      
        else {
            return (
                <div id={'dv' + rowData[weekday].shiftAllocationPlanDetailId} className="elemnetFill textUpperCase" title={rowData[weekday].shiftRemarks} style={{ color: "black" }}>
                    {rowData[weekday].shiftCode}
                    <i className="pi pi-comment dailyCommentIcon" style={{ float: 'right', cursor: 'pointer', color: areThereAnyCommentsForDayForEmployee ? leaveCodes.color_dailyCommentActive : leaveCodes.color_dailyCommentUnActive }} onClick={(e) => this.showDailyCommentOverlay(e, rowData, weekday)}></i>
                </div>
            )
        }
    }

    checkIfShiftIsAssignedToOthers = (rowData, weekday, enteredShiftCodes) => {
        let flag = "";

        if (Object.keys(shiftsCountData).length !== 0) {

            let enteredShiftArray = enteredShiftCodes.split(",")

            enteredShiftArray.map((data,index) => {
                if (shiftsCountData[rowData[weekday].date] !== undefined && shiftsCountData[rowData[weekday].date].includes(data)) {
                    if(index === 0)
                        flag = flag.concat(data);
                    else
                        flag = flag.concat(`, ${data}`);
                }
                return 0;
            })

        }
        return flag;

    }

    //Function to check if employee is trained or not on entered shift code
    checkEmployeeNotTrainedOnSkill = (employeeSkills, enteredShiftCodes) => {

        if (enteredShiftCodes.includes(',')) {    //multiple shifts entered
            enteredShiftCodes = enteredShiftCodes.split(',');
            let flag = false;
            for (let index = 0; index < enteredShiftCodes.length; index++) {

                if (employeeSkills === '')
                    flag = true
                else if (employeeSkills.includes(',')) {
                    if (!employeeSkills.includes(enteredShiftCodes[index]))
                        flag = true;
                }
                else {
                    if (employeeSkills !== enteredShiftCodes[index])
                        flag = true;
                }
            }
            return flag;
        }
        else {  //single shift entered
            let flagForSingleShift = false;
            if (employeeSkills === '')
                flagForSingleShift = true
            else if (employeeSkills.includes(',')) {
                if (!employeeSkills.includes(enteredShiftCodes))
                    flagForSingleShift = true;
            }
            else {
                if (employeeSkills !== enteredShiftCodes)
                    flagForSingleShift = true;
            }
            return flagForSingleShift;
        }
    }

    //Function to call API service for GET ShiftAllocation Data.
    getList = (payload) => {
        this.CommonService.getShiftAllocationData(payload, this.props.browserLanguage)
            .then(data => {
                if (data !== undefined && data !== null) {
                    shiftsCountData = data.shiftCountMapPerDay;
                    this.setState({
                        disableFeedFile : data.disableFeedFile,
                        holidayArray: data.holidays,
                        dates: data.dates,
                        dateString: data.dateString,
                        shiftWeeks: data.shiftWeeks,
                        pageNumber: data.pageNumber,
                        pageSize: data.shiftPageSize,
                        shiftdata: data.data,
                        totalShiftAllocatedRecords: data.totalRecords,
                        employeeTypeId: data.data.employeeTypeId,
                        loading: false,
                        displayDialog: false,
                        CumulativeDialog: false
                    })
                }
            })
            .catch(err => {
                this.setState({ loading: false, displayDialog: false, CumulativeDialog: false });
                this.showMultiple(err);
            })
    }

    //Function to call API service for GET ShiftAllocation Data.
    getListForCopyAllocation = (payload) => {

        this.CommonService.getCopyShiftAllocation(payload, this.props.browserLanguage)
            .then(res => {
                if (res !== undefined && res !== null) {
                    this.showSuccessMessageSingle(res);
                    this.setState({
                        loading: false,
                        displayDialog: false,
                        CumulativeDialog: false
                    })
                }
            })
            .catch(err => {
                this.showMultiple(err);
                this.setState({ loading: false, displayDialog: false, CumulativeDialog: false });
            })
    }

    //Function to handle page Change Event
    onPageChange = (e) => {
        if (this.state.shiftdata === undefined || this.state.shiftdata.length === undefined) {
            return false;
        }

        this.setState({ first: e.first, loading: true, displayDialog: false, CumulativeDialog: false });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeSearch.pageNumber = e.page;
        this.getList(jsonCopy);
    }

    //Function to call API for filtering records
    getFilterFuntion = (jsonCopy) => {
        if (this.state.shiftdata === undefined || this.state.shiftdata.length === undefined) {
            return false;
        }

        jsonCopy.employeeSearch.pageNumber = 0;
        this.setState({
            jsonTemplate: jsonCopy,
            loading: true,
            // first : 0
        });
        this.getList(jsonCopy);

    }

    //Function to implement Filtering functionality on Employee ID. 
    onEmployeeSkillsFilter = (empSkills, key) => {
        if (_.includes(keyboardValues, key) && empSkills !== "") {
            this.setState({ displayDialog: false, CumulativeDialog: false })
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.shiftCodeList = empSkills;
            this.getFilterFuntion(jsonCopy);
        }
    }


    onkeydownonemployeeNameFilter = (empName, key) => {
        if (_.includes(keyboardValues, key) && empName !== "") {
            this.setState({ displayDialog: false, CumulativeDialog: false });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.employeeName = empName;
            this.getFilterFuntion(jsonCopy);
        }
    }

    //Function to implement Filtering functionality on Employee Type Code. 
    onemployeeTypeFilter = (empType, key) => {
        if (_.includes(keyboardValues, key) && empType !== "") {
            this.setState({ displayDialog: false, CumulativeDialog: false })
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.employeeTypeCode = empType;
            this.getFilterFuntion(jsonCopy);
        }
    }

    //Function to get filtered list of employees based on entered groupname
    onemployeeGroupNameFilter = (empGroupName, key) => {
        if (_.includes(keyboardValues, key) && empGroupName !== "") {
            this.setState({ displayDialog: false, CumulativeDialog: false })
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeGroupName = empGroupName.includes(',') ? empGroupName.split(',') : [empGroupName];
            this.getFilterFuntion(jsonCopy);
        }
    }

    //Function to clear filtered data
    filterCleared = (e, field) => {

        if (e.target.value === "" && field === "employeeSkills") {
            this.setState({ loading: true });

            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.shiftCodeList = "";
            this.setState({ jsonTemplate: jsonCopy });
            this.getList(jsonCopy);

        }
        else if (e.target.value === "" && field === "employeeName") {
            this.setState({ loading: true });

            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.employeeName = "";
            this.setState({ jsonTemplate: jsonCopy });
            this.getList(jsonCopy);

        }
        else if (e.target.value === "" && field === "employeeTypeCode") {
            this.setState({ loading: true });

            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.employeeTypeCode = "";
            this.setState({ jsonTemplate: jsonCopy });
            this.getList(jsonCopy);

        }
        else if (e.target.value === "" && field === "employeeGroupName") {
            this.setState({ loading: true });

            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeGroupName = [];
            this.setState({ jsonTemplate: jsonCopy });
            this.getList(jsonCopy);

        }
    }

    //Function to handle Sorting on Shift Allocation Data
    onSortChange = (e) => {
        if (this.state.shiftdata === undefined || this.state.shiftdata.length === undefined) {
            return false;
        }

        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, displayDialog: false, loading: true, CumulativeDialog: false });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeSearch.sortDirection = "ASC";
        if (e.sortOrder === -1) {
            jsonCopy.employeeSearch.sortDirection = "DESC";
        }
        jsonCopy.employeeSearch.sortBy = e.sortField;
        this.getList(jsonCopy);

    }

    // Cell Editors for all columns
    WeekShiftEditors = (props, weekday) => {

        if (props.rowData[weekday] === null) {
            return <div className="elemnetFill" title={translator.employeeDeactivatedForDate} style={{ backgroundColor: leaveCodes.color_Hol }}>-</div>;
        }
        // else if (props.rowData[weekday].shiftCode === leaveCodes.Hol) {
        //     return <div className="elemnetFill" title={props.rowData[weekday].shiftRemarks} style={{ backgroundColor: leaveCodes.color_Hol }}>-</div>;
        // }
        else {
            if (this.state.commentClicked === false) {
                return this.inputTextEditor(props, weekday);
            }
            else {
                return this.colorTemplateweekday(props.rowData, weekday);
            }

        }
    }

    //Function to update shift when editor is opened and clicked any where outside that editor
    onEditorValueChangeOnBlur = (props, field, value) => {
        let temp = value.toUpperCase().split(',');
        let filteredArr = temp.filter((item, index) => temp.indexOf(item) === index);
        let tempString = '';
        filteredArr.map((data, index) => {
            if (index > 0) {
                tempString += ',' + data
            }
            else {
                tempString += data
            }
            return 0;
        })

        value = tempString;
        //Code to handle smilie and other character apperance on bulk operation through NumPad.
        if (value === "☺" || value === "☻" || value === "♥" || value === "♦" || value.includes('☺') || value.includes('☻') || value.includes('♥') || value.includes('♦')) {
            document.getElementById(props.rowData.employeeId).value = null;
            return 0;
        }

        let updatedShifts = [...props.value];
        let arrayOfDatesHavingChanges = [];
        let oldValue = props.rowData[field].shiftCode;
        if (!this.state.isBulkUpdate && (((oldValue !== "" && (value !== "" && oldValue !== value)) || (oldValue !== "" && value === "" && oldValue !== "-" && oldValue !== "Sat")))) {

            let skillsData = props.rowData.skills.split(',');
            let shiftCodes = value.includes(',') ? value.split(',') : value;
            let isValidShift = true;
            if (value.includes(',')) {
                shiftCodes.forEach(shiftCode => {
                    if (skillsData.indexOf(shiftCode) === -1 && !_.includes(leaveCodesArray, shiftCode.toUpperCase())) {
                        isValidShift = false
                    }

                })
            }
            else {
                if (skillsData.indexOf(shiftCodes) === -1 && !_.includes(leaveCodesArray, shiftCodes.toUpperCase()))
                    isValidShift = false
            }
            this.setState({ validShift: isValidShift });

            updatedShifts[props.rowIndex][field].shiftCode = value;
            let jsonCopyshiftAllo = Object.assign({}, this.state.editshiftCodejsonTemplate);
            //Sets jsonpayload 

            jsonCopyshiftAllo.costCenterId = this.state.jsonTemplate.centerMasterID;
            jsonCopyshiftAllo.shiftCodes = updatedShifts[props.rowIndex][field].shiftCode !== '' ? (updatedShifts[props.rowIndex][field].shiftCode.includes(',') ? updatedShifts[props.rowIndex][field].shiftCode.split(',') : [updatedShifts[props.rowIndex][field].shiftCode]) : [];
            arrayOfDatesHavingChanges.push(props.rowData[field].date);
            jsonCopyshiftAllo.date = arrayOfDatesHavingChanges;
            jsonCopyshiftAllo.employeeId = props.rowData.employeeId;
            jsonCopyshiftAllo.planningPeriodId = this.state.jsonTemplate.planningPeriodID;

            this.updateSingleCellData(jsonCopyshiftAllo, oldValue, value, updatedShifts, props, field)

        }
        else if (this.state.isBulkUpdate) {
            this.setState({ isBulkUpdate: false });
        }
    }
    //method to select all text in input box
    handleFocus = (event) => event.target.select();

    inputTextEditor = (props, field) => {
        return <InputText
            id={props.rowData.employeeId}
            className="textUpperCase" defaultValue={(_.includes(weekOff, props.rowData[field].shiftCode) ? "" : props.rowData[field].shiftCode === "-" ? "" : props.rowData[field].shiftCode)}
            type="text"
            // maxLength="14"
            onFocus={this.handleFocus}
            value={props.rowData[field].shiftcode}
            onKeyDown={(e) => this.onEditorValueChange(props, e.target.value, field, e.key, e)}
            onBlur={(e) => this.onEditorValueChangeOnBlur(props, field, e.target.value)} />;

    }

    //Function to open next editor when current editor is open and key like up ,down, tab or enter is pressed
    showNextEditor(empId, field, key) {
        let startindex = _.findIndex(this.state.shiftdata, function (itm) { return empId === itm.employeeId });

        if (startindex !== -1) {
            let Nextdata = undefined;
            if (key === 'Enter' || key === 'ArrowDown') {
                Nextdata = this.state.shiftdata[startindex + 1];
            }
            else if (key === 'ArrowUp') {
                Nextdata = this.state.shiftdata[startindex - 1];
            }

            if (Nextdata !== undefined && field !== undefined && Nextdata[field]) {
                let ctrlId = 'dv' + Nextdata[field].shiftAllocationPlanDetailId;
                document.getElementById(ctrlId).click();
            }
        }
    }

    // to check shifts/skills are avaialalbe for respective rows
    validShiftCheck(rowData, value, key, event) {
        let skillsData = rowData.skills.split(',');
        let shiftCodes = value.includes(',') ? value.split(',') : value;
        let isValidShift = true;

        if (value.includes(',')) {
            shiftCodes.forEach(shiftCode => {
                if (skillsData.indexOf(shiftCode.toUpperCase()) === -1 && !_.includes(leaveCodesArray, shiftCode.toUpperCase())) {
                    isValidShift = false
                }
            })
        }
        else {
            if (skillsData.indexOf(shiftCodes.toUpperCase()) === -1 && !_.includes(leaveCodesArray, shiftCodes.toUpperCase()))
                isValidShift = false
        }
        this.setState({
            displayDialog: false,
            CumulativeDialog: false,
            validShift: isValidShift
        });
    }
    //Function to update shift when editor is opened and enter or tab is pressed
    onEditorValueChange = (props, value, field, key, event) => {

        let temp = value.toUpperCase().split(',');
        let filteredArr = temp.filter((item, index) => temp.indexOf(item) === index);
        let tempString = '';
        filteredArr.map((data, index) => {
            if (index > 0) {
                tempString += ',' + data
            }
            else {
                tempString += data
            }
            return 0;
        })

        value = tempString;

        let oldValue = props.rowData[field].shiftCode;
        let bulkOldValue = [];
        let charCode = String.fromCharCode(event.which).toLowerCase();
        let arrayOfDatesHavingChanges = [];
        let jsonCopyshiftAllo = Object.assign({}, this.state.editshiftCodejsonTemplate);
        let updatedShifts = [...props.value];

        // if(_.includes(keyboardValues,key) && oldValue !== value && oldValue !== "Sat" && !(oldValue=== "-" && value === "") )
        if (_.includes(keyboardValues, key) && oldValue !== value && !(oldValue === "-" && value === "")) {
            // Check if Employee is having the skills or not            
            this.validShiftCheck(props.rowData, value, key, event);

            // Update the new value to state
            updatedShifts[props.rowIndex][field].shiftCode = value;
            this.setState({ displayDialog: false, CumulativeDialog: false, shiftdata: updatedShifts });

            //Sets jsonpayload 
            jsonCopyshiftAllo.costCenterId = this.state.jsonTemplate.centerMasterID;
            jsonCopyshiftAllo.shiftCodes = updatedShifts[props.rowIndex][field].shiftCode !== '' ? (updatedShifts[props.rowIndex][field].shiftCode.includes(',') ? updatedShifts[props.rowIndex][field].shiftCode.split(',') : [updatedShifts[props.rowIndex][field].shiftCode]) : [];
            arrayOfDatesHavingChanges.push(props.rowData[field].date);
            jsonCopyshiftAllo.date = arrayOfDatesHavingChanges;
            jsonCopyshiftAllo.employeeId = props.rowData.employeeId;
            jsonCopyshiftAllo.planningPeriodId = this.state.jsonTemplate.planningPeriodID;

            this.updateSingleCellData(jsonCopyshiftAllo, oldValue, value, updatedShifts, props, field);
        }
        // Bulk Update with hot keys
        else if (event.altKey && (event.keyCode === 49 || event.keyCode === 50 || event.keyCode === 51 || event.keyCode === 52 || event.keyCode === 97 || event.keyCode === 98 || event.keyCode === 99 || event.keyCode === 100 || charCode === '\'')) {

            this.validShiftCheck(props.rowData, value, key, event);

            let startindex = _.findIndex(weekdays, function (weekdata) { return weekdata === field });
            let endindex = 0;

            switch (key) {
                case '1':
                    endindex = 5 + (6 * Math.floor(startindex / 6));
                    break;
                case '2':
                    endindex = 11 + (6 * Math.floor(startindex / 6));
                    break;
                case '3':
                    endindex = 17 + (6 * Math.floor(startindex / 6));
                    break;
                default:
                    endindex = 23;
                    break;
            }

            endindex = endindex > 23 ? endindex = 23 : endindex;

            for (var i = startindex; i <= endindex; i++) {
                //Conditional block executing when employee is not deactivated for that day
                if (props.rowData[weekdays[i]] !== null) {
                    bulkOldValue[i] = props.rowData[weekdays[i]].shiftCode;
                    if (!_.includes(weekOff, props.rowData[weekdays[i]].shiftCode)) {

                        if ((i === startindex && oldValue !== value) || props.rowData[weekdays[i]].shiftCode === "-" || value === "" || props.rowData[weekdays[i]].shiftCode === "NA" || props.rowData[weekdays[i]].shiftCode === "NA,NA" || props.rowData[weekdays[i]].shiftCode === "NA,NA,NA"   ) {
                            // shiftAllocationPlanDetailIdsbulk.push(props.rowData[weekdays[i]].shiftAllocationPlanDetailId);
                            if (props.rowData[weekdays[i]].holiday === false) {
                                arrayOfDatesHavingChanges.push(props.rowData[weekdays[i]].date);
                            }

                            if (value === "") {
                                // index values for sat in cells
                                if (i === 5 || i === 11 || i === 17 || i === 23) {
                                    props.rowData[weekdays[i]].shiftCode = "Sat";
                                }
                                else {
                                    props.rowData[weekdays[i]].shiftCode = "-";
                                }
                            }
                            else {
                                if (!this.state.holidayArray.includes(props.rowData[weekdays[i]].date))
                                    props.rowData[weekdays[i]].shiftCode = value;
                            }
                        }
                    }
                }
                else { //Conditional block executing when employee is deactivated for that day
                    bulkOldValue[i] = '';   //just storing an empty string value for that day 
                }
            }

            //Setting values in global varibales for bulk operation
            bulkUpdateCostCenterMasterID = this.state.jsonTemplate.centerMasterID;
            bulkUpdateValue = value;
            bulkUpdateArrayOfDatesHavingChanges = arrayOfDatesHavingChanges;
            bulkUpdateOldValues = bulkOldValue;
            bulkUpdateEmployeeId = props.rowData.employeeId;
            bulkUpdatePlanningPeriodId = this.state.jsonTemplate.planningPeriodID;
            bulkUpdateStartindex = startindex;
            bulkUpdateEndindex = endindex;
            bulkUpdateProps = props;
            bulkUpdateField = field;

            //Bulk shift delete code block
            if (value === "") {
                this.setState({ displayDeleteCnfDialog: true })
            }
            //Bulk shift Update code block
            else if (value !== "") {

                this.hideDeleteCnfDialogAndDelete();
            }
            this.showNextEditor(Number(event.target.id), field, key);
        }


    }

    //Function to call service to update single cell data, show warning message when employee not trained on a skill and set old value in case of any error
    updateSingleCellData = (payload, oldValue, value, updatedShifts, props, field) => {
        //Calling service for updating shift data of single cell
        this.CommonService.updateShiftAllocation(payload, this.props.browserLanguage)
            .then(res => {
                shiftsCountData = res.data[0].shiftCountMapPerDay;
                //Conditional block which executes when cell value is updated (not deleted)
                if (res.status === 200 && value !== "") {
                    if (!this.state.validShift) {
                        if (!(res.data.filter(item => item.errorMessage !== null).length > 0)) {
                            for (let responseIndex = 0; responseIndex < res.data.length; responseIndex++) {
                                if (props.rowData[field] !== null && props.rowData[field].date === res.data[responseIndex].shiftDate) {
                                    props.rowData[field].shiftRemarks = res.data[responseIndex].shiftDescription;
                                    props.rowData[field].shiftsOnThisDay = res.data[responseIndex].shiftsOnThisDay;
                                    props.rowData[field].shiftCode = res.data[responseIndex].shiftCode;
                                    break;
                                }
                            }
                            this.setState({});
                            this.showWarningMessage(translator.skillNottrained);
                            return false;
                        }
                    }
                    this.updateCellFromResponse(res.data, props.rowData, oldValue, 0, 23, false);
                    this.setState({ validShift: false });
                }
                //Conditional block which executes when cell value is deleted
                else {
                    //Conditional block which executes when there is even a single error message in the response
                    if (res.data.filter(item => item.errorMessage !== null).length > 0) {
                        this.updateCellFromResponse(res.data, props.rowData, oldValue, 0, 23, false);
                        this.setState({ validShift: false });
                        return false;
                    }
                    this.updateCellFromResponse(res.data, props.rowData, oldValue, 0, 23, false);
                    this.setState({ validShift: false });
                    this.showDeletedMessage();
                }
            })
            .catch(err => {
                updatedShifts[props.rowIndex][field].shiftCode = oldValue;
                this.setState({
                    displayDialog: false,
                    CumulativeDialog: false,
                    loading: false,
                    shiftdata: updatedShifts
                });
                this.showMultiple(err);
            })
    }

    //Function to update shift code on Bulk
    UpdateBulkData = (payload, rowData, oldValue, value, startindex, endindex, field) => {

        this.setState({ isBulkUpdate: true });
        this.CommonService.updateShiftAllocation(payload, this.props.browserLanguage)
            .then(res => {
                shiftsCountData = res.data[0].shiftCountMapPerDay;
                if (res.status === 200 && value !== "") {

                    if (!this.state.validShift) {
                        if (!(res.data.filter(item => item.errorMessage !== null).length > 0)) {
                            // for (let responseIndex = 0; responseIndex < res.data.length; responseIndex++) {
                            //     if (rowData[field].date === res.data[responseIndex].shiftDate) {
                            //         rowData[field].shiftRemarks = res.data[responseIndex].shiftDescription;
                            //         rowData[field].shiftsOnThisDay = res.data[responseIndex].shiftsOnThisDay;
                            //         break;
                            //     }

                            // } //old code
                            this.updateCellFromResponse(res.data, rowData, oldValue, startindex, endindex, true);
                            this.showWarningMessage(translator.skillNottrained);
                            return false;
                        }
                    }

                    this.setState({ isBulkUpdate: true });
                    this.updateCellFromResponse(res.data, rowData, oldValue, startindex, endindex, true);
                    this.setState({ loading: false, validShift: false });
                }
                else {
                    if (res.data.filter(item => item.errorMessage !== null).length > 0) {
                        this.setState({ isBulkUpdate: true });
                        this.updateCellFromResponse(res.data, rowData, oldValue, startindex, endindex, true);
                        this.setState({ loading: false, validShift: false });
                        return false;
                    }
                    this.updateCellFromResponse(res.data, rowData, oldValue, startindex, endindex, true);
                    this.showDeletedMessage();
                    this.setState({ loading: false, validShift: false, isBulkUpdate: true });
                }
            })
            .catch(err => {
                this.setOldValues(rowData, oldValue, startindex, endindex);
                this.setState({
                    displayDialog: false,
                    CumulativeDialog: false,
                    isBulkUpdate: true,
                    loading: false
                });

                this.showMultiplewithScroll(err);
            })
    }

    // to set the old values for each respective cells if api is sending the error 
    setOldValues = (rowData, oldValue, startindex, endindex) => {
        for (var icount = startindex; icount <= endindex; icount++) {
            rowData[weekdays[icount]].shiftCode = oldValue[icount];
        }
    }

    // Function to update values in correspoding cells based on response in single or bulk update operation
    updateCellFromResponse = (response, rowData, oldValue, startindex, endindex, isBulkUpdateFlag) => {
        let sundayWeekdays = ['week1day7', 'week2day7', 'week3day7', 'week4day7']; //Array of sunday weekdays
        //Here 'rowData' variable holds data for only one employee on which user is performing operation
        if (response.length > 0) //Checks if response in not empty
        {
            //Called function to show error growl with list of invalid error messages
            this.showInvalidRecordwithScroll(response);

            //Iterating over each json object in response
            for (var errorindex = 0; errorindex < response.length; errorindex++) {
                //Conditional block executing when that json object does not has an error message
                if (response[errorindex].errorMessage === null) {
                    //Block to set new value on Sunday from Api response
                    sundayWeekdays.forEach(sundayWeekday => {
                        if (rowData[sundayWeekday] !== null && response[0].shiftDate === rowData[sundayWeekday].date) {
                            rowData[sundayWeekday].shiftCode = response[0].shiftCode;
                            rowData[sundayWeekday].shiftRemarks = response[0].shiftDescription;
                        }
                    })
                    //Iterating over each day of planning period for the target employee
                    for (let i = 0; i < weekdays.length; i++) {
                        if (rowData[weekdays[i]] !== null && rowData[weekdays[i]].date === response[errorindex].shiftDate) {
                            rowData[weekdays[i]].shiftCode = response[errorindex].shiftCode;
                            rowData[weekdays[i]].shiftRemarks = response[errorindex].shiftDescription;
                            rowData[weekdays[i]].shiftsOnThisDay = response[errorindex].shiftsOnThisDay;
                        }
                    }
                }

                else    //Conditional block executing when that json object has an error message
                {
                    //Block to set old value on Sunday in case of error form api
                    sundayWeekdays.forEach(sundayWeekday => {
                        if (rowData[sundayWeekday] !== null && response[0].shiftDate === rowData[sundayWeekday].date) {
                            rowData[sundayWeekday].shiftCode = oldValue;
                        }
                    })

                    //Iterating over each day of planning period for the target employee
                    for (var shiftindex1 = startindex; shiftindex1 <= endindex; shiftindex1++) {
                        // if(rowData[weekdays[shiftindex1]].shiftDate ===response[errorindex].shiftDate){  //old code
                        //Conditional block executing when date in row data matches with json object in response
                        if (rowData[weekdays[shiftindex1]] !== null && rowData[weekdays[shiftindex1]].date === response[errorindex].shiftDate) {
                            if (isBulkUpdateFlag) {
                                //Sets old shift code when bulk cells update
                                rowData[weekdays[shiftindex1]].shiftCode = oldValue[shiftindex1];
                            }
                            else {
                                //Sets old shift code when single cell update
                                rowData[weekdays[shiftindex1]].shiftCode = oldValue;
                            }
                            break;
                        }
                    }
                }

            }
        }
    }


    ///Functions to show Error,Warnings and Success
    showWarningMessage = (errorMessage) => {
        this.setState({ growlWithScroll: false });
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'warn', summary: translator.warningMessage, detail: errorMessage });
    }
    showSuccessMessage = () => {
        this.setState({ growlWithScroll: false });
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'success', summary: translator.successMessage, detail: translator.shiftUpdateSucess });
    }
    showDeletedMessage = () => {
        this.setState({ growlWithScroll: false });
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'success', summary: translator.successMessage, detail: translator.shiftDeleteSucess });
    }
    showMultiple = (errorBody) => {
        this.setState({ growlWithScroll: false });
        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });
    }
    showSuccessMessageBorrow = () => {
        this.setState({ growlWithScroll: false });
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'success', summary: translator.successMessage, detail: translator.employeeBorrowedSuccess });
    }
    showSuccessMessageSingle = (message) => {
        this.setState({ growlWithScroll: false });
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'success', summary: translator.successMessage, detail: message });
    }
    showError = (err) => {
        this.setState({ growlWithScroll: false });
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: err });
    }
    showSuccessMessageUnborrow = () => {
        this.setState({ growlWithScroll: false });
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'success', summary: translator.successMessage, detail: translator.employeeUnborrowedSuccess });
    }

    showSuccessMessageCommentSave = () => {
        this.setState({ growlWithScroll: false });
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'success', summary: translator.successMessage, detail: translator.commentSaveSuccess });
    }

    showMultiplewithScroll = (errorBody) => {
        this.setState({ growlWithScroll: true });
        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });
    }

    showInvalidRecordwithScroll = (errorBody) => {
        this.setState({ growlWithScroll: true });
        errorBody = errorBody.filter(item => item.errorMessage !== null);
        var detail = errorBody.map((item, key) => {
            return (<li key={key}>{item.errorMessage}</li>)
        })
        if (errorBody.length > 0) {
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });
        }
    }


    // TO print shift allocation report in pdf.
    printPDF = () => {
        this.setState({ CumulativeDialog: false, displayDialog: false, printCnfDialog: true });
    }

    //open pop up for borrow Employee
    borrowEmployee = () => {
        let jsonCopy = Object.assign({}, this.state.getborrowJson);
        jsonCopy.employeeSearch.recordCount = 150;
        this.setState({ CumulativeDialog: false, displayDialog: true });
    }
    //handle CumulativeSearch data
    handleCumulativeSearch = () => {
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeSearch.recordCount = 150;
        this.setState({ CumulativeDialog: true, displayDialog: false });
    }

    //Function to set default filters
    clearFilterControl = () => {
        if (document.getElementById('employeeSkills')) {
            document.getElementById('employeeSkills').value = ''
        }
        if (document.getElementById('employeeName')) {
            document.getElementById('employeeName').value = ''
        }
        if (document.getElementById('employeeTypeCode')) {
            document.getElementById('employeeTypeCode').value = ''
        }
        if (document.getElementById('employeeGroupNameFilterTextField')) {
            document.getElementById('employeeGroupNameFilterTextField').value = ''
        }

    }

    //function call when cost center selected
    onCostCenterSelect = (data, data1) => {
        if (this.growl !== undefined && this.growl !== null) {
            this.growl.clear();
            // this.growlScroll.clear();
        }
        this.clearFilterControl();
        if (data1 === '' || data === '') {
            shiftsCountData = {};
            this.setState({
                shiftdata: null,
                copyCheckbox : false,
                week1: false,
                week2: false,
                week3: false,
                week4: false,
                days: [],
                dates: [],
                dateString: [],
                shiftWeeks: [],
                pageNumber: 0,
                pageSize: 0,
                displayDialog: false,
                CumulativeDialog: false,
                totalShiftAllocatedRecords: undefined,
                jsonTemplate: {
                    planningPeriodID: "",
                    centerMasterID: null,
                    exceptionWeeks: [],
                    exceptionDates: [],
                    employeeGroupName: [],
                    employeeSearch: {
                        supervisorId: "",
                        costCenterCode: "",
                        employeeId: "",
                        employeeName: "",
                        employeeTypeCode: "",
                        sortBy: "employeeName",
                        sortDirection: "ASC",
                        pageNumber: 0,
                        recordCount: 150,
                        shiftCodeList: ""
                    }
                }
            });
            ReactDOM.unmountComponentAtNode(document.getElementById('costCenter'));
            ReactDOM.render(<CostCenter onChange={this.onCostCenterSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />, document.getElementById('costCenter'))
            return false;
        }

        this.setState({
            costCenterCode: data,
            copyCheckbox : false,
            shiftdata: null,
            centerMasterID: data1,
            removefilterdisable: true,
            displayDialog: false, CumulativeDialog: false,
            week1: false,
            week2: false,
            week3: false,
            week4: false,
        });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeSearch.costCenterCode = data;
        jsonCopy.centerMasterID = data1;
        jsonCopy.planningPeriodID = this.state.planningPeriodID;
        jsonCopy.employeeIds = [];
        jsonCopy.employeeGroupName = [];
        jsonCopy.employeeSearch.shiftCodeList = '';
        jsonCopy.employeeSearch.employeeName = '';
        jsonCopy.employeeSearch.employeeTypeCode = '';
        jsonCopy.exceptionWeeks = [];
        jsonCopy.exceptionDates = [];
        if (this.state.costCenterCode !== '' && this.state.planningPeriodID !== undefined) {

            this.setState({ loading: true, displayDialog: false, CumulativeDialog: false });
            this.getList(jsonCopy);
            this.setState({ jsonTemplate: jsonCopy });
        }

    }
    //Function which call when planning period selcted
    onPlanningPeriodSelect = (data) => {
        if (this.growl !== undefined && this.growl !== null) {
            this.growl.clear();
            // this.growlScroll.clear();
        }
        this.setState({
            planningPeriodID: data,
            copyCheckbox : false,
            shiftdata: null,
            week1: false,
            week2: false,
            week3: false,
            week4: false,
        })

        this.CommonService.getCopyPP(this.props.browserLanguage, data)
            .then(res => {
                copyPPDropdownData = res;
                this.setState({});
            })
            .catch(err => {
                this.setState({ loading: false, displayDialog: false, CumulativeDialog: false });
                this.showMultiple(err);
            })

        if (this.state.costCenterCode !== undefined && this.state.planningPeriodID !== '') {
            this.setState({
                loading: true,
                displayDialog: false,
                CumulativeDialog: false
            });

            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.centerMasterID = this.state.centerMasterID;
            jsonCopy.planningPeriodID = data;
            jsonCopy.exceptionWeeks = [];
            jsonCopy.exceptionDates = [];
            jsonCopy.employeeGroupName = [];
            jsonCopy.employeeSearch.employeeName = "";
            jsonCopy.employeeSearch.employeeTypeCode = "";
            jsonCopy.employeeSearch.shiftCodeList = "";

            this.getList(jsonCopy);
            this.setState({ jsonTemplate: jsonCopy, displayDialog: false, CumulativeDialog: false })
        }
    }

    //Function to chnage data while changing no of record   
    noOfRecordChange = (e) => {
        this.setState({
            noOfRecords: e.value.code,
            dropdownDisplay: e.value,
            loading: true,
            first: 0,
            displayDialog: false,
            CumulativeDialog: false
        })

        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeSearch.recordCount = e.value.code;
        jsonCopy.employeeSearch.pageNumber = 0;

        this.getList(jsonCopy);

        this.setState({ jsonTemplate: jsonCopy });

    }

    //Function to print exception report
    exceptionWeek = (event, weekNo) => {

        this.setState({ loading: true });
        switch (weekNo) {
            case 'week1':
                this.setState({ week1: event.checked });
                break;

            case 'week2':
                this.setState({ week2: event.checked });
                break;

            case 'week3':
                this.setState({ week3: event.checked });
                break;

            case 'week4':
                this.setState({ week4: event.checked });
                break;
            default:
                break;
        }

        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        if (event.checked) {
            jsonCopy.exceptionWeeks.push(event.value);
        }
        else {
            jsonCopy.exceptionWeeks.splice(jsonCopy.exceptionWeeks.indexOf(event.value), 1);
        }
        jsonCopy.exceptionWeeks.sort();
        this.setState({ jsonTemplate: jsonCopy });

        this.getList(jsonCopy)

    }

    exceptionDay = (event, dayDate) => {

        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        let checkedDatesCopy = this.state.checkedDates;
        //Shows loader
        this.setState({ loading: true });
        //Add or remove date for sending as request body
        if (event.checked) {
            jsonCopy.exceptionDates.push(dayDate);
            checkedDatesCopy.push(dayDate);
        }
        else {
            jsonCopy.exceptionDates.splice(jsonCopy.exceptionDates.indexOf(dayDate), 1);
            checkedDatesCopy.splice(checkedDatesCopy.indexOf(dayDate), 1);
        }
        jsonCopy.exceptionDates.sort();
        //Add or remove date for marking checked and unchecked
        this.setState({ jsonTemplate: jsonCopy, checkedDates: checkedDatesCopy });
        this.getList(jsonCopy);
    }
    
    //Function to implement functionality of Wrapping Long Text With "..."
    nameTemplate = (rowData, column) => {
        /** Set tooltip value for employee name column as cccode appended with ccname 
         * for borrowed employees otherwise show only employee name.
         * For other fields, show value*/
  
        let title = column.field === 'employeeName' ?
            column.rowData.borrowed ? rowData[column.field] + "\n" + column.rowData.homeCostCenterCodeAndName : 
            // rowData[column.field]
            column.rowData.borrowedInOtherCC ? rowData[column.field] + "\n" + column.rowData.borrowedInOtherCCNames : rowData[column.field]
            : rowData[column.field]
        return (<div className="extraTextWrappingShitAllocation" title={title} style={{ float: column.field === 'hrsPerWeek' ? 'right' : 'unset' }}>
            {rowData[column.field]}
            {(column.field === 'employeeName' && column.rowData.borrowed ) || (column.field === 'employeeName' && column.rowData.borrowedInOtherCC) ?
                <i className="pi pi-user-minus" onClick={(e) => { unborrowEmployeeId = column.rowData.employeeId; this.setState({ displayUnborrowCnfDialog: column.rowData.borrowedInOtherCC ? false : true }) }} style={{ float: 'right', cursor: 'pointer' }}></i>
                : null
            }
        </div>
        );
    }

    skillsTemplate = (rowData, column) => {

        let title = `${translator.skillTooltip}\n`;
        
        if(rowData['autoGenskills'] !== ""){
            title = title.concat(rowData['autoGenskills'])
        }
        if(rowData[column.field] !== '' && rowData['autoGenskills'] !== "" ){
            title = title.concat('  |  ')
        }
        if(rowData[column.field] !== ''){
            title = title.concat(rowData[column.field])
        }

        return (<div className="extraTextWrappingShitAllocation" title={title}>
            
                {rowData['autoGenskills'] !== "" ? <span style={{ fontWeight : 'bold' }}>{`${rowData['autoGenskills']}`}</span> : null} 
                {rowData['autoGenskills'] !== "" && rowData[column.field] !== "" ? " | " :null}
                {rowData[column.field]}                               
           
        </div>
        );
    }

    //Function to hide delete confirm dialog and set delete confirm flag false
    hideDeleteCnfDialog = () => {
        this.setState({ loading: false, CumulativeDialog: false, displayDialog: false, isBulkUpdate: true, displayDeleteCnfDialog: false });
        this.setOldValues(bulkUpdateProps.rowData, bulkUpdateOldValues, bulkUpdateStartindex, bulkUpdateEndindex, bulkUpdateField);
    }

    //Function to hide delete confirm dialog and set delete confirm flag true
    hideDeleteCnfDialogAndDelete = () => {
        this.setState({ displayDeleteCnfDialog: false })
        let jsonCopyshiftAllo = Object.assign({}, this.state.editshiftCodejsonTemplate);

        jsonCopyshiftAllo.costCenterId = bulkUpdateCostCenterMasterID;
        jsonCopyshiftAllo.shiftCodes = bulkUpdateValue !== '' ? (bulkUpdateValue.includes(',') ? bulkUpdateValue.split(',') : [bulkUpdateValue]) : [];
        jsonCopyshiftAllo.date = bulkUpdateArrayOfDatesHavingChanges;
        jsonCopyshiftAllo.employeeId = bulkUpdateEmployeeId;
        jsonCopyshiftAllo.planningPeriodId = bulkUpdatePlanningPeriodId;
        this.setState({ CumulativeDialog: false, displayDialog: false, isBulkUpdate: true });

        //call the function for DELETE operation
        this.UpdateBulkData(jsonCopyshiftAllo, bulkUpdateProps.rowData, bulkUpdateOldValues, bulkUpdateValue, bulkUpdateStartindex, bulkUpdateEndindex, bulkUpdateField);
    }

    //Function to show help dialog
    showHelpPopup = () => {
        this.setState({ displayHelpDialog: true });
    }

    //Function to check if pattern of haitta input is matched or not
    checkHaittaInputPattern = (event) => {
        var inputValue = event.target.value;
        var numberKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
        if (this.props.browserLanguage === 'en') {
            //Conditional block to prevernt entering of ,
            if (event.key === ",")
                event.preventDefault();

            //Conditional block executes when already a . is present in entered value and one more . is trying to be entered
            if ((event.key === ",")  //prevents , to be entered
                || (inputValue.length === 0 && event.key === ".")  //when input is empty doesn`t allow .
                || (inputValue.indexOf('.') === -1 && inputValue.length === 3 && numberKeys.indexOf(event.key) >= 0)  //when 3 numbers entered before decimal doesn`t allow more number to enter before decimal
                || (inputValue.indexOf('.') > -1 && event.key === ".") //prevents more than one . to be entered
                || ((inputValue.indexOf('.') > -1 && inputValue.split('.')[1].length === 1) && numberKeys.indexOf(event.key) >= 0)) //when pattern is completed doesn`t allow more number to be entered
            {
                //prevents keyboard button value as input
                event.preventDefault();
            }
        }
        else    //when language is finish
        {
            //Conditional block to prevernt entering of ,
            if (event.key === ".")
                event.preventDefault();

            //Conditional block executes when already a , is present in entered value and one more , is trying to be entered
            if ((event.key === ".")  //prevents , to be entered
                || (inputValue.length === 0 && event.key === ",")  //when input is empty doesn`t allow ,
                || (inputValue.indexOf(',') === -1 && inputValue.length === 3 && numberKeys.indexOf(event.key) >= 0)  //when 3 numbers entered before decimal doesn`t allow more number to enter before decimal
                || (inputValue.indexOf(',') > -1 && event.key === ",") //prevents more than one . to be entered
                || ((inputValue.indexOf(',') > -1 && inputValue.split(',')[1].length === 1) && numberKeys.indexOf(event.key) >= 0)) //when pattern is completed doesn`t allow more number to be entered
            {
                //prevents keyboard button value as input
                event.preventDefault();
            }
        }
    }

    //Function to check haitta pattern and update haitta input when input is valid and matches pattern
    updateHaittaValue = (event, oldHaittaValue, weeklyHaittaAndCommentId, weeklyComment, weekStartDate, newHaittaValue, costCenterMasterId, indexInShiftWeeksArray, targetHaittaTxtBoxId) => {

        if (_.includes(keyboardValues, event.key)) //check if tab or enter is pressed in haitta input box
        {
            //Set paylod for updating haitta value
            let jsonCopySaveWeeklyCommentAndHaittaInputJsonTemplate = Object.assign({}, this.state.saveWeeklyCommentAndHaittaInputJsonTemplate);
            jsonCopySaveWeeklyCommentAndHaittaInputJsonTemplate.weeklyHaittaAndCommentId = weeklyHaittaAndCommentId;
            jsonCopySaveWeeklyCommentAndHaittaInputJsonTemplate.weeklyComment = weeklyComment;
            jsonCopySaveWeeklyCommentAndHaittaInputJsonTemplate.weekStartDate = weekStartDate;
            //Pre-processing haitta value before sending to prevent values like 25. to be saved , having . at end
            //Always saving haitta value with ., if , present then replacing , with .
            let tempHaittaValueStoreForPrcessing = this.props.browserLanguage === 'en' ? newHaittaValue : newHaittaValue.replace(",",".");
            //Removing last . if haitta value ends with .
            tempHaittaValueStoreForPrcessing = (tempHaittaValueStoreForPrcessing.includes('.') && tempHaittaValueStoreForPrcessing.split('.')[1].length === 0) ? tempHaittaValueStoreForPrcessing.substring(0, tempHaittaValueStoreForPrcessing.length - 1) : tempHaittaValueStoreForPrcessing;
            jsonCopySaveWeeklyCommentAndHaittaInputJsonTemplate.weeklyHaitta = tempHaittaValueStoreForPrcessing;
            jsonCopySaveWeeklyCommentAndHaittaInputJsonTemplate.costCenterMasterId = costCenterMasterId;

            this.setState({ loading: true });
            //Calling service to update haitta value
            this.CommonService.updateWeeklyCommentAndHaittaInput(jsonCopySaveWeeklyCommentAndHaittaInputJsonTemplate, this.props.browserLanguage)
                .then(res => {
                    this.state.shiftWeeks.map((item, index) => {
                        if (index === indexInShiftWeeksArray) {
                            if (item.weeklyCommentAndHaittaInput !== null) //Updating haitta value when already value in state
                                item.weeklyCommentAndHaittaInput.weeklyHaitta = res.weeklyHaitta; //Update new haitta value in state after successful save operation in db
                            if (item.weeklyCommentAndHaittaInput === null) //Update haitta value after successful save operation in db
                                item.weeklyCommentAndHaittaInput = res;
                            return item;
                        }
                        return item;
                    })
                    //Set the updated value on UI in haitta text box
                    if (document.getElementById(targetHaittaTxtBoxId))
                        document.getElementById(targetHaittaTxtBoxId).value = this.props.browserLanguage === 'en' ? res.weeklyHaitta : res.weeklyHaitta.replace(".",",");
                    this.setState({ loading: false });
                })
                .catch(err => {
                    this.state.shiftWeeks.map((item, index) => {
                        if (index === indexInShiftWeeksArray) {
                            if (item.weeklyCommentAndHaittaInput !== null) //Setting old haitta value when already value in state
                                item.weeklyCommentAndHaittaInput.weeklyHaitta = oldHaittaValue; //Update new haitta value in state after successful save operation in db
                            return item;
                        }
                        return item;
                    })
                    //Set the updated value on UI in haitta text box
                    if (document.getElementById(targetHaittaTxtBoxId))
                        document.getElementById(targetHaittaTxtBoxId).value = oldHaittaValue;
                    this.setState({ loading: false });
                    this.showMultiple(err);
                })
        }
        else {
            this.checkHaittaInputPattern(event)
        }
    }

    //Function to set weekly dialog content and show weekly comment dialog
    createWeeklyDialogContentAndShowWeeklyDialog = (event, weeklyHaittaAndCommentId, weeklyComment, weekStartDate, weeklyHaitta, costCenterMasterId, indexInShiftWeeksArray) => {
        //Sets value of the clicked week in text field
        if (document.getElementById('weeklyCommentDialogTextField') != null)
            document.getElementById('weeklyCommentDialogTextField').value = weeklyComment;
        newEnteredComment = weeklyComment;
        weeklyDialogContent = <div id='weeklyCommentDialogInnerContent'>
            <InputText id='weeklyCommentDialogTextField' defaultValue={weeklyComment} maxLength={weeklyCommentLength} onChange={(e) => newEnteredComment = e.target.value} />
        </div>;
        weeklyDialogFooter = <Button id='updateWeekCommentBtn' className="btnDefault" label={translator.updateWeekComment} onClick={(e) => { this.updateWeeklyCommentValue(e, weeklyHaittaAndCommentId, weeklyComment, newEnteredComment, weekStartDate, weeklyHaitta, costCenterMasterId, indexInShiftWeeksArray) }} />
        this.setState({ isWeeklyCommentDialogVisible: true });
    }

    //Function to call service to update weekly comment
    updateWeeklyCommentValue = (event, weeklyHaittaAndCommentId, oldWeeklyComment, newWeeklyComment, weekStartDate, weeklyHaitta, costCenterMasterId, indexInShiftWeeksArray) => {
        //Set paylod for updating haitta value
        let jsonCopySaveWeeklyCommentAndHaittaInputJsonTemplate = Object.assign({}, this.state.saveWeeklyCommentAndHaittaInputJsonTemplate);
        jsonCopySaveWeeklyCommentAndHaittaInputJsonTemplate.weeklyHaittaAndCommentId = weeklyHaittaAndCommentId;
        jsonCopySaveWeeklyCommentAndHaittaInputJsonTemplate.weeklyComment = newWeeklyComment;
        jsonCopySaveWeeklyCommentAndHaittaInputJsonTemplate.weekStartDate = weekStartDate;
        jsonCopySaveWeeklyCommentAndHaittaInputJsonTemplate.weeklyHaitta = weeklyHaitta;
        jsonCopySaveWeeklyCommentAndHaittaInputJsonTemplate.costCenterMasterId = costCenterMasterId;

        this.setState({ loading: true, isWeeklyCommentDialogVisible: false });
        //Calling service to update haitta value
        this.CommonService.updateWeeklyCommentAndHaittaInput(jsonCopySaveWeeklyCommentAndHaittaInputJsonTemplate, this.props.browserLanguage)
            .then(res => {
                this.state.shiftWeeks.map((item, index) => {
                    if (index === indexInShiftWeeksArray) {
                        if (item.weeklyCommentAndHaittaInput !== null) //Updating haitta value when already value in state
                            item.weeklyCommentAndHaittaInput.weeklyComment = res.weeklyComment; //Update new haitta value in state after successful save operation in db
                        if (item.weeklyCommentAndHaittaInput === null) //Update haitta value after successful save operation in db
                            item.weeklyCommentAndHaittaInput = res;
                        return item;
                    }
                    return item;
                })
                //Clear the variable storing new comments
                newEnteredComment = '';
                this.setState({ loading: false });
            })
            .catch(err => {
                this.state.shiftWeeks.map((item, index) => {
                    if (index === indexInShiftWeeksArray) {
                        if (item.weeklyCommentAndHaittaInput !== null) //Setting old haitta value when already value in state
                            item.weeklyCommentAndHaittaInput.weeklyComment = oldWeeklyComment; //Update new haitta value in state after successful save operation in db
                        return item;
                    }
                    return item;
                })
                //Clear the variable storing new comments
                newEnteredComment = '';
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }

    //Function to call service to unborrow an employee
    unBorrowEmployee = (employeeID) => {
        //Set paylod for unborrowing employee
        let jsonCopyUnborrowEmployeeJsonTemplate = Object.assign({}, this.state.unborrowEmployeeJsonTemplate);
        jsonCopyUnborrowEmployeeJsonTemplate.costCenterId = this.state.jsonTemplate.centerMasterID;
        jsonCopyUnborrowEmployeeJsonTemplate.employeeId = employeeID;
        jsonCopyUnborrowEmployeeJsonTemplate.planningPeriodId = this.state.jsonTemplate.planningPeriodID;

        this.setState({ loading: true });
        //Calling service to unborrow employee
        this.CommonService.unborrowEmployee(jsonCopyUnborrowEmployeeJsonTemplate, this.props.browserLanguage)
            .then(res => {
                //Conditional block executed when employee is unborrowed successfully
                if (res.status === 200) {
                    this.showSuccessMessageUnborrow();
                    this.getList(this.state.jsonTemplate);
                    //loading false is being done in get list function so not written explicitly
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }

    //Function to call service to get leave codes and descriptio
    callServiceToGetLeaveCodeAndDescription = () => {
        this.CommonService.getLeaveCodeAndDescription(this.props.browserLanguage)
            .then(res => {
                this.setState({ leaveCodesAndDescriptionsArray: res })
                //Call function to create inner content of help popup
                this.createHelpPopupContent();
            })
            .catch(err => {
                this.showMultiple(err);
            })
    }

    //Function to create help popup content
    createHelpPopupContent = () => {
        if (this.state.leaveCodesAndDescriptionsArray !== []) { //when data about leave codes received successfully
            helpPopupInnerContent =
                <React.Fragment>
                    <DataTable id='shiftCodesDescriptionTable' value={this.state.leaveCodesAndDescriptionsArray.leaveCodes}>
                        <Column field="shiftCode" header="Leave Code" body={this.createHelpPopupLeaveCodesCell} className="text-center" style={{ width: '90px', padding: '0' }} />
                        <Column field="description" header="Description" />
                    </DataTable>
                    <br />
                    <DataTable id='shiftCodesDescriptionTable' value={this.state.leaveCodesAndDescriptionsArray.reservedCodes}>
                        <Column field="shiftCode" header="Reserved Code" body={this.createHelpPopupLeaveCodesCell} className="text-center" style={{ width: '90px', padding: '0' }} />
                        <Column field="description" header="Description" />
                    </DataTable>
                </React.Fragment>
        }
        else { //when data about leave codes is not received
            helpPopupInnerContent = <div id="helpPopupInnerContetn">
                {translator.errorLeaveCodesDesc}
                <i className="pi pi-replay" onClick={this.callServiceToGetLeaveCodeAndDescription} style={{ cursor: 'pointer' }}></i>
            </div>
        }
    }

    //Function to set colors for cell in help popup based on shift codes
    createHelpPopupLeaveCodesCell = (rowData, column) => {
        if (rowData.shiftCode.toLowerCase() === leaveCodes.VL.toLowerCase()) {
            return <div id={'leavecode' + rowData.shiftCode} className="elementFillForHelpDatatable textUpperCase" title={rowData.shiftCode} style={{ backgroundColor: leaveCodes.color_VL }}>{rowData.shiftCode}</div>
        }
        else if (rowData.shiftCode.toLowerCase() === leaveCodes.VL2.toLowerCase()) {    //Sets color when VL2 is entered
            return <div id={'leavecode' + rowData.shiftCode} className="elementFillForHelpDatatable textUpperCase" title={rowData.shiftCode} style={{ backgroundColor: leaveCodes.color_VL2 }}>{rowData.shiftCode}</div>
        }
        else if (rowData.shiftCode.toLowerCase() === leaveCodes.PO.toLowerCase()) {    //Sets color when PO is entered
            return <div id={'leavecode' + rowData.shiftCode} className="elementFillForHelpDatatable textUpperCase" title={rowData.shiftCode} style={{ backgroundColor: leaveCodes.color_PO }}>{rowData.shiftCode}</div>
        }
        else if (rowData.shiftCode.toLowerCase() === leaveCodes.JV.toLowerCase()) {    //Sets color when JV is entered
            return <div id={'leavecode' + rowData.shiftCode} className="elementFillForHelpDatatable textUpperCase" title={rowData.shiftCode} style={{ backgroundColor: leaveCodes.color_JV }}>{rowData.shiftCode}</div>
        }
        else if (rowData.shiftCode.toLowerCase() === leaveCodes.LO.toLowerCase()) {    //Sets color when LO is entered
            return <div id={'leavecode' + rowData.shiftCode} className="elementFillForHelpDatatable textUpperCase" title={rowData.shiftCode} style={{ backgroundColor: leaveCodes.color_LO }}>{rowData.shiftCode}</div>
        }
        else if (rowData.shiftCode.toLowerCase() === leaveCodes.TV.toLowerCase()) {    //Sets color when TV is entered
            return <div id={'leavecode' + rowData.shiftCode} className="elementFillForHelpDatatable textUpperCase" title={rowData.shiftCode} style={{ backgroundColor: leaveCodes.color_TV }}>{rowData.shiftCode}</div>
        }
        else if (rowData.shiftCode.toLowerCase() === leaveCodes.P.toLowerCase()) {    //Sets color when P is entered
            return <div id={'leavecode' + rowData.shiftCode} className="elementFillForHelpDatatable textUpperCase" title={rowData.shiftCode} style={{ backgroundColor: leaveCodes.color_P }}>{rowData.shiftCode}</div>
        }
        else if (reservedCodesArray.indexOf(rowData.shiftCode.toUpperCase()) !== -1) { //Sets color when code is reserved code
            return <div id={'leavecode' + rowData.shiftCode} className="elementFillForHelpDatatable textUpperCase" title={rowData.shiftCode} style={{ backgroundColor: leaveCodes.color_reservedCode, color: 'white' }}>{rowData.shiftCode}</div>
        }
        else {
            return <div id={'leavecode' + rowData.shiftCode} className="elementFillForHelpDatatable textUpperCase" title={rowData.shiftCode} style={{}}>{rowData.shiftCode}</div>
        }
    }

    //Function to create daily comment overlay and show it
    showDailyCommentOverlay = (event, rowData, weekday) => {

        this.op1.hide();
        //Creates daily comment overlay content
        dailyCommentOverlayContent = <div id="dailyCommentOverlayInnerContent" className="text-center">
            {
                rowData[weekday].shiftsOnThisDay.map((shiftDetails, index) => {

                    return (

                        <div className='dailyCommentOverlayInnerContentDiv'>
                            {index !== 0 ? <hr /> : null}
                            {
                                shiftDetails.shiftCode !== "-" ?
                                    <div className='dailyCommentOverlayInnerContentShiftCodeLabel'>
                                        {shiftDetails.shiftCode}
                                    </div>
                                    : null
                            }
                            <div className='dailyCommentOverlayInnerContentShiftCommentTextBox'>
                                <input 
                                    key={shiftDetails.shiftAllocationPlanDetailId} 
                                    autoFocus={index === 0 ? true : false} 
                                    id={'commentTextBox' + shiftDetails.shiftAllocationPlanDetailId} 
                                    defaultValue={shiftDetails.shiftComment !== null ? shiftDetails.shiftComment : ''} 
                                    onKeyDown={(e) => this.updateDailyComment(e, rowData, weekday, shiftDetails.shiftCode)} />
                            </div>
                        </div>
                    )
                })
            }
        </div>
        this.setState({ loading: false, commentClicked: true });
        //Opens daily comment overlay
        this.op1.show(event)
    }

    //Funtion to update daily comment
    updateDailyComment = (event, rowData, weekday, currentShiftCode) => {
        //Conditional block executed when enter or tab is pressed
        if (_.includes(keyboardValues, event.key)) {
            let jsonCopySaveDailyCommentJSONTemplate = Object.assign({}, this.state.saveDailyCommentJSONTemplate);
            jsonCopySaveDailyCommentJSONTemplate.empDetailsId = rowData.employeeId;
            jsonCopySaveDailyCommentJSONTemplate.shiftDate = rowData[weekday].date;
            jsonCopySaveDailyCommentJSONTemplate.costCenterMasterId = this.state.jsonTemplate.centerMasterID;
            jsonCopySaveDailyCommentJSONTemplate.plaaningPeriodId = this.state.jsonTemplate.planningPeriodID;
            //Setting old comment values in jsontemplate copy
            let oldShiftComments = rowData[weekday].shiftsOnThisDay.map((shiftObject, index) => {
                return ({
                    shiftCode: shiftObject.shiftCode,
                    userComment: shiftObject.shiftComment !== null ? shiftObject.shiftComment : ''
                })
            })
            //updating oldcomment varibale with new value
            oldShiftComments.map((shiftCommentObject, item) => {
                if (shiftCommentObject.shiftCode === currentShiftCode)
                    shiftCommentObject.userComment = event.target.value;
                return shiftCommentObject;
            })
            jsonCopySaveDailyCommentJSONTemplate.empShiftComment = oldShiftComments;
            this.callServiceToUpdateDailyComment(jsonCopySaveDailyCommentJSONTemplate, rowData, weekday, currentShiftCode, event.target.value);
        }
    }

    //Funtion to call service to update daily comment and set old comment in case of error
    callServiceToUpdateDailyComment = (jsonCopySaveDailyCommentJSONTemplate, rowData, weekday, currentShiftCode, newComment) => {
        this.setState({ loading: true, commentClicked: false });
        //Caliing service to update daily comment
        this.CommonService.saveDailyComment(jsonCopySaveDailyCommentJSONTemplate, this.props.browserLanguage)
            .then(res => {
                if (res !== null && res.status === 200) {
                    //override old values with new values
                    rowData[weekday].shiftsOnThisDay.map((shiftObject, index) => {
                        document.getElementById('dailyCommentOverlay').style.display = 'none';
                        this.showSuccessMessageCommentSave();
                        if (shiftObject.shiftCode === currentShiftCode)
                            shiftObject.shiftComment = newComment
                        return shiftObject;
                    })
                }
                this.setState({ loading: false });
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }

    //Function to execute when yes button is pressed in Confirmation Dialog
    onYesFromConfirmDialog = (action) => {
        //Execute when confirmation dialog was opened for unborrowing
        if (action === 'unborrow')
            this.unBorrowEmployee(unborrowEmployeeId);

        this.setState({ displayUnborrowCnfDialog: false });
    }

    onYesFromPrintConfirmDialog = (printPdfWithTimeFlag) =>{
        this.setState({loading:true, printCnfDialog :false})
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeIds = [];
        jsonCopy.shiftWithStartTime = printPdfWithTimeFlag;
        this.CommonService.exportShiftAllocationForPeriodPDF(jsonCopy, this.props.browserLanguage)
            .then(
                response => {
                    let fileName = '';
                    let planningPeriodindex = _.findIndex(this.props.masterData.planningPeriodList, function (planItem) { return planItem.planningPerioId === jsonCopy.planningPeriodID })
                    if (planningPeriodindex !== -1) {
                        let weekArr = this.props.masterData.planningPeriodList[planningPeriodindex].planningPeriodDescription.split(' ');
                        fileName = jsonCopy.employeeSearch.costCenterCode + "_Week" + weekArr[0].split('/')[1] + weekArr[1] + weekArr[2].split('/')[1] + "_" + moment().format('YYYY-MM-DD-hh-mm');

                        var blob = new Blob([response.data], { type: "application/pdf" });
                        var reader = new FileReader();
                        reader.onloadend = function () {
                            FileSaver.saveAs(blob, fileName);
                        }
                        reader.readAsText(blob);
                    }
                    this.setState({ loading: false });
                }
            )
            .catch(err => {
                this.setState({ loading: false, displayDialog: false, CumulativeDialog: false, printCnfDialog: false });
                this.showMultiple(err);
            })
    }

    //Function to execute when no button is pressed in Confirmation Dialog
    onNoFromConfirmDialog = () => {
        this.setState({ displayUnborrowCnfDialog: false, printCnfDialog: false });
    }
    getCopyShiftAllocation = (copyPlanningPeriodId) => {
        this.setState({ loading: true });
        let jsonCopy = Object.assign({}, this.state.copyShiftAllocationJSON);

        if (this.state.copyCheckbox) {
            jsonCopy.oldPlanningPeriodID = null;
            jsonCopy.currentPlanningPeriodID = null;
            jsonCopy.centerMasterID = this.state.jsonTemplate.centerMasterID;
            jsonCopy.copyEntireYear = true;
            jsonCopy.sourceYear = this.state.copyFromYear;
            jsonCopy.targetYear = copyPlanningPeriodId;
        }
        else {
            jsonCopy.copyEntireYear = false;
            jsonCopy.sourceYear = null;
            jsonCopy.targetYear = null;
            jsonCopy.oldPlanningPeriodID = copyPlanningPeriodId;
            jsonCopy.currentPlanningPeriodID = this.state.jsonTemplate.planningPeriodID;
            jsonCopy.centerMasterID = this.state.jsonTemplate.centerMasterID;
            // jsonCopy.employeeSearch.costCenterCode = this.state.jsonTemplate.employeeSearch.costCenterCode;
        }

        this.setState({ copyShiftAllocationJSON: jsonCopy });
        this.getListForCopyAllocation(jsonCopy);
    }

    autoGenerateShift = () => {
        let jsonCopy = Object.assign({}, this.state.autoGenerateShiftTemplate);
        jsonCopy.planningPeriodId = this.state.jsonTemplate.planningPeriodID;
        jsonCopy.costCenterId = this.state.jsonTemplate.centerMasterID;
        jsonCopy.timeToWait = this.state.timeToWait;

        this.setState({ loading: true });
        this.setState({ autoGenerateShiftTemplate: jsonCopy })

        this.CommonService.createManualAutoGenrateShifts(jsonCopy, this.props.browserLanguage)
            .then(res => {
                this.showSuccessMessageSingle(res.data);
                this.setState({ timeToWait: "", loading: false })
                document.getElementById("timeToWait").value = "";
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }

    render() {
        if (document.getElementsByClassName('p-datatable-emptymessage') !== undefined && document.getElementsByClassName('p-datatable-emptymessage').length > 0 && document.getElementsByClassName('p-datatable-emptymessage')[1] !== undefined) {
            document.getElementsByClassName('p-datatable-emptymessage')[1].style.color = "transparent";
            document.getElementsByClassName('p-datatable-emptymessage')[1].firstChild.style.borderLeft = "0 none";
        }

        translator.setLanguage(this.props.browserLanguage);
        var weekName = [
            translator.monday,
            translator.tuesday,
            translator.wednesday,
            translator.thursday,
            translator.friday,
            translator.saturday,
            translator.sunday
        ];
        const noOfRecord = [
            { ShiftAllocatedData: translator.Records_150, code: 150 },
            { ShiftAllocatedData: translator.Records_200, code: 200 },
            { ShiftAllocatedData: translator.Records_250, code: 250 }
        ];

        let employeeSkillsFilter = <InputText onChange={(e) => { this.filterCleared(e, "employeeSkills") }} id="employeeSkills" className="employeeIdFilterWidth" onKeyDown={(e) => this.onEmployeeSkillsFilter(e.target.value, e.key)} />
        let employeeNameFilter = <InputText onChange={(e) => { this.filterCleared(e, "employeeName") }} id="employeeName" className="employeeIdFilterWidth" onKeyDown={(e) => this.onkeydownonemployeeNameFilter(e.target.value, e.key)} />
        let employeeTypeFilter = <InputText onChange={(e) => { this.filterCleared(e, "employeeTypeCode") }} id="employeeTypeCode" className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeTypeFilter(e.target.value, e.key)} />
        let employeeGroupNameFilter = <InputText onChange={(e) => { this.filterCleared(e, "employeeGroupName") }} id="employeeGroupNameFilterTextField" className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeGroupNameFilter(e.target.value, e.key)} />

        //Current Week Header
        let frozenHeaderGroup = <ColumnGroup>
            {
                this.state.shiftdata ?

                    <Row>
                        <Column colSpan={5} header={translator.employeeDetails} className="EmpDetailWeekHeader" style={{ height: '56px', width: '714px' }}></Column>
                    </Row> : null
            }
            {
                this.state.shiftdata ?
                    <Row>
                        <Column field="employeeName" header={translator.employeeName} rowSpan={3} className="EmployeeDetailsHeader" sortable={true} filter={true} filterElement={employeeNameFilter} style={{ width: '150px !important' }} />
                        <Column field="employeeType" header={translator.employeeTypeCode} rowSpan={3} className="EmployeeDetailsHeader" sortable={true} filter={true} filterElement={employeeTypeFilter} style={{ width: '150px !important' }} />
                        <Column field="skills" header={translator.employeeSkills} rowSpan={3} className="EmployeeDetailsHeader paddingtop" filter={true} filterElement={employeeSkillsFilter} style={{ width: '150px !important' }} />
                        <Column field="groupName" header={translator.groupName} rowSpan={3} className="EmployeeDetailsHeader paddingtop" filter={true} filterElement={employeeGroupNameFilter} style={{ width: '150px !important' }} />
                        <Column field="hrsPerWeek" header={translator.hrsPerWeek} rowSpan={3} className="EmployeeDetailsHeader paddingtop" style={{ width: '114px !important' }} />
                    </Row> : null
            }
        </ColumnGroup>
        let headerGroup = <ColumnGroup>
            {
                this.state.shiftdata ?
                    <Row>
                        {this.state.shiftWeeks.map((item, key) => {

                            if (key === 0) {
                                return (
                                    <Column
                                        key={key}
                                        header={<div>
                                            <Checkbox value="1" onChange={e => this.exceptionWeek(e, "week1")} checked={this.state.week1}></Checkbox>{item.week}
                                            <i className="pi pi-comment" onClick={(e) => { this.createWeeklyDialogContentAndShowWeeklyDialog(e, item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyHaittaAndCommentId : '0', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : '', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weekStartDate : (this.state.dateString !== [] ? this.state.dateString[0] : ''), item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyHaitta : '', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.costCenterMasterId : this.state.jsonTemplate.centerMasterID, key) }} style={{ float: 'right', cursor: 'pointer' }}></i>
                                            <InputText id={'haittaTxtBox' + key} className="haitta-textbox" defaultValue={item.weeklyCommentAndHaittaInput !== null ? (this.props.browserLanguage === 'en' ? item.weeklyCommentAndHaittaInput.weeklyHaitta : item.weeklyCommentAndHaittaInput.weeklyHaitta.replace(/\./g, ",")) : ''} maxLength="5" keyfilter={/^\-?\d*(\.|,)?\d*$/} onKeyDown={(e) => { this.updateHaittaValue(e, item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyHaitta : '', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyHaittaAndCommentId : '0', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : '', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weekStartDate : (this.state.dateString !== [] ? this.state.dateString[0] : ''), e.target.value, item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.costCenterMasterId : this.state.jsonTemplate.centerMasterID, key, 'haittaTxtBox' + key)}} disabled={this.props.masterData.admin ?  false : true} /> WTI
                                <div
                                                title={item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : ''}
                                                className="extraTextWrapping"
                                                style={{ padding: '0.54em 0em', fontWeight: '500' }}>
                                                {item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : ''}
                                            </div>
                                        </div>}
                                        body={this.navigationTemplate} colSpan={7} className="WeekHeader" />)
                            }
                            else if (key === 1) {
                                return (
                                    <Column
                                        key={key}
                                        header={<div>
                                            <Checkbox value="2" onChange={e => this.exceptionWeek(e, "week2")} checked={this.state.week2}></Checkbox>{item.week}
                                            <i className="pi pi-comment" onClick={(e) => { this.createWeeklyDialogContentAndShowWeeklyDialog(e, item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyHaittaAndCommentId : '0', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : '', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weekStartDate : (this.state.dateString !== [] ? this.state.dateString[7] : ''), item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyHaitta : '', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.costCenterMasterId : this.state.jsonTemplate.centerMasterID, key) }} style={{ float: 'right', cursor: 'pointer' }}></i>
                                            <InputText id={'haittaTxtBox' + key} className="haitta-textbox" defaultValue={item.weeklyCommentAndHaittaInput !== null ? (this.props.browserLanguage === 'en' ? item.weeklyCommentAndHaittaInput.weeklyHaitta : item.weeklyCommentAndHaittaInput.weeklyHaitta.replace(/\./g, ",")) : ''} maxLength="5" keyfilter={/^\-?\d*(\.|,)?\d*$/} onKeyDown={(e) => { this.updateHaittaValue(e, item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyHaitta : '', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyHaittaAndCommentId : '0', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : '', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weekStartDate : (this.state.dateString !== [] ? this.state.dateString[7] : ''), e.target.value, item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.costCenterMasterId : this.state.jsonTemplate.centerMasterID, key, 'haittaTxtBox' + key) }} disabled={this.props.masterData.admin ?  false : true} /> WTI
                                <div
                                                title={item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : ''}
                                                className="extraTextWrapping"
                                                style={{ padding: '0.54em 0em', fontWeight: '500' }}>{item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : ''}
                                            </div>
                                        </div>}
                                        body={this.navigationTemplate} colSpan={7} className="WeekHeader" />)
                            }
                            else if (key === 2) {
                                return (
                                    <Column
                                        key={key}
                                        header={<div>
                                            <Checkbox value="3" onChange={e => this.exceptionWeek(e, "week3")} checked={this.state.week3}></Checkbox>{item.week}
                                            <i className="pi pi-comment" onClick={(e) => { this.createWeeklyDialogContentAndShowWeeklyDialog(e, item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyHaittaAndCommentId : '0', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : '', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weekStartDate : (this.state.dateString !== [] ? this.state.dateString[14] : ''), item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyHaitta : '', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.costCenterMasterId : this.state.jsonTemplate.centerMasterID, key) }} style={{ float: 'right', cursor: 'pointer' }}></i>
                                            <InputText id={'haittaTxtBox' + key} className="haitta-textbox" defaultValue={item.weeklyCommentAndHaittaInput !== null ? (this.props.browserLanguage === 'en' ? item.weeklyCommentAndHaittaInput.weeklyHaitta : item.weeklyCommentAndHaittaInput.weeklyHaitta.replace(/\./g, ",")) : ''} maxLength="5" keyfilter={/^\-?\d*(\.|,)?\d*$/} onKeyDown={(e) => { this.updateHaittaValue(e, item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyHaitta : '', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyHaittaAndCommentId : '0', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : '', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weekStartDate : (this.state.dateString !== [] ? this.state.dateString[14] : ''), e.target.value, item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.costCenterMasterId : this.state.jsonTemplate.centerMasterID, key, 'haittaTxtBox' + key) }} disabled={this.props.masterData.admin ?  false : true} /> WTI
                                <div
                                                title={item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : ''}
                                                className="extraTextWrapping"
                                                style={{ padding: '0.54em 0em', fontWeight: '500' }}>{item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : ''}
                                            </div>
                                        </div>}
                                        body={this.navigationTemplate} colSpan={7} className="WeekHeader" />)
                            }
                            else {
                                return (
                                    <Column
                                        key={key}
                                        header={<div>
                                            <Checkbox value="4" onChange={e => this.exceptionWeek(e, "week4")} checked={this.state.week4}></Checkbox>{item.week}
                                            <i className="pi pi-comment" onClick={(e) => { this.createWeeklyDialogContentAndShowWeeklyDialog(e, item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyHaittaAndCommentId : '0', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : '', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weekStartDate : (this.state.dateString !== [] ? this.state.dateString[21] : ''), item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyHaitta : '', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.costCenterMasterId : this.state.jsonTemplate.centerMasterID, key) }} style={{ float: 'right', cursor: 'pointer' }}></i>
                                            <InputText id={'haittaTxtBox' + key} className="haitta-textbox" defaultValue={item.weeklyCommentAndHaittaInput !== null ? (this.props.browserLanguage === 'en' ? item.weeklyCommentAndHaittaInput.weeklyHaitta : item.weeklyCommentAndHaittaInput.weeklyHaitta.replace(/\./g, ",")) : ''} maxLength="5" keyfilter={/^\-?\d*(\.|,)?\d*$/} onKeyDown={(e) => { this.updateHaittaValue(e, item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyHaitta : '', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyHaittaAndCommentId : '0', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : '', item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weekStartDate : (this.state.dateString !== [] ? this.state.dateString[21] : ''), e.target.value, item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.costCenterMasterId : this.state.jsonTemplate.centerMasterID, key, 'haittaTxtBox' + key) }} disabled={this.props.masterData.admin ?  false : true} /> WTI
                                <div
                                                title={item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : ''}
                                                className="extraTextWrapping"
                                                style={{ padding: '0.54em 0em', fontWeight: '500' }}>{item.weeklyCommentAndHaittaInput !== null ? item.weeklyCommentAndHaittaInput.weeklyComment : ''}
                                            </div>
                                        </div>}
                                        body={this.navigationTemplate} colSpan={7} className="WeekHeader" />)
                            }

                        })}
                    </Row>

                    : null
            }
            {
                this.state.shiftdata ?
                    <Row>
                        {
                            this.state.dateString.map((date, index) => {

                                return (<Column key={date.slice(-2)} header={<div title={this.state.holidayArray.includes(date) ? translator.holiday : null}><Checkbox value={this.state.dateString[index]} onChange={e => this.exceptionDay(e, this.state.dateString[index])} checked={this.state.checkedDates.includes(this.state.dateString[index])} ></Checkbox>{date.slice(-2)} </div>} style={this.state.holidayArray.includes(date) ? { width: '70px', backgroundColor: leaveCodes.color_holidayHeader } : { width: '70px' }} className="dateHeader" />)

                            })
                        }

                    </Row>
                    : null
            }
            {
                this.state.shiftdata ?
                    <Row >

                        {weekName.map(day => {
                            return (<Column key={day} header={day} style={{ width: '70px' }} className="DaysHeader" />)
                        })}
                        {weekName.map(day => {
                            return (<Column key={day} header={day} style={{ width: '70px' }} className="DaysHeader" />)
                        })}
                        {weekName.map(day => {
                            return (<Column key={day} header={day} style={{ width: '70px' }} className="DaysHeader" />)
                        })}
                        {weekName.map(day => {
                            return (<Column key={day} header={day} style={{ width: '70px' }} className="DaysHeader" />)
                        })}
                    </Row>
                    : null
            }

        </ColumnGroup>;

        return (

            <div className="bodyColor">
                {/*Dialog for showing weekly comments and editing it*/}
                <Dialog id="weeklyCommentDialog" header={translator.weekComment} footer={weeklyDialogFooter} visible={this.state.isWeeklyCommentDialogVisible} style={{ width: '50vw' }} onHide={() => this.setState({ isWeeklyCommentDialogVisible: false })}>
                    {weeklyDialogContent}
                </Dialog>

                {/*Popup for showing daily comments and editing it*/}
                <OverlayPanel id="dailyCommentOverlay" ref={(el1) => this.op1 = el1} onHide={() => this.setState({ commentClicked: false })}>
                    {dailyCommentOverlayContent}
                </OverlayPanel>
                

                <Growl ref={(el) => this.growl = el} position="topright" className={this.state.growlWithScroll ? "shiftGrowlScroll" : "shiftGrowl"}></Growl>

                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>
                {this.state.displayDeleteCnfDialog ? <DeleteConfirmationDialog callbackDeleteService={this.hideDeleteCnfDialogAndDelete} lang={this.props.browserLanguage} callbackProp={this.hideDeleteCnfDialog} screen="shiftallocation" /> : null}
                {this.state.printCnfDialog ? <ConfirmationDialog callbackForYes={this.onYesFromPrintConfirmDialog} lang={this.props.browserLanguage} callbackForNo={this.onNoFromConfirmDialog} action="printPDF" /> : null}
                {this.state.displayUnborrowCnfDialog ? <ConfirmationDialog callbackForYes={action => this.onYesFromConfirmDialog(action)} lang={this.props.browserLanguage} callbackForNo={this.onNoFromConfirmDialog} action="unborrow" /> : null}
                {/* <Breadcrumbs initial={translator.allocation} final={translator.navbarShiftAllocation} /> */}
                <Accordion activeIndex={0} style={{ marginLeft: '1%', marginTop: '-1%', width: '99%' }}>
                    <AccordionTab header={`${translator.allocation} > ${translator.navbarShiftAllocation}`}>
                        <div >


                            <div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="CostCenterDiv" id="costCenter">
                                                <CostCenter lastUsedCostCenter={this.state.lastUsedCostCenter} onChange={this.onCostCenterSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />
                                            </td>

                                            <td className="PlanningPeriodDiv">
                                                <PlanningPeriod lastUsedPlanningPeriod={this.state.lastUsedPlanningPeriod} onChange={this.onPlanningPeriodSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />
                                            </td>
                                            <td className={this.props.browserLanguage === 'en' ? "floatRightShiftAllocation floatRightShiftAllocationEN" : "floatRightShiftAllocation floatRightShiftAllocationFI"}>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="paddingRight">
                                                                {this.state.shiftdata ?
                                                                    <div>
                                                                        {!this.state.removefilterdisable ?
                                                                            <Button className="clearFilterBtn marginTop" title={translator.clearAdvanceFilter} label="X" onClick={() => this.removeAdvanceFilter()} />
                                                                            : null}
                                                                        <Button className="btnDefault marginTop" icon="pi pi-filter" label={translator.advanceFilter} onClick={() => this.handleCumulativeSearch()} />
                                                                    </div>
                                                                    : null}
                                                            </td>
                                                            <td className="paddingRight">
                                                                {(this.state.shiftdata === null ? false : this.state.shiftdata.length > 0) ?
                                                                    <div>
                                                                        <Button className="btnDefault marginTop" icon="pi pi-print" label={translator.printPDF} onClick={(e) => this.printPDF(e)} />
                                                                    </div>
                                                                    : null}
                                                            </td>
                                                            <td>
                                                                {this.state.shiftdata ?
                                                                    <div>
                                                                        <Button className="btnDefault marginTop" icon="pi pi-user-plus" title={translator.borrowEmployee} label={translator.borrow} onClick={() => this.borrowEmployee()} />
                                                                    </div>
                                                                    : null}
                                                            </td>
                                                            <td>
                                                                {this.state.shiftdata ?
                                                                    <div>
                                                                        <Button id='helpBtn' className="btnDefault marginTop" icon="pi pi-question" title={translator.help} label={translator.help} onClick={(e) => this.op.toggle(e)} />
                                                                        <OverlayPanel id='helpButtonOverlay' ref={(el) => this.op = el} dismissable={true} >
                                                                            {helpPopupInnerContent}
                                                                        </OverlayPanel>
                                                                    </div>
                                                                    : null}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        {this.state.shiftdata ?
                            <div>
                                <div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="CostCenterDiv">
                                                    <strong style={{ marginLeft: '16px', marginRight: '2%' }}>{translator.copyEntireYear}</strong>
                                                    <Checkbox style={{ marginTop: '0.8%' }} onChange={e => this.setState({ copyCheckbox: e.checked, copyFromYear: null, copyToYear: null })} checked={this.state.copyCheckbox}></Checkbox>
                                            
                                                    {/* <label style={{ marginLeft: '15px', fontWeight: 'bold' }}>{translator.timeToWait}&nbsp;:&nbsp;</label>
                                                    <InputText placeholder={translator.min} style={{ width: '10%' }} id="timeToWait" keyfilter="pint" defaultValue={this.state.timeToWait} onChange={(e) => { this.setState({ timeToWait: e.target.value }) }} />
                                                    <Button className="btnDefault" label={translator.autoGenerate} style={{ marginLeft: "10px", padding: "2px" }} onClick={this.autoGenerateShift} /> */}
                                                    
                                                </td>

                                                <td className="PlanningPeriodDiv" id="copyPlanningPeriodDiv">
                                                    {
                                                        this.state.copyCheckbox ?
                                                            <div className={ this.props.browserLanguage === 'en' ? "CopyDropdownEN dropDwonDefault" : "CopyDropdownFI dropDwonDefault" }>
                                                                <strong>{translator.copyFrom} : </strong>
                                                                <Dropdown
                                                                    value={this.state.copyFromYear}
                                                                    options={copyFromYearDropDown()}
                                                                    placeholder={translator.selectYear}
                                                                    className="dropDwonDefault planningPeriodDropdown"
                                                                    onChange={(e) => { this.setState({ copyFromYear: e.value }) }}
                                                                ></Dropdown>
                                                            </div>

                                                            : <CopyPlanningPeriod displayLabel={translator.copyFrom} onCallback={this.getCopyShiftAllocation} style={{ marginTop: "20px !important" }} lastUsedPlanningPeriod="" browserLanguage={this.props.browserLanguage} ppData={copyPPDropdownData.length > 0 ? copyPPDropdownData : null} />
                                                    }

                                                </td>
                                                {
                                                    this.state.copyCheckbox ?
                                                        <td className="PlanningPeriodDiv" id="copyPlanningPeriodDiv">
                                                            <div 
                                                                style={this.props.browserLanguage === 'en' ? { width: '75%', marginLeft: '31%' } : { width: '75%', marginLeft: '17%' }}
                                                                className={ this.props.browserLanguage === 'en' ? "CopyDropdownEN dropDwonDefault" : "CopyDropdownFI dropDwonDefault" }
                                                            >
                                                                <strong>{translator.copyTo} :</strong>
                                                                <Dropdown
                                                                    value={this.state.copyToYear}
                                                                    placeholder={translator.selectYear}
                                                                    disabled={this.state.copyFromYear ? false : true}
                                                                    className="dropDwonDefault planningPeriodDropdown"
                                                                    options={copyToYearDropDown(this.state.copyFromYear)}
                                                                    onChange={(e) => { this.setState({ copyToYear: e.value }); this.getCopyShiftAllocation(e.value) }}
                                                                ></Dropdown>
                                                            </div>
                                                        </td>
                                                    : null}
                                                <td className="floatLeftShiftAllocation">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                {/* <td className="paddingRight">
                                                                    <Button style={{ display: 'none' }} className="btnDefault marginLeftButton marginTopCopyButton" />
                                                                </td>
                                                                <td className="paddingRight">
                                                                    <Button style={{ display: 'none' }} className="btnDefault marginTopCopyButton" />
                                                                </td> */}

                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="CostCenterDiv">
                                                    <label style={{ marginLeft: '15px', fontWeight: 'bold' }}>{translator.timeToWait}&nbsp;:&nbsp;</label>
                                                    <InputText placeholder={translator.min} style={{ width: '10%' }} id="timeToWait" keyfilter="pint" defaultValue={this.state.timeToWait} onChange={(e) => { this.setState({ timeToWait: e.target.value }) }} />
                                                    <Button className="btnDefault" label={translator.autoGenerate} style={{ marginLeft: "10px", padding: "2px" }} onClick={this.autoGenerateShift} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div> : null}
                    </AccordionTab>
                </Accordion>

                {this.state.shiftdata ?
                    this.state.totalShiftAllocatedRecords === 0 && (document.getElementById('employeeName') !== null ? (document.getElementById('employeeName').value === '' && document.getElementById('employeeTypeCode').value === '' && document.getElementById('employeeSkills').value === '' && document.getElementById('employeeGroupNameFilterTextField').value === '') && this.state.loading === false : true) ?
                        null :
                        <div className="paginatorShiftAllo" >
                            <span className="recordOF">{this.state.totalShiftAllocatedRecords === undefined || this.state.totalShiftAllocatedRecords === 0 ? '0-0' : (this.state.pageNumber * this.state.noOfRecords) + 1 + '-' + Number((this.state.pageNumber * this.state.noOfRecords) + Number((this.state.pageSize)))} {translator.of} {this.state.totalShiftAllocatedRecords === undefined ? 0 : this.state.totalShiftAllocatedRecords} {translator.records}</span>
                            <div className="paginatorEmp">
                                <Paginator rows={this.state.noOfRecords} totalRecords={this.state.totalShiftAllocatedRecords === undefined ? 0 : this.state.totalShiftAllocatedRecords} first={this.state.first} onPageChange={(e) => this.onPageChange(e)}></Paginator>
                                <Dropdown value={this.state.dropdownDisplay} className="dropDwonDefault colRecord dropmargin" options={noOfRecord} onChange={(e) => this.noOfRecordChange(e)} placeholder={translator.Records_150} optionLabel="ShiftAllocatedData" />
                            </div>
                        </div>
                    : null}
                {this.state.shiftdata ?
                    this.state.totalShiftAllocatedRecords === 0 && (document.getElementById('employeeName') !== null ? (document.getElementById('employeeName').value === '' && document.getElementById('employeeTypeCode').value === '' && document.getElementById('employeeSkills').value === '' && document.getElementById('employeeGroupNameFilterTextField').value === '' && this.state.jsonTemplate.exceptionDates.length === 0 && this.state.jsonTemplate.exceptionWeeks.length === 0) && this.state.loading === false : true) ?
                        <h4 className="noRecordFoundMessage">{translator.noRecordFound}</h4> :
                        <div className="ShiftAllocationTable">
                            <DataTable
                                value={this.state.shiftdata}
                                scrollable={browser().name === 'ie' ? false : true}
                                paginatorPosition="top"
                                headerColumnGroup={headerGroup}
                                frozenHeaderColumnGroup={frozenHeaderGroup}
                                editable={true}
                                selectionMode="multiple"
                                rows={this.state.jsonTemplate.recordCount}
                                onSort={(e) => this.onSortChange(e)}
                                scrollHeight={getResolutionShiftAllocation()}
                                frozenWidth="714px"
                                // unfrozenWidth="790px"
                                resizableColumns={true}
                                className="dataTableShiftAllocation"
                                sortField={this.state.sortField}
                                sortOrder={this.state.sortOrder}
                                emptyMessage={translator.noRecordFound}
                            >
                                <Column field="employeeName" frozen={true} sortable={true} className="EmployeeNameColShiftAllo" filter={true} filterElement={employeeNameFilter} body={this.nameTemplate} style={{ width: '150px' }} />
                                <Column field="employeeType" frozen={true} sortable={true} className="EmpTypeColShiftAllo" filter={true} filterElement={employeeTypeFilter} style={{ width: '150px' }} />
                                <Column field="skills" frozen={true} className="EmpSkillsColShiftAllo" filter={true} filterElement={employeeSkillsFilter} body={this.skillsTemplate} style={{ width: '150px' }} />
                                <Column field="groupName" frozen={true} className="EmpGroupNameColShiftAllo" filter={true} filterElement={employeeGroupNameFilter} body={this.nameTemplate} style={{ width: '150px' }} />
                                <Column field="hrsPerWeek" frozen={true} sortable={true} className="EmpGroupNameColShiftAllo" body={this.nameTemplate} style={{ width: '114px' }} />
                                <Column field="week1day1.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week1day1')} body={(e) => this.colorTemplateweekday(e, 'week1day1')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week1day2.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week1day2')} body={(e) => this.colorTemplateweekday(e, 'week1day2')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week1day3.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week1day3')} body={(e) => this.colorTemplateweekday(e, 'week1day3')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week1day4.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week1day4')} body={(e) => this.colorTemplateweekday(e, 'week1day4')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week1day5.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week1day5')} body={(e) => this.colorTemplateweekday(e, 'week1day5')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week1day6.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week1day6')} body={(e) => this.colorTemplateweekday(e, 'week1day6')} className="SatColShiftAllo" style={{ backgroundColor: leaveCodes.color_weekend, width: '70px' }} />
                                <Column field="week1day7.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week1day7')} body={(e) => this.colorTemplateweekday(e, 'week1day7')} className="MonToFriColShiftAllo" style={{ backgroundColor: leaveCodes.color_weekend, width: '70px' }} />
                                <Column field="week2day1.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week2day1')} body={(e) => this.colorTemplateweekday(e, 'week2day1')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week2day2.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week2day2')} body={(e) => this.colorTemplateweekday(e, 'week2day2')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week2day3.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week2day3')} body={(e) => this.colorTemplateweekday(e, 'week2day3')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week2day4.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week2day4')} body={(e) => this.colorTemplateweekday(e, 'week2day4')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week2day5.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week2day5')} body={(e) => this.colorTemplateweekday(e, 'week2day5')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week2day6.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week2day6')} body={(e) => this.colorTemplateweekday(e, 'week2day6')} className="SatColShiftAllo" style={{ backgroundColor: leaveCodes.color_weekend, width: '70px' }} />
                                <Column field="week2day7.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week2day7')} body={(e) => this.colorTemplateweekday(e, 'week2day7')} className="MonToFriColShiftAllo" style={{ backgroundColor: leaveCodes.color_weekend, width: '70px' }} />
                                <Column field="week3day1.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week3day1')} body={(e) => this.colorTemplateweekday(e, 'week3day1')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week3day2.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week3day2')} body={(e) => this.colorTemplateweekday(e, 'week3day2')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week3day3.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week3day3')} body={(e) => this.colorTemplateweekday(e, 'week3day3')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week3day4.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week3day4')} body={(e) => this.colorTemplateweekday(e, 'week3day4')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week3day5.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week3day5')} body={(e) => this.colorTemplateweekday(e, 'week3day5')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week3day6.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week3day6')} body={(e) => this.colorTemplateweekday(e, 'week3day6')} className="SatColShiftAllo" style={{ backgroundColor: leaveCodes.color_weekend, width: '70px' }} />
                                <Column field="week3day7.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week3day7')} body={(e) => this.colorTemplateweekday(e, 'week3day7')} className="MonToFriColShiftAllo" style={{ backgroundColor: leaveCodes.color_weekend, width: '70px' }} />
                                <Column field="week4day1.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week4day1')} body={(e) => this.colorTemplateweekday(e, 'week4day1')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week4day2.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week4day2')} body={(e) => this.colorTemplateweekday(e, 'week4day2')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week4day3.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week4day3')} body={(e) => this.colorTemplateweekday(e, 'week4day3')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week4day4.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week4day4')} body={(e) => this.colorTemplateweekday(e, 'week4day4')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week4day5.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week4day5')} body={(e) => this.colorTemplateweekday(e, 'week4day5')} style={{ width: '70px' }} className="MonToFriColShiftAllo" />
                                <Column field="week4day6.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week4day6')} body={(e) => this.colorTemplateweekday(e, 'week4day6')} className="SatColShiftAllo" style={{ backgroundColor: leaveCodes.color_weekend, width: '70px' }} />
                                <Column field="week4day7.shiftCode" editor={(e) => this.WeekShiftEditors(e, 'week4day7')} body={(e) => this.colorTemplateweekday(e, 'week4day7')} className="MonToFriColShiftAllo" style={{ backgroundColor: leaveCodes.color_weekend, width: '70px' }} />
                            </DataTable>
                            <br />
                        </div>
                    : null}
                {(this.props.masterData === null ? false : this.props.masterData.admin) && this.state.shiftdata ?
                    this.state.totalShiftAllocatedRecords === 0 && (document.getElementById('employeeName') !== null ? (document.getElementById('employeeName').value === '' && document.getElementById('employeeTypeCode').value === '' && document.getElementById('employeeSkills').value === '' && document.getElementById('employeeGroupNameFilterTextField').value === '') && this.state.loading === false : true) ?
                        null :
                        <BaselineAllocationPlan disableFeedFile={this.state.disableFeedFile} browserLanguage={this.props.browserLanguage} planningPeriodID={this.state.jsonTemplate.planningPeriodID} centerMasterID={this.state.jsonTemplate.centerMasterID} createBaselineEmp={this.props.masterData.employeeType} loading={this.state.loading} />
                    : null}
                {this.state.displayDialog ?

                    <BorrowEmpDialog
                        browserLanguage={this.props.browserLanguage}
                        borrowjsonTemplate={this.state.getborrowJson}
                        displayDialog={true}
                        planningPeriodID={this.state.jsonTemplate.planningPeriodID}
                        centerMasterID={this.state.jsonTemplate.centerMasterID}
                        onChange={this.callbackFromDialog}
                        loading={this.state.loading} />
                    : null}
                {this.state.CumulativeDialog ?
                    <AdvanceFilterShiftAllocation
                        browserLanguage={this.props.browserLanguage}
                        onHideDialog={this.hideCumulativeDialog}
                        onSelectedEmp={this.handleCumulativeSelection}
                        costCenterCode={this.state.costCenterCode}
                        jsonTemplate={this.state.jsonTemplate}
                        removeClicked={removeClicked}
                        CumulativeDialog={true}
                    />
                    : null}

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        masterData: state.masterData,
        browserLanguage: state.browserLanguage
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftAllocation); 
