import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import { Column } from 'primereact/column';
import { CommonService } from '../../service/CommonService';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Growl } from 'primereact/growl';
import { Paginator } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { keyboardValues } from "../../utility/commonUtility";
import _ from 'lodash';
import './ShiftAllocation.css';
import leaveCodes from '../../utility/leaveCodes.json';

let translator = new LocalizedStrings(translation);
let empids = [];
let selectedCumulativeData = [];

class AdvanceFilterCopyShift extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            disableOk: true,
            disablefilter: true,
            getAdvanceFilterData: null,
            CumulativeData: {
                exceptionWeeks: [],
                exceptionDates: [],
                employeeIds: [],
                employeeGroupName: [],
                copyFromLogId: 1,
                employeeSearch: {
                    supervisorId: "",
                    costCenterCode: "1",
                    employeeId: "",
                    employeeName: "",
                    employeeTypeCode: "",
                    sortBy: "employeeName",
                    sortDirection: "ASC",
                    pageNumber: 0,
                    recordCount: 150,
                    shiftCodeList: ""
                }
            }
            , AdvanceFilterDialog: false
        }
        this.CommonService = new CommonService();

    }
    getAdvanceFilterList = (payload) => {
        this.CommonService.getCumulativeSearchForCopyShift(payload, this.props.browserLanguage)
            .then(data => {
                this.setState({
                    getAdvanceFilterData: data.content,
                    totalEmployeeRecords: data.totalRecords,
                    pageNumber: data.pageNumber,
                    pageSize: data.shiftPageSize,
                    recordSize: data.totalRecords,
                    loading: false,
                    AdvanceFilterDialog: true
                })
            })
            .catch(err => {
                this.setState({
                    loading: false, displayDialog: false,
                    CumulativeDialog: false,
                    AdvanceFilterDialog: false
                });
                this.showMultiple(err);
            })
    }

    //Function Called when component first loads
    componentDidMount = () => {

        if (this.props.removeClicked === true) {
            selectedCumulativeData = [];
        }

        let jsonCopy = Object.assign({}, this.props.jsonTemplate);
        if (this.props.jsonTemplate.centerMasterID !== jsonCopy.costCenterId) {
            selectedCumulativeData = [];
        }
        jsonCopy.employeeIds = [];
        jsonCopy.employeeSearch.employeeName = "";
        jsonCopy.employeeSearch.employeeTypeCode = "";
        jsonCopy.employeeSearch.shiftCodeList = "";
        jsonCopy.employeeSearch.costCenterCode = "";
        this.setState({ loading: true, CumulativeData: jsonCopy });
        this.getAdvanceFilterList(jsonCopy);

        document.getElementsByClassName("dialogbox")[0].getElementsByClassName("p-datatable-thead")[0].firstChild.firstElementChild.innerHTML = "&nbsp;"
    }

    //To receive Data from Parent Component
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.removeClicked === true || nextProps.jsonTemplate.centerMasterID !== this.state.costCenterId) {
            selectedCumulativeData = [];

        }
        this.setState({
            costCenterId: nextProps.jsonTemplate.centerMasterID,
            planningPeriodID: nextProps.jsonTemplate.planningPeriodID,
            CumulativeDialog: false,
            AdvanceFilterDialog: true,
            costCenterChangeFlag: false
        })

    }
    //handle selected values in pop up 
    handleSelectionChange = (e) => {
        if (e.originalEvent.checked === true) {
            let selectedCumVal = e.value;
            selectedCumulativeData = selectedCumVal;
        }
        if (e.originalEvent.checked === false) {
            let selectedCumVal = e.value;
            if (selectedCumulativeData.length < 1) {
                selectedCumulativeData = selectedCumulativeData.concat(selectedCumVal);

            }
            else {
                for (var i = 0; i <= selectedCumVal.length; i++) {
                    var checkElement = selectedCumVal[i];
                    for (var j = 0; j <= selectedCumVal.length; j++) {
                        if (checkElement === selectedCumulativeData[j]) {
                            continue;
                        }
                        else {
                            selectedCumulativeData.concat(selectedCumVal[i])
                        }
                    }
                }
            }
        }
        else if (e.originalEvent.checked === undefined) {
            selectedCumulativeData = Array.from(new Set(e.value));
        }
        if (e.value.length !== 0) {
            this.setState({ disableOk: false });
        }
        else {
            this.setState({ disableOk: true });
        }
    }

    //handle hide Dialog Functionality
    handleHideDialog = () => {

        this.props.onHideDialog();
        this.setState({ AdvanceFilterDialog: false, loading: false, CumulativeDialog: false })
    }

    //Handle Selected data for Cumulative Search----call on ok button
    handleCumulativeSelection = () => {
        let selectedEmpData = selectedCumulativeData;
        if (selectedCumulativeData.length > 0) {

            this.setState({ disableOk: false, disablefilter: false })
            empids = [];
            selectedEmpData.map(selecteddata => {

                if (empids.indexOf(selecteddata.employeeId) === -1) {
                    // selectedEmplist.push(selecteddata);
                    empids.push(selecteddata.employeeId);
                }
                else {
                    empids.pop(selecteddata.employeeId);
                }
                return selectedEmpData;
            });
            let jsonCopy = Object.assign({}, this.state.CumulativeData);
            jsonCopy.employeeIds = empids;
            jsonCopy.planningPeriodID = this.props.jsonTemplate.planningPeriodID;
            jsonCopy.employeeSearch.costCenterCode = this.props.costCenterCode;
            jsonCopy.centerMasterID = this.props.jsonTemplate.centerMasterID;
            jsonCopy.employeeSearch.employeeName = "";
            jsonCopy.employeeSearch.costCenterCode = "";
            jsonCopy.employeeSearch.employeeTypeCode = "";
            jsonCopy.employeeSearch.shiftCodeList = "";
            jsonCopy.employeeSearch.pageNumber = 0;
            this.props.onSelectedEmp(jsonCopy, this.state.disablefilter, this.state.AdvanceFilterDialog);
        }
        else {
            this.showSelectionError();
        }


    }
    //Function to implement Change Page functionality of Paginator. 
    onPageChange = (e) => {
        let selectedEmpData = selectedCumulativeData;
        if (this.state.getAdvanceFilterData === undefined || this.state.getAdvanceFilterData.length === undefined) {
            return false;
        }
        else if (selectedCumulativeData.length > 0) {

            this.setState({ disableOk: false, disablefilter: false })
            empids = [];
            selectedEmpData.map(selecteddata => {
                if (!(_.includes(empids, selecteddata.employeeId))) {
                    // selectedEmplist.push(selecteddata);
                    empids.push(selecteddata.employeeId);
                }
                else {
                    empids.pop(selecteddata.employeeId);
                }
                return selectedEmpData;
            });
        }
        this.setState({ first: e.first, loading: true });
        let jsonCopy = Object.assign({}, this.state.CumulativeData);
        jsonCopy.employeeIds = empids;
        jsonCopy.employeeSearch.pageNumber = e.page;
        this.getAdvanceFilterList(jsonCopy);
    }
    //Function to implement functionality of Number of Record Dropdown change of Pagination. 
    noOfRecordChange = (e) => {
        this.setState({
            dropdownDisplay: e.value,
            loading: true,
            first: 0
        })
        let jsonCopy = Object.assign({}, this.state.CumulativeData);
        jsonCopy.employeeSearch.recordCount = e.value.code;
        jsonCopy.employeeSearch.pageNumber = 0;

        if (this.state.getAdvanceFilterData === undefined || this.state.getAdvanceFilterData.length === undefined) {
            this.setState({ loading: false });
            this.setState({ CumulativeData: jsonCopy });
            return false;
        }

        this.getAdvanceFilterList(jsonCopy);
        this.setState({ CumulativeData: jsonCopy });
    }
    //Error Messages
    showMultiple = (errorBody) => {
        
        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });
    }
    showSelectionError = () => {
        
        this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.selectionErrorMsg });
    }
    //Function to handle Sorting on Shift Allocation Data
    onSortChange = (e) => {
        if (this.state.getAdvanceFilterData === undefined || this.state.getAdvanceFilterData.length === undefined) {
            return false;
        }
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, displayDialog: false, loading: true });
        let jsonCopy = Object.assign({}, this.state.CumulativeData);
        jsonCopy.employeeSearch.sortDirection = "ASC";
        if (e.sortOrder === -1) {
            jsonCopy.employeeSearch.sortDirection = "DESC";
        }
        jsonCopy.employeeSearch.sortBy = e.sortField;
        this.getAdvanceFilterList(jsonCopy);
    }

    //Function to call API for filtering records
    getFilterFuntion = (jsonCopy) => {
        if (this.state.getAdvanceFilterData === undefined || this.state.getAdvanceFilterData.length === undefined) {
            return false;
        }

        jsonCopy.employeeSearch.pageNumber = 0;
        this.setState({
            jsonTemplate: jsonCopy,
            loading: true,
            // first : 0
        });
        this.getAdvanceFilterList(jsonCopy);

    }
    //Function to implement Filtering functionality on Employee ID. 
    onEmployeeSkillsFilter = (empSkills, key) => {
        if (_.includes(keyboardValues, key)) {
            this.setState({ displayDialog: false })
            let jsonCopy = Object.assign({}, this.state.CumulativeData);
            jsonCopy.employeeSearch.shiftCodeList = empSkills;
            this.getFilterFuntion(jsonCopy);
        }
    }
    //Function to implement Filtering functionality on Employee ID. 
    onEmployeeIdFilter = (empId, key) => {
        if (_.includes(keyboardValues, key)) {
            let jsonCopy = Object.assign({}, this.state.CumulativeData);
            jsonCopy.employeeSearch.employeeId = empId;
            this.getFilterFuntion(jsonCopy);
        }
    }

    onkeydownonemployeeNameFilter = (empName, key) => {
        if (_.includes(keyboardValues, key)) {
            let jsonCopy = Object.assign({}, this.state.CumulativeData);
            jsonCopy.employeeSearch.employeeName = empName;
            this.getFilterFuntion(jsonCopy);
        }
    }

    //Function to implement Filtering functionality on Employee Type Code. 
    onemployeeTypeFilter = (empType, key) => {
        if (_.includes(keyboardValues, key)) {
            let jsonCopy = Object.assign({}, this.state.CumulativeData);
            jsonCopy.employeeSearch.employeeTypeCode = empType;
            this.getFilterFuntion(jsonCopy);
        }
    }
    onCostCenterFilter = (costcentercode, key) => {
        if (_.includes(keyboardValues, key)) {
            let jsonCopy = Object.assign({}, this.state.CumulativeData);
            jsonCopy.employeeSearch.costCenterCode = costcentercode;
            this.getFilterFuntion(jsonCopy);
        }
    }

    //Function to implement functionality of Wrapping Long Text With "...".
    nameTemplate = (rowData, column) => {

        return <div className="extraTextWrapping" title={rowData[column.field]}>{rowData[column.field]}</div>;

    }

    render() {
        translator.setLanguage(this.props.browserLanguage);
        const noOfRecord = [
            { name: translator.Records_150, code: 150 },
            { name: translator.Records_200, code: 200 },
            { name: translator.Records_250, code: 250 }
        ];
        let dialogFooter = (<div className="ui-dialog-buttonpane p-clearfix">
            <Button disabled={this.state.disableOk} label={translator.OK} icon="pi pi-check" className="BorrowOkButton" type="submit" onClick={() => this.handleCumulativeSelection()} />
        </div>);
        let employeeIdFilter = <InputText maxLength="9" id="employeeId" keyfilter={/^[0-9\b]+$/} className="employeeIdFilterWidth" onKeyDown={(e) => this.onEmployeeIdFilter(e.target.value, e.key)} />
        let employeeNameFilter = <InputText type="text" id="employeeName" className="employeeIdFilterWidth" onKeyDown={(e) => this.onkeydownonemployeeNameFilter(e.target.value, e.key)} />
        let employeeTypeFilter = <InputText type="text" id="employeeTypeCode" className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeTypeFilter(e.target.value, e.key)} />
        let employeeSkillsFilter = <InputText id="employeeSkills" className="employeeIdFilterWidth" onKeyDown={(e) => this.onEmployeeSkillsFilter(e.target.value, e.key)} />
        let costCenterFilter = <InputText id="costcenter" className="employeeIdFilterWidth" onKeyDown={(e) => this.onCostCenterFilter(e.target.value, e.key)} />
        return (
            <div className="dialogbox">
                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>
                <Growl ref={(el) => this.growl = el} position="topright"></Growl>
                <Dialog header={translator.cumulativeEmpSearch} visible={this.state.AdvanceFilterDialog} footer={dialogFooter} className="defaultDialog" modal={true} onHide={() => this.handleHideDialog()}>
                    <div className="paginatorAdvanceFilterPosition" >
                        <div className="paginatorAdvanceFilter">
                            <Paginator leftContent={<span className="recordOFDialog">{this.state.totalEmployeeRecords === undefined || this.state.totalEmployeeRecords === 0 ? '0-0' : (this.state.pageNumber * this.state.pageSize) + 1 + '-' + Number((this.state.pageNumber * this.state.pageSize) + Number(this.state.recordSize))} {translator.of} {this.state.totalEmployeeRecords === undefined ? 0 : this.state.totalEmployeeRecords} {translator.records}</span>}
                                rows={this.state.CumulativeData.employeeSearch.recordCount}
                                totalRecords={this.state.totalEmployeeRecords === undefined ? 0 : this.state.totalEmployeeRecords}
                                first={this.state.first}
                                onPageChange={(e) => this.onPageChange(e)}
                            >
                            </Paginator>
                            <Dropdown value={this.state.dropdownDisplay} className="dropDwonDefault colRecordAdvanceFilter dropmarginfilter" options={noOfRecord} onChange={(e) => this.noOfRecordChange(e)} placeholder={translator.Records_150} optionLabel="name" />
                        </div>
                    </div>
                    <DataTable value={this.state.getAdvanceFilterData}
                        rows={this.state.pageSize}
                        className="dataTableCumulativeSearch"
                        onSort={(e) => this.onSortChange(e)}
                        selection={selectedCumulativeData}
                        sortField={this.state.sortField}
                        sortOrder={this.state.sortOrder}
                        onSelectionChange={e => this.handleSelectionChange(e)}>
                        <Column selectionMode="multiple" style={{ width: '2em' }} />
                        <Column field="costCenterCode" header={translator.costCenter} sortable={true} filter={true} className="numberRightAlign" filterElement={costCenterFilter} />
                        <Column field="employeeName" header={translator.employeeName} sortable={true} filter={true} filterElement={employeeNameFilter} className="textLeftAlign" body={this.nameTemplate} />
                        <Column field="employeeType" header={translator.employeeTypeCode} sortable={true} filter={true} filterElement={employeeTypeFilter} className="textLeftAlign" />
                        <Column field="employeeId" header={translator.employeeId} sortable={true} filter={true} className="numberRightAlign" filterElement={employeeIdFilter} />
                        <Column field="skills" header={translator.employeeSkills} filter={true} className="numberRightAlign" filterElement={employeeSkillsFilter} body={this.nameTemplate} />

                    </DataTable>
                    <br />
                    <div>
                        <span className="selectedRecord">
                            {translator.selectedRecords + ": " + selectedCumulativeData.length}
                        </span>
                        <DataTable
                            className="dataTableCumulativeSearch"
                            emptyMessage={translator.noRecordSelected}
                            value={selectedCumulativeData}>

                            <Column field="costCenterCode" header={translator.costCenter} style={{ textAlign: "center" }} />
                            <Column field="employeeName" header={translator.employeeName} style={{ textAlign: "left" }} />
                            <Column field="employeeType" header={translator.employeeTypeCode} style={{ textAlign: "center" }} />
                            <Column field="employeeId" header={translator.employeeId} style={{ textAlign: "right" }} />
                            <Column field="skills" header={translator.employeeSkills} style={{ textAlign: "center" }} />

                        </DataTable>
                    </div>
                </Dialog>
            </div>
        )
    }
}
export default AdvanceFilterCopyShift;