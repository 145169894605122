import React, { Component } from 'react';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Card } from 'primereact/card';
import { CommonService } from '../../service/CommonService';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import { Growl } from 'primereact/growl';
import './dashboard.css';
import TargetHoursGraph from './TargetHoursGraph';
import AllocationDataGraph from './AllocationDataGraph';
import EmployeeGroupDataGraph from './EmployeeGroupDataGraph';
import TargetHoursGraphAdmin from './TargetHoursGraphAdmin';
import AllocationDataGraphAdmin from './AllocationDataGraphAdmin';
import Notification from './Notification';
import leaveCode from '../../utility/leaveCodes.json';

let translator = new LocalizedStrings(translation);

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            notification: []
        }
        this.commonService = new CommonService();

        this.costCenterNameArrayForTargetHours = [];
        this.targetHoursArray = [];
        this.allocatedHoursArray = [];

        this.costCenterNameArrayForAllocationData = [];
        this.allocatedDataArray = [];
        this.unAllocatedDataArray = [];

        this.employeeGroupNameArray = [];
        this.employeeGroupAllocatedArray = [];
        this.employeeGroupUnAllocatedArray = [];

        this.lastUsedCostCenter = {};
        this.lastUsedPlanningPeriod = {};

        this.costCenterNameArrayForTargetHoursAdmin = [];
        this.targetHoursArrayAdmin = [];
        this.allocatedHoursArrayAdmin = [];
        this.lastUsedPlanningPeriodAdminTargetHours = {};
        this.lastUserCostCenterAdminTargetHours = [];

        this.costCenterNameArrayForAllocationDataAdmin = [];
        this.allocatedDataArrayAdmin = [];
        this.unAllocatedDataArrayAdmin = [];
        this.lastUsedPlanningPeriodAdminAllocationData = {};
        this.lastUserCostCenterAdminAllocationData = [];

    }

    componentDidMount = () => {
        this.getNotificationData();

        this.interval = setInterval(() => {
            this.getNotificationData();
        }, leaveCode.notificationTimer);

        this.setState({ loading: true });
        this.commonMethodForLifecycleMethod();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    //Function To Call API for Notification Data
    getNotificationData = () => {
        this.commonService.notificationData(this.props.browserLanguage)
            .then(res => {
                this.setState({ notification: res });
            })
            .catch(err => {
                this.showMultiple(err);
            })
    }

    commonMethodForLifecycleMethod = () => {
        this.costCenterNameArrayForTargetHours = [];
        this.targetHoursArray = [];
        this.allocatedHoursArray = [];

        this.costCenterNameArrayForAllocationData = [];
        this.allocatedDataArray = [];
        this.unAllocatedDataArray = [];

        this.employeeGroupNameArray = [];
        this.employeeGroupAllocatedArray = [];
        this.employeeGroupUnAllocatedArray = [];

        this.lastUsedCostCenter = {};
        this.lastUsedPlanningPeriod = {};

        this.costCenterNameArrayForTargetHoursAdmin = [];
        this.targetHoursArrayAdmin = [];
        this.allocatedHoursArrayAdmin = [];
        this.lastUsedPlanningPeriodAdminTargetHours = {};
        this.lastUserCostCenterAdminTargetHours = [];

        this.costCenterNameArrayForAllocationDataAdmin = [];
        this.allocatedDataArrayAdmin = [];
        this.unAllocatedDataArrayAdmin = [];
        this.lastUsedPlanningPeriodAdminAllocationData = {};
        this.lastUserCostCenterAdminAllocationData = [];

        this.props.masterData.admin ?
            this.commonService.getLastUsedFilterDataDashboardForAdmin(this.props.browserLanguage)
                .then(res => {
                    if (res !== null && res !== undefined) {
                        res.groupNameData.map(data => {
                            this.employeeGroupNameArray.push(data.groupName);
                            this.employeeGroupAllocatedArray.push(data.allocatedHours);
                            this.employeeGroupUnAllocatedArray.push(data.nonAllocatedHours);
                            return 0;
                        })
                        res.shiftData.map(data => {
                            this.costCenterNameArrayForAllocationDataAdmin.push(data.costCenterCode);
                            this.allocatedDataArrayAdmin.push(data.allocatedShifts);
                            this.unAllocatedDataArrayAdmin.push(data.nonAllocatedShifts);
                            return 0;
                        })
                        res.targetHoursData.map(data => {
                            this.costCenterNameArrayForTargetHoursAdmin.push(data.costCenterCode);
                            this.targetHoursArrayAdmin.push(data.targetHours);
                            this.allocatedHoursArrayAdmin.push(data.sumOfAllocatedHours);

                            return 0;
                        })
                        this.lastUsedPlanningPeriodAdminTargetHours = res.planningPeriodForTargetHours;
                        this.lastUserCostCenterAdminTargetHours = res.costCenterIdsForTargetHours;
                        this.lastUsedCostCenter = res.costCenter;
                        this.lastUsedPlanningPeriod = res.planningPeriod;
                        this.lastUsedPlanningPeriodAdminAllocationData = res.planningPeriodForShift;
                        this.lastUserCostCenterAdminAllocationData = res.costCenterIdsForShift;
                    }
                    this.setState({ loading: false });
                })
                .catch(err => {
                    this.setState({ loading: false });
                    this.showMultiple(err);
                })
            :
            this.commonService.getLastUsedFilterDataDashboard(this.props.browserLanguage)
                .then(res => {
                    if (res !== null && res !== undefined) {
                        res.groupNameData.map(data => {
                            this.employeeGroupNameArray.push(data.groupName);
                            this.employeeGroupAllocatedArray.push(data.allocatedHours);
                            this.employeeGroupUnAllocatedArray.push(data.nonAllocatedHours);
                            return 0;
                        })
                        res.shiftData.map(data => {
                            this.costCenterNameArrayForAllocationData.push(data.costCenterCode);
                            this.allocatedDataArray.push(data.allocatedShifts);
                            this.unAllocatedDataArray.push(data.nonAllocatedShifts);
                            return 0;
                        })
                        res.targetHoursData.map(data => {
                            this.costCenterNameArrayForTargetHours.push(data.costCenterCode);
                            this.targetHoursArray.push(data.targetHours);
                            this.allocatedHoursArray.push(data.sumOfAllocatedHours);
                            return 0;
                        })
                        this.lastUsedCostCenter = res.costCenter;
                        this.lastUsedPlanningPeriod = res.planningPeriod;
                    }
                    this.setState({ loading: false });
                })
                .catch(err => {
                    this.setState({ loading: false });
                    this.showMultiple(err);
                })
    }
    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {
      
        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life : leaveCode.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });
        
    }

    render() {
        translator.setLanguage(this.props.browserLanguage);

        return (
            <div className="bodyColor">

                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>

                <Growl ref={(el) => this.growl = el} position="topright" ></Growl>
                {
                    this.state.loading === false ?
                        <Container>
                            <Row className="notificationRow">
                                <Col id="dashboardCard">
                                    <div>
                                        <Notification
                                            notification={this.state.notification}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <br/>
                            <Row className="graphFirstRow">
                                <Col id="dashboardCard">

                                    <Card>
                                        {
                                            this.props.masterData.admin ?
                                                <TargetHoursGraphAdmin
                                                    costCenterNameArrayForTargetHoursAdmin={this.costCenterNameArrayForTargetHoursAdmin}
                                                    targetHoursArrayAdmin={this.targetHoursArrayAdmin}
                                                    allocatedHoursArrayAdmin={this.allocatedHoursArrayAdmin}
                                                    lastUsedPlanningPeriodAdminTargetHours={this.lastUsedPlanningPeriodAdminTargetHours}
                                                    lastUserCostCenterAdminTargetHours={this.lastUserCostCenterAdminTargetHours}
                                                />
                                                :
                                                <TargetHoursGraph
                                                    costCenterNameArrayForTargetHours={this.costCenterNameArrayForTargetHours}
                                                    targetHoursArray={this.targetHoursArray}
                                                    allocatedHoursArray={this.allocatedHoursArray}
                                                />
                                        }


                                    </Card>

                                </Col>

                                <Col id="dashboardCard">

                                    <Card>
                                        {
                                            this.props.masterData.admin ?
                                                <AllocationDataGraphAdmin
                                                    costCenterNameArrayForAllocationDataAdmin={this.costCenterNameArrayForAllocationDataAdmin}
                                                    allocatedDataArrayAdmin={this.allocatedDataArrayAdmin}
                                                    unAllocatedDataArrayAdmin={this.unAllocatedDataArrayAdmin}
                                                    lastUsedPlanningPeriodAdminAllocationData={this.lastUsedPlanningPeriodAdminAllocationData}
                                                    lastUserCostCenterAdminAllocationData={this.lastUserCostCenterAdminAllocationData}
                                                />
                                                :
                                                <AllocationDataGraph
                                                    costCenterNameArrayForAllocationData={this.costCenterNameArrayForAllocationData}
                                                    allocatedDataArray={this.allocatedDataArray}
                                                    unAllocatedDataArray={this.unAllocatedDataArray}
                                                />
                                        }
                                    </Card>


                                </Col>

                            </Row>
                            <br />
                            <Row className="graphSecondRow">
                                <Col id="dashboardCard">

                                    <Card className="">
                                        <EmployeeGroupDataGraph
                                            employeeGroupNameArray={this.employeeGroupNameArray}
                                            employeeGroupAllocatedArray={this.employeeGroupAllocatedArray}
                                            employeeGroupUnAllocatedArray={this.employeeGroupUnAllocatedArray}
                                            lastUsedCostCenter={this.lastUsedCostCenter}
                                            lastUsedPlanningPeriod={this.lastUsedPlanningPeriod}
                                        />
                                    </Card>

                                </Col>

                            </Row>

                        </Container>
                        : null
                }

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        masterData: state.masterData,
        browserLanguage: state.browserLanguage
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard); 
