import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js'
let translator = new LocalizedStrings(translation);

class DeleteConfirmationDialog extends Component {

    //Function to call Employee Dialog function to dismiss delete confirmation dialog and again show Employee Dialog
    callEmpDialogFunctionToDismissDelDialog = () => {
        this.props.callbackProp();
    }

    //Function to call Employee Dialog function to call delete service
    callEmpDialogFunctionToDelete = () => {
        this.props.callbackDeleteService();
    }

    //Function to set focus to Yes button
    setFocusOnYesBtn = () => {
        if (document.getElementById("yesbtn") !== null)
            setTimeout(function () { document.getElementById("yesbtn").focus(); }, 2);
    }

    render() {
        translator.setLanguage(this.props.lang);

        let dialogFooter = (<div className="ui-dialog-buttonpane p-clearfix">
            <Button id="yesbtn" label={translator.yes} className="deleteButton" onClick={this.callEmpDialogFunctionToDelete} />
            <Button label={translator.no} className="deleteButton" onClick={this.callEmpDialogFunctionToDismissDelDialog} />
        </div>);

        return (
            <Dialog visible={true} className="deleteDialogBoxPosition" modal={true} header={translator.deleteDialogHeader} onHide={this.callEmpDialogFunctionToDismissDelDialog} footer={dialogFooter} onShow={this.setFocusOnYesBtn}>
                {this.props.screen === "shiftMaster" ? translator.confirmShiftDeleteMessage : null}
            </Dialog>
        );

    }

}

export default DeleteConfirmationDialog;