import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import Table from 'react-bootstrap/Table'
let translator = new LocalizedStrings(translation);

class CostCenter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            costCenterSelected: null
        }
    }

    componentWillUpdate = () => {
        
        let selectedCostCenter = null;
        if (this.props.masterData.costCenter && this.props.lastUsedCostCenter && this.state.costCenterSelected === null) {
            selectedCostCenter = this.props.masterData.costCenter.filter((costCenter) => {
                return costCenter.costCenterMasterId === this.props.lastUsedCostCenter.costCenterMasterId;
            });
            if (this.state.costCenterSelected === null) {
                this.setState({ costCenterSelected: selectedCostCenter[0]});
            }
        }
    }

    //Function to send values corresponding selected cost center to various components.
    onCostCenterSelect = (e) => {

        this.setState({
            costCenterSelected: e.value
        });
        if (e.target.value !== null) {
            this.props.onChange(e.target.value.costCenterCode, e.target.value.costCenterMasterId);
        }
        else {
            this.props.onChange('', '');
        }
    }

    //Template for Cost Center Dropdown display.
    costCenterTemplate = (option) => {
        return (
            <div className="dvcostCenter">
                <Table className="costCenterTable table-striped">
                    <tbody>
                        <tr>
                            <td className="costCenterCode">{option.costCenterCode}</td>
                            <td className="costCenterName">{option.costCenterName}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>

        );
    }

    render() {
        translator.setLanguage(this.props.browserLanguage);

        return (
            <div className="costCenter">
                <strong>{translator.costCenter} : </strong>
                <Dropdown value={this.props.disabled !==undefined && this.props.disabled ? "All CostCenter" : this.state.costCenterSelected} 
                    options={this.props.masterData === null ? null : this.props.masterData.costCenter} 
                    onChange={(e) => this.onCostCenterSelect(e)} 
                    placeholder={this.props.disabled !==undefined && this.props.disabled ? translator.allCostCenter :translator.searchCostCenter}
                    itemTemplate={this.costCenterTemplate}
                    optionLabel="value"
                    filter={true}
                    filterPlaceholder={translator.searchCostCenter}
                    filterBy="label,value"
                    className="costcenterdrop"
                    showClear={true} 
                    disabled={this.props.disabled !==undefined ? this.props.disabled : false}/>
            </div>

        );
    }
}

export default CostCenter;