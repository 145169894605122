import axios from 'axios';
import apiUrl from '../CommonConstants.json';
import LocalizedStrings from 'react-localization';
import { translation } from '../../src/locales/translation';
import { getHeader } from "../utility/commonUtility";
import { getBlobHeader } from "../utility/commonUtility";
import { authContext } from '../../src/adalConfig';
let translator = new LocalizedStrings(translation);
let language = "";
export class CommonService {
    //Function to implement logout.
    logout = () => {
        authContext.logOut();
    }

    handleError = (error) => {
        translator.setLanguage(language);
        console.log(' -> handleError', Object.assign({}, error));

        if (error.message === "Network Error") {
            throw new Error(translator.serverError)
        }
        else if (error.response.status === 401) {
            this.logout();
        }
        else if (error.response.data.status === 500) {
            throw new Error(error.response.data.message);
        }
        else {
            if (error.response.data.apierror === undefined) {
                throw new Error(translator.serverError);
            }
            else {
                throw new Error(error.response.data.apierror.message);
            }
        }
    }

    getMasterData = (lang) => {
        language = lang;
        return axios.get(apiUrl.getMasterDataUrl, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getShiftAllocationData = (bodyTemplate, lang) => {
        return axios.post(apiUrl.getShiftAllocationUrl, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getLastFilterShiftAllocation = (lang) => {
        return axios.get(apiUrl.getLastFilterShiftAllocationURL, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getLastFilterTVSDaily = (lang) => {
        return axios.get(apiUrl.getLastFilterTVSDailyURL, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getLastFilterTVSWeekly = (lang) => {
        return axios.get(apiUrl.getLastFilterTVSWeeklyURL, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getLastFilterEmployeeMaster = (lang) => {
        return axios.get(apiUrl.getLastFilterEmployeeMasterURL, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getLastFilterSkillMaster = (lang) => {
        return axios.get(apiUrl.getLastFilterSkillMasterURL, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getLastFilterShiftMaster = (lang) => {
        return axios.get(apiUrl.getLastFilterShiftMasterURL, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    updateShiftAllocation = (bodyTemplate, lang) => {
        return axios.post(apiUrl.updateMultipleShiftAllocationUrl, bodyTemplate, getHeader(lang))
            .then(res => res)
            .catch(this.handleError);
    }

    getborrowEmployee = (bodyTemplate, lang) => {
        return axios.post(apiUrl.getBorrowEmployee, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    saveborrowEmployee = (bodyTemplate, lang) => {
        return axios.post(apiUrl.savebrrowEmployeeUrl, bodyTemplate, getHeader(lang))
            .then(res => res.status)
            .catch(this.handleError);
    }

    createBaseline = (bodyTemplate, lang) => {
        return axios.post(apiUrl.createBaseline, bodyTemplate, getHeader(lang))
            .then(res => res)
            .catch(this.handleError);
    }

    getAdvanceFilter = (bodyTemplate, lang) => {
        return axios.post(apiUrl.getAdvanceFilterUrl, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    updateEmployee = (bodyTemplate, lang) => {
        return axios.post(apiUrl.updateEmployeeUrl, bodyTemplate, getHeader(lang))
            .then(res => res)
            .catch(this.handleError);
    }

    deleteEmployee = (id, lang) => {
        return axios.delete(apiUrl.deleteEmployeeUrl + id, getHeader(lang))
            .then(res => res)
            .catch(this.handleError);
    }

    getEmployeeList = (bodyTemplate, lang) => {
        return axios.post(apiUrl.employeeListUrl, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getShiftList = (bodyTemplate, lang) => {
        return axios.post(apiUrl.getShiftListUrl, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getDailyTVS = (bodyTemplate, lang) => {
        return axios.post(apiUrl.getDailyTVS, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getWeeklyTVSData = (bodyTemplate, lang) => {
        return axios.post(apiUrl.getWeeklyTVSData, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    updateDailyTVSData = (bodyTemplate, lang) => {
        return axios.post(apiUrl.updateDailyTVSData, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    exportShiftAllocationForPeriodPDF = (bodyTemplate, lang) => {
        return axios.post(apiUrl.exportShiftAllocationForPeriodPDFURL, bodyTemplate, getBlobHeader(lang))
            .then(res => res)
            .catch(this.handleError);
    }

    getExcelTemplate = (lang, downloadFileName) => {
        return axios.get(apiUrl.getExcelTemplateURL + downloadFileName, getBlobHeader(lang))
            .then(res => res)
            .catch(this.handleError);
    }

    uploadEXCEL = (bodyTemplate, uploadType, lang) => {
        return axios.post(apiUrl.excelUploadURL + uploadType, bodyTemplate, getBlobHeader(lang))
            .then(res => res)
            .catch(this.handleError);
    }

    generateFeedFile = (bodyTemplate, lang) => {
        return axios.post(apiUrl.generateFeedFileURL, bodyTemplate, getHeader(lang))
            .then(res => res)
            .catch(this.handleError);
    }

    getEmployeeSkillMapping = (bodyTemplate, lang) => {
        return axios.post(apiUrl.getEmployeeSkillMappingURL, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getEmpSkillsList = (lang, costCenterMasterId) => {
        return axios.get(apiUrl.getEmpSkillsListURL + costCenterMasterId, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    updateEmployeeSkillMapping = (bodyTemplate, lang) => {
        return axios.post(apiUrl.updateEmployeeSkillMappingURL, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getEmployeeNameList = (lang, CostCenterMasterId) => {
        language = lang;
        return axios.get(apiUrl.getEmployeeNameListURL + CostCenterMasterId, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getEmployeeAllocationList = (bodyTemplate, lang) => {
        language = lang;
        return axios.post(apiUrl.getEmployeeAllocationList, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }
    updateEmployeeAllocation = (bodyTemplate, lang) => {
        language = lang;
        return axios.post(apiUrl.updateEmployeeAllocation, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    //Function to update weekly comments and haitta value
    updateWeeklyCommentAndHaittaInput = (bodyTemplate, lang) => {
        language = lang;
        return axios.post(apiUrl.saveWeeklyCommentAndHaittaUrl, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    //Function to call service to unborrow an employee
    unborrowEmployee = (bodyTemplate, lang) => {
        language = lang;
        return axios.post(apiUrl.unborrowEmployeeUrl, bodyTemplate, getHeader(lang))
            .then(res => res)
            .catch(this.handleError);
    }

    //Function to call service to get leave codes and description
    getLeaveCodeAndDescription = (lang) => {
        language = lang;
        return axios.get(apiUrl.getLeaveCodeAndDescriptionUrl, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    //Function to update daily comments
    saveDailyComment = (bodyTemplate, lang) => {
        language = lang;
        return axios.post(apiUrl.saveDailyComment, bodyTemplate, getHeader(lang))
            .then(res => res)
            .catch(this.handleError)
    }
    //Function to call service to get employee details on Dialog box
    getEmployee = (id, lang) => {
        return axios.get(apiUrl.getEmployee + id, getHeader(lang))
            .then(res => res)
            .catch(this.handleError);
    }

    getLastFilterEmployeeAllocation = (lang) => {
        return axios.get(apiUrl.getLastFilterEmployeeAllocationUrl, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getCopyShiftAllocation = (bodyTemplate, lang) => {
        return axios.post(apiUrl.copyShiftAllocationUrl, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    acceptRejectShiftAllocation = (bodyTemplate, lang) => {
        return axios.post(apiUrl.acceptRejectAllocationUrl, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    //Function to get dummy employee details in Dropdown in Employee Dialog 
    getDummyEmployee = (costCenter, lang) => {
        return axios.get(apiUrl.getDummyEmployee + costCenter, getHeader(lang))
            .then(res => res)
            .catch(this.handleError);
    }

    getGroups = (ccMasterId, lang) => {
        return axios.get(apiUrl.getGroups + ccMasterId, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getExcelTemplateForAdmin = (lang, downloadFileName) => {
        return axios.get(apiUrl.adminExcelDownloadUrl + downloadFileName, getBlobHeader(lang))
            .then(res => res)
            .catch(this.handleError);
    }

    uploadExcelForAdmin = (bodyTemplate, uploadType, lang) => {
        return axios.post(apiUrl.adminExcelUploadUrl + uploadType, bodyTemplate, getBlobHeader(lang))
            .then(res => res)
            .catch(this.handleError);
    }

    getCostCenterMaintenanceAdmin = (lang) => {
        return axios.get(apiUrl.getCostCenterMaintenanceUrl, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }
    getHolidayForYear = (lang, year) => {
        return axios.get(apiUrl.getHolidayForYear + year, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }
    getExcelTemplateHoliday = (lang, year) => {
        return axios.get(apiUrl.getExcelTemplateForHoliday + year, getBlobHeader(lang))
            .then(res => res)
            .catch(this.handleError);
    }

    getCopyPP = (lang, currentPP) => {
        return axios.get(apiUrl.getCopyPPDropdown + currentPP, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    createManualAutoGenrateShifts = (bodyTemplate, lang) => {
        language = lang;
        return axios.post(apiUrl.processInputForShiftOptimizer, bodyTemplate, getHeader(lang))
            .then(res => res)
            .catch(this.handleError)
    }

    //Function to call service to get cost center supervisor mapping details
    getCostCenterSupervisorMappingData = (lang) => {
        return axios.get(apiUrl.getCostCenterSupervisorMappingData, getHeader(lang))
            .then(res => res)
            .catch(this.handleError);
    }

    //Function to call service to update cost center supervisor mapping details
    updateCostCenterSupervisorMappingData = (bodyTemplate, lang) => {
        return axios.post(apiUrl.updateCostCenterSupervisorMappingData, bodyTemplate, getHeader(lang))
            .then(res => res)
            .catch(this.handleError)
    }

    getAutoGeneratedId = (bodyTemplate, lang) => {
        return axios.post(apiUrl.autoGenerateDropDown,bodyTemplate , getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    viewProposedShift = (bodyTemplate, lang) => {
        language = lang;
        return axios.post(apiUrl.viewProposedShiftPlan, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError)
    }

    //Function to call service to accept auto generated shift
    acceptOrRejectProposedShiftPlan = (bodyTemplate, lang) => {
        language = lang;
        return axios.post(apiUrl.acceptOrRejectProposedShiftPlan, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError)
    }

    //Function to call service to retrieve vacation plan for specific cost center
    getEmployeeVacationPlan = (bodyTemplate, lang) => {
        return axios.post(apiUrl.getEmployeeVacationPlan, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    //Function to call service to retrieve advance filter on auto shift generation
    getEmployeeCumulativeSearchForOptimizerView = (bodyTemplate, lang) => {
        return axios.post(apiUrl.getEmployeeCumulativeSearchForOptimizerView, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    saveDeleteLeaveRecord = (bodyTemplate, lang) => {
        return axios.post(apiUrl.saveDeleteLeaveRecord, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getVacationPlanningDetailsAsPerLastFilter = (lang) => {
        return axios.get(apiUrl.getVacationPlanningDetailsAsPerLastFilter, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getTargetHoursForSupervisorDashboard = (ppId, lang) => {
        return axios.get(apiUrl.getTargetHoursForDashboardUrl + ppId, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getTargetHoursForSupervisorDashboardForAdmin = (bodyTemplate, lang) => {
        return axios.post(apiUrl.getTargetHoursForDashboardAdmin, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getAllocationDataForSupervisorDashboard = (ppId, lang) => {
        return axios.get(apiUrl.getAllocationDataForDashboardUrl + ppId, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getAllocationDataForSupervisorDashboardForAdmin = (bodyTemplate, lang) => {
        return axios.post(apiUrl.getAllocationDataForDashboardAdmin, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getEmployeeGroupDataDashboard = (url, bodyTemplate, lang) => {
        return axios.post(url, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getLastUsedFilterDataDashboard = (lang) => {
        return axios.get(apiUrl.getLastUsedFilterDataForDashboard, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getLastUsedFilterDataDashboardForAdmin = (lang) => {
        return axios.get(apiUrl.getLastUsedFilterDataForDashboardAdmin, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    updateShift = (bodyTemplate, lang) => {
        return axios.post(apiUrl.saveUpdateShiftUrl, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    deleteShift = (bodyTemplate, lang) => {
        return axios.post(apiUrl.deleteShiftUrl, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    //Function to update cost center data
    updateCostCenterMaintenanceData = (bodyTemplate, lang) => {
        return axios.post(apiUrl.updateCostCentersDataUrl, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    notificationData = (lang) => {
        return axios.get(apiUrl.notificationUrl, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }    
    getCopyData = (bodyTemplate, lang) => {
        return axios.post(apiUrl.viewCopyDataUrl, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getCumulativeSearchForCopyShift = (bodyTemplate, lang) => {
        return axios.post(apiUrl.cumulativeFilterCopyShiftUrl, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    notificationRedirectData = (id, lang) => {
        return axios.get(apiUrl.notificationRedirectUrl + id, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    } 
    
    getListOfUnallocatedEmployees = (bodyTemplate, lang) => {
        return axios.post(apiUrl.getListOfUnallocatedEmployees, bodyTemplate, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    saveTransferEmployee = (bodyTemplate, lang) =>{
        return axios.post(apiUrl.saveTransferEmployee, bodyTemplate, getHeader(lang))
        .then(res => res)
        .catch(this.handleError);
    }

    getEmployeePurgeData = (lang) => {
        return axios.get(apiUrl.getEmployeePurgeData, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    updatePurgeData = (bodyTemplate, lang) =>{
        return axios.post(apiUrl.updateEmployeePurgeData, bodyTemplate, getHeader(lang))
        .then(res => res.data)
        .catch(this.handleError);
    }

    getAllocationPurgeData = (lang) => {
        return axios.get(apiUrl.getAllocationPurgeData, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    getShiftExcelTemplateWithData = (lang, ccMasterId) => {
        return axios.get(apiUrl.shiftMasterTemplateWithDataUrl + ccMasterId, getBlobHeader(lang))
            .then(res => res)
            .catch(this.handleError);
    }

    getEmployeeExcelTemplateWithData = (bodyTemplate, lang) =>{
        return axios.post(apiUrl.employeeMasterTemplateWithDataUrl, bodyTemplate, getBlobHeader(lang))
        .then(res => res)
        .catch(this.handleError);
    }

    getSharepointConfigData = (lang) => {
        return axios.get(apiUrl.getSharepointConfigData, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    saveSharepointConfigData = (bodyTemplate, lang) =>{
        return axios.post(apiUrl.saveSharepointConfigData, bodyTemplate, getHeader(lang))
        .then(res => res.data)
        .catch(this.handleError);
    }

    copyDownShiftTimings = (bodyTemplate, lang) =>{
        return axios.post(apiUrl.copyDownShiftTimings, bodyTemplate, getHeader(lang))
        .then(res => res)
        .catch(this.handleError);
    }

    getKikyHoursData = (bodyTemplate, lang) =>{
        return axios.post(apiUrl.getKikyHoursData, bodyTemplate, getHeader(lang))
        .then(res => res.data)
        .catch(this.handleError);
    }

    getKikyHoursDataAsPerLastUsedFilter = (lang) => {
        return axios.get(apiUrl.getKikyHoursDataAsPerLastUsedFilter, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    updateWeekAndHoursMapping = (bodyTemplate, lang) =>{
        return axios.post(apiUrl.updateWeekAndHoursMapping, bodyTemplate, getHeader(lang))
        .then(res => res)
        .catch(this.handleError);
    }

    updateAvailableHoursForAYear = (bodyTemplate, lang) =>{
        return axios.post(apiUrl.updateAvailableHoursForAYear, bodyTemplate, getHeader(lang))
        .then(res => res)
        .catch(this.handleError);
    }

    getShiftAllocationForYear = (bodyTemplate, lang) =>{
        return axios.post(apiUrl.getShiftAllocationForYear, bodyTemplate, getHeader(lang))
        .then(res => res.data)
        .catch(this.handleError);
    }

    getShiftAllocationForYearAsPerLastFilter = (lang) => {
        return axios.get(apiUrl.getShiftAllocationForYearAsPerLastFilter, getHeader(lang))
            .then(res => res.data)
            .catch(this.handleError);
    }

    yearlyUpdateShiftAllocation = (bodyTemplate, lang) => {
        return axios.post(apiUrl.yearlyUpdateMultipleShiftAllocationUrl, bodyTemplate, getHeader(lang))
            .then(res => res)
            .catch(this.handleError);
    }

      //Function to update daily comments
      saveDailyCommentForYearly = (bodyTemplate, lang) => {
        language = lang;
        return axios.post(apiUrl.saveDailyCommentForYearly, bodyTemplate, getHeader(lang))
            .then(res => res)
            .catch(this.handleError)
    }
    
}
