import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js'
let translator = new LocalizedStrings(translation);

class TransferConfirmation extends Component {

    //Function to call Transfer Dialog function to dismiss Transfer confirmation dialog and again show Transfer Dialog
    callTransferDialogToHide = () => {
        this.props.callbackProp();
    }

    //Function to call Transfer Dialog function to call save service
    callTransferDialogToSave = () => {
        this.props.callbackSaveService();
    }

    //Function to set focus to Yes button
    setFocusOnYesBtn = () => {
        if (document.getElementById("yesbtn") !== null)
            setTimeout(function () { document.getElementById("yesbtn").focus(); }, 2);
    }

    render() {
        
        translator.setLanguage(this.props.lang);

        let dialogFooter = (<div className="ui-dialog-buttonpane p-clearfix">
            <Button id="yesbtn" label={translator.yes} className="deleteButton" onClick={this.callTransferDialogToSave} />
            <Button label={translator.no} className="deleteButton" onClick={this.callTransferDialogToHide} />
        </div>);

        return (
            <Dialog visible={this.props.visible} className="deleteDialogBoxPosition" modal={true} header={translator.confirmTransfer} onHide={this.callTransferDialogToHide} footer={dialogFooter}>
                {translator.confirmTransferMessage}
            </Dialog>
        );

    }

}

export default TransferConfirmation;