import moment from 'moment';

export const maxDuration = 15;

// import { ensureToken } from '../../src/adalConfig';
export const getHeader = (lang) => {
    //ensureToken();
    let config = {
        headers: {
            'Accept-Language': lang,
            'Authorization': 'Bearer ' + localStorage.getItem('adal.idtoken')
        }
    };
    return config;
}
export const getBlobHeader = (lang) => {
    //ensureToken();
    let config = {
        headers: {
            'Accept-Language': lang,
            'Authorization': 'Bearer ' + localStorage.getItem('adal.idtoken')
        },
        responseType: 'blob'
    };
    return config;
}

export const keyboardValues = ['Tab', 'Enter'];

export const dailyWeekend = [
    'week1day6',
    'week1day7',
    'week2day6',
    'week2day7',
    'week3day6',
    'week3day7',
    'week4day6',
    'week4day7'
]

export const getResolution = () => {
    if (window.matchMedia("(min-width: 1920px)").matches === true) {
        return '850px'
    }
    else if (window.matchMedia("(min-width: 1680px)").matches === true) {
        return '825px'
    }
    else if (window.matchMedia("(min-width: 1300px)").matches === true) {
        return '440px'
    }
    else {
        return '700px'
    }
}

export const getResolutionShiftAllocation = () => {
    if (window.matchMedia("(min-width: 1920px)").matches === true) {
        return '640px'
    }
    else if (window.matchMedia("(min-width: 1680px)").matches === true) {
        return '600px'
    }                         
    else if (window.matchMedia("(min-width: 1300px)").matches === true) {
        return '330px'
    }
    else {
        return '560px'
    }
}
export const getResolutionDailyTVS = () => {
    if (window.matchMedia("(min-width: 1920px)").matches === true) {
        return '680px'
    }
    else if (window.matchMedia("(min-width: 1680px)").matches === true) {
        return '650px'
    }
    else if (window.matchMedia("(min-width: 1300px)").matches === true) {
        return '250px'
    }
    else {
        return '480px'
    }
}
export const getResolutionEmployeeAllocation = () => {
    if (window.matchMedia("(min-width: 1920px)").matches === true) {
        return '800px'
    }
    else if (window.matchMedia("(min-width: 1680px)").matches === true) {
        return '780px'
    }
    else if (window.matchMedia("(min-width: 1300px)").matches === true) {
        return '440px'
    }
    else {
        return '640px'
    }
}
export const getResolutionCCMaintainenace = () => {
    if (window.matchMedia("(min-width: 1920px)").matches === true) {
        return '63vh'
    }
    else if (window.matchMedia("(min-width: 1680px)").matches === true) {
        return '60vh'
    }
    else if (window.matchMedia("(min-width: 1300px)").matches === true) {
        return '58vh'
    }
    else {
        return '58vh'
    }
}
export const getResolutionCCMapping = () => {
    if (window.matchMedia("(min-width: 1920px)").matches === true) {
        return '60vh'
    }
    else if (window.matchMedia("(min-width: 1680px)").matches === true) {
        return '59vh'
    }
    else if (window.matchMedia("(min-width: 1300px)").matches === true) {
        return '58vh'
    }
    else {
        return '54vh'
    }
}
export const getResolutionAutoGeneratedShift = () => {
    if (window.matchMedia("(min-width: 1920px)").matches === true) {
        return '640px'
    }
    else if (window.matchMedia("(min-width: 1680px)").matches === true) {
        return '720px'
    }
    else if (window.matchMedia("(min-width: 1300px)").matches === true) {
        return '330px'
    }
    else {
        return '560px'
    }
}

//Function to display current year, previous and future two years from current in Year dropDown.
export const yearDropDown = () => {
    let currentYear = new Date().getFullYear();
    let years = [currentYear - 2, currentYear - 1, currentYear, currentYear + 1, currentYear + 2]
    let YearsDropDown = years.map(data => {
        return ({ 'label': data, 'value': data })
    })

    return YearsDropDown
}

//Function to display years other than the year in arguments of method.
export const copyFromYearDropDown = (removeYear) => {
    let currentYear = new Date().getFullYear();
    let years = [currentYear - 3, currentYear - 2, currentYear - 1 , currentYear]
    
    var index = years.indexOf(removeYear)
    
    if(index !== -1)
        years.splice(index,1);
    
    let YearsDropDown = years.map(data => {
        return ({ label: data, value: data })
    })

    return YearsDropDown
}
//Function to display years other than the year in arguments of method.
export const copyToYearDropDown = (selectedYear) => {
    
    let years = [selectedYear + 1, selectedYear + 2, selectedYear + 3, selectedYear + 4];
    
    let YearsDropDown = years.map(data => {
        return ({ label: data, value: data })
    })

    return YearsDropDown
}


export const en = {
    firstDayOfWeek: 1,
    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    dateFormat: "yy-mm-dd",
    weekHeader: 'Sm'
};

export const fi = {
    firstDayOfWeek: 1,
    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    dayNamesShort: ["su", "ma", "ti", "ke", "to", "pe", "la"],
    dayNamesMin: ["su", "ma", "ti", "ke", "to", "pe", "la"],
    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    monthNamesShort: ["Tammi", "Helmi", "Maalis", "Huhti", "Touko", "Kesä", "Heinä", "Elo", "Syys", "Loka", "Marras", "Joulu"],
    dateFormat: "yy-mm-dd",
    weekHeader: 'Sm'
};

export const ENGLISH_LOCALE = 'en-US';

export const FINNISH_LOCALE = 'fi-FI' ;

export const  minutesToHHmm = (totalMinutes) => {
    
    if(totalMinutes < 60){
        return `00:${moment.duration({"minutes": totalMinutes}).format("HH:mm")}`
    }
    else{
        return moment.duration({"minutes": totalMinutes}).format("HH:mm")
    }
}

export const  hhmmToMinutes = (hhmm) => {
    
    let minutes  = 0;

    minutes += parseInt(hhmm.split(":")[0],10) *60;
    minutes += parseInt(hhmm.split(":")[1],10);

    return minutes;
   
}


