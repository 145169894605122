import React, { Component } from 'react';
import { CommonService } from '../../service/CommonService';
import { Dropdown } from 'primereact/dropdown';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Growl } from 'primereact/growl';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import './vacationPlanning.css';
import leaveCodes from '../../utility/leaveCodes.json';
import { en, fi } from '../../../src/utility/commonUtility';

let translator = new LocalizedStrings(translation);
let keepLeaveDialogCompOpen = false;
var currentYear = new Date().getFullYear();

class VacationLeaveDialog extends Component {
    constructor(props) {
        super(props);

        let today = new Date();
        let minDate = new Date(today - 86400000);
        let disableDateConstraint = [minDate]

        this.state = {
            loading: false,
            startDateDisplay: null,
            endDateDisplay: null,
            minDate: minDate,
            maxDate: null,
            disableDateConstraint: disableDateConstraint,
            leaveDetail: {
                leaveCode: '',
                leaveStartDate: '',
                leaveEndDate: ''
            },
            leaveCodeShow: null,
            leaveDates: null,
            displayDialog: false,
            startDate: new Date()
        };
        this.commonService = new CommonService();
    }

    componentDidMount = () => {
        if (this.props.employeeLeaveData !== null) {
            // let date1 = new Date("01-01-" + this.props.yearSelected)
            // let minDate = new Date(date1 - 86400000);
            let today = new Date();
            let minDate = new Date(today - 86400000);
            let maxDate = new Date();
           
            maxDate.setFullYear(this.props.yearSelected);
            maxDate.setMonth("11");
            maxDate.setDate("31");
           
            maxDate = new Date(maxDate + 86400000);
           
            let disableDate = new Date();
            disableDate.setFullYear(this.props.yearSelected - 1);
            disableDate.setMonth("11");
            disableDate.setDate("31");       
            let newArray = this.state.disableDateConstraint;
            newArray.push(disableDate)

            this.setState({
                loading: false,
                centerMasterIDShow: this.props.centerMasterID,
                displayDialog: this.props.displayDialog,
                leaveTemplate: this.props.leaveTemplate,
                employeeLeaveData: this.props.employeeLeaveData,
                disabledDates: this.props.disabledDates,
                yearSelected: this.props.yearSelected,
                leaveCodesAndDescriptionsArray: this.props.leaveCodesAndDescriptionsArray,
                disableDateConstraint: newArray,
                minDate: minDate,
                maxDate: maxDate
            })
        }

        this.disabledDates();
        document.getElementById('leaveStartDate').firstChild.style.width = "174px";

    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.employeeLeaveData !== null) {
            this.setState({
                loading: false,
                centerMasterIDShow: nextProps.centerMasterID,
                displayDialog: nextProps.displayDialog,
                leaveTemplate: nextProps.leaveTemplate,
                employeeLeaveData: nextProps.employeeLeaveData
            })
        }

        // this.callServiceToGetLeaveCodeAndDescription();
    }

    preventCloseOfDialog = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    //Function to disable dates where already leave is allocated
    disabledDates = () => {

        let newArray = this.state.disableDateConstraint;
        // if(this.state.disabledDates!==undefined && this.state.disabledDates!==[]){
        if (this.props.disabledDates !== undefined && this.props.disabledDates !== []) {
            this.props.disabledDates.map(leaveDate => {
                let newLeavedate = new Date(leaveDate)
                return newArray.push(newLeavedate)
            })
            this.setState({ disableDateConstraint: newArray })
        }
    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {

        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life: leaveCodes.growlMessageTimeout, summary: translator.errorMessage, detail: detail, severity: "error" });
    }

    //Function to call calbackprop and hide this dialog
    callcallbackToHideDialog = () => {
        this.setState({ displayDialog: false })
        this.props.callbackPropToHideDialog();

    }

    //Function to call Employee Dialog function to call delete service
    callVacationDialogFunctionToSave = () => {
        let leaveDates = this.state.leaveDates
        let leaveStartDate = "";
        let leaveEndDate = "";
        let date1 = new Date(leaveDates[0]);
        let date2 = new Date(leaveDates[1]);
        if (date2 < date1) {
            leaveStartDate = date1.getFullYear() + '-' + (this.getMonthCheck(date1.getMonth() + 1)) + '-' + this.getDateCheck(date1.getDate());
            leaveEndDate = null;

            let jsonCopy = Object.assign({}, this.state.employeeLeaveData);
            let jsonCopy1 = Object.assign({}, this.state.leaveTemplate);
            jsonCopy1.costCenterMasterId = this.state.centerMasterIDShow;
            jsonCopy1.employeeId = this.state.employeeLeaveData.employeeDetailsId;
            jsonCopy1.leaveCode = this.state.leaveDetail.leaveCode;
            jsonCopy1.dates = [leaveStartDate, leaveStartDate];
            this.setState({ leaveTemplate: jsonCopy1 })
            this.props.onCallbackSaveService(jsonCopy1, jsonCopy);
        }
        else {
            leaveStartDate = date1.getFullYear() + '-' + (this.getMonthCheck(date1.getMonth() + 1)) + '-' + this.getDateCheck(date1.getDate());
            leaveEndDate = date2.getFullYear() + '-' + (this.getMonthCheck(date2.getMonth() + 1)) + '-' + this.getDateCheck(date2.getDate());

            let jsonCopy = Object.assign({}, this.state.employeeLeaveData);
            let jsonCopy1 = Object.assign({}, this.state.leaveTemplate);
            jsonCopy1.costCenterMasterId = this.state.centerMasterIDShow;
            jsonCopy1.employeeId = this.state.employeeLeaveData.employeeDetailsId;
            jsonCopy1.leaveCode = this.state.leaveDetail.leaveCode;
            jsonCopy1.dates = [leaveStartDate, leaveEndDate];
            this.setState({ leaveTemplate: jsonCopy1 })
            this.props.onCallbackSaveService(jsonCopy1, jsonCopy);
        }

    }

    //function to add '0' in single digit month (ex:01,02)
    getMonthCheck = (month) => {
        let newMonth = 0
        if (month < 10) {
            newMonth = '0' + month;
            return newMonth
        }
        else {
            return month
        }

    }

    //function to add '0' in single digit date (ex:01,02)
    getDateCheck = (date) => {
        let newDate = 0
        if (date < 10) {
            newDate = '0' + date;
            return newDate
        }
        else {
            return date
        }
    }

    updateProperty = (property, value) => {

        if (property === "leaveCode") {
            let jsonCopy = Object.assign({}, this.state.leaveDetail);
            jsonCopy[property] = value.value;
            this.setState({ leaveDetail: jsonCopy, leaveCodeShow: value })
        }

    }

    setCalendarDate = (e) => {
        if (e.value !== null) {
            this.setState({ leaveDates: e.value });

            let leaveDates = e.value;
            let date1 = new Date(leaveDates[0]);
            let date2 = new Date(leaveDates[1]);
            this.setState({
                startDateDisplay: date1.getFullYear() + '-' + (this.getMonthCheck(date1.getMonth() + 1)) + '-' + this.getDateCheck(date1.getDate()),
                endDateDisplay: leaveDates[1] === null ? null : date2.getFullYear() + '-' + (this.getMonthCheck(date2.getMonth() + 1)) + '-' + this.getDateCheck(date2.getDate())
            })

            if (date2 > date1) {
                document.getElementsByClassName("p-datepicker")[0].style = "display:none !important";
            }
        }

    }



    render() {
       
        translator.setLanguage(this.props.browserLanguage);

        let leaveDropDown = this.props.leaveCodesAndDescriptionsArray;

        let dialogFooter = (<div className="ui-dialog-buttonpane p-clearfix">
            <Button id="saveLeave" label={translator.save} icon="pi pi-check" className="deleteButton" onClick={this.callVacationDialogFunctionToSave} disabled={this.state.leaveCodeShow !== null && this.state.leaveDates !== null ? false : true} />
        </div>);


        return (

            <React.Fragment>

                <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                <Dialog visible={this.state.displayDialog} className="dialogBoxPosition" id="vacationDialog" modal={true} header="Plan Leave" footer={dialogFooter} onHide={keepLeaveDialogCompOpen ? () => { } : this.callcallbackToHideDialog}>
                    {
                        <div>
                            <table>
                                <tbody>
                                    <tr className="padding7pxBottom">
                                        <td >
                                            <label >{translator.employeeId}</label>
                                        </td>
                                        <td>
                                            <span className="marginLeft">
                                                <InputText id="employeeIdDialog" maxLength="8" value={this.props.employeeLeaveData.employeeType !== translator.dummy ? this.props.employeeLeaveData.employeeNumber : translator.dummyEmployee} disabled={true} style={{ width: "206px" }} />
                                            </span>
                                        </td>
                                    </tr>

                                    <tr className="padding7pxBottom">
                                        <td >
                                            <label >{translator.employeeName}</label>
                                        </td>
                                        <td>
                                            <span className="marginLeft">
                                                <InputText id="employeeNameDialog" disabled={true} value={this.props.employeeLeaveData.employeeName} style={{ width: "206px" }} />
                                            </span>
                                        </td>
                                    </tr>

                                    <tr className="padding7pxBottom">
                                        <td >
                                            <label >{translator.leaveCode}<span className="requiredAsterisk">*</span></label>
                                        </td>

                                        <td>
                                            <span className="dropDwonDefault marginLeft">
                                                <Dropdown
                                                    id="leaveCode"
                                                    value={this.state.leaveCodeShow}
                                                    options={leaveDropDown}
                                                    optionLabel="label"
                                                    style={{ width: "14.8em" }}
                                                    onChange={(e) => this.updateProperty("leaveCode", e.value)}
                                                />
                                            </span>
                                        </td>
                                    </tr>

                                    <tr className="padding7pxBottom">
                                        <td>
                                            <label >{translator.dateRange}<span className="requiredAsterisk">*</span></label>
                                        </td>

                                        <td>
                                            <span className="marginLeft">
                                                <Calendar
                                                    id="leaveStartDate"
                                                    className="vacationDialogCalendar"
                                                    locale={this.props.browserLanguage === 'en' ? en : fi}
                                                    viewDate={this.props.yearSelected === currentYear ? new Date() : new Date("01/01/" + String(this.props.yearSelected))}
                                                    showIcon={true}
                                                    minDate={this.state.minDate}
                                                    maxDate={this.state.maxDate}
                                                    readOnlyInput={true}
                                                    disabledDates={this.state.disableDateConstraint}
                                                    value={this.state.leaveDates}
                                                    onChange={(e) => this.setCalendarDate(e)}
                                                    selectionMode="range" />
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            {this.state.startDateDisplay !== null ? <div className="dispalyLeave font-weight-bold">StartDate : {this.state.startDateDisplay}</div> : null}
                            {this.state.endDateDisplay !== null ? <div className="dispalyLeave font-weight-bold">End Date : {this.state.endDateDisplay}</div> : null}

                        </div>
                    }
                </Dialog>

            </React.Fragment>
        );
    }
}

export default VacationLeaveDialog;