import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
let translator = new LocalizedStrings(translation);

class CopyPlanningPeriod extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPlaningPeriod: []
        }
    }

    onPlanningPeriodSelect = (e) => {

        this.setState({
            selectedPlaningPeriod: e.target.value
        });
        if (e.target.value !== null) {
            this.props.onCallback(e.value.planningPerioId);
        }
        else {
            this.props.onCallback('');
        }
    }

    render() {
        translator.setLanguage(this.props.browserLanguage);

        return (
            <div className={ this.props.browserLanguage === 'en' ? "CopyDropdownEN dropDwonDefault" : "CopyDropdownFI dropDwonDefault" }>
                <strong>{this.props.displayLabel} : </strong>
                <Dropdown value={this.state.selectedPlaningPeriod}
                    options={this.props.ppData === null ? null : this.props.ppData}
                    onChange={(e) => this.onPlanningPeriodSelect(e)}
                    placeholder={translator.selectPlanningPeriod}
                    optionLabel='planningPeriodDescription'
                    className="planningPeriodDropdown"
                />
            </div>
        );
    }
}


export default CopyPlanningPeriod;