import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import { TabView, TabPanel } from 'primereact/tabview';
import { RadioButton } from '../../../node_modules/primereact/radiobutton';
let translator = new LocalizedStrings(translation);

class ConfirmationDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPlanningPeriod: "",
            printFormat: false,
            shiftwithStartTime: true
        }
    }

    //Function to call Employee Dialog function to dismiss delete confirmation dialog and again show Employee Dialog
    callToDismissDialog = () => {
        this.props.callbackProp();
    }

    //Function to call Employee Dialog function to call delete service
    callPrintFunctionInTVS = () => {
        let planningRecordSelected = this.props.planningPeriodList.filter(data => data.planingPeriodPart === this.state.selectedPlanningPeriod);
        this.props.callbackPrintService(this.state.selectedPlanningPeriod, planningRecordSelected, this.state.shiftWithTimeFormat);
    }

    //Function to set focus to Yes button
    setFocusOnYesBtn = () => {
        if (document.getElementById("yesbtn") !== null)
            setTimeout(function () { document.getElementById("yesbtn").focus(); }, 2);
    }

    displayShiftFormat = (value) => {
        this.setState({ loading: true });
        if (value === "shiftwithStartTime") {
            this.setState({ shiftWithTimeFormat: true})
        }
        else {
            this.setState({ shiftWithTimeFormat: false})
        }
    }

    render() {

        translator.setLanguage(this.props.lang);

        let dialogFooter = (<div className="ui-dialog-buttonpane p-clearfix">
            <Button id="yesbtn" label={translator.yes} className="deleteButton" onClick={this.callPrintFunctionInTVS} disabled={this.state.selectedPlanningPeriod === "" ? true : false} style={{ padding: '2px' }} />
            <Button label={translator.no} className="deleteButton" onClick={this.callToDismissDialog} style={{ padding: '2px' }} />
        </div>);

        return (

            <Dialog visible={true} modal={true} header={translator.selectPlanningPeriod} onHide={this.callToDismissDialog} footer={dialogFooter} onShow={this.setFocusOnYesBtn} style={{ width: '27%' }}>
                
                {this.props.planningPeriodList !== undefined ?
                    <div>
                        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                            <TabPanel header={translator.twoWeeks}>
                                <div style={{ padding: '10px' }}>
                                    <div className="padding8pxBottom"><RadioButton value={this.props.planningPeriodList[0].planingPeriodPart} name="planningPeriod" onChange={(e) => this.setState({ selectedPlanningPeriod: e.value })} checked={this.state.selectedPlanningPeriod === this.props.planningPeriodList[0].planingPeriodPart} /> <label className="marginRight20 padding3PerTop"> {this.props.planningPeriodList[0].planingPeriodPart}</label></div>
                                    <div className="padding8pxBottom"><RadioButton value={this.props.planningPeriodList[1].planingPeriodPart} name="planningPeriod" onChange={(e) => this.setState({ selectedPlanningPeriod: e.value })} checked={this.state.selectedPlanningPeriod === this.props.planningPeriodList[1].planingPeriodPart} /> <label className="marginRight20 padding3PerTop"> {this.props.planningPeriodList[1].planingPeriodPart}</label></div>
                                    <div className="padding8pxBottom"><RadioButton value={this.props.planningPeriodList[3].planingPeriodPart} name="planningPeriod" onChange={(e) => this.setState({ selectedPlanningPeriod: e.value })} checked={this.state.selectedPlanningPeriod === this.props.planningPeriodList[3].planingPeriodPart} /> <label className="marginRight20 padding3PerTop"> {this.props.planningPeriodList[3].planingPeriodPart}</label></div>
                                    <div className="padding8pxBottom"><RadioButton value={this.props.planningPeriodList[4].planingPeriodPart} name="planningPeriod" onChange={(e) => this.setState({ selectedPlanningPeriod: e.value })} checked={this.state.selectedPlanningPeriod === this.props.planningPeriodList[4].planingPeriodPart} /> <label className="marginRight20 padding3PerTop"> {this.props.planningPeriodList[4].planingPeriodPart}</label></div>
                                    <div className="padding8pxBottom"><RadioButton value={this.props.planningPeriodList[6].planingPeriodPart} name="planningPeriod" onChange={(e) => this.setState({ selectedPlanningPeriod: e.value })} checked={this.state.selectedPlanningPeriod === this.props.planningPeriodList[6].planingPeriodPart} /> <label className="marginRight20 padding3PerTop"> {this.props.planningPeriodList[6].planingPeriodPart}</label></div>
                                    <div ><RadioButton value={this.props.planningPeriodList[7].planingPeriodPart} name="planningPeriod" onChange={(e) => this.setState({ selectedPlanningPeriod: e.value })} checked={this.state.selectedPlanningPeriod === this.props.planningPeriodList[7].planingPeriodPart} /> <label className="marginRight20 padding3PerTop"> {this.props.planningPeriodList[7].planingPeriodPart}</label></div>
                                </div>
                            </TabPanel>
                            <TabPanel header={translator.fourWeeks}>
                                <div style={{ padding: '10px' }}>
                                    <div className="padding8pxBottom"><RadioButton value={this.props.planningPeriodList[2].planingPeriodPart} name="planningPeriod" onChange={(e) => this.setState({ selectedPlanningPeriod: e.value })} checked={this.state.selectedPlanningPeriod === this.props.planningPeriodList[2].planingPeriodPart} /> <label className="marginRight20 padding3PerTop"> {this.props.planningPeriodList[2].planingPeriodPart}</label></div>
                                    <div className="padding8pxBottom"><RadioButton value={this.props.planningPeriodList[5].planingPeriodPart} name="planningPeriod" onChange={(e) => this.setState({ selectedPlanningPeriod: e.value })} checked={this.state.selectedPlanningPeriod === this.props.planningPeriodList[5].planingPeriodPart} /> <label className="marginRight20 padding3PerTop"> {this.props.planningPeriodList[5].planingPeriodPart}</label></div>
                                    <div ><RadioButton value={this.props.planningPeriodList[8].planingPeriodPart} name="planningPeriod" onChange={(e) => this.setState({ selectedPlanningPeriod: e.value })} checked={this.state.selectedPlanningPeriod === this.props.planningPeriodList[8].planingPeriodPart} /> <label className="marginRight20 padding3PerTop"> {this.props.planningPeriodList[8].planingPeriodPart}</label></div>
                                </div>
                            </TabPanel>
                            <TabPanel header={translator.printFormat}>
                            <div style={{ padding: '10px' }}>
                                    <div className="padding8pxBottom"><RadioButton value="shiftwithStartTime" name="displayFormat" onChange={(e) => this.displayShiftFormat(e.target.value)} checked={this.state.shiftWithTimeFormat} /> <label className="marginRight20 padding3PerTop"> {translator.printPDFWithTime}</label></div>
                                    <div className="padding8pxBottom"><RadioButton value="shiftwithoutStartTime" name="displayFormat" onChange={(e) => this.displayShiftFormat(e.target.value)} checked={!this.state.shiftWithTimeFormat} /> <label className="marginRight20 padding3PerTop"> {translator.printPDFWithoutTime}</label></div>
                                </div>
                            
                            </TabPanel>
                        </TabView>
                    </div>

                    : null
                    }
            </Dialog>
        );

    }

}

export default ConfirmationDialog;