import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import FileSaver from 'file-saver';
import { DataTable } from 'primereact/datatable';
import { CommonService } from '../../service/CommonService';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Button } from 'primereact/button';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
// import { InputMask } from 'primereact/inputmask';
import InputMask from 'react-input-mask';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import leaveCodes from '../../utility/leaveCodes.json';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import { InputText } from 'primereact/inputtext';
import browser from 'browser-detect';
import { OverlayPanel } from 'primereact/overlaypanel';
import ConfirmationDialog from './ConfirmationDialog.js';
import { keyboardValues, dailyWeekend, getResolutionDailyTVS, maxDuration } from "../../utility/commonUtility";
import './TVSReport.css';

let translator = new LocalizedStrings(translation);
let headerDates = [];
let headerWeeks = [];
let headerERO = [];
let headerScheduledHrsForDay = [];
let headerTargetHoursForDay = [];
let averageWeekData = [];
let holidayArray = [];
let totalTvsRecords = 0;
let pageNumber = 0;
let pageSize = 0;
let startDate = "";
let noOfWorkDay = 0;
let commentData = '';

let weekdays = ['week1day1', 'week1day2', 'week1day3', 'week1day4', 'week1day5', 'week1day6', 'week1day7',
    'week2day1', 'week2day2', 'week2day3', 'week2day4', 'week2day5', 'week2day6', 'week2day7',
    'week3day1', 'week3day2', 'week3day3', 'week3day4', 'week3day5', 'week3day6', 'week3day7',
    'week4day1', 'week4day2', 'week4day3', 'week4day4', 'week4day5', 'week4day6', 'week4day7',
    'week5day1', 'week5day2', 'week5day3', 'week5day4', 'week5day5', 'week5day6', 'week5day7',
    'week6day1', 'week6day2', 'week6day3', 'week6day4', 'week6day5', 'week6day6', 'week6day7',
    'week7day1', 'week7day2', 'week7day3', 'week7day4', 'week7day5', 'week7day6', 'week7day7',
    'week8day1', 'week8day2', 'week8day3', 'week8day4', 'week8day5', 'week8day6', 'week8day7',
    'week9day1', 'week9day2', 'week9day3', 'week9day4', 'week9day5', 'week9day6', 'week9day7',
    'week10day1', 'week10day2', 'week10day3', 'week10day4', 'week10day5', 'week10day6', 'week10day7',
    'week11day1', 'week11day2', 'week11day3', 'week11day4', 'week11day5', 'week11day6', 'week11day7',
    'week12day1', 'week12day2', 'week12day3', 'week12day4', 'week12day5', 'week12day6', 'week12day7',];

class DailyReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            blankValue: false,
            totalRecordsCount: 0,
            printPDFClicked: false,
            shiftStartTimeFormat: true,
            updateTemplate: {
                allocationPlanDetailId: null,
                tvsStartAndEndTime: ''
            },
            printTemplate: {
                aiIntegrationLogId: 0,
                planningPeriodID: "",
                centerMasterID: null,
                startWeek: "",
                twoWeekPlanningPeriod: true,
                shiftWithStartTime: true,
                exceptionWeeks: [],
                exceptionDates: [], //array of dates checked for getting data
                employeeIds: [],
                employeeGroupName: [],
                employeeSearch: {
                    supervisorId: "",
                    costCenterCode: "",
                    employeeId: "",
                    employeeName: "",
                    employeeTypeCode: "",
                    sortBy: "employeeName",
                    sortDirection: "ASC",
                    pageNumber: 0,
                    recordCount: 150,
                    shiftCodeList: ""
                }
            },
            tvsData: null,
            nextPP: 0,
            copyShiftTimeJson :{
                fromEmployeeId: null,
                toEmployeeIdList: [],
                selectedDates: [],
                costCenterId: null
            },
            selectedEmployee : []

        }
        this.commonService = new CommonService();
    }

    componentDidMount = () => {

        if (document.getElementById('employeeName')) {
            document.getElementById('employeeName').value = ''
        }
        if (document.getElementById('employeeTypeCode')) {
            document.getElementById('employeeTypeCode').value = ''
        }

        if (this.props.previousData === null) {
            this.setState({ jsonTemplate: this.props.jsonTemplate, nextPP: 0 });

            if (this.props.jsonTemplate.balancingPeriodID !== "" && this.props.jsonTemplate.centerMasterID !== "" && this.props.jsonTemplate.planningPeriodID !== "")
                this.getTVS(this.props.jsonTemplate);
        }
        else {
            holidayArray = this.props.previousData.holidays;
            headerDates = this.props.previousData.dateString;
            headerWeeks = this.props.previousData.shiftWeeks;
            headerERO = this.props.previousData.ero;
            headerScheduledHrsForDay = this.props.previousData.scheduledHrsForDay;
            headerTargetHoursForDay = this.props.previousData.targetHoursForDay;
            averageWeekData = this.props.previousData.averageWeekData;
            totalTvsRecords = this.props.previousData.totalRecords;
            pageNumber = this.props.previousData.pageNumber;
            pageSize = this.props.previousData.shiftPageSize;
            startDate = this.props.previousData.startDate;
            noOfWorkDay = this.props.previousData.workingDays;
            this.props.masterData.balancingPeriodList.map((data) => {
                data.planningPeriodList.map((pp, key) => {
                    if (pp.planningPeriodId === this.props.previousData.planningPeriod.planningPeriodId) {
                        this.setState({ nextPP: key });
                    }
                    return false;
                })

                return false;
            });
            this.setState({
                tvsData: this.props.previousData.data,
                tvsDataCopy: this.props.previousData.data,
                jsonTemplate: this.props.jsonTemplate,
                loading: false,
                totalRecordsCount: this.props.previousData.totalRecords
            });

        }

    }

    componentWillReceiveProps = (nextProps) => {
        if (document.getElementById('employeeName')) {
            document.getElementById('employeeName').value = ''
        }
        if (document.getElementById('employeeTypeCode')) {
            document.getElementById('employeeTypeCode').value = ''
        }
        if (nextProps.previousData === null) {
            this.setState({
                jsonTemplate: nextProps.jsonTemplate,
                nextPP: 0
            });
            if (nextProps.jsonTemplate.balancingPeriodID !== "" && nextProps.jsonTemplate.centerMasterID !== "" && nextProps.jsonTemplate.planningPeriodID !== "")
                this.getTVS(nextProps.jsonTemplate);
        }
        else {
            holidayArray = nextProps.previousData.holidays;
            headerDates = nextProps.previousData.dateString;
            headerWeeks = nextProps.previousData.shiftWeeks;
            headerERO = nextProps.previousData.ero;
            headerScheduledHrsForDay = nextProps.previousData.scheduledHrsForDay;
            headerTargetHoursForDay = nextProps.previousData.targetHoursForDay;
            averageWeekData = nextProps.previousData.averageWeekData;
            totalTvsRecords = nextProps.previousData.totalRecords;
            pageNumber = nextProps.previousData.pageNumber;
            pageSize = nextProps.previousData.shiftPageSize;
            startDate = nextProps.previousData.startDate;
            noOfWorkDay = nextProps.previousData.workingDays;
            nextProps.masterData.balancingPeriodList.map((data) => {
                data.planningPeriodList.map((pp, key) => {
                    if (pp.planningPeriodId === nextProps.previousData.planningPeriod.planningPeriodId) {
                        this.setState({ nextPP: key });
                    }
                    return false;
                })

                return false;
            });
            this.setState({
                tvsData: nextProps.previousData.data,
                tvsDataCopy: nextProps.previousData.data,
                jsonTemplate: nextProps.jsonTemplate,
                loading: false
            });

        }
    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {

        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });


    }
    //Function to implement success handling and show Growl message.
    showSuccessMessage = () => {

        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'success', summary: translator.successMessage, detail: translator.detailsUpdateSucess });

    }
    //Function to call API for Daily TVS Data
    getTVS = (payload) => {
        this.setState({ loading: true });
        this.commonService.getDailyTVS(payload, this.props.browserLanguage)
            .then(res => {
                if (res !== undefined && res !== null) {
                    holidayArray = res.holidays;
                    headerDates = res.dateString;
                    headerWeeks = res.shiftWeeks;
                    headerERO = res.ero;
                    headerScheduledHrsForDay = res.scheduledHrsForDay;
                    headerTargetHoursForDay = res.targetHoursForDay;
                    averageWeekData = res.averageWeekData;
                    totalTvsRecords = res.totalRecords;
                    pageNumber = res.pageNumber;
                    pageSize = res.shiftPageSize;
                    startDate = res.startDate;
                    noOfWorkDay = res.workingDays;
                    this.setState({
                        tvsData: res.data,
                        tvsDataCopy: res.data,
                        loading: false,
                        totalRecordsCount: res.totalRecords,
                        copyShiftTimeJson :{
                            fromEmployeeId: null,
                            toEmployeeIdList: [],
                            selectedDates: [],
                            costCenterId: null
                        },
                        selectedEmployee : []
                    });
                }

            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
                //Utility.showMultiple(err);
            })
    }

    validationError(errorBody) {
        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: errorBody });
    }

    // function to check if shift duration does not exceed 15 hour
    checkDuration = (duration) => {
        let durationArray = duration.split(':');

        if ((Number(durationArray[1]) > 59) || (Number(durationArray[0]) > maxDuration) || ((Number(durationArray[0]) === maxDuration) && (Number(durationArray[1]) > 0))) {
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'warn', summary: translator.warningMessage, detail: translator.shiftDurationError });
            return false
        }

        else {
            return true
        }
    }

    // function to check if shift start time does not exceed 24 hour
    checkStartTime = (startTime) => {
        let startTimeArray = startTime.split(':');
        if ((Number(startTimeArray[1]) > 59) || (Number(startTimeArray[0]) > 23) || ((Number(startTimeArray[0]) === 23) && (Number(startTimeArray[1]) > 0))) {
            this.validationError(translator.timeError);
            return false
        }
        else {
            return true
        }
    }

    //function to add '0' in single digit time (ex:01,02)
    getTimeCheck = (date) => {
        let newDate = 0
        if (date < 10) {
            newDate = '0' + date;
            return newDate
        }
        else {
            return date
        }
    }

    //function to get updated end time when duration is changed.
    getEndTime = (startTime, duration) => {
        let shiftEndTime = "";
        let newHour = 0;
        let startTimeArray = startTime.split(':');
        let durationArray = duration.split(':');
        let hours = ((Number(startTimeArray[0]) * 60 + Number(startTimeArray[1])) + (Number(durationArray[0]) * 60 + Number(durationArray[1]))) / 60
        let rhours = Math.floor(hours);
        let minutes = (hours - rhours) * 60;
        let rminutes = Math.round(minutes);

        if (hours > 24) {
            newHour = rhours - 24;
        }
        else {
            newHour = rhours;
        }

        if (isNaN(rhours) && isNaN(rminutes)) {
            shiftEndTime = "";
        }

        else {
            shiftEndTime = this.getTimeCheck(newHour) + ":" + this.getTimeCheck(rminutes);
        }
        return shiftEndTime
    }

    // update the value of rowData onChange event while editing start time and duration.
    updatePropertyDuration = (rowData, data, value, oldDuration) => {
        if (!value.includes('_')) {
            let splitDuration = value.split('|');
            if (this.checkDuration(splitDuration[1]) && this.checkStartTime(splitDuration[0])) {
                let newEndTime = this.getEndTime(splitDuration[0], splitDuration[1]);
                var newStartEndTime = splitDuration[0] + " - " + newEndTime

                document.getElementById('s' + rowData.shiftAllocationPlanDetailId).blur();
                rowData.tvsStartAndEndTime = newStartEndTime;
                rowData.tvsShiftDuration = splitDuration[1];

                let jsonCopy = Object.assign({}, this.state.updateTemplate);
                jsonCopy.allocationPlanDetailId = [rowData.shiftAllocationPlanDetailId];
                jsonCopy.tvsStartAndEndTime = newStartEndTime;
                this.setState({ updateTemplate: jsonCopy, loading: true });

                this.commonService.updateDailyTVSData(jsonCopy, this.props.browserLanguage)
                    .then(res => {
                        this.getTVS(this.state.jsonTemplate);

                        if (res.errorMessages.length > 0) {
                            this.showMultiplewithScroll(res, 'error');
                        }
                        else if(res.errorMessages.length === 0){
                            this.showSuccessMessage();
                        }
                    })
                    .catch(err => {
                        // this.getTVS(this.state.jsonTemplate);
                        this.showMultiple(err);
                    })
            }

            else {
                rowData.tvsStartTimeAndDuration = oldDuration;
                this.validationError(translator.shiftDurationError);
                document.getElementById('s' + rowData.shiftAllocationPlanDetailId).blur();
                return false;
            }
        }
    }

    //function to update the tvsStartAndEndTime and tvsStartTimeAndDuration on change of the value
    updateProperty = (e, rowData, data, value, property) => {
        this.setState({ hidetooltip: true });
        if (!rowData.tvsStartAndEndTime.includes('_')) {this.setState({oldDataTime:rowData.tvsStartAndEndTime}) }

        if (property === "tvsStartAndEndtime") {
            rowData.tvsStartAndEndTime = value;
            if (!value.includes('_')) {

                if (
                    Number(value.split('-')[0].trim().split(':')[0]) > 23
                    || Number(value.split('-')[0].trim().split(':')[1]) > 59
                    || Number(value.split('-')[1].trim().split(':')[0]) > 23
                    || Number(value.split('-')[1].trim().split(':')[1]) > 59
                ) {
                    this.validationError(translator.timeError);
                    rowData.tvsStartAndEndTime = this.state.oldDataTime;
                    document.getElementById('d' + rowData.shiftAllocationPlanDetailId).blur();
                    return false;
                }

                let jsonCopy = Object.assign({}, this.state.updateTemplate);
                jsonCopy.allocationPlanDetailId = [rowData.shiftAllocationPlanDetailId];
                jsonCopy.tvsStartAndEndTime = value;
                this.setState({ updateTemplate: jsonCopy });

                this.setState({ loading: true, hidetooltip: false });
                document.getElementById('d' + rowData.shiftAllocationPlanDetailId).blur();
                this.commonService.updateDailyTVSData(jsonCopy, this.props.browserLanguage)
                    .then(res => {
                        this.getTVS(this.state.jsonTemplate);

                        if (res.errorMessages.length > 0) {
                            this.showMultiplewithScroll(res, 'error');
                        }

                        else if(res.errorMessages.length === 0){
                            this.showSuccessMessage();
                        }
                    })
                    .catch(err => {
                        // this.getTVS(this.state.jsonTemplate);
                        this.showMultiple(err);
                    })

            }
        }

        else if (property === "tvsShiftDuration") {
            if (!rowData.tvsStartTimeAndDuration.includes('_')) { this.setState({oldDataDuration:rowData.tvsStartTimeAndDuration}) }
            rowData.tvsStartTimeAndDuration = value;
            if (!value.includes('_')) {
                this.updatePropertyDuration(rowData, data, value, this.state.oldDataDuration);
            }
        }
    }

    showMultiplewithScroll = (errorBody, type) => {
        
        this.setState({ growlWithScroll: true });
        errorBody = errorBody.errorMessages.filter(item => item !== null);
        // errorBody = errorBody.errorMessages;
        var detail = errorBody.map((item, key) => {
            return (<li key={key}>{item}</li>)
        })
        if (errorBody.length > 0) {
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: type, summary: translator.errorMessage, detail: detail });
        }
    }


    timeConvert = (n) => {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + ":" + rminutes;
    }

    //function to called on bulk update of tvsStartAndEndTime or tvsStartTimeAndDuration
    bulkUpdate = (event, rowData, value, data, column, property) => {
        let arraySample = [];
        let weekdayLength = this.state.jsonTemplate.twelveWeekDisplay === true ? 84 : 28;
        if ((event.altKey && (event.keyCode === 48 || event.keyCode === 49 || event.keyCode === 50 || event.keyCode === 51 || event.keyCode === 52)) && !value.includes('_')) {

            let startIndex = headerDates.indexOf(data[column.field].shiftsOnThisDay[0].date);
            let endIndex = "";
            switch (event.key) {
                case '1':
                    endIndex = 7 + (7 * Math.floor(startIndex / 7));
                    break;

                case '2':
                    endIndex = 14 + (7 * Math.floor(startIndex / 7));
                    break;

                case '3':
                    endIndex = 21 + (7 * Math.floor(startIndex / 7));
                    break;

                case '4':
                    endIndex = 28 + (7 * Math.floor(startIndex / 7));
                    break;

                case '0':
                    endIndex = weekdayLength;
                    break;

                default:
                    break;
            }

            for (let i = startIndex; i < endIndex; i++) {
                for (let j = 0; j < weekdayLength; j++) {
                    if (data[weekdays[i]].shiftsOnThisDay[0].date === headerDates[i]) {
                        data[weekdays[i]].shiftsOnThisDay.map(data => {
                            if (data.shiftCode === rowData.shiftCode && !arraySample.includes(data.shiftAllocationPlanDetailId)) {
                                arraySample.push(data.shiftAllocationPlanDetailId);
                            }
                            return 0;
                        })
                    }
                }
            }
            if (!value.includes('_')) {

                if (property === "tvsStartAndEndtime") { document.getElementById('d' + rowData.shiftAllocationPlanDetailId).blur(); } else { document.getElementById('s' + rowData.shiftAllocationPlanDetailId).blur(); }
                let jsonCopy = Object.assign({}, this.state.updateTemplate);
                jsonCopy.allocationPlanDetailId = arraySample;
                jsonCopy.tvsStartAndEndTime = rowData.tvsStartAndEndTime;
                this.setState({ updateTemplate: jsonCopy, loading: true });


                this.commonService.updateDailyTVSData(jsonCopy, this.props.browserLanguage)
                    .then(res => {
                        this.getTVS(this.state.jsonTemplate);

                        if(this.state.updateTemplate.allocationPlanDetailId.length > res.errorMessages.length){
                            this.showSuccessMessage();
                        }

                        if (res.errorMessages.length > 0) {
                            this.showMultiplewithScroll(res, 'error');
                        }

                    })
                    .catch(err => {
                        // this.getTVS(this.state.jsonTemplate);
                        this.showMultiple(err);
                    })
            }
        }
    }

    eroTemplate = (rowData, column) => {
        if (rowData.ero.split(':')[0].includes('-')) {
            return (
                <div className="negativeDifference textAlignRightHeaderTVS">{rowData.ero}</div>
            )
        }
        else {
            return (
                <div className="textAlignRightHeaderTVS">{rowData.ero}</div>
            )
        }

    }

    dataTemplate = (rowData, column) => {
        return (
            <div className="textAlignRightHeaderTVS">{rowData[column.field]}</div>
        )
    }
    showDailyComment = (e, rowData, column) => {
        commentData = '';
        this.op.toggle(e);
        commentData =
            <div>
                {rowData[column.field].shiftsOnThisDay.map((data, index) => {
                    return (
                        <div key={index}>
                            {index !== 0 ? <hr /> : null}
                            <span>
                                {data.shiftCode} - {data.shiftComment}
                            </span>
                        </div>
                    )
                })}
            </div>
        this.setState({});

    }
    //Function to set the body template of Each Cell.
    bodyTemplate = (rowData, column) => {
        
        let displayTick=<div  style={{ width: '15%' ,float :'right', position: 'absolute', bottom : 0, right: 0}}>
        <i  className="pi pi-check-circle" style ={{color : this.state.copyShiftTimeJson.fromEmployeeId!==null &&
             this.state.copyShiftTimeJson.fromEmployeeId===rowData.employeeId && 
             rowData[column.field] !== undefined && rowData[column.field] !== null &&
             this.state.copyShiftTimeJson.selectedDates.includes(rowData[column.field].shiftsOnThisDay[0].date )
             ? "green" : "rgb(206, 199, 199)"}} onClick={(e) => this.selectCopyFromData(e, rowData, column)}></i>
    </div>;
        if (rowData[column.field] !== undefined && rowData[column.field] !== null) {
            let commentIcon = true;
            return (<div style={{ height: '69px' ,position: 'relative'}}>
                {
                    rowData[column.field].shiftsOnThisDay.map((data, key) => {
                        if (data.shiftComment !== null && data.shiftComment !== "" && commentIcon === true && data.shiftCode.toLowerCase() !== leaveCodes.DE.toLowerCase()) {
                            commentIcon = false;
                            return (
                               
                                <div key={key} style={{ width: '15%', float: 'right' }}>
                                    <i style={{ color: leaveCodes.color_dailyCommentActive }} className="pi pi-comment commentPositionForShiftTags" onClick={(e) => this.showDailyComment(e, rowData, column)}></i>
                                </div>
                               
                            )
                        }
                        if (data.shiftCode.toLowerCase() === leaveCodes.DE.toLowerCase()) {
                            commentIcon = false;
                        }
                        return false;
                    })
                }
                {
                    commentIcon === true ?
                        
                        <div style={{ width: '15%', float: 'right' }}>
                            <i style={{ color: leaveCodes.color_dailyCommentUnActive }} className="pi pi-comment commentPositionForShiftTags" onClick={(e) => this.showDailyComment(e, rowData, column)}></i>
                        </div>
                        
                  
                        :  null
                }
                <div style={{ width: '85%' }}>
                    {
                        rowData[column.field].shiftsOnThisDay.map((data, key) => {

                            if (data.shiftCode.toLowerCase() === leaveCodes.DE.toLowerCase() && dailyWeekend.includes(column.field)) {
                                displayTick=null;
                                return (<div key={key} className="elementFillTVSDeactivated cellAlignmentTVS" title={data.shiftRemarks} style={{ backgroundColor: leaveCodes.color_Hol }}>-</div>)
                            }
                            if (data.shiftCode === '-' && dailyWeekend.includes(column.field)) {
                                displayTick=null;
                                return (<div key={key} style={{ backgroundColor: leaveCodes.color_weekend }} className="elementFillTVS cellAlignmentTVS">-</div>)
                            }
                            if (rowData[column.field] !== null) {

                                switch (data.shiftCode.toLowerCase()) {

                                    case '-':
                                        displayTick=null;
                                        return (<div key={key} className="elementFillTVS cellAlignmentTVS">-</div>)

                                    case leaveCodes.DE.toLowerCase():
                                        displayTick=null;
                                        return (<div key={key} className="elementFillTVSDeactivated cellAlignmentTVS" title={data.shiftRemarks} style={{ backgroundColor: leaveCodes.color_Hol }}>-</div>)

                                    case leaveCodes.VL.toLowerCase():
                                        return (this.state.shiftStartTimeFormat ? <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_VL }} id={"d" + data.shiftAllocationPlanDetailId} mask="99:99 - 99:99" value={String(data.tvsStartAndEndTime)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsStartAndEndtime") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsStartAndEndtime") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span> :
                                            <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_VL }} id={"s" + data.shiftAllocationPlanDetailId} mask="99:99 | 99:99" value={String(data.tvsStartTimeAndDuration)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsShiftDuration") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsShiftDuration") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span>)

                                    case leaveCodes.VL2.toLowerCase():
                                        return (this.state.shiftStartTimeFormat ? <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_VL2 }} id={"d" + data.shiftAllocationPlanDetailId} mask="99:99 - 99:99" value={String(data.tvsStartAndEndTime)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsStartAndEndtime") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsStartAndEndtime") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span> :
                                            <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_VL2 }} id={"s" + data.shiftAllocationPlanDetailId} mask="99:99 | 99:99" value={String(data.tvsStartTimeAndDuration)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsShiftDuration") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsShiftDuration") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span>)

                                    case leaveCodes.PO.toLowerCase():
                                        return (this.state.shiftStartTimeFormat ? <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_PO }} id={"d" + data.shiftAllocationPlanDetailId} mask="99:99 - 99:99" value={String(data.tvsStartAndEndTime)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsStartAndEndtime") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsStartAndEndtime") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span> :
                                            <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_PO }} id={"s" + data.shiftAllocationPlanDetailId} mask="99:99 | 99:99" value={String(data.tvsStartTimeAndDuration)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsShiftDuration") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsShiftDuration") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span>)
                                    // return (<div key={key} style={{ backgroundColor: leaveCodes.color_PO }} className="elementFillTVSForLeaveCodes cellAlignmentTVS">{data.shiftCode}</div>)

                                    case leaveCodes.JV.toLowerCase():
                                        return (this.state.shiftStartTimeFormat ? <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_JV }} id={"d" + data.shiftAllocationPlanDetailId} mask="99:99 - 99:99" value={String(data.tvsStartAndEndTime)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsStartAndEndtime") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsStartAndEndtime") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span> :
                                            <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_JV }} id={"s" + data.shiftAllocationPlanDetailId} mask="99:99 | 99:99" value={String(data.tvsStartTimeAndDuration)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsShiftDuration") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsShiftDuration") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span>)
                                    
                                    case leaveCodes.LO.toLowerCase():
                                        return (this.state.shiftStartTimeFormat ? <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_LO }} id={"d" + data.shiftAllocationPlanDetailId} mask="99:99 - 99:99" value={String(data.tvsStartAndEndTime)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsStartAndEndtime") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsStartAndEndtime") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span> :
                                            <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_LO }} id={"s" + data.shiftAllocationPlanDetailId} mask="99:99 | 99:99" value={String(data.tvsStartTimeAndDuration)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsShiftDuration") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsShiftDuration") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span>)

                                    case leaveCodes.TV.toLowerCase():
                                        return (this.state.shiftStartTimeFormat ? <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_TV }} id={"d" + data.shiftAllocationPlanDetailId} mask="99:99 - 99:99" value={String(data.tvsStartAndEndTime)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsStartAndEndtime") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsStartAndEndtime") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span> :
                                            <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_TV }} id={"s" + data.shiftAllocationPlanDetailId} mask="99:99 | 99:99" value={String(data.tvsStartTimeAndDuration)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsShiftDuration") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsShiftDuration") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span>)
                                    // return (<div key={key} style={{ backgroundColor: leaveCodes.color_TV }} className="elementFillTVSForLeaveCodes cellAlignmentTVS">{data.shiftCode}</div>)

                                    case leaveCodes.P.toLowerCase():
                                        return (this.state.shiftStartTimeFormat ? <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_P }} id={"d" + data.shiftAllocationPlanDetailId} mask="99:99 - 99:99" value={String(data.tvsStartAndEndTime)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsStartAndEndtime") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsStartAndEndtime") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span> :
                                            <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_P }} id={"s" + data.shiftAllocationPlanDetailId} mask="99:99 | 99:99" value={String(data.tvsStartTimeAndDuration)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsShiftDuration") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsShiftDuration") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span>)
                                    // return (<div key={key} style={{ backgroundColor: leaveCodes.color_P }} className="elementFillTVSForLeaveCodes cellAlignmentTVS">{data.shiftCode}</div>)

                                    case leaveCodes.AY.toLowerCase():
                                        return (this.state.shiftStartTimeFormat ? <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} id={"d" + data.shiftAllocationPlanDetailId} mask="99:99 - 99:99" value={String(data.tvsStartAndEndTime)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsStartAndEndtime") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsStartAndEndtime") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span> :
                                            <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} id={"s" + data.shiftAllocationPlanDetailId} mask="99:99 | 99:99" value={String(data.tvsStartTimeAndDuration)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsShiftDuration") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsShiftDuration") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span>)
                                    // return (<div key={key} className="elementFillTVSForLeaveCodes cellAlignmentTVS">{data.shiftCode}</div>)

                                    case leaveCodes.SV.toLowerCase():
                                        return (this.state.shiftStartTimeFormat ? <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} id={"d" + data.shiftAllocationPlanDetailId} mask="99:99 - 99:99" value={String(data.tvsStartAndEndTime)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsStartAndEndtime") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsStartAndEndtime") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span> :
                                            <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} id={"s" + data.shiftAllocationPlanDetailId} mask="99:99 | 99:99" value={String(data.tvsStartTimeAndDuration)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsShiftDuration") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsShiftDuration") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span>)
                                    // return (<div key={key} className="elementFillTVSForLeaveCodes cellAlignmentTVS">{data.shiftCode}</div>)

                                    case leaveCodes.Hol.toLowerCase():
                                        return (<div key={key} style={{ backgroundColor: leaveCodes.color_Hol }} title={data.shiftRemarks} className="elementFillTVSDeactivated cellAlignmentTVS">-</div>)

                                    case leaveCodes.L.toLowerCase():
                                        return (this.state.shiftStartTimeFormat ? <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_reservedCode, color: 'white' }} id={"d" + data.shiftAllocationPlanDetailId} mask="99:99 - 99:99" value={String(data.tvsStartAndEndTime)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsStartAndEndtime") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsStartAndEndtime") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span> :
                                            <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_reservedCode, color: 'white' }} id={"s" + data.shiftAllocationPlanDetailId} mask="99:99 | 99:99" value={String(data.tvsStartTimeAndDuration)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsShiftDuration") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsShiftDuration") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span>)
                                    // return (<div key={key} style={{ backgroundColor: leaveCodes.color_reservedCode }} className="elementFillTVSForLeaveCodes cellAlignmentTVS">{data.shiftCode}</div>)

                                    case leaveCodes.R.toLowerCase():
                                        return (this.state.shiftStartTimeFormat ? <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_reservedCode, color: 'white' }} id={"d" + data.shiftAllocationPlanDetailId} mask="99:99 - 99:99" value={String(data.tvsStartAndEndTime)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsStartAndEndtime") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsStartAndEndtime") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span> :
                                            <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_reservedCode, color: 'white' }} id={"s" + data.shiftAllocationPlanDetailId} mask="99:99 | 99:99" value={String(data.tvsStartTimeAndDuration)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsShiftDuration") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsShiftDuration") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span>)
                                    // return (<div key={key} style={{ backgroundColor: leaveCodes.color_reservedCode }} className="elementFillTVSForLeaveCodes cellAlignmentTVS">{data.shiftCode}</div>)

                                    case leaveCodes.PR.toLowerCase():
                                        return (this.state.shiftStartTimeFormat ? <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_reservedCode, color: 'white' }} id={"d" + data.shiftAllocationPlanDetailId} mask="99:99 - 99:99" value={String(data.tvsStartAndEndTime)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsStartAndEndtime") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsStartAndEndtime") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span> :
                                            <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_reservedCode, color: 'white' }} id={"s" + data.shiftAllocationPlanDetailId} mask="99:99 | 99:99" value={String(data.tvsStartTimeAndDuration)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsShiftDuration") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsShiftDuration") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span>)                                    // return (<div key={key} style={{ backgroundColor: leaveCodes.color_reservedCode }} className="elementFillTVSForLeaveCodes cellAlignmentTVS">{data.shiftCode}</div>)

                                    case leaveCodes.C.toLowerCase():
                                        return (this.state.shiftStartTimeFormat ? <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_reservedCode, color: 'white' }} id={"d" + data.shiftAllocationPlanDetailId} mask="99:99 - 99:99" value={String(data.tvsStartAndEndTime)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsStartAndEndtime") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsStartAndEndtime") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span> :
                                            <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_reservedCode, color: 'white' }} id={"s" + data.shiftAllocationPlanDetailId} mask="99:99 | 99:99" value={String(data.tvsStartTimeAndDuration)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsShiftDuration") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsShiftDuration") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span>)
                                    // return (<div key={key} style={{ backgroundColor: leaveCodes.color_reservedCode }} className="elementFillTVSForLeaveCodes cellAlignmentTVS">{data.shiftCode}</div>)

                                    case leaveCodes.H.toLowerCase():
                                        return (this.state.shiftStartTimeFormat ? <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_reservedCode, color: 'white' }} id={"d" + data.shiftAllocationPlanDetailId} mask="99:99 - 99:99" value={String(data.tvsStartAndEndTime)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsStartAndEndtime") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsStartAndEndtime") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span> :
                                            <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_reservedCode, color: 'white' }} id={"s" + data.shiftAllocationPlanDetailId} mask="99:99 | 99:99" value={String(data.tvsStartTimeAndDuration)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsShiftDuration") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsShiftDuration") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span>)
                                    // return (<div key={key} style={{ backgroundColor: leaveCodes.color_reservedCode }} className="elementFillTVSForLeaveCodes cellAlignmentTVS">{data.shiftCode}</div>)

                                    case leaveCodes.M.toLowerCase():
                                        return (this.state.shiftStartTimeFormat ? <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_reservedCode, color: 'white' }} id={"d" + data.shiftAllocationPlanDetailId} mask="99:99 - 99:99" value={String(data.tvsStartAndEndTime)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsStartAndEndtime") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsStartAndEndtime") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span> :
                                            <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}> <InputMask tooltip={data.shiftCode} key={key} style={{ backgroundColor: leaveCodes.color_reservedCode, color: 'white' }} id={"s" + data.shiftAllocationPlanDetailId} mask="99:99 | 99:99" value={String(data.tvsStartTimeAndDuration)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsShiftDuration") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsShiftDuration") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> </span>)
                                    // return (<div key={key} style={{ backgroundColor: leaveCodes.color_reservedCode }} className="elementFillTVSForLeaveCodes cellAlignmentTVS">{data.shiftCode}</div>)

                                    case leaveCodes.Sat.toLowerCase():
                                        displayTick=null;
                                        return (<div key={key} className="elementFillTVS cellAlignmentTVS" style={{ backgroundColor: leaveCodes.color_weekend }}>-</div>)

                                    case leaveCodes.Sun.toLowerCase():
                                        displayTick=null;
                                        return (<div key={key} style={{ backgroundColor: leaveCodes.color_weekend }} className="elementFillTVS cellAlignmentTVS">-</div>)

                                    default:
                                        return (this.state.shiftStartTimeFormat ?
                                            <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}><InputMask tooltip={data.shiftCode} key={key} id={"d" + data.shiftAllocationPlanDetailId} mask="99:99 - 99:99" value={String(data.tvsStartAndEndTime)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsStartAndEndtime") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsStartAndEndtime") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /></span> :
                                            // <InputMask type="text" tooltip={data.shiftCode} key={key} id={"d" + data.shiftAllocationPlanDetailId} mask="99:99 - 99:99" value={String(data.tvsStartAndEndTime)} onComplete={(e) => { this.updateProperty(e, data, e.originalEvent.target.value) }} onKeyPress={(e)=>{console.log("atleast here", e.keyCode)}} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column) }} className="inputAlignDurationTVS1 tvsInputMaskSize" /> :
                                            <span key={key} id={this.state.hidetooltip ? null : "shiftCodeTooltip"} aria-label={data.shiftCode}><InputMask tooltip={data.shiftCode} key={key} id={"s" + data.shiftAllocationPlanDetailId} mask="99:99 | 99:99" value={String(data.tvsStartTimeAndDuration)} onChange={(e) => { this.updateProperty(e, data, rowData, e.target.value, "tvsShiftDuration") }} onKeyUp={(e) => { this.bulkUpdate(e, data, e.target.value, rowData, column, "tvsShiftDuration") }} className="inputAlignDurationTVS1 tvsInputMaskSize" /></span>)
                                }
                            }
                            return 0;
                        })
                    }
                </div>
                {displayTick}
                 
            </div>)
        }
    }

    //Function to call API when Next Arrow is clicked for change of Planning Period.
    changePlanningPeriodForward = () => {
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);

        this.props.masterData.balancingPeriodList.map((data, key) => {
            if (data.balancingPerioId === this.state.jsonTemplate.balancingPeriodID && data.planningPeriodList.length > 0) {
                if (this.state.nextPP === 0) {
                    jsonCopy.planningPeriodID = data.planningPeriodList[1].planningPeriodId
                    this.setState({ nextPP: this.state.nextPP + 1 })
                }
                else if (this.state.nextPP === 1) {
                    jsonCopy.planningPeriodID = data.planningPeriodList[2].planningPeriodId
                    this.setState({ nextPP: this.state.nextPP + 1 })
                }
                this.getTVS(jsonCopy);
                this.setState({ jsonTemplate: jsonCopy });
            }
            return true;
        })
    }

    //Function to call API when Back Arrow is clicked for change of Planning Period.
    changePlanningPeriodBackward = () => {
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.planningPeriodID = this.state.jsonTemplate.planningPeriodID - 1;
        this.setState({ nextPP: this.state.nextPP - 1 })
        this.setState({ jsonTemplate: jsonCopy });
        this.getTVS(jsonCopy);
    }

    //Function to implement functionality of Number of Record Dropdown change of Pagination. 
    noOfRecordChange = (e) => {

        this.setState({
            dropdownDisplay: e.value,
            loading: true,
            first: 0
        })
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeSearch.recordCount = e.value.code;
        jsonCopy.employeeSearch.pageNumber = 0;

        this.getTVS(jsonCopy);
        this.setState({ jsonTemplate: jsonCopy });
    }

    //Function to implement Change Page functionality of Paginator. 
    onPageChange = (e) => {
        this.setState({ first: e.first, loading: true, displayDialog: false });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeSearch.pageNumber = e.page;
        this.getTVS(jsonCopy);
    }

    //Function to implement Sorting functionality. 
    onSortChange = (e) => {

        this.setState({
            sortField: e.sortField,
            sortOrder: e.sortOrder,
            loading: true,
            first: 0
        });

        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.employeeSearch.sortDirection = 'ASC';
        jsonCopy.employeeSearch.sortBy = e.sortField;

        if (e.sortOrder === -1) {
            jsonCopy.employeeSearch.sortDirection = "DESC"
        }

        this.getTVS(jsonCopy);
        this.setState({ jsonTemplate: jsonCopy });
    }

    //Function to implement Filtering functionality on Employee Name. 
    onemployeeNameFilter = (empName, key) => {

        if (keyboardValues.includes(key) && empName !== "") {
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.employeeName = empName;
            jsonCopy.employeeSearch.pageNumber = 0;
            this.getFilterFunction(jsonCopy);
        }
    }

    //Function to implement Filtering functionality on Employee Type Code. 
    onemployeeTypeFilter = (empType, key) => {
        if (keyboardValues.includes(key) && empType !== "") {
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.employeeTypeCode = empType;
            jsonCopy.employeeSearch.pageNumber = 0;
            this.getFilterFunction(jsonCopy);
        }
    }

    filterCleared = (e, field) => {

        if (e.target.value === "" && field === "employeeName") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.employeeName = "";
            jsonCopy.employeeSearch.pageNumber = 0;
            this.setState({ jsonTemplate: jsonCopy });
            this.getTVS(jsonCopy);
        }
        else if (e.target.value === "" && field === "employeeTypeCode") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeSearch.employeeTypeCode = "";
            jsonCopy.employeeSearch.pageNumber = 0;
            this.setState({ jsonTemplate: jsonCopy });
            this.getTVS(jsonCopy);
        }

    }

    //Function to call API for filtering records
    getFilterFunction = (jsonCopy) => {
        jsonCopy.employeeSearch.pageNumber = 0;
        this.setState({
            jsonTemplate: jsonCopy,
            loading: true,
            first: 0
        });
        this.getTVS(jsonCopy);
    }

    //Function to implement functionality of Wrapping Long Text With "..."
    nameTemplate = (rowData, column) => {
        /** Set tooltip value for employee name column as cccode appended with ccname 
         * for borrowed employees otherwise show only employee name.
         * For other fields, show value*/
        let title = rowData[column.field]
        let iconShowBorrowed=false
        if(column.rowData.borrowedInOtherCC){
            title=rowData[column.field] + "\n" + column.rowData.borrowedInOtherCCNames
            iconShowBorrowed=true
        }else if(column.rowData.borrowed){
            title=rowData[column.field] + "\n" + column.rowData.homeCostCenterCodeAndName
            iconShowBorrowed=true
        }
       // let title = column.rowData.borrowed ? rowData[column.field] + "\n" + column.rowData.homeCostCenterCodeAndName : rowData[column.field]
        return (<div className="extraTextWrappingShitAllocation" title={title} style={{ float: 'unset' }}>
            {rowData[column.field]}
            {column.field === 'employeeName' && iconShowBorrowed ?
                <i className="pi pi-user-minus" style={{ float: 'right', cursor: 'pointer' }}></i>
                : null
            }
        </div>
        );
    }

    displayShiftFormat = (value) => {
        this.setState({ loading: true });
        if (value === translator.duration) {
            this.setState({ shiftStartTimeFormat: false, loading: false })
        }
        else {
            this.setState({ shiftStartTimeFormat: true, loading: false })
        }

        this.getTVS(this.state.jsonTemplate);
    }

    displayPreference = (value) => {
        // this.props.onDisplayPreferenceChange(value);
        this.setState({ loading: true });

        let jsonCopy = Object.assign({}, this.state.jsonTemplate);

        if (value === '4 weeks') {
            jsonCopy.twelveWeekDisplay = false;
            this.props.masterData.balancingPeriodList.map((data) => {
                if (data.balancingPerioId === this.state.jsonTemplate.balancingPeriodID) {
                    jsonCopy.planningPeriodID = data.planningPeriodList[0].planningPeriodId;
                }
                return 0;
            })
            this.setState({ nextPP: 0 });
        }
        else {
            jsonCopy.twelveWeekDisplay = true;
        }
        this.setState({ jsonTemplate: jsonCopy });
        this.getTVS(jsonCopy);

    }

    //function to add '0' in single digit month (ex:01,02)
    getMonthCheck = (month) => {
        let newMonth = 0
        if (month < 10) {
            newMonth = '0' + month;
            return newMonth
        }
        else {
            return month
        }

    }

    //function to add '0' in single digit month (ex:01,02)
    getMonthAddition = (month, year) => {
        let newMonth = 0;
        let yearMonth = "";
        let addMonth = Number(month) + 1;

        if (month === 53) {
            yearMonth = String((year + 1) + '/01');
            return yearMonth
        }

        else if (addMonth < 10) {
            newMonth = '0' + addMonth;
            yearMonth = String(year + '/' + newMonth);
            return yearMonth
        }
        else {
            yearMonth = String(year + '/' + addMonth);
            return yearMonth
        }
    }

    getMonthSubraction = (month) => {
        let newMonth = 0;
        let addMonth = Number(month) - 1;
        if (month < 10) {
            newMonth = '0' + addMonth;
            return newMonth
        }
        else {
            return addMonth
        }
    }

    getPlanningPeriod = (balancingPeriodId) => {
        let planningPeriod = [];
        let balancingPeriodRecord = this.props.masterData.balancingPeriodList.filter(data => data.balancingPerioId === balancingPeriodId);
        let planningPeriodList = balancingPeriodRecord[0].planningPeriodList
        planningPeriodList.map(data => {
            planningPeriod.push({ planingPeriodPart: data.startYear + '/' + this.getMonthCheck(data.startWeek) + ' - ' + this.getMonthAddition(data.startWeek, data.startYear), planningPeriod: data.startYear + '/' + this.getMonthCheck(data.startWeek) + ' - ' + data.endYear + '/' + this.getMonthCheck(data.endWeek), twoWeekPlanningPeriod: true });
            planningPeriod.push({ planingPeriodPart: data.endYear + '/' + this.getMonthSubraction(data.endWeek) + ' - ' + data.endYear + '/' + this.getMonthCheck(data.endWeek), planningPeriod: data.startYear + '/' + this.getMonthCheck(data.startWeek) + ' - ' + data.endYear + '/' + this.getMonthCheck(data.endWeek), twoWeekPlanningPeriod: true })
            planningPeriod.push({ planingPeriodPart: data.startYear + '/' + this.getMonthCheck(data.startWeek) + ' - ' + data.endYear + '/' + this.getMonthCheck(data.endWeek), planningPeriod: data.startYear + '/' + this.getMonthCheck(data.startWeek) + ' - ' + data.endYear + '/' + this.getMonthCheck(data.endWeek), twoWeekPlanningPeriod: false })
            return 0;
            // return(data.startYear+'/'+this.getMonthCheck(data.startWeek)+' - '+data.endYear+'/'+this.getMonthCheck(data.endWeek))
        })

        this.setState({ planningPeriodList: planningPeriod })
    }

    showConfirmationDialog = () => {
        this.getPlanningPeriod(this.state.jsonTemplate.balancingPeriodID)
        this.setState({ printPDFClicked: true })
    }

    //function to download pdf file
    printPDF = (jsonCopy, selectedPP) => {
        this.commonService.exportShiftAllocationForPeriodPDF(jsonCopy, this.props.browserLanguage)
            .then(
                response => {
                    let fileName = '';
                    let planningPeriodindex = _.findIndex(this.props.masterData.planningPeriodList, function (planItem) { return planItem.planningPerioId === jsonCopy.planningPeriodID })
                    if (planningPeriodindex !== -1) {
                        // let weekArr = this.props.masterData.planningPeriodList[planningPeriodindex].planningPeriodDescription.split(' ');
                        let weekArr = selectedPP.split(' ');
                        fileName = jsonCopy.employeeSearch.costCenterCode + "_Week" + weekArr[0].split('/')[1] + weekArr[1] + weekArr[2].split('/')[1] + "_" + moment().format('YYYY-MM-DD-hh-mm');

                        var blob = new Blob([response.data], { type: "application/pdf" });
                        var reader = new FileReader();
                        reader.onloadend = function () {
                            FileSaver.saveAs(blob, fileName);
                        }
                        reader.readAsText(blob);
                    }
                    this.setState({ loading: false });
                }
            )
            .catch(err => {
                this.setState({ loading: false, displayDialog: false, CumulativeDialog: false });
                this.showMultiple(err);
            })
    }

    callPrintService = (planningPeriod, record, printPdfWithTimeFlag) => {
        this.setState({ printPDFClicked: false });
        if (planningPeriod !== "") {
            let selectedPPId = this.props.masterData.planningPeriodList.filter(data => data.planningPeriodDescription === record[0].planningPeriod);
            let startWeek = planningPeriod.split('-');
            let startPeriod = startWeek[0].split('/');

            let printCopy = Object.assign({}, this.state.printTemplate);
            printCopy.centerMasterID = this.state.jsonTemplate.centerMasterID;
            printCopy.planningPeriodID = selectedPPId[0].planningPerioId
            printCopy.employeeSearch.costCenterCode = this.state.jsonTemplate.employeeSearch.costCenterCode;
            printCopy.startWeek = Number(startPeriod[1]);
            printCopy.twoWeekPlanningPeriod = record[0].twoWeekPlanningPeriod;
            printCopy.shiftWithStartTime = printPdfWithTimeFlag;

            this.setState({ loading: true, printTemplate: printCopy })
            this.printPDF(printCopy, planningPeriod);
        }
    }

    hideDialog = () => {
        this.setState({ printPDFClicked: false })
    }
    copyDownShiftTimings = () => {
        this.setState({ loading: true });

        this.commonService.copyDownShiftTimings(this.state.copyShiftTimeJson, this.props.browserLanguage)
            .then(res => {
                 this.getTVS(this.state.jsonTemplate);
                this.setState({ 
                    loading: false,
                    copyShiftTimeJson :{
                        fromEmployeeId: null,
                        toEmployeeIdList: [],
                        selectedDates: [],
                        costCenterId: null
                    },
                    selectedEmployee :[] 
                });
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }

    handleSelection = (value) => {
        this.setState({ selectedEmployee: value})
        let jsonCopy = Object.assign({}, this.state.copyShiftTimeJson);
        
        if (value.length > 0) {    
          jsonCopy.costCenterId = this.state.jsonTemplate.centerMasterID;
          jsonCopy.toEmployeeIdList=[];
            value.map(data => {
                if(jsonCopy.fromEmployeeId=== data.employeeId){
                    this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.fromAndToEmployeeError })
                }else{
                    if (!jsonCopy.toEmployeeIdList.includes(data.employeeId)){
                        jsonCopy.toEmployeeIdList.push(data.employeeId);
                     }
                }   
            })
          
        }else{
            jsonCopy.toEmployeeIdList=[]; 
        }
        this.setState({copyShiftTimeJson: jsonCopy });
      
      };
    selectCopyFromData = (e, rowData, column) => {    
        
        let jsonCopy = Object.assign({}, this.state.copyShiftTimeJson);
        if(jsonCopy.toEmployeeIdList.includes( rowData.employeeId)){
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.fromAndToEmployeeError })
        }else{
            jsonCopy.fromEmployeeId = rowData.employeeId;

            if (!jsonCopy.selectedDates.includes(rowData[column.field].shiftsOnThisDay[0].date)) {
                jsonCopy.selectedDates.push(rowData[column.field].shiftsOnThisDay[0].date)
            } else {
                const index = jsonCopy.selectedDates.indexOf(rowData[column.field].shiftsOnThisDay[0].date);
                if (index > -1) {
                    jsonCopy.selectedDates.splice(index, 1);
                }
            }
            if(jsonCopy.selectedDates.length===0){
                jsonCopy.fromEmployeeId=null;
            }
            this.setState({ copyShiftTimeJson: jsonCopy })
        }
       
    }
    
    render() {
       
        //Conditional block executing to hide one of the two "No record found" messages displaying in datatable
        if (document.getElementsByClassName('p-datatable-emptymessage') !== undefined && document.getElementsByClassName('p-datatable-emptymessage').length > 0 && document.getElementsByClassName('p-datatable-emptymessage')[1] !== undefined) {
            document.getElementsByClassName('p-datatable-emptymessage')[1].style.color = "transparent";
            document.getElementsByClassName('p-datatable-emptymessage')[1].firstChild.style.borderLeft = "0 none";
        }

        translator.setLanguage(this.props.browserLanguage);

        var weekName = [
            translator.monday,
            translator.tuesday,
            translator.wednesday,
            translator.thursday,
            translator.friday,
            translator.saturday,
            translator.sunday
        ];
        let noOfWeek = headerWeeks;

        const noOfRecord = [
            { name: translator.Records_150, code: 150 },
            { name: translator.Records_200, code: 200 },
            { name: translator.Records_250, code: 250 }
        ];
        let employeeNameFilter = <InputText type="text" onChange={(e) => { this.filterCleared(e, "employeeName") }} id="employeeName" className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeNameFilter(e.target.value, e.key)} />
        let employeeTypeFilter = <InputText type="text" onChange={(e) => { this.filterCleared(e, "employeeTypeCode") }} id="employeeTypeCode" className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeTypeFilter(e.target.value, e.key)} />

        let frozenHeaderGroup =
            averageWeekData !== null ?
                <ColumnGroup>
                    <Row>
                        <Column header={translator.startDate + " : " + startDate} colSpan={6} className="textAlignLeftHeaderTVS" />
                    </Row>
                    <Row>
                        <Column header={translator.weeks} colSpan={6} className="textAlignRightHeaderTVS" style={{ height: '41px' }} />
                    </Row>
                    <Row>
                        <Column header={translator.days} colSpan={6} className="textAlignRightHeaderTVS" />
                    </Row>
                    <Row>
                        <Column header={translator.planningInSection} colSpan={3} className="textAlignCenterHeaderTVS" />
                        <Column header={translator.weekDays} colSpan={3} className="textAlignRightHeaderTVS" />
                    </Row>
                    <Row>
                        <Column header={translator.targetHours} colSpan={6} className="textAlignRightHeaderTVS" />
                    </Row>
                    <Row>
                        <Column header={noOfWorkDay} colSpan={3} className="textAlignCenterHeaderTVS" />
                        <Column header={translator.scheduledHours} colSpan={3} className="textAlignRightHeaderTVS" />
                    </Row>
                    <Row>
                    <Column  style={{width:'2em'}}/>
                        <Column header={translator.employeeName} field="employeeName" filter={true} filterElement={employeeNameFilter} sortable={true} style={{ height: '65px' }} />
                        <Column header={translator.employeeTypeCode} field="employeeType" filter={true} filterElement={employeeTypeFilter} sortable={true} style={{ height: '65px' }} />
                        <Column header={translator.availableHours} style={{ height: '65px' }} />
                        <Column header={translator.scheduledHours} style={{ height: '65px' }} />
                        <Column header={<span>{translator.remainingHours}<img alt="img" style={{ width: '11px', height: '11px' }} src={require('../../images/arrow.png')} /></span>} style={{ height: '65px' }} />
                    </Row>
                </ColumnGroup>
                : null

        let headerGroup =
            averageWeekData !== null ?
                <ColumnGroup>

                    <Row>

                        {
                            averageWeekData.map((data, key) => {
                                return (
                                    <Column
                                        header=
                                        {
                                            <React.Fragment>
                                                <span style={{ float: 'left' }}>
                                                    {translator.target + data.targetHoursForWeek}
                                                </span>
                                                <span style={{ float: 'center' }}>
                                                    {translator.scheduled + data.scheduledHrsForWeek}
                                                </span>
                                                {
                                                    Number(data.eroForWeek.split(':')[0]) < 0 ?
                                                        <span style={{ float: 'right' }}>
                                                            {translator.remaining}<span style={{ color: 'red' }}>{data.eroForWeek}</span>
                                                        </span>
                                                        :
                                                        <span style={{ float: 'right' }}>
                                                            {translator.remaining + data.eroForWeek}
                                                        </span>
                                                }
                                            </React.Fragment>
                                        }
                                        key={key}
                                        colSpan={7} />
                                )
                            })
                        }
                    </Row>



                    <Row>

                        {this.state.jsonTemplate !== undefined && this.state.jsonTemplate.twelveWeekDisplay === false ? noOfWeek.map((week, key) => {
                            if (key === 3) {
                                return (
                                    <Column
                                        header={<div style={{ height: '28px', display: 'contents' }}><span>{week.week}</span>{this.state.nextPP >= 2 ? null : <i onClick={() => this.changePlanningPeriodForward()} className="pi pi-chevron-right rightArrowTVS" ></i>}{week.weeklyCommentAndHaittaInput !== null ? <div className="extraTextWrapping" title={week.weeklyCommentAndHaittaInput.weeklyComment} style={{ fontWeight: '500' }}>{week.weeklyCommentAndHaittaInput.weeklyComment}</div> : null}</div>}
                                        key={key}
                                        colSpan={7}
                                        className="unFrozenWeekNoHeader"
                                    />
                                )
                            }
                            else if (key === 0) {
                                return (
                                    <Column
                                        header={<div style={{ height: '28px', display: 'contents' }}>{this.state.nextPP === 1 || this.state.nextPP === 2 ? <i onClick={() => this.changePlanningPeriodBackward()} className="pi pi-chevron-left leftArrowTVS"></i> : null}<span>{week.week}{week.weeklyCommentAndHaittaInput !== null ? <div className="extraTextWrapping" title={week.weeklyCommentAndHaittaInput.weeklyComment} style={{ fontWeight: '500' }}>{week.weeklyCommentAndHaittaInput.weeklyComment}</div> : null}</span></div>}
                                        key={key}
                                        colSpan={7}
                                        className="unFrozenWeekNoHeader" />
                                )
                            }

                            else {
                                return (
                                    <Column
                                        header={<div style={{ height: '28px', display: 'contents' }}>{week.week}{week.weeklyCommentAndHaittaInput !== null ? <div className="extraTextWrapping" title={week.weeklyCommentAndHaittaInput.weeklyComment} style={{ fontWeight: '500' }}>{week.weeklyCommentAndHaittaInput.weeklyComment}</div> : null}</div>}
                                        key={key}
                                        colSpan={7}
                                        className="unFrozenWeekNoHeader" />
                                )
                            }
                        })
                            :
                            noOfWeek.map((week, key) => {
                                return (
                                    <Column
                                        header={<div style={{ height: '28px', display: 'contents' }}>{week.week}{week.weeklyCommentAndHaittaInput !== null ? <div className="extraTextWrapping" title={week.weeklyCommentAndHaittaInput.weeklyComment} style={{ fontWeight: '500' }}>{week.weeklyCommentAndHaittaInput.weeklyComment}</div> : null}</div>}
                                        key={key}
                                        colSpan={7}
                                        className="unFrozenWeekNoHeader" />
                                )
                            })

                        }
                    </Row>

                    <Row>

                        {
                            headerDates.map((date, key) => {
                                return <Column header={<div title={holidayArray.includes(date) ? translator.holiday : null}>{date.slice(-2)}</div>} key={key} style={holidayArray.includes(date) ? { backgroundColor: leaveCodes.color_holidayHeader } : null} />
                            })
                        }
                    </Row>

                    <Row>

                        {noOfWeek.map(() => {
                            return (weekName.map(week => {
                                return (
                                    <Column header={week} />
                                )
                            }))
                        })}
                    </Row>
                    <Row>

                        {
                            headerTargetHoursForDay.map((data, key) => {
                                return <Column header={data} key={key} className="headerColorTargetHours" />
                            })
                        }
                    </Row>

                    <Row>

                        {
                            headerScheduledHrsForDay.map((data, key) => {
                                return <Column header={data} key={key} className="" />
                            })
                        }
                    </Row>

                    <Row>

                        {
                            headerERO.map((data, key) => {
                                if (data.split(':')[0] < 0) {
                                    return <Column header={data} key={key} className="negativeDifference" style={{ height: '65px' }} />
                                }
                                else {
                                    return <Column header={data} key={key} className="" style={{ height: '65px' }} />
                                }
                            })
                        }

                    </Row>
                </ColumnGroup>
                : null

        return (
            <div className="bodyColorTVS">
                <OverlayPanel ref={(el) => this.op = el}>
                    {commentData}
                </OverlayPanel>

                <Growl ref={(el) => this.growl = el} position="topright" ></Growl>

                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner className="loadingTVS" strokeWidth="5" animationDuration=".8s" />
                </Dialog>

                {this.state.printPDFClicked ? <ConfirmationDialog callbackPrintService={this.callPrintService} lang={this.props.browserLanguage} callbackProp={this.hideDialog} planningPeriodList={this.state.planningPeriodList} screen="dailyTVSScreen"/> : null}

                {this.state.tvsData ?
                    this.state.totalRecordsCount === 0 && (document.getElementById('employeeName') !== null ? (document.getElementById('employeeName').value === "" && document.getElementById('employeeTypeCode').value === "" && this.state.loading === false) : true) ?
                        <h4 className="noRecordFoundMessage">{translator.noRecordFound}</h4> :
                        <div>
                            <div style={{ float: 'right', marginTop: '-3.2%', marginRight: '-1%' }}>
                                <label style={{ marginLeft: '-10%' }}><strong>{translator.displayFormat}</strong></label>
                                <span >
                                    <input name="displayFormat" type="radio" value={translator.startAndEndTime} checked={this.state.shiftStartTimeFormat ? true : false} style={{ marginLeft: '20px' }} onChange={(e) => this.displayShiftFormat(e.target.value)} /><span id="tooltip" aria-label={translator.tooltipStartTimeFormat}>{translator.startAndEndTime}</span>
                                    <input name="displayFormat" type="radio" value={translator.duration} checked={!this.state.shiftStartTimeFormat ? true : false} style={{ marginLeft: '20px' }} onChange={(e) => this.displayShiftFormat(e.target.value)} /><span id="tooltipDuration" aria-label={translator.tooltipDurationFormat}>{translator.duration}</span>
                                </span>
                                <Button type="button" className="btnDefault" style={{ marginLeft: '8%' }} icon="pi pi-copy" label={translator.copyTvsTimings}
                                    onClick={() => this.copyDownShiftTimings()}
                                    disabled={this.state.copyShiftTimeJson.fromEmployeeId === null ||
                                        this.state.copyShiftTimeJson.costCenterId === null ||
                                        this.state.copyShiftTimeJson.toEmployeeIdList.length === 0 ||
                                        this.state.copyShiftTimeJson.selectedDates.length === 0 ? true : false} />
                                <Button type="button" className="btnDefault" style={{ marginLeft: '1%' }} icon="pi pi-print" label={translator.printPDF} onClick={this.showConfirmationDialog} disabled={this.state.jsonTemplate.balancingPeriodID === "" ? true : false} />
                            </div>
                            <div className="dvtvsPagging">

                                <div className="recordOfTVS">
                                    {totalTvsRecords === undefined || totalTvsRecords === 0 ? '0-0' : (pageNumber * this.state.jsonTemplate.employeeSearch.recordCount) + 1 + '-' + Number((pageNumber * this.state.jsonTemplate.employeeSearch.recordCount) + Number((pageSize)))} {translator.of} {totalTvsRecords === undefined ? 0 : totalTvsRecords} {translator.records}
                                    <span style={{ marginLeft: '350px' }}>
                                       <strong>{translator.displayPreference}</strong> 

                                        <span >
                                            <input name="displayPreference" type="radio" value="4 weeks" checked={this.state.jsonTemplate.twelveWeekDisplay === false ? true : false} style={{ marginLeft: '20px' }} onChange={(e) => this.displayPreference(e.target.value)} />{translator.fourWeeks}
                                            <input name="displayPreference" type="radio" value="12 weeks" checked={this.state.jsonTemplate.twelveWeekDisplay === true ? true : false} style={{ marginLeft: '20px' }} onChange={(e) => this.displayPreference(e.target.value)} />{translator.twelveWeeks}
                                        </span>
                                    </span>
                                </div>

                                <Paginator
                                    className="tvsPagginator"
                                    rows={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.employeeSearch.recordCount : null}
                                    totalRecords={totalTvsRecords === undefined ? 0 : totalTvsRecords}
                                    first={this.state.first}
                                    onPageChange={(e) => this.onPageChange(e)}></Paginator>

                                <Dropdown
                                    value={this.state.dropdownDisplay}
                                    className="colRecordTVS dropmarginTVS dropDwonDefault"
                                    options={noOfRecord}
                                    onChange={(e) => this.noOfRecordChange(e)}
                                    placeholder={translator.Records_150}
                                    optionLabel="name" />
                            </div>

                            <DataTable
                                value={this.state.tvsData}
                                headerColumnGroup={this.state.tvsData ? headerGroup : null}
                                frozenHeaderColumnGroup={this.state.tvsData ? frozenHeaderGroup : null}
                                scrollable={browser().name === 'ie' ? false : true}
                                ref={(el) => this.dt = el}
                                onSort={(e) => this.onSortChange(e)}
                               // selectionMode="single"
                                scrollHeight={getResolutionDailyTVS()}
                                className="tvsDatatable dailyReportTvsDatatable"
                                frozenWidth="560px"
                                sortField={this.state.sortField}
                                sortOrder={this.state.sortOrder}
                                emptyMessage={translator.noRecordFound}
                                onSelectionChange = {(e) => this.handleSelection(e.value)}
                                selection={this.state.selectedEmployee}
                               
                            >
                                <Column selectionMode="multiple" frozen={true} style={{width:'2em'}}/>
                                <Column field="employeeName" frozen={true} filter={true} filterElement={employeeNameFilter} sortable={true} header={translator.employeeName} className="textAlignLeftHeaderTVS" style={{ width: '140px', height: '69px' }} body={this.nameTemplate} />
                                <Column field="employeeType" frozen={true} filter={true} filterElement={employeeTypeFilter} sortable={true} header={translator.employeeTypeCode} className="textAlignLeftHeaderTVS" style={{ width: '140px' }} />
                                <Column field="hoursAvailablePerTVS" frozen={true} header={translator.availableHours} className="textAlignCenterHeaderTVS" style={{ width: '86px' }} body={this.dataTemplate} />
                                <Column field="scheduledHrs" frozen={true} header={translator.scheduledHours} className="textAlignCenterHeaderTVS" style={{ width: '86px' }} body={this.dataTemplate} />
                                <Column field="ero" frozen={true} header={translator.remainingHours} className="textAlignCenterHeaderTVS textBold" style={{ width: '86px' }} body={this.eroTemplate} />

                                <Column field="week1day1" style={{ width: '90px', padding: '0px', height: '69px' }} body={this.bodyTemplate} />
                                <Column field="week1day2" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week1day3" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week1day4" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week1day5" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week1day6" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week1day7" style={{ width: '90px', padding: '0px', backgroundColor: leaveCodes.color_weekend }} body={this.bodyTemplate} />

                                <Column field="week2day1" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week2day2" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week2day3" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week2day4" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week2day5" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week2day6" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week2day7" style={{ width: '90px', padding: '0px', backgroundColor: leaveCodes.color_weekend }} body={this.bodyTemplate} />

                                <Column field="week3day1" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week3day2" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week3day3" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week3day4" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week3day5" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week3day6" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week3day7" style={{ width: '90px', padding: '0px', backgroundColor: leaveCodes.color_weekend }} body={this.bodyTemplate} />

                                <Column field="week4day1" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week4day2" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week4day3" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week4day4" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week4day5" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week4day6" style={{ width: '90px', padding: '0px' }} body={this.bodyTemplate} />
                                <Column field="week4day7" style={{ width: '90px', padding: '0px', backgroundColor: leaveCodes.color_weekend }} body={this.bodyTemplate} />

                                <Column field="week5day1" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week5day2" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week5day3" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week5day4" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week5day5" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week5day6" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week5day7" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px', backgroundColor: leaveCodes.color_weekend } : null} body={this.bodyTemplate} />

                                <Column field="week6day1" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week6day2" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week6day3" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week6day4" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week6day5" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week6day6" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week6day7" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px', backgroundColor: leaveCodes.color_weekend } : null} body={this.bodyTemplate} />

                                <Column field="week7day1" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week7day2" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week7day3" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week7day4" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week7day5" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week7day6" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week7day7" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px', backgroundColor: leaveCodes.color_weekend } : null} body={this.bodyTemplate} />

                                <Column field="week8day1" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week8day2" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week8day3" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week8day4" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week8day5" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week8day6" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week8day7" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px', backgroundColor: leaveCodes.color_weekend } : null} body={this.bodyTemplate} />

                                <Column field="week9day1" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week9day2" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week9day3" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week9day4" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week9day5" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week9day6" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week9day7" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px', backgroundColor: leaveCodes.color_weekend } : null} body={this.bodyTemplate} />

                                <Column field="week10day1" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week10day2" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week10day3" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week10day4" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week10day5" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week10day6" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week10day7" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px', backgroundColor: leaveCodes.color_weekend } : null} body={this.bodyTemplate} />

                                <Column field="week11day1" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week11day2" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week11day3" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week11day4" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week11day5" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week11day6" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week11day7" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px', backgroundColor: leaveCodes.color_weekend } : null} body={this.bodyTemplate} />

                                <Column field="week12day1" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week12day2" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week12day3" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week12day4" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week12day5" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week12day6" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px' } : null} body={this.bodyTemplate} />
                                <Column field="week12day7" style={this.state.jsonTemplate !== undefined ? this.state.jsonTemplate.twelveWeekDisplay === false ? { width: '90px', padding: '0px', display: 'none' } : { width: '90px', padding: '0px', backgroundColor: leaveCodes.color_weekend } : null} body={this.bodyTemplate} />

                            </DataTable>
                        </div>
                    : null
                }
            </div>
        );
    }
}

export default DailyReport;