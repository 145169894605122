import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CommonService } from '../../service/CommonService';
import { Dropdown } from 'primereact/dropdown';
import CostCenter from '../../components/costcenter/costCenter';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js'
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Growl } from 'primereact/growl';
import browser from 'browser-detect'
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import './SkillSet.css';
import { DraggableArea } from 'react-draggable-tags';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import { Chips } from 'primereact/chips';
import { getResolution } from '../../../src/utility/commonUtility';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import leaveCodes from '../../utility/leaveCodes.json';

let translator = new LocalizedStrings(translation);

class SkillSet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            skillFilters: [],
            skill: [],
            responseFromAPI: [],
            filteredCountriesSingle: null,
            loading: false,
            deleteButton: true,
            jsonTemplate: {
                costCenterMasterId: "",
                employeeId: "",
                employeeName: "",
                employeeTypeCode: "",
                shiftIdList: [],
                sortBy: "empID",
                sortDirection: "ASC",
                pageNumber: 0,
                recordCount: 150
            }
        };
        this.commonService = new CommonService();
        this.filterCountrySingle = this.filterCountrySingle.bind(this);
    }

    componentDidMount = () => {
        this.setState({ loading: true });
        this.commonMethodForLifecycleMethod();
    }

    commonMethodForLifecycleMethod = () =>{
        this.commonService.getLastFilterSkillMaster(this.props.browserLanguage)
                .then(res => {

                    let jsonCopy1 = Object.assign({}, this.state.jsonTemplate);
                    if (res.costCenter !== null) {
                        jsonCopy1.costCenterMasterId = res.costCenter.costCenterMasterId;
                        this.getEmpSkillsList(res.costCenter.costCenterMasterId);
                        this.setState({
                            lastUsedCostCenter: res.costCenter,
                            pageNumber: res.skillPage.number,
                            pageSize: res.skillPage.size,
                            employeeData: res.skillPage.content,
                            totalEmployeeRecords: res.skillPage.totalElements,
                            recordSize: res.skillPage.numberOfElements,
                            loading: false
                        })
                        this.setState({ jsonTemplate: jsonCopy1 });

                    }
                    this.setState({ loading: false });
                })
                .catch(err => {
                    this.setState({ loading: false });
                    this.showMultiple(err);
                })
    }

    //Function to set default filters
    clearFilterControl = () => {
        if (document.getElementById('employeeId')) {
            document.getElementById('employeeId').value = ''
        }
        if (document.getElementById('employeeName')) {
            document.getElementById('employeeName').value = ''
        }
        if (document.getElementById('employeeTypeCode')) {
            document.getElementById('employeeTypeCode').value = ''
        }

        this.setState({ skillFilters: [] });
    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {
        
        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: detail });
        
    }

    //Function to call API service for GET Skill List.
    getList = (payload) => {
        this.commonService.getEmployeeSkillMapping(payload, this.props.browserLanguage)
            .then(data => {
                if (data !== undefined && data !== null) {
                    this.setState({
                        pageNumber: data.skillPage.number,
                        pageSize: data.skillPage.size,
                        employeeData: data.skillPage.content,
                        totalEmployeeRecords: data.skillPage.totalElements,
                        recordSize: data.skillPage.numberOfElements,
                        loading: false,
                    })
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }

    //Function to implement Change Page functionality of Paginator. 
    onPageChange = (e) => {

        if (this.state.employeeData === undefined || this.state.employeeData.length === undefined) {
            return false;
        }

        this.setState({ first: e.first, loading: true });
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.pageNumber = e.page;
        this.getList(jsonCopy);
    }

    //Function to call API for filtering records
    getFilterFuntion = (jsonCopy) => {
        if (this.state.employeeData === undefined || this.state.employeeData.length === undefined) {
            return false;
        }
        jsonCopy.pageNumber = 0;
        this.setState({
            jsonTemplate: jsonCopy,
            loading: true,
            first: 0
        });
        this.getList(jsonCopy);
    }

    //Function to implement Filtering functionality on Employee ID. 
    onEmployeeIdFilter = (empId, key) => {
        if ((key === 'Enter' || key === 'Tab') && empId !== "") {
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeId = empId;
            this.getFilterFuntion(jsonCopy);
        }
    }

    //Function to implement Filtering functionality on Employee Name. 
    onemployeeNameFilter = (empName, key) => {
        if ((key === 'Enter' || key === 'Tab') && empName !== "") {
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeName = empName;
            this.getFilterFuntion(jsonCopy);
        }
    }
    //Function to implement Filtering functionality on Employee Type Code. 
    onemployeeTypeFilter = (empType, key) => {
        if ((key === 'Enter' || key === 'Tab') && empType !== "") {
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeTypeCode = empType;
            this.getFilterFuntion(jsonCopy);
        }
    }

    filterCleared = (e, field) => {

        if (e.target.value === "" && field === "employeeId") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeId = "";
            this.setState({ jsonTemplate: jsonCopy });
            this.getList(jsonCopy);
        }
        else if (e.target.value === "" && field === "employeeName") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeName = "";
            this.setState({ jsonTemplate: jsonCopy });
            this.getList(jsonCopy);
        }
        else if (e.target.value === "" && field === "employeeTypeCode") {
            this.setState({ loading: true });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.employeeTypeCode = "";
            this.setState({ jsonTemplate: jsonCopy });
            this.getList(jsonCopy);
        }

    }

    //funtion to apply skill/shift filter
    skillFilter = (value) => {
        if (value.length === 0) {
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            jsonCopy.shiftIdList = [];
            this.setState({
                jsonTemplate: jsonCopy,
                skillFilters: []
            })
            this.getFilterFuntion(jsonCopy);
            return false;
        }
        let restrictAPICall = true;
        let lastIndex = value.length - 1;
        let skillId = value[lastIndex];
        let results = value.filter(shiftId => skillId === shiftId);
        if (value.length === 0 || results.length === 1) {
            //this.growl.clear();
            this.setState({ skillFilters: value });
            let jsonCopy = Object.assign({}, this.state.jsonTemplate);
            let shiftCodeIds = [];
            value.map(item => {
                let shiftCodeId = this.getShiftCodeId(item);
                if (shiftCodeId !== undefined) {
                    restrictAPICall = false;
                    shiftCodeIds.push(shiftCodeId);
                }
                return value;
            })
            jsonCopy.shiftIdList = shiftCodeIds;

            if (restrictAPICall === false) {
                this.getFilterFuntion(jsonCopy);
            }
            else {
                this.setState({
                    employeeData: [],
                    totalEmployeeRecords: 0
                })
            }
        }
        else {
            this.showErrorMessageForFilter();
        }
    }

    //Function to implement Sorting functionality. 
    onSortChange = (e) => {

        if (this.state.employeeData === undefined || this.state.employeeData.length === undefined) {
            return false;
        }
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true, first: 0 });

        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.sortDirection = 'ASC';
        jsonCopy.sortBy = e.sortField;

        if (e.sortOrder === -1) {
            jsonCopy.sortDirection = "DESC"
        }

        this.getList(jsonCopy);
        this.setState({ jsonTemplate: jsonCopy });
    }

    //Function To call get skills by cost center master Id
    getEmpSkillsList = (costCenterMasterId) => {
        this.setState({ loading: true })
        this.commonService.getEmpSkillsList(this.props.browserLanguage, costCenterMasterId)
            .then(res => {
                let shiftCodes = [];
                res.map(item => {
                    return shiftCodes.push(item.content);
                })
                this.setState({ suggestionData: shiftCodes, responseFromAPI: res })
            })
            .catch(err => {
                this.showMultiple(err)
            })
    }

    //Function to implement functionality for cost center dropdown selection. 
    onCostCenterSelect = (data, costCenterMasterId) => {
        if (this.growl !== undefined && this.growl !== null) {
            this.growl.clear();
        }
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        this.clearFilterControl();

        if (data === '') {
            jsonCopy.costCenterMasterId = '';
            this.setState({
                employeeData: [],
                totalEmployeeRecords: undefined,
                jsonTemplate: jsonCopy
            });
            ReactDOM.unmountComponentAtNode(document.getElementById('costCenter'));
            ReactDOM.render(<CostCenter onChange={this.onCostCenterSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />, document.getElementById('costCenter'))
            return false;
        }

        jsonCopy.costCenterMasterId = costCenterMasterId;
        jsonCopy.employeeName = '';
        jsonCopy.employeeId = '';
        jsonCopy.employeeTypeCode = '';
        jsonCopy.pageNumber = 0;
        jsonCopy.shiftIdList = [];
        this.setState({
            loading: true,
            jsonTemplate: jsonCopy,
            sortField: '',
            sortOrder: null,
            first: 0,
            skillFilters: []
        });

        this.getEmpSkillsList(costCenterMasterId);
        this.getList(jsonCopy);
    }

    //Function to implement functionality of Number of Record Dropdown change of Pagination. 
    noOfRecordChange = (e) => {

        this.setState({
            dropdownDisplay: e.value,
            loading: true,
            first: 0
        })
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        jsonCopy.recordCount = e.value.code;
        jsonCopy.pageNumber = 0;

        if (this.state.employeeData === undefined || this.state.employeeData.length === undefined) {
            this.setState({ loading: false });
            this.setState({ jsonTemplate: jsonCopy });
            return false;
        }

        this.getList(jsonCopy);
        this.setState({ jsonTemplate: jsonCopy });
    }

    //Function to implement functionality of Wrapping Long Text With "...".
    nameTemplate = (rowData, column) => {
        return <div className="extraTextWrapping" title={rowData[column.field]}>{rowData[column.field]}</div>;
    }

    setSkill = (employeeId, value) => {
        this.setState(
            {
                skill: [{
                    employeeId: employeeId,
                    value: value
                }]
            }
        )
    }

    getSkill = (employeeId) => {
        let results = this.state.skill.filter(t => employeeId === t.employeeId);
        if (results.length) {
            return results[0].value;
        }
    }

    upadteRecord = (skillArray, empId, tagId) => {
        //this.growl.clear();
        let jsonCopy = Object.assign({}, this.state.jsonTemplate);

        let bodyTemplate = {
            "employeeId": empId,
            "shiftIdList": skillArray,
            "costCenterMasterId": jsonCopy.costCenterMasterId
        }
        this.commonService.updateEmployeeSkillMapping(bodyTemplate, this.props.browserLanguage)
            .then(data => {
                //let startindex = this.state.employeeData.findIndex(itm =>empId === itm.empID);
                if (this.state.jsonTemplate.shiftIdList.includes(tagId)) {
                    this.setState({ loading: true });
                    this.getFilterFuntion(this.state.jsonTemplate);
                }
                let startindex = _.findIndex(this.state.employeeData, function (itm) { return empId === itm.empID });
                if (startindex !== -1) {
                    let jsonCopy = this.state.employeeData;
                    jsonCopy[startindex] = data;
                    this.setState({ employeeData: jsonCopy });
                }
                this.setState({
                    loading: false,
                })
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }
    getShiftCodeId = (shiftCode) => {
        let results = this.state.responseFromAPI.filter(itm => shiftCode.toLowerCase() === itm.content.toLowerCase());
        if (results.length) {
            return results[0].id
        }
    }

    getShiftCodeRemark = (shiftCode) => {
        let results = this.state.responseFromAPI.filter(itm => shiftCode.toLowerCase() === itm.content.toLowerCase());
        if (results.length) {
            return results[0].remark
        }
    }

    handleClickAdd = (rowData, value) => {
        //this.growl.clear();
        if (value.length < 1) return;
        //let startindex = this.state.employeeData.findIndex(itm =>rowData.empID === itm.empID);
        let startindex = _.findIndex(this.state.employeeData, function (itm) { return rowData.empID === itm.empID });
        if (startindex !== -1) {
            this.setState({ loading: true });
            //let index = this.state.employeeData[startindex].skills.findIndex(itm => value.toLowerCase() === itm.content.toLowerCase());
            let index = _.findIndex(this.state.employeeData[startindex].skills, function (itm) { return value.toLowerCase() === itm.content.toLowerCase() });
            if (index === -1) {
                const tags = this.state.employeeData[startindex].skills.slice();
                let shiftCodeId = this.getShiftCodeId(value);
                tags.push({ id: shiftCodeId, content: value });

                let jsonCopy = this.state.employeeData;
                jsonCopy[startindex].skills = tags;
                this.setState({
                    employeeData: jsonCopy
                })
                this.setState({ skill: [] });
                let skillArray = [];
                tags.map(item => {
                    return skillArray.push(item.id);
                })
                this.upadteRecord(skillArray, rowData.empID);
            }
            else {
                this.setState({ skill: [] });
                this.showErrorMessage();
            }
        }
    }

    showErrorMessage = () => {
        
        this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.skillAlreadyMessage });
        this.setState({ loading: false });
    }

    showErrorMessageForFilter = () => {

        this.growl.show({ life : leaveCodes.growlMessageTimeout, severity: 'warn', summary: translator.warningMessage, detail: translator.skillAlreadyFilterMessage });
        this.setState({ loading: false });
    }

    handleClickDelete = (rowData, tag) => {
        // let jsonCopy = Object.assign({}, this.state.jsonTemplate);
        let startindex = _.findIndex(this.state.employeeData, function (itm) { return rowData.empID === itm.empID });
        if (startindex !== -1) {
            this.setState({ loading: true });

            let skillArray = [];
            let tags = this.state.employeeData[startindex].skills.filter(t => tag.id !== t.id);
            tags.map(item => {
                return skillArray.push(item.id);
            })
            this.upadteRecord(skillArray, rowData.empID, tag.id);
            // if(jsonCopy.shiftIdList.includes(tag.id))
            // {
            //     this.setState({loading:true});
            //     this.getFilterFuntion(jsonCopy);
            // }           
        }
    }
    ontagChange = (tags, empId) => {
        this.setState({ loading: true });
        let skillArray = [];
        tags.map(item => {
            return skillArray.push(item.id);
        })
        this.upadteRecord(skillArray, empId);
    }

    filterCountrySingle(event) {
        setTimeout(() => {
            if (event.query !== '') {
                var results = this.state.suggestionData.filter((itm) => {
                    return itm.toLowerCase().startsWith(event.query.toLowerCase());
                });
                this.setState({ filteredCountriesSingle: results });
            }
            else {
                this.setState({ filteredCountriesSingle: this.state.suggestionData });
            }
        }, 100);
    }

    itemTemplate(skillCode) {
        return (
            <span title={this.getShiftCodeRemark(skillCode)}>{skillCode}</span>
        );
    }

    idTemplate = (rowData) =>{

        if (rowData.empType !== translator.dummy) {
            return (
                <div>{rowData.empID}</div>
            )
        }
    }

    tagTemplate = (rowData) => {
        return (
            <div>
                <div className="dvdraggable">
                    <DraggableArea
                        tags={rowData.skills}
                        render={
                            ({ tag, index }) => (
                                <div className="tag">
                                    <Button className="cleartagBtn" label="X" onClick={() => this.handleClickDelete(rowData, tag)} />
                                    <span title={tag.remark}> {tag.content}</span>
                                </div>
                            )}
                        onChange={tag => this.ontagChange(tag, rowData.empID)}
                    />
                </div>
                <div className="dvAutoComplete">
                    <AutoComplete value={this.getSkill(rowData.empID)} delay={800} inputId={"tagtxt" + rowData.empID}
                        dropdown={true} suggestions={this.state.filteredCountriesSingle}
                        completeMethod={this.filterCountrySingle}
                        size={8} placeholder={translator.addSkill} minLength={1}
                        onSelect={(e) => this.handleClickAdd(rowData, e.value)}
                        onChange={(e) => this.setSkill(rowData.empID, e.value)}
                        itemTemplate={this.itemTemplate.bind(this)}
                    />
                </div>


            </div>
        )
    }


    render() {
        translator.setLanguage(this.props.browserLanguage);

        const noOfRecord = [
            { name: translator.Records_150, code: 150 },
            { name: translator.Records_200, code: 200 },
            { name: translator.Records_250, code: 250 }
        ];

        let employeeIdFilter = <InputText maxLength="9" onChange={(e) => { this.filterCleared(e, "employeeId") }} id="employeeId" keyfilter={/^[0-9\b]+$/} className="employeeIdFilterWidth" onKeyDown={(e) => this.onEmployeeIdFilter(e.target.value, e.key)} />
        let employeeNameFilter = <InputText type="text" onChange={(e) => { this.filterCleared(e, "employeeName") }} id="employeeName" className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeNameFilter(e.target.value, e.key)} />
        let employeeTypeFilter = <InputText type="text" onChange={(e) => { this.filterCleared(e, "employeeTypeCode") }} id="employeeTypeCode" className="employeeIdFilterWidth" onKeyDown={(e) => this.onemployeeTypeFilter(e.target.value, e.key)} />
        let employeeSkillFilter = <Chips value={this.state.skillFilters} id="skillFilterselement" className="skillFilterWidth" onChange={(e) => this.skillFilter(e.value)}></Chips>

        return (

            <div className="bodyColor">

                <Dialog visible={this.state.loading} className="Loader" onHide={() => this.setState({ loading: false })}>
                    <ProgressSpinner style={{ width: '75px', height: '75px' }} strokeWidth="5" animationDuration=".8s" />
                </Dialog>

                <Growl ref={(el) => this.growl = el} position="topright" ></Growl>

                <BreadCrumbs initial={translator.master} final={translator.skill} browserLanguage={this.props.browserLanguage} />

                <div className="costcenterPosition" id="costCenter">
                    <CostCenter lastUsedCostCenter={this.state.lastUsedCostCenter} onChange={this.onCostCenterSelect} browserLanguage={this.props.browserLanguage} masterData={this.props.masterData === null ? null : this.props.masterData} />
                </div>

                {
                    this.state.jsonTemplate.costCenterMasterId !== '' ?
                        this.state.totalEmployeeRecords === 0 && (document.getElementById('employeeName') !== null ? (document.getElementById('employeeName').value === '' && document.getElementById('employeeTypeCode').value === '' && document.getElementById('employeeId').value === '' && this.state.skillFilters.length === 0) && this.state.loading === false : true) ?
                            null :
                            <div className="paginatorDropdownPosition" >
                                <span className="recordOF">{this.state.totalEmployeeRecords === undefined || this.state.totalEmployeeRecords === 0 ? '0-0' : (this.state.pageNumber * this.state.pageSize) + 1 + '-' + Number((this.state.pageNumber * this.state.pageSize) + Number(this.state.recordSize))} {translator.of} {this.state.totalEmployeeRecords === undefined ? 0 : this.state.totalEmployeeRecords} {translator.records}</span>
                                <div className="paginatorEmp">
                                    <Paginator rows={this.state.jsonTemplate.recordCount} totalRecords={this.state.totalEmployeeRecords === undefined ? 0 : this.state.totalEmployeeRecords} first={this.state.first} onPageChange={(e) => this.onPageChange(e)}></Paginator>
                                    <Dropdown value={this.state.dropdownDisplay} className="dropDwonDefault colRecord dropmargin" options={noOfRecord} onChange={(e) => this.noOfRecordChange(e)} placeholder={translator.Records_150} optionLabel="name" />
                                </div>
                            </div>
                        : null
                }

                <br />
                {
                    this.state.jsonTemplate.costCenterMasterId !== '' ?
                        this.state.totalEmployeeRecords === 0 && (document.getElementById('employeeName') !== null ? (document.getElementById('employeeName').value === '' && document.getElementById('employeeTypeCode').value === '' && document.getElementById('employeeId').value === '' && this.state.skillFilters.length === 0) && this.state.loading === false : true) ?
                            <h4 className="noRecordFoundMessage">{translator.noRecordFound}</h4> :
                            <DataTable
                                scrollable={browser().name === 'ie' ? false : true}
                                editable={true}
                                value={this.state.employeeData}
                                selectionMode="multiple"
                                rows={this.state.pageSize}
                                onSort={(e) => this.onSortChange(e)}
                                scrollHeight={getResolution()}
                                className="dataTableSkill"
                                sortField={this.state.sortField}
                                sortOrder={this.state.sortOrder}
                                emptyMessage={translator.noRecordFound}
                                frozenWidth="0px"
                            >
                                <Column field="empName" id="employeeName" header={translator.employeeName} sortable={true} filter={true} filterElement={employeeNameFilter} className="textLeftAlign datatableHeaderWidth135" body={this.nameTemplate} />
                                <Column field="empType" id="employeeType" header={translator.employeeTypeCode} sortable={true} filter={true} filterElement={employeeTypeFilter} className="textLeftAlign datatableHeaderWidth135" />
                                <Column field="empID" header={translator.employeeId} sortable={true} filter={true} filterElement={employeeIdFilter} className="numberRightAlign datatableHeaderWidth150" body={this.idTemplate}/>
                                <Column field="skills" id="employeeSkills" header={translator.employeeSkills} filter={true} filterElement={employeeSkillFilter} className="textLeftAlign " body={this.tagTemplate} />

                            </DataTable>
                        : null
                }


            </div>


        );
    }
}

const mapStateToProps = (state) => {
    return {
        masterData: state.masterData,
        browserLanguage: state.browserLanguage
    }
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SkillSet); 
