import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js'
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { CommonService } from '../../service/CommonService';
import { Growl } from 'primereact/growl';
import { ColumnGroup } from 'primereact/columngroup';
import { RadioButton } from 'primereact/radiobutton';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import DeleteConfirmation from './DeleteConfirmation';
import { AutoComplete } from 'primereact/autocomplete';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import leaveCodes from '../../utility/leaveCodes.json';
import { en, fi } from '../../../src/utility/commonUtility';
import './employeeDialog.css';

let translator = new LocalizedStrings(translation);
let keepEmpDialogCompOpen = false;

let contractDetails = {
    empContractDetailId: '',
    empContractEndDate: '',
    empContractStartDate: '',
    empHourperWeek: '',
    description: '',
    costCenterCodeshow: {},
    costCenterCode: ''
}
let contractEdit = {
    empContractStartDate: '',
    empContractEndDate: '',
    empHourperWeek: '',
    description: '',
    costCenterCode: {}
}
class EmployeeDialog extends Component {
    constructor(props) {
        super(props);

        let today = new Date();
        let minDate = new Date(today - 86400000);

        this.state = {
            groupNameArray: [],
            displayDeleteDialog: false,
            displayContractDelete: false,
            minDate: minDate,
            disableArray: [],
            editClick: false,
            addBtn: true,
            disable: true,
            dateError: false,
            dummyEmployeeName: '',
            dummyEmployeeIdArray: [],
            mapWithDummy: "No",
            contractEdited: false,
            displayDialog: true,
            startDateConfirm: '',
            endDateConfirm: ''
        }
        this.commonService = new CommonService();
        this.groupNameSuggestion = [];
    }

    componentDidMount = () => {

        if (this.props.costCenterShow.costCenterMasterId !== undefined) {
            this.commonService.getDummyEmployee(this.props.costCenterShow.costCenterMasterId, this.props.browserLanguage).then(res => {
                if (res.data !== null) {
                    res.data.map((record) => {
                        return this.state.dummyEmployeeIdArray.push({ 'label': record.employeeDetailsId + ' | ' + record.employeeName, 'value': record.employeeDetailsId });
                    })
                    this.commonService.getGroups(this.props.costCenterShow.costCenterMasterId, this.props.browserLanguage).then(res => {
                        if (res !== null && res !== undefined) {
                            for (let i = 0; i < res.length; i++) {
                                this.groupNameSuggestion.push(res[i]);
                            }
                        }
                    }).catch(err => {
                        this.showMultiple(err);
                    })
                }
            }).catch(err => {
                this.showMultiple(err);
            })
        }

        if (this.props.employeeEditData !== undefined && this.props.employeeEditData.employeeDetailsId !== 0 && this.props.employeeEditData.wfmTEmployeeType.employeeType === translator.dummy) {
            this.setState({ DummyCheck: true })
        }

        if (this.props.employeeEditData) {

            let jsonCopy = Object.assign([], this.state.groupNameArray);
            this.props.employeeEditData.empGroupDetails.map(data => {
                jsonCopy.push(data.groupName)
                return 0;
            })

            this.setState({
                groupNameArray: jsonCopy,
                employeeIdShow: this.props.employeeEditData.employeeId,
                employeeEditData: this.props.employeeEditData,
                employeeTypeCodeShow: this.props.employeeTypeCodeShow,
                costCenterShow: this.props.costCenterShow,
                masterCostCenterCode: this.props.masterCostCenterCode,
                costCenterDisplay: this.props.costCenterDisplay,
                displayDialog: this.props.displayDialog,
                jsonTemplate: this.props.jsonTemplate,
                deleteButton: this.props.deleteButton,
                wageTypeShow: this.props.wageTypeCodeShow,
                contractDetailShow: this.props.contractDetailData,
                contractDetailRefreshShow: this.props.contractDetailData,
              //  pomoAccess: this.props.pomoAccess,
                addBtn: true,
                mapWithDummy: "No"
            })
        }

    }

    componentWillReceiveProps = (nextProps) => {

        if (this.props.employeeEditData) {
            let jsonCopy = [];
            nextProps.employeeEditData.empGroupDetails.map(data => {
                jsonCopy.push(data.groupName)
                return 0;
            })
            this.setState({
                groupNameArray: jsonCopy,
                employeeIdShow: this.props.employeeEditData.employeeId,
                employeeEditData: nextProps.employeeEditData,
                employeeTypeCodeShow: nextProps.employeeTypeCodeShow,
                costCenterShow: nextProps.costCenterShow,
                displayDialog: nextProps.displayDialog,
                jsonTemplate: nextProps.jsonTemplate,
                deleteButton: nextProps.deleteButton,
                wageTypeShow: nextProps.wageTypeCodeShow,
                contractDetailShow: nextProps.contractDetailData,
                mapWithDummy: "No"
            })
        }
    }

    //To prevent dialog from getting closed on pressing enter
    preventCloseOfDialog = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    //To check if Date is valid
    checkDate = (e) => {
        var timestamp = Date.parse(e.originalEvent.target.value)
        if (isNaN(timestamp) === false) {
            var timestampDate = new Date(timestamp);
            return timestampDate;
        }
    }

    //Update the json template using property
    updateProperty = (property, value) => {

        if (value.employeeType === translator.dummy) {
            this.setState({ DummyCheck: true })
        }
        else {
            this.setState({ DummyCheck: false })
        }

        if (property === 'wfmTEmployeeType') {

            if (value.employeeType === translator.dummy && this.props.employeeEditData.employeeDetailsId === 0) {
                let employeeEditData = this.state.employeeEditData;
                employeeEditData[property] = value;
                employeeEditData['employeeId'] = '';
                this.setState({ employeeEditData: employeeEditData, employeeTypeCodeShow: value });
            }
            else {
                let employeeEditData = this.state.employeeEditData;
                employeeEditData[property] = value;
                this.setState({ employeeEditData: employeeEditData, employeeTypeCodeShow: value });
            }

        }

        else if (property === 'wfmTCostCenterMaster') {

            let employeeEditData = this.state.employeeEditData;
            employeeEditData[property] = value;
            this.setState({ employeeEditData: employeeEditData, costCenterShow: value });

            if (value.costCenterMasterId !== undefined || value.costCenterMasterId !== null) {
                this.commonService.getDummyEmployee(value.costCenterMasterId, this.props.browserLanguage).then(res => {
                    if (res.data !== null) {
                        this.setState({ dummyEmployeeIdArray: [], mapWithDummy: "No" })
                        res.data.map((record) => {
                            return this.state.dummyEmployeeIdArray.push({ 'label': record.employeeDetailsId + ' | ' + record.employeeName, 'value': record.employeeDetailsId });
                        })
                    }
                }).catch(err => {
                    this.showMultiple(err);
                })

                this.commonService.getGroups(value.costCenterMasterId, this.props.browserLanguage).then(res => {
                    if (res !== null && res !== undefined) {
                        for (let i = 0; i < res.length; i++) {
                            this.groupNameSuggestion.push(res[i]);
                        }
                    }
                }).catch(err => {
                    this.showMultiple(err);
                })

            }
        }

        else if (property === 'wfmTWagesType') {

            let employeeEditData = this.state.employeeEditData;
            employeeEditData[property] = value;
            this.setState({ employeeEditData: employeeEditData, wageTypeShow: value });
        }

        else if (property === 'employeeGroupName') {
            let groupNamesValue = [];

            if (typeof value === 'string') {
                groupNamesValue = this.state.groupNameArray;
                groupNamesValue.push(value.toUpperCase())
                let jsonCopy = Object.assign({}, this.state.employeeEditData);
                jsonCopy.empGroupNames = groupNamesValue;
                this.setState({ employeeEditData: jsonCopy, groupNameArray: groupNamesValue, added: true });
                document.getElementById("employeeGroupName").value = ""
            }
            else {
                groupNamesValue = value;
                let jsonCopy = Object.assign({}, this.state.employeeEditData);
                jsonCopy.empGroupNames = groupNamesValue;
                this.setState({ employeeEditData: jsonCopy, groupNameArray: groupNamesValue, added: true });
                document.getElementById("employeeGroupName").value = ""
            }
        }

        else if (property === 'dummyEmployeeToBeMapped' && this.state.mapWithDummy === "Yes") {
            //this.setState({dummyEmployeeId:value.value})
            let employeeEditData = this.state.employeeEditData;
            employeeEditData[property] = value.value;
            this.setState({ employeeEditData: employeeEditData, dummyEmployeeName: value });
        }

        else if (property === 'includedInTime') {
            let employeeEditData = this.state.employeeEditData;
            employeeEditData[property] = value ? 'Y' : 'N';
            this.setState({ employeeEditData: employeeEditData });
        }

        else if (property === 'inactive') {
            let employeeEditData = this.state.employeeEditData;
            employeeEditData[property] = value ? 'N' : 'Y';
            this.setState({ employeeEditData: employeeEditData });
        }

        // else if (property === 'pomoAccess') {
        //     let employeeEditData = this.state.employeeEditData;
        //     employeeEditData[property] = value ? 'Y' : 'N';
        //     this.setState({ employeeEditData: employeeEditData });
        // }

        else {
            let employeeEditData = this.state.employeeEditData;
            employeeEditData[property] = value;
            this.setState({ employeeEditData: employeeEditData });
        }
    }

    //Function to unMap employee once it is mapped on radio button
    unmapDummyEmployee = (e, property) => {
        let employeeEditData = this.state.employeeEditData;
        employeeEditData[property] = null;
        this.setState({ employeeEditData: employeeEditData, dummyEmployeeName: null, mapWithDummy: e.target.value });
    }


    //Function to implement functionality of Add and Edit Validations On Pop Up Box.
    handleSubmit = (event) => {
        event.preventDefault();

        if (this.state.deleteClicked !== true) {
            if (this.state.mapWithDummy === "Yes" && this.state.employeeEditData.dummyEmployeeToBeMapped === null) {
                this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.dummyEmployeeNotSelectedError })
                document.getElementById("dummyCheck").focus();

            }

            else if (this.state.mapWithDummy === "Yes" && this.state.employeeEditData.dummyEmployeeToBeMapped !== null) {
                this.confirmMapping();

            }

            else if (this.state.editClick || this.state.addNew) {
                if(this.state.addNew && this.state.disable){
                    if(this.state.startDateNew===""){
                        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.selectContractStartDate })
                    }
                    else if(this.state.endDateNew === ""){
                        this.setState({addBtn:true, addNew:true})
                        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.selectContractEndDate })
                    }
                    else if(contractDetails.empHourperWeek === ""){
                        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.enterHoursperWeek })
                    }
                }
                else{
                keepEmpDialogCompOpen = false;
                this.setState({ displayDialog: false, confirmContract: true })}

            }

            else {
                this.save();
            }
        }
    }

    //Function to implement functionality of Save Button On Add And Edit Employee.
    save = () => {

        if (this.state.employeeData === undefined) {
            this.commonService.updateEmployee(this.state.employeeEditData, this.props.browserLanguage)
                .then(data => {
                    this.props.onChange(this.state.employeeEditData, this.state.contractEdited);
                    this.setState({ loading: true });
                })
                .catch(err => {
                    this.setState({ loading: false });
                    this.showMultiple(err);
                })
        }

        else {
            let employeeData = [...this.state.employeeData];
            if (this.newEmployeeData)
                employeeData.push(this.state.employeeEditData);
            else
                employeeData[this.findSelectedRowIndex()] = this.state.employeeEditData;

            this.setState({
                employeeData: employeeData,
                selectedRecord: null,
                employeeEditData: null,
                displayDialog: false,
                loading: true
            });

            this.commonService.updateEmployee(this.state.employeeEditData, this.props.browserLanguage)
                .then(data => {
                    this.props.onChange(this.state.employeeEditData, this.state.contractEdited);
                    this.setState({ loading: true });
                })
                .catch(err => {
                    this.setState({ loading: false });
                    this.showMultiple(err);
                })
        }
    }


    //Function to implement functionality of Delete Button On Edit Employee.
    delete = () => {
        keepEmpDialogCompOpen = false;
        this.setState({
            // selectedRecord: null,
            // employeeEditData: null,
            displayDialog: false,
            deleteClicked: true
        });
        this.setState({ displayDeleteDialog: true });

    }

    // Function to set record that has to be deleted and display dialog to confirm delete contract.
    deleteContract = (e, rowData, column) => {
        keepEmpDialogCompOpen = false;
        this.setState({
            displayDialog: false,
            deleteContractClicked: true,
            rowDataDelete: rowData
        });
        this.setState({ displayContractDelete: true });
    }

    //Function to display dialog to confirm mapping of dummy employee.
    confirmMapping = () => {
        keepEmpDialogCompOpen = false;
        this.setState({
            displayDialog: false,
            deleteContractClicked: true,
        });
        this.setState({ displayContractDelete: true });
    }

    //Function to implement error handling and show Growl message.
    showMultiple = (errorBody) => {
        var detail = errorBody.message.split(',').map(item => {
            return (<li key={item}>{item}</li>)
        })
        this.growl.show({ life: leaveCodes.growlMessageTimeout, summary: translator.errorMessage, detail: detail, severity: "error" });
    }

    //Function to prevent multiple .(dot) and not more than 2 digits after decimal as input
    preventMultipleDotsInput = (event) => {
        var inputValue = event.target.value;
        if ((inputValue.indexOf('.') > -1 && event.key === ".") || (inputValue.indexOf('.') > -1 && inputValue.split('.')[1].length > 1)) {
            //prevents keyboard button value as input
            event.preventDefault();
        }
    }

    //Function to prevent .(dot) as input
    preventDotAsInput = (event) => {
        if (event.key === ".") {
            //prevents keyboard button value as input
            event.preventDefault();
        }
    }

    //Function to show Employee Dialog and hide delete employee confirmation dialog after deleting employee record. 
    showEmployeeDialogAgainAfterDeleteDialog = () => {
        keepEmpDialogCompOpen = false;
        this.setState({ displayDeleteDialog: false, displayDialog: true, deleteClicked: false, displayContractDelete: false });
    }

    //Function to show Employee Dialog and hide delete contract confirmation dialog after deleting employee record. 
    showEmployeeDialogAgainAfterDeleteContract = () => {
        keepEmpDialogCompOpen = false;
        this.setState({ displayDialog: true, deleteContractClicked: false, displayContractDelete: false });
    }

    //Function to call delete service
    callDeleteService = () => {
        this.setState({ displayDeleteDialog: false });
        this.commonService.deleteEmployee(this.state.employeeEditData.employeeDetailsId, this.props.browserLanguage)
            .then(res => {
                this.props.onChange();

            }).catch(err => {
                this.setState({ loading: false });
                this.showMultiple(err);
            })
    }

    //Function to call service to delete contract from UI.
    callContractRecordDelete = () => {
        keepEmpDialogCompOpen = false;
        this.setState({ displayContractDelete: false, displayDialog: true });
        this.deleteContractDetails(this.state.rowDataDelete)
    }

    //Function to hide dialog after confirming and save/update record.
    callDummyConfirmDialog = () => {
        keepEmpDialogCompOpen = false;
        this.setState({ displayContractDelete: false, displayDialog: false });
        this.save();
    }


    //Function to call calbackprop and hide this dialog
    callcallbackToHideDialog = () => {
        if (!this.state.confirmContract) {
            this.setState({ editClick: false, displayDialog: false, addBtn: true, addNew: false })
        }

        else {
            this.setState({ displayDialog: false, addBtn: true, addNew: false })
        }

        document.getElementById("employeeGroupName").value = ""
    }

    //function to save edited contract details on edit save.
    saveEdit = (e, rowData) => {

        let newStartDate = new Date(this.state.startDateConfirm);
        let contractStartDate = newStartDate.getFullYear() + '-' + (this.getMonthCheck(newStartDate.getMonth() + 1)) + '-' + this.getDateCheck(newStartDate.getDate());

        let newEndDate = new Date(this.state.endDateConfirm);
        let contractEndDate = newEndDate.getFullYear() + '-' + (this.getMonthCheck(newEndDate.getMonth() + 1)) + '-' + this.getDateCheck(newEndDate.getDate());

        contractEdit.description = this.state.sample;

        rowData.empContractNumber = contractEdit.empContractNumber;
        rowData.empContractStartDate = contractStartDate;
        rowData.empContractEndDate = contractEndDate;
        rowData.empHourperWeek = contractEdit.empHourperWeek;
        rowData.description = contractEdit.description;
        rowData.costCenterCode = contractEdit.costCenterCode;
        rowData.isActive = true;

        let employeeEditData = this.state.employeeEditData;
        employeeEditData["contractBlockModified"] = true;
        this.setState({ employeeEditData: employeeEditData, editClick: false, contractEdited: true });

        if (this.state.editClick === false) {
            contractEdit.empContractStartDate = '';
            contractEdit.empContractEndDate = '';
            contractEdit.hoursPerWeek = '';
            contractEdit.description = '';
            contractEdit.costCenterCode = {};
        }
    }

    //function to cancel edited contract details
    cancelEdit = () => {
        this.setState({ editClick: false })
        contractEdit.empContractNumber = '';
        contractEdit.empHourperWeek = '';
        contractEdit.empContractEndDate = '';
        contractEdit.description = '';
        contractEdit.costCenterCode = {}
    }

    //Function to Display Icon 
    iconTemplate = (rowData, column) => {
        let startDate = new Date(rowData.empContractStartDate);
        let endDate = new Date(rowData.empContractEndDate)
        let currentDate = new Date();

        if (rowData.source === 'WFM' && (((currentDate > startDate && currentDate < endDate) || (this.dateCompare(currentDate, startDate)) || (this.dateCompare(currentDate, endDate))) || (startDate > currentDate))) {
            return (
                this.state.editClick && this.state.targetEmpContract === rowData.empContractNumber && column.rowIndex === this.state.rowSelected ?
                    <span style={{ float: "right" }} className="elementNoFillIcon" >
                        <i className="pi pi-times" style={{ marginLeft: "12px" }} onClick={(e) => { this.cancelEdit() }}></i>
                        <i className="pi pi-check makeitOrange" onClick={(e) => { this.saveEdit(e, rowData) }}></i>
                    </span>
                    :
                    <span style={{ float: "right" }} className={rowData.source === "WFM" ? "elementFillIcon" : "elementNoFillIcon"}>
                        <i className="pi pi-trash" style={{ marginLeft: "12px" }} onClick={(e) => { this.deleteContract(e, rowData, column) }}></i>
                        <i className="pi pi-pencil" onClick={(e) => { this.editContract(rowData, e) }}></i>
                    </span>

            )
        }

        else {
            return (<div className={rowData.source === "WFM" ? "elementEmptyFillIcon" : "elementEmptyNoFillIcon"} />)
        }

    }

    sourceEditor = (rowData, column) => {
        if (rowData.source === "WFM") {
            return (<div className={this.state.editClick && this.state.targetEmpContract === rowData.empContractNumber && column.rowIndex === this.state.rowSelected ? "elementNoFill" : " elementFill"}> {rowData[column.field]} </div>)
        }

        else {
            return (<div className="elementNoFill"> {rowData[column.field]} </div>)
        }
    }

    //Function to display input for newly added contract(yet not saved)
    contractNumberEditor = (rowData, column) => {
        if (rowData.source === "WFM") {
            return (<div className={this.state.editClick && this.state.targetEmpContract === rowData.empContractNumber && column.rowIndex === this.state.rowSelected ? "elementNoFill" : "elementFill"}></div>)
        }

        else {
            return (<div className="elementNoFill"> {rowData[column.field]} </div>)
        }
    }

    //Function to compare if two dates are equal
    dateCompare = (date1, date2) => {
        if (date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()) {
            return true;
        }
        else {
            return false
        }
    }

    // Function to display Input text to edit Hours/week for active contract detail
    contractStartDateEditor = (rowData, column) => {

        let date = new Date(rowData[column.field])
        let formatDate = date.getFullYear() + '-' + (this.getMonthCheck(date.getMonth() + 1)) + '-' + this.getDateCheck(date.getDate());

        if (this.state.editClick && (this.state.targetEmpContract === rowData.empContractNumber) && column.rowIndex === this.state.rowSelected) {
            let Datevalue = new Date(rowData.empContractStartDate);
            contractEdit.empContractStartDate = Datevalue;
            return (
                <Calendar id="editContractStartDate"
                    value={this.state.startDateConfirm}
                    locale={this.props.browserLanguage === 'en' ? en : fi}
                    onChange={(e) => this.setState({ startDateConfirm: e.value })}
                    onBlur={(e) => { this.contractStartDateChange(e, rowData) }}
                />
            )
        }

        else if (!this.state.editClick && rowData.empContractDetailId === "") {
            return (<div className={rowData.source === "WFM" ? "elementFill" : "elementNoFill"}>{formatDate}</div>)
        }

        else {
            return (<div className={rowData.source === "WFM" ? "elementFill" : "elementNoFill"}>{rowData[column.field]}</div>)
        }
    }

    contractExist = (date, rowData) => {

        let dateCheck = new Date(date);
        let costCenter = rowData.costCenterCode;
        let rowStart = new Date(rowData.empContractStartDate);
        let rowEnd = new Date(rowData.empContractEndDate);
        let contractDetails = this.state.contractDetailShow;
        let wfmcontract = contractDetails.filter(data => data.source === 'WFM');
        let costcenterSpecific = wfmcontract.filter(data => data.costCenterCode === costCenter);

        for (let i = 0; i < costcenterSpecific.length; i++) {
            let loopStartDate = new Date(costcenterSpecific[i].empContractStartDate);
            let loopEndDate = new Date(costcenterSpecific[i].empContractEndDate);

            if (((!this.dateCompare(rowStart, loopStartDate)) && (!this.dateCompare(rowEnd, loopEndDate)))) {
                if ((dateCheck > loopStartDate && dateCheck < loopEndDate) || this.dateCompare(dateCheck, loopStartDate) === true || this.dateCompare(dateCheck, loopEndDate)) {
                    return true
                }
            }
        }
    }

    contractStartDateChange = (e, rowData) => {
        let newstartDate = new Date(e.target.value);
        let endDate = new Date(rowData.empContractEndDate);
        let oldStartDate = new Date(contractEdit.empContractStartDate);
        // let setDate = newstartDate.getFullYear() + '-' + (this.getMonthCheck(newstartDate.getMonth() + 1)) + '-' + this.getDateCheck(newstartDate.getDate());
        var date = newstartDate.getFullYear() + '-' + (this.getMonthCheck(newstartDate.getMonth() + 1)) + '-' + this.getDateCheck(newstartDate.getDate());

        if (!this.checkInvalidDate(this.state.startDateConfirm)) {
            this.setState({ startDateConfirm: oldStartDate })
            document.getElementById("editContractStartDate").value = oldStartDate;
            document.getElementById("editContractStartDate").blur();
            document.getElementById("editContractStartDate").focus();
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.invalidDate })
        }

        else if (newstartDate > endDate) {
            this.setState({ startDateConfirm: oldStartDate })
            document.getElementById("editContractStartDate").firstChild.value = oldStartDate;
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'warn', summary: translator.warningMessage, detail: translator.contractStartDateError })

        }

        else if (this.state.contractDetailShow.length === 0) {
            contractEdit.empContractStartDate = date;
        }

        else if (this.contractExist(newstartDate, rowData)) {
            this.setState({ startDateConfirm: oldStartDate })
            document.getElementById("editContractStartDate").value = oldStartDate;
            document.getElementById("editContractStartDate").blur();
            document.getElementById("editContractStartDate").focus();
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.contractExistCostCenter })
        }

        else {
            contractEdit.empContractStartDate = date;
        }
    }

    // Function to display Input text to edit Hours/week for active contract detail
    contractEndDateEditor = (rowData, column) => {

        let date = new Date(rowData[column.field])
        let formatDate = date.getFullYear() + '-' + (this.getMonthCheck(date.getMonth() + 1)) + '-' + this.getDateCheck(date.getDate());

        if (this.state.editClick && this.state.targetEmpContract === rowData.empContractNumber && column.rowIndex === this.state.rowSelected) {
            let Datevalue = new Date(rowData.empContractEndDate)
            contractEdit.empContractEndDate = Datevalue;

            return (
                <Calendar id="editContractEndDate"
                    value={this.state.endDateConfirm}
                    locale={this.props.browserLanguage === 'en' ? en : fi}
                    onChange={(e) => this.setState({ endDateConfirm: e.value })}
                    onBlur={(e) => { this.contractEndDateChange(e, rowData) }}
                />
            )
        }

        else if (!this.state.editClick && rowData.empContractDetailId === "") {
            return (<div className={rowData.source === "WFM" ? "elementFill" : "elementNoFill"}>{formatDate}</div>)
        }

        else {
            return (<div className={rowData.source === "WFM" ? "elementFill" : "elementNoFill"}>{rowData[column.field]}</div>)
        }

    }

    //Update contract EndDate on edit in state.
    contractEndDateChange = (e, rowData, column) => {
        var newEndDate = new Date(e.target.value);
        var newStartDate = new Date(rowData.empContractStartDate);
        var date = newEndDate.getFullYear() + '-' + (this.getMonthCheck(newEndDate.getMonth() + 1)) + '-' + this.getDateCheck(newEndDate.getDate());

        if (!this.checkInvalidDate(this.state.endDateConfirm)) {
            this.setState({ endDateConfirm: new Date(rowData.empContractEndDate) })
            document.getElementById("editContractEndDate").blur();
            document.getElementById("editContractEndDate").focus();
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.invalidDate })
        }

        else if (this.checkEndDate(this.state.startDateConfirm, this.state.endDateConfirm)) {
            this.setState({ endDateConfirm: new Date(rowData.empContractEndDate) })
            document.getElementById("editContractEndDate").blur();
            document.getElementById("editContractEndDate").focus();
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.contractEndDateAfter })
        }

        else if (this.contractExist(newEndDate, rowData)) {
            this.setState({ endDateConfirm: new Date(rowData.empContractEndDate) })
            document.getElementById("editContractEndDate").blur();
            document.getElementById("editContractEndDate").focus();
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.contractExistCostCenter })
        }

        else if (newEndDate > newStartDate || this.dateCompare(newEndDate, newStartDate)) {
            contractEdit.empContractEndDate = date;
        }
    }

    // Function to display Input text to edit Hours/week for active contract detail
    hourPerWeekEditor = (rowData, column) => {

        if (this.state.editClick && this.state.targetEmpContract === rowData.empContractNumber && column.rowIndex === this.state.rowSelected) {
            return (
                <InputText
                    style={{ width: "97px", height: "26px", marginLeft: "4px" }}
                    defaultValue={contractEdit.empHourperWeek === '' ? rowData.empHourperWeek.toString().replace(".", ":") : contractEdit.empHourperWeek.toString().replace(".", ":")}
                    id="empContractDetails"
                    onChange={(e) => this.contractHoursChange(e, rowData)}
                    onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                />
            )
        }
        else if (this.state.hideInput) {
            return (<div className={rowData.source === "WFM" ? "elementFill" : "elementNoFill"}>{rowData[column.field].toString().replace(".", ":")}</div>)
        }

        else {
            return (<div className={rowData.source === "WFM" ? "elementFill" : "elementNoFill"}>{rowData[column.field].toString().replace(".", ":")}</div>)
        }
    }

    //Update contract hour/week on edit in state.
    contractHoursChange = (e, rowData) => {
        let rawInput = e.target.value;
        let allowedCharacters = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ":"];
        let splitArray = (rawInput).split(':');
        let part2 = splitArray[1];
        let checkStr= 0;
        if(part2 !== undefined){checkStr = part2.charAt(0)}

        if (
            rawInput !== "" && ((rawInput.match(/:/g) || []).length > 1
                || rawInput.includes(".")
                || rawInput === ":"
                || rawInput === "120:"
                || checkStr > 5
                || !allowedCharacters.includes(rawInput.substr(rawInput.length - 1, rawInput.length)))
        ) {
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.contractHrsperWeek })
            e.target.value = e.target.value.substring(0, e.target.value.length - 1)
        }
        else {
            let inputValue = e.target.value.replace(":", ".")

            contractEdit.empHourperWeek = rowData.empHourperWeek

            if (inputValue === '') {
                contractEdit.empHourperWeek = rowData.empHourperWeek
            }
            else if (inputValue !== contractEdit.empHourperWeek) {

                if (Number(inputValue) % 1 === 0) {
                    if (Number(inputValue) <= 120) {
                        contractEdit.empHourperWeek = Number(inputValue);
                    }
                    else {
                        document.getElementById("empContractDetails").value = contractEdit.empHourperWeek.toString().replace(".", ":");
                        document.getElementById("empContractDetails").blur();
                        document.getElementById("empContractDetails").focus();
                        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.contractHrsperWeek })
                    }

                }
                else {
                    let CIDArray = (inputValue).split('.');
                    let minutes = Number(CIDArray[1])

                    if (minutes > 59 || (Number(CIDArray[0]) === 120 && minutes !== 0) || (Number(CIDArray[0]) > 120)) {
                        document.getElementById("empContractDetails").value = contractEdit.empHourperWeek.toString().replace(".", ":");
                        document.getElementById("empContractDetails").blur();
                        document.getElementById("empContractDetails").focus();
                        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.contractHrsperWeek })
                    }

                    else {
                        contractEdit.empHourperWeek = Number(inputValue);
                    }
                }
            }
            else {
                contractEdit.empHourperWeek = Number(inputValue)
            }
        }

    }

    // Function to display Input text to edit costcenter for selected contract and display costcenter value for other contract
    costcenterEditor = (rowData, column) => {
        if (this.state.editClick && this.state.targetEmpContract === rowData.empContractNumber && column.rowIndex === this.state.rowSelected) {
            return (
                <select id="editContractCostCenterCode"
                    disabled={rowData.empContractDetailId === "" ? false : true}
                    className={rowData.empContractDetailId !== "" ? "makeitGray" : null}
                    style={{ width: "120px", height: "26px", marginLeft: "5px", borderRadius: "3px" }}
                    onChange={e => { this.costCenterEditChange(rowData) }}>
                    <option></option>
                    {this.state.costCenterCodeDropdown.map(data => {
                        return (<option value={data} selected={contractEdit.costCenterCode === data ? true : false}>{data}</option>)
                    })}
                </select>
            )

        }
        else if (this.state.hideInput) {
            return (<div className={rowData.source === "WFM" ? "elementFill" : "elementNoFill"}>{rowData[column.field]}</div>)
        }

        else {
            return (<div className={rowData.source === "WFM" ? "elementFill" : "elementNoFill"}>{rowData[column.field]}</div>)
        }
    }

    handleChange() {
        var data = new FormData(this.refs.form);
        for (var key of data.entries()) {
            this.setState({ selectedCarId: key[1] });
        }
    }

    //Function to validate contract period on editing cost center
    checkContractforCostCenter = (costCenter, lastCostCenter) => {
        let contractDetails = this.state.contractDetailShow;
        let wfmcontract = contractDetails.filter(data => data.source === 'WFM');
        let costcenterSpecific = wfmcontract.filter(data => data.costCenterCode === costCenter);

        let startDate = new Date(contractEdit.empContractStartDate);
        let endDate = new Date(contractEdit.empContractEndDate)


        for (let i = 0; i < costcenterSpecific.length; i++) {
            let loopStartDate = new Date(costcenterSpecific[i].empContractStartDate);
            let loopEndDate = new Date(costcenterSpecific[i].empContractEndDate);

            if ((startDate > loopStartDate && startDate < loopEndDate) || this.dateCompare(startDate, loopStartDate) === true || this.dateCompare(startDate, loopEndDate)) {
                if ((endDate > loopStartDate && endDate < loopEndDate) || this.dateCompare(endDate, loopStartDate) === true || this.dateCompare(endDate, loopEndDate)) {
                    return true
                }
            }

        }

    }

    //Function to update the edited cost center value for selected contract
    costCenterEditChange = (rowData) => {
        let lastCostCenter = contractEdit.costCenterCode;
        var e = document.getElementById("editContractCostCenterCode");
        var newCostCenter = e.options[e.selectedIndex].text;
        contractEdit.costCenterCode = String(newCostCenter);

        if (this.checkContractforCostCenter(contractEdit.costCenterCode, lastCostCenter)) {
            document.getElementById("editContractCostCenterCode").value = rowData.costCenterCode;
            contractEdit.costCenterCode = rowData.costCenterCode;
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.contractExistCostCenter + " " + contractEdit.costCenterCode })
        }
    }

    // Function to display Input text to edit description for selected contract and display description value for other contract
    descriptionEditor = (rowData, column) => {
        if (this.state.editClick && this.state.targetEmpContract === rowData.empContractNumber && column.rowIndex === this.state.rowSelected) {
            return (
                <InputTextarea
                    id="editContractDescription"
                    rows={5}
                    cols={35}
                    value={this.state.sample}
                    // value={contractEdit.description}
                    style={{ width: "232px", marginLeft: "5px", marginTop: "2px", height: "26px" }}
                    onChange={(e) => { this.setState({ sample: e.target.value }) }} />)
            // onChange={(e) => { this.descriptionChange(e, rowData) }} />)

        }
        else if (this.state.hideInput) {
            return (<div className={rowData.source === "WFM" ? "elementFill extraTextWrapping" : "elementNoFill extraTextWrapping"}>{rowData[column.field]}</div>)
        }

        else {
            return (<div className={rowData.source === "WFM" ? "elementFill extraTextWrapping" : "elementNoFill extraTextWrapping"}>{rowData[column.field]}</div>)
        }
    }

    //To get empContractDetailId for current active rowData to update.
    editContract = (rowData, e) => {
        let data1 = [];
        this.props.costCenterDisplay.map(data => {
            // return data1.push({"data":data.costCenterCode})
            return data1.push(data.costCenterCode)
        })

        this.setState({ addNew: false, editClick: true, targetEmpContractDetailId: rowData.empContractDetailId, targetEmpContract: rowData.empContractNumber, contractEditRow: rowData, startDateConfirm: new Date(rowData.empContractStartDate), endDateConfirm: new Date(rowData.empContractEndDate), costCenterCodeDropdown: data1 })
        contractEdit.empContractNumber = rowData.empContractNumber;
        contractEdit.empContractStartDate = rowData.empContractStartDate;
        contractEdit.empContractEndDate = rowData.empContractEndDate;
        contractEdit.empHourperWeek = rowData.empHourperWeek;
        contractEdit.costCenterCode = rowData.costCenterCode;
        contractEdit.description = rowData.description
        this.setState({ sample: rowData.description })
        this.cancelNewContract(e);
    }

    //Function to add new contract detail (AddNew Button)
    addNewContract = (e) => {
        e.preventDefault();
        contractDetails = {
            empContractDetailId: '',
            empContractEndDate: '',
            empContractNumber: '',
            empContractStartDate: '',
            empHourperWeek: '',
            description: '',
            costCenterCodeshow: this.props.masterCostCenterCode,
            costCenterCode: this.props.masterCostCenterCode.costCenterCode
        }
        this.setState({ addNew: true, cancelBtn: true, addBtn: false, editClick: false, startDateNew: "", endDateNew: "" });
    }

    //Function to clear contract details entered (Clear Button)
    cancelNewContract = (e) => {
        e.preventDefault();
        this.setState({ addNew: false, cancelBtn: false, addBtn: true });
        contractDetails = {
            empContractDetailId: '',
            empContractEndDate: '',
            empContractNumber: '',
            empContractStartDate: '',
            empHourperWeek: '',
            description: '',
            costCenterCodeshow: {},
            costCenterCode: ''
        }
    }

    // Function to delete contract details from contractDetails locally(to show in UI)
    deleteContractDetails = (rowData) => {
        let jsonCopy = Object.assign({}, this.state.employeeEditData);
        let jsonCopy1 = Object.assign([], this.state.contractDetailShow);

        var indexData = null
        var array = this.state.employeeEditData.empContractDetails;
        array.map((data, index) => {
            if (data.empContractDetailId !== "" && data.empContractDetailId === rowData.empContractDetailId) {
                indexData = index;
                jsonCopy.empContractDetails[indexData].isDeleted = true
                jsonCopy.contractBlockModified = true;
                jsonCopy1.splice(indexData, 1)
            }
            else if (data.empContractDetailId === "" && data.empContractDetailId === rowData.empContractDetailId) {
                jsonCopy.empContractDetails.splice(index, 1)
                jsonCopy.contractBlockModified = true;
                jsonCopy1.splice(index, 1)
            }
            return 0;
        })

        this.setState({ employeeEditData: jsonCopy, contractDetailShow: jsonCopy1 });

    }

    contractExistNewContract = (date) => {
        let dateCheck = new Date(date);
        let contractDetailshow = this.state.contractDetailShow;
        let wfmcontract = contractDetailshow.filter(data => data.source === 'WFM');
        let costCenter = contractDetails.costCenterCode;
        let costcenterSpecific = wfmcontract.filter(data => data.costCenterCode === costCenter);

        for (let i = 0; i < costcenterSpecific.length; i++) {
            let loopStartDate = new Date(costcenterSpecific[i].empContractStartDate);
            let loopEndDate = new Date(costcenterSpecific[i].empContractEndDate);

            if ((dateCheck > loopStartDate && dateCheck < loopEndDate) || this.dateCompare(dateCheck, loopStartDate) === true || this.dateCompare(dateCheck, loopEndDate)) {
                return true
            }
        }
    }

    //check Invalid Date
    checkInvalidDate = (contractDate) => {
        if (contractDate instanceof Date) {
            return true;
        }
        else return false;
    }

    //Function to check endate is before startdate
    checkEndDate = (startDate, endDate) => {

        if ((this.dateCompare(startDate, endDate))) {
            return false;
        }
        else if (endDate.getFullYear() > startDate.getFullYear()) {
            return false;
        }

        else if ((startDate.getFullYear() === endDate.getFullYear()) && (endDate.getMonth() > startDate.getMonth())) {
            return false;
        }

        else if ((startDate.getFullYear() === endDate.getFullYear()) && (startDate.getMonth() === endDate.getMonth()) && (endDate.getDate() > startDate.getDate())) {
            return false;
        }

        else {
            return true;
        }

    }

    //function called onfocus of other component to check invalid date
    onChangeContractDate = (e, property) => {
        if (property === "empContractStartDate") {

            if (!this.checkInvalidDate(this.state.startDateNew)) {

                this.setState({ startDateNew: "" });
                contractDetails.empContractStartDate = '';
                document.getElementById("empContractStartDate").value = "";
                document.getElementById("empContractStartDate").blur();
                document.getElementById("empContractStartDate").focus();
                this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.invalidDate })
            }
        }

        else if (property === "empContractEndDate") {

            if (!this.checkInvalidDate(this.state.endDateNew)) {
                this.setState({ endDateNew: "" });
                contractDetails.empContractStartDate = '';
                document.getElementById("empContractEndDate").value = "";
                document.getElementById("empContractEndDate").blur();
                document.getElementById("empContractEndDate").focus();
                this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.invalidDate })
            }

            else if (this.checkEndDate(this.state.startDateNew, this.state.endDateNew)) {
                this.setState({ endDateNew: "" });
                contractDetails.empContractStartDate = '';
                document.getElementById("empContractEndDate").value = "";
                document.getElementById("empContractEndDate").blur();
                document.getElementById("empContractEndDate").focus();
                this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.contractEndDateAfter })
            }
        }

    }

    //Function to update contract details while adding new detail in local variable
    updateContractDetail = (e, property) => {
        let totalRecords = this.state.contractDetailShow.length;

        if (property === "empContractStartDate") {

            let startDate = new Date(e.target.value);

            if (totalRecords === 0) {
                let minDate = new Date();
                contractDetails.empContractStartDate = startDate;
                this.setState({ minDate: minDate });
            }

            else if (this.contractExistNewContract(startDate)) {
                this.setState({ startDateNew: "" });
                contractDetails.empContractStartDate = '';
                document.getElementById("empContractStartDate").value = "";
                document.getElementById("empContractStartDate").blur();
                document.getElementById("empContractStartDate").focus();
                this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.contractExistCostCenter })
            }

            else {
                let minDate = new Date();
                this.setState({ minDate: minDate });
                contractDetails.empContractStartDate = startDate;
            }

        }

        else if (property === "empContractEndDate") {
            let endDate = new Date(e.target.value);
            var addedStartDate = new Date(contractDetails.empContractStartDate);

            if (this.contractExistNewContract(endDate)) {
                this.setState({ endDateNew: "" });
                contractDetails.empContractStartDate = '';
                document.getElementById("empContractEndDate").value = "";
                document.getElementById("empContractEndDate").blur();
                document.getElementById("empContractEndDate").focus();
                this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.contractExistCostCenter })
            }

            else if (endDate > addedStartDate || this.dateCompare(endDate, addedStartDate)) {
                contractDetails.empContractEndDate = endDate;
            }

        }

        else if (property === "empContractHoursperWeek") {
            let rawInput = e.target.value;
            let allowedCharacters = ["1","2","3","4","5","6","7","8","9","0",":"];
            let splitArray = (rawInput).split(':');
            let part2 = splitArray[1];
            let checkString = 0;
            if(part2 !== undefined){checkString = part2.charAt(0)}

            if (
                rawInput !== "" && ((rawInput.match(/:/g) || []).length > 1 
                || rawInput.includes("." ) 
                || rawInput === ":" 
                || rawInput === "120:"
                || checkString > 5
                || !allowedCharacters.includes(rawInput.substr(rawInput.length-1 , rawInput.length)))
            ) {
                this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.contractHrsperWeek })
                e.target.value = e.target.value.substring(0, e.target.value.length - 1)
            }
            else{
                let inputValue = e.target.value.replace(":", ".")

                if (Number(inputValue) % 1 === 0) {
                    if(Number(inputValue) <= 120){
                        contractDetails.empHourperWeek = inputValue;

                    }
                    else{
                        document.getElementById("empContractHoursperWeek").value = contractDetails.empHourperWeek.toString().replace(".",":");
                        document.getElementById("empContractHoursperWeek").blur();
                        document.getElementById("empContractHoursperWeek").focus();
                        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.contractHrsperWeek })
                    }
                }

                else {

                    let CIDArray = (inputValue).split('.');
                    let minutes = CIDArray[1];
                   
                    if (minutes > 59 || (Number(CIDArray[0]) === 120 && minutes !== 0) || (Number(CIDArray[0]) > 120)) {
                        document.getElementById("empContractHoursperWeek").value = contractDetails.empHourperWeek.toString().replace(".",":");
                        document.getElementById("empContractHoursperWeek").blur();
                        document.getElementById("empContractHoursperWeek").focus();
                        this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.contractHrsperWeek })
                    }

                    else if (minutes >= 0 && minutes <= 59) {
                        contractDetails.empHourperWeek = inputValue;
                    }
                }
            }

        }

        else if (property === "empContractDescription") {
            contractDetails.description = e.target.value;
        }

        else if (property === "empContractCostCenter") {
            contractDetails.costCenterCodeshow = e.target.value;
            contractDetails.costCenterCode = contractDetails.costCenterCodeshow.costCenterCode;
            let startDate = contractDetails.empContractStartDate;
            let endDate = contractDetails.empContractEndDate;

            if (this.contractExistNewContract(startDate) && this.contractExistNewContract(endDate)) {
                contractDetails.empContractStartDate = '';
                contractDetails.empContractEndDate = '';
                document.getElementById("empContractStartDate").value = "";
                document.getElementById("empContractEndDate").value = "";
                this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.contractExistCostCenterCostCenter })
            }

        }


        if (
            this.state.startDateNew !== ""
            && this.state.endDateNew !== ""
            && contractDetails.empHourperWeek !== "") {
            this.setState({ disable: false });
        }
        else {
            this.setState({ disable: true });
        }
    }

    //function to add '0' in single digit month (ex:01,02)
    getMonthCheck = (month) => {
        let newMonth = 0
        if (month < 10) {
            newMonth = '0' + month;
            return newMonth
        }
        else {
            return month
        }

    }

    //function to add '0' in single digit date (ex:01,02)
    getDateCheck = (date) => {
        let newDate = 0
        if (date < 10) {
            newDate = '0' + date;
            return newDate
        }
        else {
            return date
        }
    }

    //function to set dummy employee detail if cost center selected else throw select cost center error
    selectCheckMapDummy = (e) => {
        if ("wfmTCostCenterMaster" in this.state.employeeEditData) {
            this.setState({ mapWithDummy: e.value })
        }
        else {
            this.setState({ mapWithDummy: "No" })
            this.growl.show({ life: leaveCodes.growlMessageTimeout, severity: 'error', summary: translator.errorMessage, detail: translator.selectCostCenter })
        }

    }

    //Function to save/update new contract detail in state
    saveContract = (e) => {

        e.preventDefault();
        var indexData = null
        var array = this.state.employeeEditData.empContractDetails;
        array.map((data, index) => {
            if (data.isDeleted === true) {
                indexData = index;
            }
            return 0;
        })
        let jsonCopy = Object.assign({}, this.state.employeeEditData);
        jsonCopy.contractBlockModified = true;
        let jsonCopy1 = Object.assign([], this.state.employeeEditData);
        jsonCopy1.contractBlockModified = true;

        let startDate = new Date(this.state.startDateNew);
        let endDate = new Date(this.state.endDateNew);

        let contractStartDate = startDate.getFullYear() + '-' + (this.getMonthCheck(startDate.getMonth() + 1)) + '-' + this.getDateCheck(startDate.getDate());
        let contractEndDate = endDate.getFullYear() + '-' + (this.getMonthCheck(endDate.getMonth() + 1)) + '-' + this.getDateCheck(endDate.getDate());

    
        if (indexData === null) {
            if (this.state.employeeEditData !== undefined) {
                jsonCopy.empContractDetails.push({
                    source: "WFM",
                    empContractDetailId: contractDetails.empContractDetailId,
                    empContractNumber: "",
                    empContractStartDate: contractStartDate,
                    empContractEndDate: contractEndDate,
                    empHourperWeek: Number(contractDetails.empHourperWeek),
                    description: contractDetails.description,
                    costCenterCode: contractDetails.costCenterCode
                })

            }
            this.setState({ employeeEditData: jsonCopy, contractDetailShow: jsonCopy.empContractDetails, addNew: false, addBtn: true });
        }

        else {
            if (this.state.employeeEditData !== undefined) {
                jsonCopy.empContractDetails.push({
                    empContractDetailId: contractDetails.empContractDetailId,
                    empContractNumber: contractDetails.empContractNumber,
                    empContractStartDate: contractDetails.empContractStartDate,
                    empContractEndDate: contractDetails.empContractEndDate,
                    empHourperWeek: contractDetails.empHourperWeek
                })
                jsonCopy1.empContractDetails.splice(indexData, 1)
            }
            this.setState({ employeeEditData: jsonCopy, contractDetailShow: jsonCopy1.empContractDetails, addNew: false, addBtn: true });
        }
    }

    //Add multiple groups from Group Filter
    filterGroupMultiple = (event) => {
        setTimeout(() => {
            let results = this.groupNameSuggestion.filter((groupSuggestion) => {
                return groupSuggestion.toUpperCase().startsWith(event.query.toUpperCase());
            });

            this.setState({ filteredGroupMultiple: results });
        }, 250);
    }

    //Add new group other than suggestions
    addNewGroupNotInSuggestion = (e) => {

        if (e.key === 'Enter' || e.keyCode === 9) {
            e.preventDefault();
            let value = e.target.value
            let endSpace = value.replace(/\s+$/g, '');  //trim space from end
            let textTrim = endSpace.replace(/^\s+/, ''); //trim space from start
            let groupName = this.state.employeeEditData.empGroupNames;
            let count = 0;

            if (groupName.length === 0) {
                count += 1;
            }
            else if (groupName.length > 0) {
                for (let i = 0; i < groupName.length; i++) {
                    if (groupName[i] !== textTrim.toUpperCase()) {
                        count += 1;
                        break;
                    }
                    else {
                        document.getElementById("employeeGroupName").value = ""
                    }
                }
            }
            if (count > 0) {
                //this.groupNameSuggestion.push(textTrim.toUpperCase());
                this.updateProperty('employeeGroupName', textTrim);
            }
        }
    }


    confirmContractDetails = (e) => {

        if (this.state.editClick) {
            contractEdit.empContractStartDate = this.state.startDateConfirm;
            contractEdit.empContractEndDate = this.state.endDateConfirm;
            
            this.saveEdit(e, this.state.contractEditRow)
        }

        else if (this.state.addBtn) {
            
            this.saveContract(e)
        }

        setTimeout(() => this.handleSubmit(e), 2)

        this.setState({ confirmContract: false, displayDialog: true })
    }

    cancelConfirmContractDetails = (e) => {
        this.cancelEdit()
        this.cancelNewContract(e)
        this.setState({ confirmContract: false, displayDialog: true })
        this.handleSubmit(e)
    }

    onRowSelect = (e) => {
        this.setState({ rowSelected: e.index })
    }

    render() {
        translator.setLanguage(this.props.browserLanguage);

        const costCenter = this.props.costCenterDisplay;
        const wagesType = this.props.wagesType;
        var employeeType = this.props.employeeTypeDrop;

        if (this.state.editClick === false) {
            contractEdit.empContractNumber = '';
            contractEdit.empContractStartDate = '';
            contractEdit.empContractEndDate = '';
            contractEdit.hoursPerWeek = '';
            contractEdit.description = '';
            contractEdit.costCenterCode = {};
        }

        if (this.props.employeeEditData !== undefined && this.props.employeeEditData.employeeDetailsId !== 0 && this.props.employeeEditData.wfmTEmployeeType.employeeType !== translator.dummy && this.props.employeeTypeCodeShow.employeeType !== translator.dummy) {
            employeeType = employeeType.filter(data => data.employeeType !== translator.dummy)
        }

        let dialogFooter = (<div className="ui-dialog-buttonpane p-clearfix">
            {this.state.deleteButton && this.props.employeeTypeCodeShow.employeeType !== translator.dummy ? <Button label={translator.delete} icon="pi pi-times" className="deleteButton" onClick={this.delete} /> : null}
            <Button label={translator.save} icon="pi pi-check" className="deleteButton" type="submit" />
        </div>);

        let confirmFooter = (<div className="ui-dialog-buttonpane p-clearfix">
            <Button id="yesbtn" label={translator.yes} className="deleteButton" onClick={this.confirmContractDetails} />
            <Button label={translator.no} className="deleteButton" onClick={this.cancelConfirmContractDetails} />
        </div>);

        let headerGroupNew = <ColumnGroup>
            <Row>
                <Column className="datatableHeaderWidth65" />
                <Column header={translator.source} className="textLeftAlign datatableHeaderWidth80" />
                <Column header={translator.costCenter} className="textLeftAlign datatableHeaderWidth132" />
                <Column header={translator.contractNumber} className="textLeftAlign datatableHeaderWidth125" />
                <Column header={translator.startDate} className="textLeftAlign datatableHeaderWidth105" />
                <Column header={translator.endDate} className="textLeftAlign datatableHeaderWidth105" />
                <Column header={translator.hoursPerWeekly} className="textLeftAlign datatableHeaderWidth105" />
                <Column header={translator.description} className="textLeftAlign " />
            </Row>

            <Row>
                <Column style={{ backgroundColor: "white", padding: "2.5px 0px" }} className="datatableHeaderWidth65" header={<Button onClick={(e) => { this.saveContract(e) }} id="saveContract" icon="pi pi-plus" className="contractFooter" style={{ width: "22px", height: "22px", padding: "0px" }} disabled={this.state.disable} />} />

                <Column className="textLeftAlign datatableHeaderWidth80" header="WFM" />

                <Column className="textLeftAlign datatableHeaderWidth132" header={
                    <Dropdown id="empContractCostCenter"
                        className="dropDwonDefault"
                        dataKey="costCenterCode"
                        value={contractDetails.costCenterCodeshow}
                        options={costCenter}
                        filter={true}
                        filterPlaceholder={translator.searchCostCenter}
                        filterBy="costCenterCode"
                        onChange={(e) => { this.updateContractDetail(e, "empContractCostCenter") }} optionLabel="costCenterCode" />} />

                <Column style={{ backgroundColor: "white", padding: "0px" }} className="textLeftAlign datatableHeaderWidth125" />

                <Column style={{ backgroundColor: "white", padding: "0px" }} className="textLeftAlign datatableHeaderWidth105" header={
                    <Calendar id="empContractStartDate"
                        value={this.state.startDateNew}
                        // dateFormat="yy-mm-dd"
                        locale={this.props.browserLanguage === 'en' ? en : fi}
                        onChange={(e) => { this.setState({ startDateNew: e.target.value }) }}
                        onKeyPress={(e) => { e.preventDefault() }}
                        onBlur={(e) => { this.updateContractDetail(e, "empContractStartDate") }} />

                } />

                <Column style={{ backgroundColor: "white", padding: "0px" }} className="textLeftAlign datatableHeaderWidth105" header={
                    <Calendar id="empContractEndDate"
                        value={this.state.endDateNew}
                        // dateFormat="yy-mm-dd"
                        onKeyPress={(e) => { e.preventDefault() }}
                        locale={this.props.browserLanguage === 'en' ? en : fi}
                        onFocus={(e) => { this.onChangeContractDate(e, "empContractStartDate") }}
                        onChange={(e) => { this.setState({ endDateNew: e.target.value }) }}
                        minDate={this.state.endDateNew !== '' ? new Date(this.state.endDateNew) : null}
                        onBlur={(e) => { this.updateContractDetail(e, "empContractEndDate") }} />} />

                <Column style={{ backgroundColor: "white", padding: "0px" }} className="textLeftAlign datatableHeaderWidth105" header={
                    <InputText style={{ width: "96px", height: "26px" }}
                        id="empContractHoursperWeek"
                        onFocus={(e) => { this.onChangeContractDate(e, "empContractEndDate") }}
                        onChange={(e) => { this.updateContractDetail(e, "empContractHoursperWeek") }}
                        pattern="^[0-9]{1,3}(\:\d{1,2})?$" title="ex: 1, 12, 120, 40:30, 119:59" 
                        disabled={this.state.endDateNew===""? true : false}
                    />} />

                <Column className="textLeftAlign " header={
                    <InputTextarea
                        id="empContractDescription"
                        rows={5}
                        cols={35}
                        value={this.state.value}
                        style={{ width: "228px", height: "26px" }}
                        onChange={(e) => { this.updateContractDetail(e, "empContractDescription") }} />} />
            </Row>
        </ColumnGroup>;

        return (

            <React.Fragment>

                <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                {this.state.confirmContract ? <Dialog modal={true} visible={true} className="deleteDialogBoxPosition" header={translator.confirmContractChanges} footer={confirmFooter} onHide={() => { this.setState({ confirmContract: false, displayDialog: true }) }}> {translator.unsavedContractChanges} </Dialog> : null}

                {this.state.displayDeleteDialog ? <DeleteConfirmationDialog callbackDeleteService={this.callDeleteService} lang={this.props.browserLanguage} callbackProp={this.showEmployeeDialogAgainAfterDeleteDialog} screen="employeemaster" /> : null}

                {this.state.displayContractDelete ? <DeleteConfirmation callbackDeleteService={this.state.mapWithDummy === "Yes" ? this.callDummyConfirmDialog : this.callContractRecordDelete} lang={this.props.browserLanguage} callbackProp={this.showEmployeeDialogAgainAfterDeleteContract} screen={this.state.mapWithDummy === "Yes" ? null : "employeeDialog"} /> : null}

                <form onSubmit={this.handleSubmit}>
                    <Dialog visible={this.state.displayDialog} className="dialogBoxPosition" modal={true} header={translator.employeeDetail} footer={dialogFooter} onHide={keepEmpDialogCompOpen ? () => { } : this.callcallbackToHideDialog}>
                        {
                            this.state.employeeEditData &&
                            <div>
                                <Container>

                                    <Row className="padding7pxBottom">
                                        <Col><label >{translator.costCenterId}<span className="requiredAsterisk">*</span></label></Col>
                                        <Col><Dropdown id="wfmTCostCenterMaster" className="dropDwonDefault defaultWidth" dataKey="costCenterCode" value={this.state.costCenterShow} options={costCenter} onChange={(e) => { this.updateProperty('wfmTCostCenterMaster', e.target.value) }} placeholder={translator.selectCostCenter} optionLabel="codeAndName" filter={true} filterPlaceholder={translator.searchCostCenter} filterBy="costCenterCode" required disabled={this.state.deleteButton ? true : false} /></Col>
                                        <Col><label >{translator.employeeId}{this.state.employeeTypeCodeShow.employeeType !== translator.dummy && this.state.employeeTypeCodeShow.employeeType !== translator.rented && this.state.employeeTypeCodeShow.employeeType !== translator.subContractor ? <span className="requiredAsterisk">*</span> : null}</label>
                                        </Col>
                                        <Col><InputText className="defaultWidth" id="employeeIdDialog" maxLength="8" keyfilter={/\d/} onChange={(e) => { this.updateProperty('employeeId', e.target.value) }} value={this.state.employeeTypeCodeShow.employeeType !== translator.dummy && this.state.employeeEditData.employeeId > 0 ? this.state.employeeEditData.employeeId : ""} onKeyPress={(e) => this.preventDotAsInput(e)} required={this.state.employeeTypeCodeShow.employeeType !== translator.dummy && this.state.employeeTypeCodeShow.employeeType !== translator.rented && this.state.employeeTypeCodeShow.employeeType !== translator.subContractor ? true : false} disabled={this.state.employeeTypeCodeShow.employeeType !== translator.dummy ? false : true} /></Col>
                                    </Row>
                                    <Row className="padding7pxBottom">
                                        <Col><label >{translator.employeeName}<span className="requiredAsterisk">*</span></label></Col>
                                        <Col><InputText onKeyDown={(e) => this.preventCloseOfDialog(e)} className="defaultWidth" id="employeeNameDialog" type="text" maxLength={90} onChange={(e) => { this.updateProperty('employeeName', e.target.value) }} value={this.state.employeeEditData.employeeName} required /></Col>
                                        <Col><label >{translator.wagesType}<span className="requiredAsterisk">*</span></label></Col>
                                        <Col>
                                            <Dropdown id="wfmTWagesType" className="dropDwonDefault defaultWidth" value={this.state.wageTypeShow} options={wagesType} onChange={(e) => { this.updateProperty('wfmTWagesType', e.target.value) }} placeholder={translator.selectWagesType} optionLabel="wagesTypeName" required />
                                        </Col>
                                    </Row>
                                    <Row className="padding7pxBottom">
                                        <Col><label >{translator.employeeTypeCode}<span className="requiredAsterisk">*</span></label></Col>
                                        <Col><Dropdown id="wfmTEmployeeType" className="dropDwonDefault defaultWidth" value={this.state.employeeTypeCodeShow} options={employeeType} onChange={(e) => { this.updateProperty('wfmTEmployeeType', e.target.value) }} placeholder={translator.selectEmployeeType} optionLabel="employeeType" disabled={this.state.employeeTypeCodeShow.employeeType === translator.dummy && this.state.deleteButton ? true : false} required /></Col>
                                        <Col><label >{translator.remainingFlexLeaves}</label></Col>
                                        <Col><InputText className="defaultWidth" id="remainingFlexLeaves" maxLength="7" keyfilter="pnum" onChange={(e) => { this.updateProperty('remainingFlexLeaves', e.target.value) }} value={this.state.employeeEditData.remainingFlexLeaves !== null ? this.state.employeeEditData.remainingFlexLeaves : ""} pattern="^[0-9]{1,4}(\.\d{1,2})?$" title="ex: 1, 12, 123, 1234, 1234.5, 1234.56" /></Col>
                                    </Row>

                                    <Row className="padding7pxBottom">
                                        <Col><label >{translator.remainingVacations}</label></Col>
                                        <Col><InputText className="defaultWidth" id="remainingVacations" maxLength="7" keyfilter="pnum" onChange={(e) => { this.updateProperty('remainingVacations', e.target.value) }} value={this.state.employeeEditData.remainingVacations !== null ? this.state.employeeEditData.remainingVacations : ""} pattern="^[0-9]{1,4}(\.\d{1,2})?$" title="ex: 1, 12, 123, 1234, 1234.5, 1234.56" /></Col>
                                        <Col><label >{translator.savedLeaves}</label></Col>
                                        <Col><InputText className="defaultWidth" id="savedLeaves" maxLength="7" keyfilter="pnum" onChange={(e) => { this.updateProperty('savedLeaves', e.target.value) }} value={this.state.employeeEditData.savedLeaves !== null ? this.state.employeeEditData.savedLeaves : ""} pattern="^[0-9]{1,4}(\.\d{1,2})?$" title="ex: 1, 12, 123, 1234, 1234.5, 1234.56" /> </Col>
                                    </Row>

                                    <Row className="padding7pxBottom">
                                        <Col>
                                            <label >{translator.groupName}</label>
                                        </Col>
                                        <Col>
                                            <AutoComplete className="employeeGroupName" inputId="employeeGroupName" style={{ zIndex: '1', marginLeft: '-53%' }} defaultValue={this.state.groupNameArray} value={this.state.groupNameArray} suggestions={this.state.filteredGroupMultiple} completeMethod={this.filterGroupMultiple}
                                                minLength={1} onKeyPress={(e) => this.addNewGroupNotInSuggestion(e)} placeholder={translator.groupName} field="" multiple={true} onChange={(e) => this.updateProperty('employeeGroupName', e.value)} />
                                        </Col>
                                        {/* <Col>
                                        <label style={{marginLeft:'18%'}}>Include in TIME</label>
                                        <Checkbox
                                                id="includedInTime"
                                                onChange={(e) =>{this.updateProperty('includedInTime', e.checked)}}
                                                checked={this.state.employeeEditData.includedInTime  === 'Y'} />
                                        </Col> */}
                                    </Row>

                                    <Row className="padding15pxBottom">
                                        <Col>
                                            <label >{translator.includeInTime}</label>
                                            <Checkbox
                                                id="includedInTime"
                                                onChange={(e) => { this.updateProperty('includedInTime', e.checked) }}
                                                checked={this.state.employeeEditData.includedInTime === 'Y'} />
                                        </Col>
                                    </Row>
                                    <Row className="padding15pxBottom">
                                        <Col>
                                            <label >{translator.active}</label>
                                            <Checkbox
                                                id="inactive"
                                                onChange={(e) => { this.updateProperty('inactive', e.checked) }}
                                                checked={this.state.employeeEditData.inactive === 'N'} />
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col>
                                            <label >{translator.pomoAccess}</label>
                                            <Checkbox
                                                id="pomoAccess"
                                                onChange={(e) => { this.updateProperty('pomoAccess', e.checked) }}
                                                checked={this.state.employeeEditData.pomoAccess === 'Y'} />
                                        </Col>
                                    </Row> */}
                                    {this.state.employeeTypeCodeShow.employeeType !== translator.dummy ?
                                        <div>

                                            <hr style={{ bordertop: "1px solid #c8c8c8 !important" }}></hr>

                                            {/*  Contract Details Section */}
                                            <Row style={{ paddingLeft: "1em" }}>
                                                <span style={{ color: "#333333", fontWeight: "700" }} className="padding7pxBottom">Contract Details</span>
                                            </Row>

                                            <Row className="padding7pxBottom" >
                                                <Col>
                                                    <div className="addContractButton">
                                                        <Button label={this.state.addBtn ? translator.new : translator.clear} icon={this.state.addBtn ? "pi pi-plus" : "pi pi-times"} onClick={(e) => { this.state.addBtn ? this.addNewContract(e) : this.cancelNewContract(e) }} />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className="padding20pxBottom">
                                                {/* <span> */}
                                                <DataTable value={this.state.contractDetailShow}
                                                    id="contractTable"
                                                    scrollable={true} scrollHeight="100px"
                                                    headerColumnGroup={this.state.addNew ? headerGroupNew : null}
                                                    ref={(el) => this.dt = el}
                                                    className="contractDetailDialog"
                                                    onRowClick={this.onRowSelect} >

                                                    <Column field="active" style={{ padding: "0px" }} className="datatableHeaderWidth65" body={this.iconTemplate} />
                                                    <Column field="source" style={{ padding: "0px" }} header={translator.source} className="textLeftAlign datatableHeaderWidth80" sortable={true} body={this.sourceEditor} />
                                                    <Column field="costCenterCode" style={{ padding: "0px" }} header={translator.costCenter} className="textLeftAlign datatableHeaderWidth132" sortable={true} body={this.costcenterEditor} />
                                                    <Column field="empContractNumber" style={{ padding: "0px" }} header={translator.contractNumber} className="textLeftAlign datatableHeaderWidth125" sortable={true} body={this.contractNumberEditor} />
                                                    <Column field="empContractStartDate" style={{ padding: "0px" }} header={translator.startDate} className="textLeftAlign datatableHeaderWidth105" sortable={true} body={this.contractStartDateEditor} />
                                                    <Column field="empContractEndDate" style={{ padding: "0px" }} header={translator.endDate} className="textLeftAlign datatableHeaderWidth105" sortable={true} body={this.contractEndDateEditor} />
                                                    <Column field="empHourperWeek" style={{ padding: "0px" }} header={translator.hoursPerWeekly} className="textLeftAlign datatableHeaderWidth105" body={this.hourPerWeekEditor} sortable={true} />
                                                    <Column field="description" style={{ padding: "0px" }} header={translator.description} className="textLeftAlign " sortable={true} body={this.descriptionEditor} />
                                                </DataTable>
                                                {/* </span> */}

                                                {/* <span className="addContractBtn">
                                            <Button label={this.state.addBtn ? translator.new : translator.clear} icon={this.state.addBtn ? "pi pi-plus" : "pi pi-times"} onClick={(e) => { this.state.addBtn ? this.addNewContract(e) : this.cancelNewContract(e) }} />
                                        </span> */}
                                            </Row> </div> : null}

                                    {this.state.employeeTypeCodeShow.employeeType !== translator.dummy && this.state.employeeTypeCodeShow.employeeType !== undefined && this.props.empActive === true ?
                                        <Container>
                                            <Row className="padding7pxBottom">
                                                <Col><label className="dummyRadioMarginRight">Map with Dummy</label>
                                                    <RadioButton value="Yes" id="dummyCheck" name="mapWithDummy" onChange={(e) => this.selectCheckMapDummy(e)} checked={this.state.mapWithDummy === 'Yes'} />
                                                    <label htmlFor="rb1" className="p-radiobutton-label dummyRadioMarginRight" >Yes </label> &nbsp;
                                            <RadioButton id="dummyUnCheck" className="dummyRadioMarginLeft" value="No" name="mapWithDummy" onChange={(e) => this.unmapDummyEmployee(e, "dummyEmployeeToBeMapped")} checked={this.state.mapWithDummy === 'No'} />
                                                    <label htmlFor="rb1" className="p-radiobutton-label">No </label>
                                                </Col>
                                            </Row>

                                            <Row className="padding7pxBottom" >
                                                <Col>
                                                    <label className="dummyRadioMarginRight">{translator.employeeId}</label>
                                                    <Dropdown id="dummyEmployeeToBeMapped" className="dropDwonDefault defaultWidth" placeholder="Select" options={this.state.dummyEmployeeIdArray} optionLabel="label" value={this.state.dummyEmployeeName} onChange={(e) => { this.updateProperty('dummyEmployeeToBeMapped', e.target.value) }} filterInputAutoFocus={true} disabled={this.state.mapWithDummy === "No" ? true : false}
                                                        style={{ boxShadow: this.state.mapWithDummy !== undefined && this.state.employeeEditData !== undefined && this.state.mapWithDummy === "Yes" && this.state.employeeEditData.dummyEmployeeToBeMapped === null ? "0 0 5px 2px #ff751a" : null, border: this.state.mapWithDummy !== undefined && this.state.employeeEditData !== undefined && this.state.mapWithDummy === "Yes" && this.state.employeeEditData.dummyEmployeeToBeMapped === null ? "1px #ff751a solid" : null }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Container>
                                        : null}
                                </Container>
                            </div>

                        }
                    </Dialog>
                </form>
            </React.Fragment>
        );
    }
}

export default EmployeeDialog;