import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';
import { Dropdown } from 'primereact/dropdown';
let translator = new LocalizedStrings(translation);

class BalancingPeriod extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedBalancingPeriod: []
        }
    }

    componentWillUpdate = () => {
        let selectedCostCenter = null;
        if (this.props.masterData.balancingPeriodList && this.props.lastUsedBalancingPeriod && this.state.selectedBalancingPeriod) {
            selectedCostCenter = this.props.masterData.balancingPeriodList.filter((costCenter) => {
                return costCenter.balancingPerioId === this.props.lastUsedBalancingPeriod.balancingPeriodId;
            });
            if (this.state.selectedBalancingPeriod.length === 0) {
                this.setState({ selectedBalancingPeriod: selectedCostCenter[0] });
            }
        }
    }

    onBalancingPeriodSelect = (e) => {

        this.setState({
            selectedBalancingPeriod: e.target.value
        });
        if (e.target.value === null) {
            this.props.onChange('', '');
        }
        else if (e.target.value.planningPeriodList.length > 0) {
            if (e.target.value !== null && e.target.value.planningPeriodList[0] !== null) {
                this.props.onChange(e.value.balancingPerioId, e.target.value.planningPeriodList[0].planningPeriodId);
            }
            else {
                this.props.onChange('', '');
            }
        }

    }

    render() {

        translator.setLanguage(this.props.browserLanguage);
        return (
            <div className="dropDwonDefault">
                <strong >{translator.balancingPeriod} : </strong>
                <Dropdown
                    value={this.state.selectedBalancingPeriod}
                    options={this.props.masterData === null ? null : this.props.masterData.balancingPeriodList}
                    onChange={(e) => this.onBalancingPeriodSelect(e)}
                    placeholder={translator.selectBalancing}
                    optionLabel='balancingPeriodDescription'
                    className="balancingPeriodDropdown"
                />
            </div>
        );
    }
}

export default BalancingPeriod;